import React, { useState, useContext, useEffect } from 'react'
import { Box, Text, Grid, Input, Select } from 'flexibull2'
import { Button } from 'flexibull2/build/button'
import { Checkbox } from 'flexibull2/build/checkbox'
import styled from 'styled-components'
import { Divider } from '../components/Styles/Divider'
import { initPayment, getFees } from '../requestModal/services'
import { useMain } from '../../context/main-context'
import { useAuth } from '../../context/auth-context'
import { extractRemitaUSerDetails, getYears } from '../../utils'
import { validate } from '../../utils/useValidation.js'
import FacultyContext from '../../context/Faculty/facultyContext'
import DepartmentContext from '../../context/Department/departmentContext'
import CourseContext from '../../context/Course/courseContext'
import { Notify } from 'flexibull2/build/notify'
import { ERROR_ALERT, TOP_RIGHT } from '../../utils/constants'
import { CountrySelect } from '../../utils/Countries'
import { NigerianState } from '../../utils/States'
import { MOBILE_NUMBER_REGEX } from '../../utils/constants'
import { useRegex } from '../../utils/useRegEx'
import { Alert } from './Payment'

const ModifiedBox = styled(Box)`
    font-size: 11px;
    .flexi-label-wrapper,
    label {
        font-size: 10px;
        font-weight: 300;
        text-transform: capitalize !important;
    }
    .flexi-select-label {
        font-size: 9px;
        text-transform: capitalize !important;
        /* font-weight: 300; */
    }
    input {
        ::placeholder {
            font-size: 10px;
        }
    }
`

const ErrorContainer = styled.div`
    font-size: 9px;
    color: red;
`

const InformationPayment = ({
    handlePrevious,
    requestType,
    handleNext,
    setCurrentStep,
}) => {
    const [loading, setLoading] = useState(false)
    const [error, setError] = React.useState({})
    // eslint-disable-next-line
    const [addressError, setAddressErrorError] = React.useState({})
    // eslint-disable-next-line
    const [stateRegion, setStateRegion] = useState(false)
    const { main, updateMain } = useMain()
    const { data } = useAuth()
    const user = extractRemitaUSerDetails(data)
    const [deliveryType, setDeliveryType] = useState(false)
    const [remitaInfo, setRemitaInfo] = useState({})
    const [feesInfo, setFeesInfo] = useState([])
    const [filteredCourses, setFilteredCourses] = useState([])
    const facultyContext = useContext(FacultyContext)
    const departmentContext = useContext(DepartmentContext)
    const courseContext = useContext(CourseContext)

    const { faculties, getFaculties } = facultyContext
    const { departments, getDepartments } = departmentContext
    const { courses, getCourses } = courseContext
    const [departmentFilter, setDepartmentFilter] = React.useState([])
    const [matricError, setMatricError] = React.useState(null)
    const [regexError, checkRegex] = useRegex(null)

    const [yearBefore, setYearBefore] = useState(null)
    const [yearAfter, setYearAfter] = useState(null)
    const [otherValues, setOtherValues] = useState(null)

    const [documentCharge, setDocumentCharge] = useState()

    let Dept = []

    const filterDepartment = (faculty) => {
        if (faculty) {
            let res =
                departments &&
                departments?.data.filter((dep) =>
                    dep.faculty.faculty_name
                        ? dep.faculty.faculty_name === faculty
                        : ''
                )
            setDepartmentFilter(res)
        }
    }

    if (departmentFilter.length > 0) {
        departmentFilter?.map((dep) =>
            Dept.push({
                label: dep.department_name,
                value: dep.id,
            })
        )
    }
    
    const filterCourses = (dep) => {
        if (dep) {
            const res =
                courses &&
                courses?.data.filter((course) =>
                    course?.department?.department_name
                        ? course?.department?.department_name === dep
                        : ''
                )
                setFilteredCourses(res)
        }
    }
    useEffect(() => {
        getFaculties(100, 1)
        getDepartments(100, 1)
        getCourses(100, 1)

        // eslint-disable-next-line
    }, [])
    useEffect(() => {
        getFees().then(({ data }) => {
            const deliveryFeeModified = data.delivery_fees.map((d) => {
                return { ...d, request_type: d.request_type.toUpperCase() }
            })
            const documentFeeModified = data.document_fees.map((d) => {
                return {
                    ...d,
                    fee_type: d.fee_type.toUpperCase(),
                }
            })

            const deliveryFee = deliveryFeeModified.filter((charge) => {
                return charge.request_type === requestType
            })

            const documentFee = documentFeeModified.filter((fee) => {
                return fee.fee_type === requestType
            })
            const temp = deliveryFee.length > 0 ? deliveryFee : { amount: 0 }

            const checkTemp = () => {
                if (deliveryFee.length > 0) return { ...temp[0] }
                return { ...temp }
            }

            const useDelivery = !deliveryType
                ? { ...deliveryFee[0], amount: 0 }
                : checkTemp()

            const fee = [useDelivery, ...documentFee]

            let total = fee
                .filter((element) => element?.amount !== null)
                .reduce((prev, cur) => prev + cur?.amount, 0)
            setDocumentCharge(documentFee.reduce((a, b) => a + b?.amount, 0))

            let result = {
                ...fee[0],
                amount: total,
                delivery_fee: fee && fee[0]?.amount,
            }
            setFeesInfo(result)
        })
    }, [deliveryType, requestType])

    const handleChange = (e, name) => {
        const remitaInfoCopy = { ...remitaInfo }
        filterDepartment(e?.label)
        filterCourses(e?.label)

        if (name === 'year') {
            setYearAfter(e.value)
        }
        if (e?.target?.name === 'phone_number') {
            checkRegex(e.target.name, e.target.value, MOBILE_NUMBER_REGEX)
        }

        if (name === 'admission_year') {
            if (e.value >= yearAfter) {
                setYearBefore(
                    'There is an error in the Year of Admission or Graduation selected, please rectify.'
                )
            } else {
                setYearBefore(null)
            }
        }

        if (e?.target?.name === 'matric_number') {
            if (e?.target?.value?.length <= 4) {
                setMatricError(
                    'Matric Number should be greater than four characters'
                )
            } else {
                setMatricError(null)
            }
        }
        if (name) {
            remitaInfoCopy[name] = e?.value
            setOtherValues({ ...otherValues, [name]: e?.label })
        } else {
            remitaInfoCopy[e?.target.name] = e?.target.value
        }

        setRemitaInfo(remitaInfoCopy)
    }
    const handleAddress = (key, value) => {
        if (key === 'country' && value === 'Nigeria') {
            setStateRegion(true)
        } else if (key === 'country' && value !== 'Nigeria') {
            setStateRegion(false)
        }
        const addressCopy = remitaInfo.address || {}
        const remitaInfoCopy = { ...remitaInfo }
        addressCopy[key] = value
        remitaInfoCopy['address'] = addressCopy
        setRemitaInfo(remitaInfoCopy)
    }

    const handleInitiate = async () => {
        if (
            matricError !== null ||
            yearBefore !== null ||
            regexError !== null
        ) {
            Notify(`Please check and validate your inputs.`, {
                position: TOP_RIGHT,
                status: ERROR_ALERT,
            })
        } else {
            setCurrentStep(1)
            const { errorObj, errorCount } = validate({
                first_name: '',
                last_name: '',
                matric_number: '',
                admission_year: '',
                year: '',
                faculty_id: '',
                department_id: '',
                course_id: '',
                phone_number: '',
                ...remitaInfo,
            })
            const {
                errorObj: addressErrorObj,
                // errorCount: addressErrorCount
            } = validate({
                country: '',
                street_address: '',
                town: '',
                postal_code: '',
                ...remitaInfo.address,
            })

            setError(errorObj)
            setAddressErrorError(addressErrorObj)
            if (errorCount < 1) {
                let feeObj = {}

                let localFee
                // let localFee, internationalFee

                if (!feesInfo.is_international) {
                    localFee = feesInfo
                }
                // if (feesInfo.is_international) {
                //     internationalFee = feesInfo
                // }

                if (!deliveryType) {
                    feeObj = {
                        fee: localFee?.amount,
                        delivery_charge: localFee?.delivery_fee,
                    }
                }
                // else {
                //     if (remitaInfo.address.country === 'Nigeria') {
                //         feeObj = {
                //             fee: localFee?.amount,
                //             delivery_charge: localFee?.delivery_fee,
                //         }
                //     } else {
                //         feeObj = {
                //             fee: internationalFee.amount,
                //             delivery_charge: internationalFee.delivery_fee,
                //         }
                //     }
                // }
                const remitaInfoCopy = {
                    status: 'Submitted',
                    ...remitaInfo,
                    ...feeObj,
                    request_type: requestType,
                }

                if (deliveryType) {
                    remitaInfoCopy.fee = documentCharge
                    remitaInfoCopy.delivery_charge = feesInfo.delivery_fee
                }
                if (!deliveryType) {
                    delete remitaInfoCopy.delivery_charge
                }
                const payerInfo = {
                    payer_name: `${user.first_name} ${user.last_name}`,
                    payer_phone: user.phone,
                    payer_email: user.email,
                    amount: feeObj.fee || feesInfo.amount,
                }

                remitaInfoCopy['payer_info'] = payerInfo
                setRemitaInfo(remitaInfoCopy)
                setLoading(true)
                try {
                    const initResponse = await initPayment(remitaInfoCopy)
                    const { data } = initResponse
                    updateMain({
                        ...main,
                        paymentInfo: data,
                    })
                    setLoading(false)
                    handleNext()
                } catch (err) {
                    setLoading(false)
                    Notify(`Something went wrong please try again.`, {
                        position: TOP_RIGHT,
                        status: ERROR_ALERT,
                    })
                }
            }
        }
        localStorage.setItem('user', JSON.stringify(remitaInfo))
        localStorage.setItem('otherValues', JSON.stringify(otherValues))
    }
    const yearsOptions = getYears()

    return (
        <div>
            <Box>
                <Text size="18px" block bold>
                    Request {requestType}
                </Text>
                <Box pad="10px 0">
                    <Text size="10px" block>
                        Fill out the form below to request a {requestType}
                    </Text>
                </Box>
                <Box pad="20px 0">
                    <Grid default="1fr 1fr 1fr">
                        <Box pad="0 30px 0 0" id="request-form">
                            <ModifiedBox pad="30px 0 0 0">
                                <Grid default="1fr 1fr">
                                    <Box pad="20px 0">
                                        <Input
                                            name="first_name"
                                            onChange={handleChange}
                                            type="text"
                                            placeholder="First Name"
                                            label="First Name"
                                            width="100%"
                                            error={error.first_name}
                                        />
                                    </Box>
                                    <Box pad="20px 0">
                                        <Input
                                            name="last_name"
                                            onChange={handleChange}
                                            type="text"
                                            placeholder="Last Name"
                                            label="Last Name"
                                            width="100%"
                                            error={error.last_name}
                                        />
                                    </Box>
                                </Grid>
                                <Grid default="1fr 1fr">
                                    <Box pad="20px 0">
                                        <Input
                                            name="matric_number"
                                            onChange={handleChange}
                                            type="text"
                                            placeholder="Matriculation Number"
                                            label="Matriculation Number"
                                            error={error.matric_number}
                                            width="100%"
                                        />
                                        {matricError !== null && (
                                            <ErrorContainer>
                                                {matricError}
                                            </ErrorContainer>
                                        )}
                                    </Box>
                                    <Box pad="20px 0">
                                        <Select
                                            label="Faculty"
                                            block
                                            onChange={(e) =>
                                                handleChange(e, 'faculty_id')
                                            }
                                            options={faculties?.data.map(
                                                (item) => ({
                                                    value: item.id,
                                                    label: item.faculty_name,
                                                })
                                            )}
                                            name="faculty"
                                            error={error.faculty_id}
                                            width="100%"
                                        />
                                    </Box>
                                </Grid>
                                <Grid default="1fr 1fr">
                                    <Box pad="20px 0">
                                        <Select
                                            block
                                            onChange={(e) =>
                                                handleChange(e, 'department_id')
                                            }
                                            label="Department"
                                            options={Dept}
                                            name="department"
                                            error={error.department_id}
                                        />
                                    </Box>
                                    <Box pad="20px 0">
                                        <Select
                                            block
                                            onChange={(e) =>
                                                handleChange(e, 'course_id')
                                            }
                                            label="Course"
                                            options={filteredCourses.map(
                                                (item) => ({
                                                    value: item.id,
                                                    label: item.name,
                                                })
                                            )}
                                            name="course"
                                            error={error.course_id}
                                        />
                                    </Box>
                                </Grid>
                                <Grid default="1fr 1fr">
                                    <Box pad="20px 0">
                                        <Select
                                            onChange={(e) =>
                                                handleChange(e, 'year')
                                            }
                                            label="Year of graduation"
                                            options={yearsOptions}
                                            width="100%"
                                            name="year"
                                            error={error.year}
                                            style={{
                                                width: '200px',
                                            }}
                                        />
                                    </Box>
                                    <Box pad="20px 0">
                                        <Select
                                            onChange={(e) =>
                                                handleChange(
                                                    e,
                                                    'admission_year'
                                                )
                                            }
                                            label="Year of admission"
                                            block
                                            options={yearsOptions}
                                            name="admission_year"
                                            error={error.admission_year}
                                            style={{
                                                width: '200px',
                                            }}
                                        />
                                        {yearBefore !== null && (
                                            <ErrorContainer>
                                                {yearBefore}
                                            </ErrorContainer>
                                        )}
                                    </Box>
                                </Grid>
                                <Grid default="1fr 1fr">
                                    <Box pad="20px 0">
                                        <Input
                                            name="delivery_email"
                                            onChange={handleChange}
                                            type="text"
                                            placeholder="Delivery Email"
                                            label="Delivery Email"
                                            error={error.delivery_email}
                                            block
                                        />
                                    </Box>
                                    <Box pad="20px 0">
                                        <Input
                                            name="phone_number"
                                            onChange={handleChange}
                                            placeholder="Enter Phone Number"
                                            label="Phone Number"
                                            type="text"
                                            block
                                            error={error.phone_number}
                                        />
                                        {regexError !== null && (
                                            <ErrorContainer>
                                                {regexError}
                                            </ErrorContainer>
                                        )}
                                    </Box>
                                </Grid>
                                <Divider></Divider>
                                <Alert
                                    background={true}
                                    color={'red'}
                                    marginBottom="0px"
                                >
                                    <Text size="12px" block bold>
                                        Note: By selecting this option, you are
                                        paying additional charges.
                                    </Text>
                                </Alert>

                                <Box
                                    pad="10px 0"
                                    style={{
                                        marginLeft: '-0.5rem',
                                    }}
                                >
                                    <Checkbox
                                        label="Physical Delivery"
                                        checked={deliveryType}
                                        onClick={() =>
                                            setDeliveryType(!deliveryType)
                                        }
                                    />
                                </Box>
                                {deliveryType ? (
                                    <div>
                                        <Grid default="2fr 1fr">
                                            <Box pad="20px 0">
                                                <CountrySelect
                                                    onChange={(e) =>
                                                        handleAddress(
                                                            'country',
                                                            e.value
                                                        )
                                                    }
                                                    error={addressError.country}
                                                />
                                            </Box>
                                            {stateRegion && (
                                                <Box pad="20px 0">
                                                    <NigerianState
                                                        onChange={(e) =>
                                                            handleAddress(
                                                                'state',
                                                                e.value
                                                            )
                                                        }
                                                        error={
                                                            addressError.state
                                                        }
                                                    />
                                                </Box>
                                            )}
                                        </Grid>
                                        <Grid default="1fr">
                                            <Box pad="20px 0">
                                                <Input
                                                    label="Street address"
                                                    block
                                                    placeholder="Street address"
                                                    onChange={(e) =>
                                                        handleAddress(
                                                            'street_address',
                                                            e.target.value
                                                        )
                                                    }
                                                    type="text"
                                                    error={
                                                        addressError.street_address
                                                    }
                                                />
                                            </Box>
                                            <Box pad="20px 0">
                                                <Input
                                                    label="Town / city"
                                                    block
                                                    placeholder="Town / city"
                                                    onChange={(e) =>
                                                        handleAddress(
                                                            'town',
                                                            e.target.value
                                                        )
                                                    }
                                                    error={addressError.town}
                                                    name="town"
                                                />
                                            </Box>
                                            <Box pad="20px 0">
                                                <Input
                                                    onChange={(e) =>
                                                        handleAddress(
                                                            'postal_code',
                                                            e.target.value
                                                        )
                                                    }
                                                    placeholder="Enter P.O. Box Number"
                                                    label="p.o. box number"
                                                    name="postal_code"
                                                    error={
                                                        addressError.postal_code
                                                    }
                                                    style={{
                                                        width: '150px',
                                                    }}
                                                />
                                            </Box>
                                        </Grid>
                                    </div>
                                ) : null}
                            </ModifiedBox>
                            <Box
                                style={{
                                    display: 'flex',
                                    flex: 1,
                                    // padding: "12px"
                                    // justifyContent: 'space-around',
                                    // marginLeft:"-4rem"
                                }}
                            >
                                <Box>
                                    <Box>
                                        <Button
                                            width={90}
                                            height={31}
                                            onClick={handlePrevious}
                                            pad="0 20px"
                                            pale
                                        >
                                            Previous
                                        </Button>
                                    </Box>
                                </Box>
                                <Box>
                                    <Button
                                        height={31}
                                        spaceRight
                                        spaceLeft
                                        onClick={handleInitiate}
                                        progress={loading}
                                        iconRight={
                                            <i className="flexibull-angle-right"></i>
                                        }
                                    >
                                        Proceed to checkout
                                    </Button>
                                </Box>
                            </Box>
                        </Box>
                    </Grid>
                </Box>
            </Box>
        </div>
    )
}

export default InformationPayment

import React, { useEffect, useContext } from 'react'
import { Box, Tabs, Grid, Text, Button, Input, Select } from 'flexibull2'
import { LightText } from '../../pages/components/Styles/LightText.style'
import { useAuth } from '../../context/auth-context'
import InstitutionContext from '../../context/institution/institutionContext'
import { FacultyDetails } from '../Customer/Details/FacultyDetails'
import { Theme } from '../config/theme'
import EditProfile from './Edit'
import Support from './Support'
import { Admin } from '../Customer/Details/Admin'
import AddAdmin from '../Customer/Details/AddAdmin'
import { DepartmentDetails } from '../Customer/Details/DepartmentDetails'
import { CourseDetails } from '../Customer/Details/CourseDetails'
import { getRoleId } from '../Customer/Details/services'

const Configurations = () => {
    const { org } = useAuth()
    const [open, setOpen] = React.useState(false)
    const [adminOpen, setAdminOpen] = React.useState(false)
    const [roleId, setRoleId] = React.useState('')

    const [supportOpen, setSupportOpen] = React.useState(false)

    const institutionContext = useContext(InstitutionContext)
    const loadingText = '..'

    const {
        current,
        onProgress,
        getInstitutionByName,
        loading,
        fetchPayment,
        payments,
        fetchSupportDetails,
        createSupportDetails,
    } = institutionContext

    useEffect(() => {
        getRoleId(org).then(({ data: { entities } }) => {
            const { id } = entities[0]
            setRoleId(id)
        })
        if (!open) {
            getInstitutionByName(org)
            fetchSupportDetails(org)
        }
        if (payments && payments) {
            fetchPayment(org)
        }
        // eslint-disable-next-line
    }, [open])
    return (
        <>
            <Box pad="46px">
                <Box pad="20px 50px">
                    <Grid default="1fr  3fr">
                        <Box
                            pad="5px"
                            style={{ marginTop: '-1em', marginRight: '-2rem' }}
                        >
                            <img
                                style={{ borderRadius: '50%' }}
                                src={current?.logo_url}
                                width="150px"
                                height="150px"
                                alt="user"
                            />
                        </Box>

                        <Box style={{ marginLeft: '-2rem' }} relative>
                            <Grid default="1fr 1fr 1fr 1fr">
                                <Box>
                                    <LightText bold>
                                        <Text size="15px"> Name</Text>
                                    </LightText>
                                    <Box margin="5px 0">
                                        <Text size="15px" bold>
                                            {loading
                                                ? loadingText
                                                : current?.full_name}
                                        </Text>
                                    </Box>
                                </Box>
                                <Box>
                                    <LightText bold>
                                        <Text size="15px"> Short Name</Text>
                                    </LightText>
                                    <Box margin="5px 0">
                                        <Text size="15px" bold>
                                            {loading
                                                ? loadingText
                                                : current?.short_name}
                                        </Text>
                                    </Box>
                                </Box>
                                <Box>
                                    <LightText bold>
                                        <Text size="15px"> Location</Text>
                                    </LightText>
                                    <Box margin="5px 0">
                                        <Text size="15px" bold>
                                            {loading
                                                ? loadingText
                                                : current?.location}
                                        </Text>
                                    </Box>
                                </Box>
                                <Box>
                                    <Box margin="5px 0">
                                        <Box></Box>
                                    </Box>
                                </Box>
                            </Grid>
                            <Box margin="20px 0">
                                <Grid default="1fr 3fr ">
                                    <Box>
                                        <LightText bold>
                                            <Text size="15px"> Email</Text>
                                        </LightText>
                                        <Box margin="5px 0">
                                            <Text size="15px" bold>
                                                {loading
                                                    ? loadingText
                                                    : current?.email_address}
                                            </Text>
                                        </Box>
                                    </Box>
                                    <Box>
                                        <LightText bold>
                                            <Text size="15px">
                                                {' '}
                                                Phone Number
                                            </Text>
                                        </LightText>
                                        <Box margin="5px 0">
                                            <Text size="15px" bold>
                                                {loading
                                                    ? loadingText
                                                    : current?.phone_number}
                                            </Text>
                                        </Box>
                                    </Box>
                                </Grid>
                            </Box>
                            <Box margin="20px 0">
                                <Grid default="1fr">
                                    <Box>
                                        <LightText bold>
                                            <Text size="15px">
                                                {' '}
                                                Physical Address
                                            </Text>
                                        </LightText>
                                        <Box margin="5px 0">
                                            <Text size="15px" bold>
                                                {loading
                                                    ? loadingText
                                                    : current?.physical_address}
                                            </Text>
                                        </Box>
                                    </Box>
                                </Grid>
                            </Box>
                        </Box>
                    </Grid>
                </Box>
            </Box>
            <Box pad="50px">
                <Button
                    pale
                    spaceRight
                    color={Theme.PrimaryBlue}
                    fontColor={Theme.PrimaryBlue}
                    iconRight={<i className="icon-pencil" />}
                    onClick={() => setOpen(true)}
                >
                    Edit
                </Button>
            </Box>
            <Box pad="50px">
                <Tabs bgColor="transparent">
                    <div label="Faculties">
                        <FacultyDetails />
                    </div>

                    <div label="Departments">
                        <DepartmentDetails />
                    </div>

                    <div label="Courses">
                        <CourseDetails />
                    </div>
                    <div label="Users">
                        <Admin setOpen={setAdminOpen} />
                    </div>
                    <div label="Timeframe">
                        <Box
                            background="#fff"
                            pad="50px"
                            margin="30px 0 0 0"
                            width="40vw"
                        >
                            <Text bold size="25px">
                                Transcript Processing Timeframe
                            </Text>
                            <Box margin="30px 0">
                                <Text size="14px">
                                    This is the maximum amount of time it should
                                    take to complete transcript request
                                </Text>
                            </Box>
                            <Grid default="1fr 1fr">
                                <Box margin="50px 0">
                                    <Input
                                        label="Quantity"
                                        name="Quantity"
                                        width="50%"
                                        type="number"
                                    />
                                </Box>
                                <Box
                                    margin="50px 0"
                                    style={{
                                        marginLeft: '-9em',
                                    }}
                                >
                                    <Select
                                        name="location"
                                        label="Options"
                                        spaceBottom
                                        options={[
                                            { label: 'Days', value: 'Days' },
                                            { label: 'Weeks', value: 'Weeks' },
                                        ]}
                                    />
                                </Box>
                            </Grid>
                            <Box>
                                <Button
                                    width={120}
                                    height={46}
                                    progress={onProgress}
                                    disabled={onProgress}
                                    iconRight={
                                        <i className="flexibull-angle-right" />
                                    }
                                >
                                    {onProgress ? 'Updating' : 'Save'}
                                </Button>
                            </Box>
                        </Box>
                    </div>
                </Tabs>
            </Box>

            <EditProfile
                open={open}
                onClose={() => setOpen(false)}
                data={current}
            />
            <Support
                org={org}
                createSupportDetails={createSupportDetails}
                open={supportOpen}
                onClose={() => setSupportOpen(false)}
            />
            <AddAdmin
                open={adminOpen}
                setOpen={() => setAdminOpen(false)}
                roleId={roleId}
                setRoleId={setRoleId}
            />
        </>
    )
}

export default Configurations

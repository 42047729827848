import React from 'react';
import styled from 'styled-components';
import { Theme } from '../../config/theme';


const Cell = styled.div`
    width: 100%;
    height: 254px;
    background-color: ${(props) => props.bgColor ? props.bgColor: Theme.PrimaryColor};
    /* background-color: ${Theme.PrimaryColor}; */
    padding: 30px;
    position: relative;
    overflow: hidden;
    z-index: -1;

    &::before {
        content: '';
        width: 150%;
        height: 150%;
        background-color: rgba(255, 255, 255, .1);
        position: absolute;
        top: -300px;
        left: -60px;
        transform: rotate(-12deg);
    }
    &::after {
        content: '';
        width: 150%;
        height: 150%;
        background-color: rgba(255, 255, 255, .1);
        position: absolute;
        top: 60px;
        left: -40px;
        transform: rotate(-3deg);
    }
`

const BannerCard = (props) => {
    return (
        <Cell {...props}>
            {/* <Line transform="rotate(35deg)"/>
            <Line transform="rotate(58deg)"/>
            <Line transform="rotate(73deg)"/> */}
            {props.children}
        </Cell>
    )
}

export default BannerCard;
import React, { useState } from 'react'
import {
    Modal,
    ModalBody,
    ModalFooter,
    Button,
    Box,
    Text,
    Select,
    TextArea,
} from 'flexibull2'

import { getTranscriptUrl } from '../RequestDetails/services'
import { Theme } from '../config/theme'
import { lighten } from 'polished'

const StatusModal = ({ open, toggleModal, handleTranscriptStatus, id }) => {
    const [value, setValue] = useState('')
    const [error, setError] = useState(false)
    function submit() {
        if (value === 'Transcript Ready') {
            getTranscriptUrl(id)
                .then(() => {
                    handleTranscriptStatus(value)
                })
                .catch((e) => {
                    setError(true)
                })
        } else { 
            handleTranscriptStatus(value)
        }
    }
    return (
        <Modal outerClick open={open}>
            <ModalBody width="450px">
                <Box pad="25px">
                    <Text size="22px" block>
                        Change Request Status
                    </Text>
                </Box>
                <Box pad="25px">
                    {error && (
                        <Box
                            pad="10px 12px"
                            display="flex"
                            style={{
                                backgroundColor: lighten(
                                    0.4,
                                    Theme.PrimaryOrange
                                ),
                                borderRadius: '5px',
                                border: `1px solid ${Theme.PrimaryOrange}`,
                            }}
                        >
                            <Text
                                color={Theme.PrimaryOrange}
                                style={{ marginRight: '5px' }}
                            >
                                <i className="icon-info" />
                            </Text>
                            <Text
                                pad="10px"
                                size="14px"
                                block
                                color={Theme.PrimaryOrange}
                            >
                                You need to upload a document before you can
                                set the status to ready
                            </Text>
                        </Box>
                    )}
                </Box>
                <Box pad="25px">
                    <Select
                        label="Status"
                        options={[
                            { label: 'Processing', value: 'Processing' },
                            { label: 'Ready', value: 'Transcript Ready' },
                            { label: 'Issued', value: 'Issued' },
                            {
                                label: 'Request Declined',
                                value: 'Request Declined',
                            },
                        ]}
                        style={{ width: '100%' }}
                        onChange={(val) => setValue(val.value)}
                    />
                </Box>

                <Box pad="25px">
                    {value === 'Request Declined' && (
                        <>
                            <Text pad="10px" size="14px" block>
                                Reason
                            </Text>
                            <TextArea
                                height="150px"
                                placeholder="Enter reason for declining"
                                style={{ width: '100%' }}
                            ></TextArea>
                        </>
                    )}
                </Box>
                <ModalFooter>
                    <Box pad="20px" align="right">
                        <Button
                            spaceRight
                            color="transparent"
                            fontColor="rgba(0,0,0,0.8)"
                            onClick={() => toggleModal(false)}
                        >
                            Cancel
                        </Button>
                        <Button onClick={() => submit()}>Save</Button>
                    </Box>
                </ModalFooter>
            </ModalBody>
        </Modal>
    )
}

export default StatusModal

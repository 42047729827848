import * as React from 'react'
import PropTypes from 'prop-types'
import { Box, Button, Text, Grid, Input, Select, Checkbox } from 'flexibull2'
import FieldSets from './FieldSets'
import { Theme } from '../../config/theme'
import bankOptions from '../banks'
import usePaymentConfigurationHook from './payment_configuration.hooks'

const Buttons = ({
    isDisable,
    handleUpdateBeneficiary,
    tempId,
    handleSaveUpdatedBeneficiary,
    handleDeleteBeneficiary,
}) => (
    <Box
        align="right"
        style={{
            marginTop: '-3.5rem',
            textAling: 'center',
            marginRight: '6.5rem',
        }}
    >
        {isDisable === true ? (
            <Button
                color={Theme.PrimaryGreen}
                onClick={() =>
                    handleUpdateBeneficiary({
                        id: tempId,
                        isDisable: false,
                    })
                }
                style={{
                    marginRight: '1rem',
                }}
            >
                Edit
            </Button>
        ) : (
            <Button
                color={Theme.PrimaryGreen}
                onClick={() => handleSaveUpdatedBeneficiary(tempId)}
                style={{
                    marginRight: '1rem',
                }}
            >
                Save
            </Button>
        )}

        <Button
            round
            color={Theme.PrimaryRed}
            onClick={() => handleDeleteBeneficiary(tempId)}
        >
            X
        </Button>
    </Box>
)

const Service = ({
    beneficiaryType,
}) => {
    const {
        handleDeleteBeneficiary,
        handleAddBeneficiary,
        handleUpdateBeneficiaryCopyOnChange,
        handleUpdateBeneficiary,
        handleSaveUpdatedBeneficiary,
        inputs,
        singleBeneficiary,
        setSingleBeneficiary,
        beneficiariesCpoy,
        setInputs,
    } = usePaymentConfigurationHook(
        beneficiaryType
    )
 
    return (
        <Box
            style={{
                position: 'relative',
            }}
        >
            <Box
                pad="30px 0 0"
                style={{
                    position: 'relative',
                }}
            >
                <Box pad="6px 0">
                    <Box height="10px" style={{ marginBottom: '50px' }}>
                        <Text block bold>
                            Split Configurations
                        </Text>
                    </Box>
                    <Box
                        style={{
                            position: 'absolute',
                            top: '5.6rem',
                            right: '13rem',
                        }}
                    >
                        <Text size="0.9em" bold>
                            DEDUCTIBLE
                        </Text>
                    </Box>

                    {beneficiariesCpoy.length > 0 &&
                        beneficiariesCpoy
                            .filter(
                                ({ beneficiary_type }) =>
                                    beneficiary_type === beneficiaryType
                            )
                            .map(
                                ({
                                    accountName,
                                    bankCode,
                                    accountNumber,
                                    deduct_from,
                                    tempId,
                                    amount,
                                    isDisable,
                                }) => {
                                    return (
                                        <div key={tempId}>
                                            <Box margin="15px 0">
                                                <Grid
                                                    default="1fr 1fr 1fr 1fr 1fr 1fr"
                                                    gap="20px"
                                                >
                                                    <Input
                                                        type="text"
                                                        name="accountName"
                                                        label="Account Name"
                                                        spaceTop
                                                        width="170px"
                                                        value={
                                                            isDisable === true
                                                                ? accountName
                                                                : inputs.accountName
                                                        }
                                                        disabled={isDisable}
                                                        onChange={
                                                            handleUpdateBeneficiaryCopyOnChange
                                                        }
                                                    />
                                                    <Select
                                                        spaceRight
                                                        label="Bank"
                                                        name="defaultAccount"
                                                        block
                                                        spaceTop
                                                        required
                                                        options={bankOptions}
                                                        value={
                                                            isDisable === true
                                                                ? bankOptions.filter(
                                                                      ({
                                                                          value,
                                                                      }) =>
                                                                          value ===
                                                                          bankCode
                                                                  )[0]
                                                                : bankOptions.filter(
                                                                      ({
                                                                          value,
                                                                      }) =>
                                                                          value ===
                                                                          inputs.bankCode
                                                                  )[0]
                                                        }
                                                        disable={isDisable}
                                                        onChange={
                                                            handleUpdateBeneficiaryCopyOnChange
                                                        }
                                                    />
                                                    <Input
                                                        type="number"
                                                        name="accountNumber"
                                                        label="Account Number"
                                                        spaceTop
                                                        width="170px"
                                                        value={
                                                            isDisable === true
                                                                ? accountNumber
                                                                : inputs.accountNumber
                                                        }
                                                        disabled={isDisable}
                                                        onChange={
                                                            handleUpdateBeneficiaryCopyOnChange
                                                        }
                                                    />
                                                    <Input
                                                        type="number"
                                                        name="amount"
                                                        block
                                                        label="Amount"
                                                        spaceTop
                                                        value={
                                                            isDisable === true
                                                                ? amount
                                                                : inputs.amount
                                                        }
                                                        disabled={isDisable}
                                                        onChange={
                                                            handleUpdateBeneficiaryCopyOnChange
                                                        }
                                                    />
                                                    <Box
                                                        style={{
                                                            marginLeft: '2rem',
                                                            marginTop: '1.5rem',
                                                        }}
                                                    >
                                                        <Checkbox
                                                            disabled={isDisable}
                                                            onChange={(e) => {
                                                             isDisable === true
                                                                 ? setSingleBeneficiary(
                                                                       {
                                                                           ...singleBeneficiary,
                                                                           deduct_from:
                                                                               !deduct_from,
                                                                       }
                                                                   )
                                                                 : setInputs(
                                                                       {
                                                                           ...inputs,
                                                                           deduct_from:
                                                                               !inputs.deduct_from,
                                                                       }
                                                                   )
                                                            }}
                                                            checked={
                                                                isDisable === true ? deduct_from : inputs.deduct_from
                                                            }
                                                        />
                                                    </Box>
                                                </Grid>
                                            </Box>
                                            <Buttons
                                                {...{
                                                    isDisable,
                                                    handleUpdateBeneficiary,
                                                    tempId,
                                                    handleSaveUpdatedBeneficiary,
                                                    handleDeleteBeneficiary,
                                                }}
                                            />
                                        </div>
                                    )
                                }
                            )}
                    <FieldSets />
                    <Box
                        style={{
                            position: 'absolute',
                            bottom: '1.5rem',
                            right: '8em',
                        }}
                    >
                        <Button
                            onClick={handleAddBeneficiary}
                            block
                            spaceRight
                            iconLeft={<i className="flexibull-plus" />}
                            color={Theme.PrimaryBlue}
                        >
                            Add
                        </Button>
                    </Box>
                </Box>
            </Box>
        </Box>
    )
}

Service.propTypes = {
    setPayments: PropTypes.func.isRequired,
    beneficiary_type: PropTypes.string.isRequired,
}

export default Service

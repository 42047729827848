import React, { useContext, useEffect, useState } from 'react'
import { Box, Grid } from 'flexibull2/build/layout'
import { Table } from 'flexibull2/build/table'
import AddService from './AddService'
import { Button } from 'flexibull2/build/button'
import { Loader } from 'flexibull2/build/loader'
import { Theme } from '../../config/theme'
import { DropDown } from 'flexibull2/build/dropdown'
import EditService from './EditService'
import DialogBox from '../../components/dialogbox/DialogBox'
import { LightText } from '../../components/Styles/LightText.style'
import { Text } from 'flexibull2/build/typo'
import InstitutionContext from '../../../context/institution/institutionContext'

const Service = ({ orgId, open, setOpen }) => {
    const [edit, setEdit] = useState(false)
    const [openDialog, setOpenDialog] = useState(false)
    const institutionContext = useContext(InstitutionContext)

    const { current, loading, setCurrent, getServices, updateService, deleteService, services } = institutionContext

    useEffect(() => {
        getServices(orgId)
        // eslint-disable-next-line
    }, [orgId])

    if (loading) {
        return (
            <Box vAlign height={`calc(100vh - 200px)`}>
                <Box margin="0 auto" align="center">
                    <Loader color={Theme.PrimaryColor} />
                </Box>
            </Box>
        )
    };

    const onEdit = (service) => {
        setEdit(true)
        setCurrent(service)
    }


    const onDelete = (id) => {
        setOpenDialog(true)
        setCurrent(id)
    }

    const handleOnConfirm = async () => {
        deleteService(current, orgId)
        setOpenDialog(false)
    }


    return (
        <>
            <div>
                <Grid default="1fr">

                    <Box pad="20px 0" align="right">
                        <Button
                            width={140}
                            pad="0 1px"
                            onClick={() => setOpen(true)}
                            spaceLeft
                        >
                            Add Service
                        </Button>
                    </Box>
                </Grid>
                <Box margin="5px 0 5px 0" align="left" >
                    <Table style={{ paddingTop: '10px' }}>
                        <table>
                            <thead>
                                <tr>
                                    <th>Service Type</th>
                                    <th>Service ID</th>
                                    <th>Merchant ID</th>
                                    <th>Action</th>
                                </tr>
                            </thead>
                            <tbody>

                                {services?.map(service => (
                                    <tr key={service.service_type_id}>
                                        <td>{service.service_type}</td>
                                        <td>{service.service_type_id}</td>
                                        <td>{service.merchant_id}</td>
                                        <td>
                                            <DropDown
                                                label={
                                                    <Button
                                                        pale
                                                        height={20}
                                                        style={{
                                                            border: 'none',
                                                        }}
                                                    >
                                                        <i className="icon-dot-3" />
                                                    </Button>
                                                }
                                                trigger="hover"
                                                menuAlign="top right"
                                                menuList={[
                                                    {
                                                        onClick: () =>
                                                            onEdit(service),
                                                        label: 'Edit Service',
                                                        iconLeft:
                                                            'icon-edit tblue',
                                                    },
                                                    {
                                                        onClick: () =>
                                                            onDelete(
                                                                service.service_type_id
                                                            ),
                                                        label: 'Delete Service',
                                                        iconLeft:
                                                            'icon-trash tred',
                                                    },
                                                ]}
                                                style={{
                                                    cursor: 'pointer',
                                                }}
                                            />
                                        </td>
                                    </tr>
                                ))}

                            </tbody>
                        </table>
                    </Table>
                </Box>
                <AddService
                    open={open}
                    orgId={orgId}
                    getServices={getServices}
                    loading={loading}
                    setOpen={setOpen}
                />
                <EditService
                    services={services}
                    open={edit}
                    orgId={orgId}
                    current={current}
                    setEdit={setEdit}
                    setOpen={setOpen}
                    updateService={updateService}
                />
                <DialogBox
                    visible={openDialog}
                    okType="danger"
                    okText="Confirm"
                    onConfirm={handleOnConfirm}
                    onCancel={() => setOpenDialog(false)}
                >
                    {' '}
                    <LightText>
                        <Text size="14px">
                            Are you sure you want to delete this Servive.
                            <br /> this action cannot be undone.
                        </Text>
                    </LightText>{' '}
                </DialogBox>
            </div>
        </>
    )
};

export default Service;

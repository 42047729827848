import React, { useEffect, useState, useContext } from 'react'
import { useHistory } from 'react-router-dom'
import Joyride, { ACTIONS, EVENTS } from 'react-joyride'
import { Theme } from '../config/theme'
import TagComp from './TagComp'
import styled from 'styled-components'
import { getServices } from './services'
import { useAuth } from '../../context/auth-context'
import CourseContext from '../../context/Course/courseContext'
import DepartmentContext from '../../context/Department/departmentContext'
import FacultyContext from '../../context/Faculty/facultyContext'
import {
    ListContainer,
    ListEntry,
    ListHeader,
    ListBody,
    List,
} from '../components/ListContainer/ListContainer'
import { RequestWizard } from './RequestWizard'
import {
    Box,
    Table,
    Button,
    Loader,
    FlexiPagination,
    Select,
    Input,
    Text,
    Spacer,
    EmptyState

} from 'flexibull2'
import {
    getYears,
    getFacultyFromId,
    getDepartmentFromId,
    getCourseFromId,
} from '../../utils'
import { ENDPOINT } from '../../utils/httpClient'
import usePagination from '../../utils/usePagination'
import useDebounce from '../../utils/useDebounce'
import { capitalize } from '../../utils/Capitalize'
import { Tag } from 'flexibull2/build/tags'
import { Datepicker } from 'flexibull2/build/datepicker'
import { FilterBox } from '../instituteDashboard'
import TourContent from '../components/TourContent'

const ResponsiveTable = styled(Table)`
    @media (max-width: 560px) {
        & table {
            display: none;
        }
    }
`

const ResponsiveBox = styled(Box)`
    @media (max-width: 560px) {
        /* display: none; */
        padding: 0;
    }
`
const ModifiedBox = styled(Box)`
    @media (max-width: 560px) {
        display: flex;
        padding: 0;
        flex-direction: column;
        flex: 1;
        margin: 10px;
    }
    margin-right: 10px;
`

const Requests = () => {
    const history = useHistory()
    const { org, isAdmin } = useAuth()
    const courseContext = useContext(CourseContext)
    const departmentContext = useContext(DepartmentContext)
    const facultyContext = useContext(FacultyContext)
    const { courses, getCourses } = courseContext
    const { departments, getDepartments } = departmentContext
    const { faculties, getFaculties } = facultyContext
    const [matric, setMatric] = useState('')
    const [requestModalOpen, toggleRequestModal] = useState(false)
    const [services, setServices] = useState([])
    const [sort, setSort] = useState(null)
    const [startDate, setStartDate] = useState('')
    const [endDate, setEndDate] = useState('')
    const [disable, setDisable] = React.useState(true)
    const [currentStep, setCurrentStep] = useState(0)
    const [state, setState] = useState({
        run: false,
        tourStarted: false,
        steps: [
            {
                content: (
                    <TourContent
                        heading="Welcome, let's take a quick tour"
                        content={
                            'I will guide you on how to navigate safcert and perform your request'
                        }
                    />
                ),
                locale: {
                    skip: <strong>SKIP</strong>,
                    next: 'Take Tour',
                },
                placement: 'center',
                target: 'body',
            },
            {
                target: '.request-button',
                content: (
                    <TourContent
                        heading={'Request Button'}
                        content={'This is where you start your request process'}
                    />
                ),
                locale: { skip: <strong>SKIP</strong>, next: 'NEXT' },
            },
            {
                target: '#certificate',
                content: (
                    <TourContent
                        heading={'Select Service Type'}
                        content={
                            'Select what service you would like to request'
                        }
                    />
                ),
                locale: { skip: <strong>SKIP</strong>, next: 'NEXT' },
            },
            {
                target: '#request-form',
                content: (
                    <TourContent
                        heading={'Fill Request Form'}
                        content={'Complete this form and proceed to checkout'}
                    />
                ),
                locale: { skip: <strong>SKIP</strong>, next: 'NEXT' },
            },
            {
                target: '#payment-button',
                content: (
                    <TourContent
                        heading={'Confirm Request'}
                        content={
                            'Confirm the summary of your request and proceed to pay'
                        }
                    />
                ),
                locale: { last: 'DONE' },
                showSkipButton: false,
                showProgress: false,
            },
        ],
        stepIndex: 0,
    })

    const handleJoyrideCallback = (data) => {
        const { action, index, type, status } = data

        if (type === EVENTS.STEP_AFTER && index === 0) {
            setState({ ...state, stepIndex: index + 1 })
        } else if (
            type === EVENTS.STEP_AFTER &&
            index === 1 &&
            action === ACTIONS.NEXT
        ) {
            toggleRequestModal(true)
            setState({ ...state, stepIndex: index + 1 })
        } else if (
            type === EVENTS.STEP_AFTER &&
            index === 1 &&
            action === ACTIONS.PREV
        ) {
            toggleRequestModal(false)
            setState({ ...state, stepIndex: index + -1 })
        } else if (
            type === EVENTS.STEP_AFTER &&
            index === 2 &&
            action === ACTIONS.NEXT
        ) {
            setCurrentStep(1)
            setState({ ...state, stepIndex: index + 1 })
        } else if (
            type === EVENTS.STEP_AFTER &&
            index === 2 &&
            action === ACTIONS.PREV
        ) {
            toggleRequestModal(false)
            setState({ ...state, stepIndex: index + -1 })
        } else if (
            type === EVENTS.STEP_AFTER &&
            index === 3 &&
            action === ACTIONS.NEXT
        ) {
            setCurrentStep(2)
            setState({ ...state, stepIndex: index + 1 })
        } else if (
            type === EVENTS.STEP_AFTER &&
            index === 3 &&
            action === ACTIONS.PREV
        ) {
            setCurrentStep(0)
            setState({ ...state, stepIndex: index + -1 })
        } else if (
            type === EVENTS.STEP_AFTER &&
            index === 4 &&
            action === ACTIONS.PREV
        ) {
            setCurrentStep(1)
            setState({ ...state, stepIndex: index + -1 })
        } else if (
            type === EVENTS.STEP_AFTER &&
            index === 4 &&
            action === ACTIONS.NEXT
        ) {
            toggleRequestModal(false)
            setState({ ...state, run: false })
        } else if (action === ACTIONS.SKIP) {
            toggleRequestModal(false)
            setState({ ...state, run: false })
        } else if (status !== 'running') {
            setState({ ...state, run: false })
        }
    }

    const startTour = () => {
        setState({ ...state, run: true, tourStarted: true })
    }

    const debouncedMatric = useDebounce(matric, 1000)
    const {
        data: requestData,
        options,
        setOptions,
        pageSizes,
        loading,
    } = usePagination(`${ENDPOINT}/requests`, 'all')
    const [adminfilter, setAdminFilters] = useState(false)
    const [servicesFilter, setServicesFilter] = useState(null)
    useEffect(() => {
        getServices(org).then(({ data }) => {
            if (data) {
                const res = data.map((dt) => {
                    return {
                        label: dt.service_type
                            .toLowerCase()
                            .replace(/\b\w/g, (l) => l.toUpperCase()),
                        value: dt.service_type,
                    }
                })
                setServicesFilter(res)
            }
        })
    }, [org])

    useEffect(() => {
        getCourses(100, 1)
        getDepartments(100, 1)
        getFaculties(100, 1)
        getServices(org).then(({ data }) => {
            const dataModified = data.map((d) => d.service_type.toUpperCase())
            setServices(dataModified)
        })
        // eslint-disable-next-line
    }, [])
    useEffect(() => {
        if (debouncedMatric) {
            setOptions({
                ...options,
                matric_no: debouncedMatric,
            })
        } else {
            setOptions({
                size: 10,
                page: 0,
                request_type: 'All',
            })
        }
        //eslint-disable-next-line
    }, [debouncedMatric])

    useEffect(() => {
        if (startDate !== '' && endDate !== '') {
            setDisable(false)
        }
    }, [disable, endDate, startDate])

    const handlePagination = (page) => {
        setOptions({
            ...options,
            page: page - 1,
        })
    }

    const handleFilterChange = (name, option) => {
        setOptions({
            ...options,
            page: 0,
            [name]: option.value,
        })
    }

    const handlePageSize = (pageSize) => {
        setOptions({
            ...options,
            size: pageSize.value,
        })
    }

    const yearsOptions = getYears()
    if (loading) {
        return (
            <Box vAlign height={`calc(100vh - 200px)`}>
                <Box margin="0 auto" align="center">
                    <Loader color={Theme.PrimaryColor} />
                </Box>
            </Box>
        )
    }

    const filterDate = async () => {
        let startDay = startDate?.getDate()
        let startMonth = startDate?.getMonth()
        let startYear = startDate?.getFullYear()

        function padTo2Digits(num) {
            return num.toString().padStart(2, '0')
        }

        let endDay = endDate?.getDate()
        let endMonth = endDate?.getMonth()
        let endYear = endDate?.getFullYear()

        const day = padTo2Digits(startDay)
        const mon = padTo2Digits(startMonth + 1)
        const dayend = padTo2Digits(endDay)
        const monend = padTo2Digits(endMonth + 1)

        setOptions({
            ...options,
            page: 0,
            start_date: `${day}-${mon}-${startYear}`,
            end_date: `${dayend}-${monend}-${endYear}`,
        })
    }

    function handleMatricNumberChange(e) {
        let val = e.target.value
        setMatric(val)
    }

    const handleSort = (name, option) => {
        setSort(option?.value)

        setOptions({
            ...options,

            page: 0,

            [name]: option?.value,
        })
    }
    return (
        <>
            <Joyride
                continuous={true}
                callback={handleJoyrideCallback}
                run={state.run}
                steps={state.steps}
                hideCloseButton
                hideBackButton={false}
                scrollToFirstStep
                showSkipButton={true}
                showProgress={true}
                stepIndex={state.stepIndex}
                styles={{
                    options: {
                        width: 450,
                        zIndex: 1000,
                        arrowColor: '#fff',
                        backgroundColor: '#fff',
                        primaryColor: ' #2CCCE4',
                    },
                    buttonNext: {
                        outline: 'none',
                        boxShadow: 'none',
                    },
                }}
            />
            <ResponsiveBox margin="20px 0 0 0" pad="0px 30px">
                {/* <BannerCard /> */}

                <Box
                    style={{
                        display: 'flex',
                        flex: '1',
                    }}
                >
                    <ModifiedBox align="left">
                        <Input
                            onChange={handleMatricNumberChange}
                            type="text"
                            placeholder="Enter Matric Number"
                            width="400px"
                            value={matric}
                        />
                    </ModifiedBox>
                    <ModifiedBox>
                        <Select
                            placeholder="Request Type"
                            onChange={(e) =>
                                handleFilterChange('request_type', e)
                            }
                            value={
                                options.request_type
                                    ? {
                                          label: options.request_type,
                                          value: options.request_type,
                                      }
                                    : null
                            }
                            options={servicesFilter}
                        />
                    </ModifiedBox>
                    <ModifiedBox>
                        <Select
                            placeholder="Sort By"
                            onChange={(e) => handleSort('sort_direction', e)}
                            value={sort}
                            options={[
                                {
                                    label: 'Ascending',
                                    value: 'asc',
                                },
                                {
                                    label: 'Descending',
                                    value: 'desc',
                                },
                            ]}
                        />
                    </ModifiedBox>

                    <ModifiedBox>
                        {isAdmin && (
                            <>
                                <Button
                                    pale
                                    color={Theme.PrimaryBlue}
                                    fontColor={Theme.PrimaryBlue}
                                    iconRight={<i className="icon-sliders" />}
                                    onClick={() =>
                                        setAdminFilters(!adminfilter)
                                    }
                                >
                                    Filter
                                </Button>
                                <Button
                                    pale
                                    style={{ marginLeft: 5 }}
                                    color={Theme.PrimaryRed}
                                    fontColor={Theme.PrimaryRed}
                                    iconRight={
                                        <i className="icon-user-delete-outline" />
                                    }
                                    onClick={() => {
                                        setMatric('')
                                        setOptions({
                                            size: 10,
                                            page: 0,
                                            request_type: 'Transcript',
                                        })
                                        setAdminFilters(false)
                                    }}
                                >
                                    Clear
                                </Button>
                            </>
                        )}
                    </ModifiedBox>

                    {!isAdmin && (
                        <>
                            <ModifiedBox>
                                <Button
                                    iconRight={
                                        <i className="icon-doc-text-1" />
                                    }
                                    height={35}
                                    spaceRight
                                    onClick={() => toggleRequestModal(true)}
                                    id="make"
                                    className="request-button"
                                >
                                    Make a request
                                </Button>
                            </ModifiedBox>
                            <Button
                                color={Theme.DefaultColor}
                                disabled={state.tourStarted}
                                onClick={startTour}
                            >
                                Take a Tour
                            </Button>
                        </>
                    )}
                </Box>

                {adminfilter ? (
                    <FilterBox style={{ marginTop: 25 }}>
                        <Box>
                            <Select
                                placeholder="Graduation Year"
                                options={yearsOptions}
                                onChange={(e) => handleFilterChange('year', e)}
                                value={
                                    options.year
                                        ? {
                                              label: options.year,
                                              value: options.year,
                                          }
                                        : null
                                }
                            />
                        </Box>
                        <Box
                            style={{
                                marginLeft: '5px',
                            }}
                        >
                            <Select
                                placeholder="Faculty"
                                options={faculties?.data.map((i) => ({
                                    label: i.faculty_name,
                                    value: i.id,
                                }))}
                                onChange={(e) =>
                                    handleFilterChange('faculty', e)
                                }
                                value={
                                    options.faculty
                                        ? {
                                              label: getFacultyFromId(
                                                  faculties.data,
                                                  options.faculty
                                              ),
                                              value: options.faculty,
                                          }
                                        : null
                                }
                            />
                        </Box>
                        <Box
                            style={{
                                marginLeft: '5px',
                            }}
                        >
                            <Select
                                placeholder="Department"
                                options={departments?.data.map((i) => ({
                                    label: i.department_name,
                                    value: i.id,
                                }))}
                                onChange={(e) =>
                                    handleFilterChange('department', e)
                                }
                                value={
                                    options.department
                                        ? {
                                              label: getDepartmentFromId(
                                                  departments.data,
                                                  options.department
                                              ),
                                              value: options.department,
                                          }
                                        : null
                                }
                            />
                        </Box>
                        <Box
                            style={{
                                marginLeft: '5px',
                            }}
                        >
                            <Select
                                placeholder="Course"
                                options={courses?.data.map((i) => ({
                                    label: i.name,
                                    value: i.id,
                                }))}
                                onChange={(e) =>
                                    handleFilterChange('course', e)
                                }
                                value={
                                    options.course
                                        ? {
                                              label: getCourseFromId(
                                                  courses.data,
                                                  options.course
                                              ),
                                              value: options.course,
                                          }
                                        : null
                                }
                            />
                        </Box>

                        <Box
                            style={{
                                marginLeft: '5px',
                            }}
                        >
                            <Datepicker
                                label="Start Date"
                                onChange={(val) => setStartDate(val)}
                                value={startDate}
                            />
                        </Box>
                        <Box
                            style={{
                                marginLeft: '5px',
                            }}
                        >
                            <Datepicker
                                label="End Date"
                                onChange={(val) => setEndDate(val)}
                                value={endDate}
                            />
                        </Box>

                        <Box
                            style={{
                                marginLeft: '15px',
                            }}
                        >
                            <Button
                                style={{
                                    padding: '20px 30px',
                                }}
                                disabled={disable}
                                onClick={filterDate}
                            >
                                {' '}
                                Filter
                            </Button>
                        </Box>
                    </FilterBox>
                ) : null}
                {requestData.data.length > 0 ? (
                    <>
                        <ResponsiveTable
                            style={
                                adminfilter
                                    ? { marginTop: '20em' }
                                    : { marginTop: '2em' }
                            }
                        >
                            <table>
                                <thead>
                                    <tr>
                                        <th>Applicant Name</th>
                                        <th>Matriculation Number</th>
                                        <th>Year Of Graduation</th>
                                        <th>Faculty</th>
                                        <th>Department</th>
                                        <th>Course</th>
                                        <th>Type</th>
                                        <th>Status</th>
                                        <th>Action</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {requestData.data &&
                                        requestData.data.map((request) => {
                                            return (
                                                <tr key={request.id}>
                                                    <td>
                                                        {capitalize([
                                                            request.first_name,
                                                            ...request.first_name,
                                                        ])}{' '}
                                                        {capitalize([
                                                            request.last_name,
                                                            ...request.last_name,
                                                        ])}
                                                    </td>
                                                    <td>
                                                        {request.matric_number.toUpperCase()}
                                                    </td>
                                                    <td>{request.year}</td>
                                                    <td>
                                                        {
                                                            request?.course
                                                                ?.department
                                                                ?.faculty
                                                                ?.faculty_name
                                                        }
                                                    </td>
                                                    <td>
                                                        {
                                                            request.course
                                                                .department
                                                                .department_name
                                                        }
                                                    </td>
                                                    <td>
                                                        {request.course.name}
                                                    </td>
                                                    <td>
                                                        <Tag>
                                                            {
                                                                request.request_type
                                                            }
                                                        </Tag>
                                                    </td>
                                                    <td>
                                                        <TagComp
                                                            status={
                                                                request.status
                                                            }
                                                        />
                                                    </td>

                                                    <td>
                                                        {!isAdmin ? (
                                                            <Button
                                                                color={
                                                                    Theme.PrimaryBlue
                                                                }
                                                                style={{
                                                                    color: Theme.PrimaryBlue,
                                                                }}
                                                                pale
                                                                onClick={() =>
                                                                    history.push(
                                                                        `/${org}/track/${request.id}`
                                                                    )
                                                                }
                                                            >
                                                                Track
                                                            </Button>
                                                        ) : (
                                                            <Button
                                                                color={
                                                                    Theme.PrimaryBlue
                                                                }
                                                                style={{
                                                                    color: Theme.PrimaryBlue,
                                                                }}
                                                                pale
                                                                onClick={() =>
                                                                    history.push(
                                                                        `/${org}/requests/${request.id}`
                                                                    )
                                                                }
                                                            >
                                                                View Details
                                                            </Button>
                                                        )}
                                                    </td>
                                                </tr>
                                            )
                                        })}
                                </tbody>
                            </table>
                            <ListContainer>
                                {requestData.data &&
                                    requestData.data.map((request) => {
                                        return (
                                            <ListEntry key={request.id}>
                                                <ListHeader>
                                                    <div>
                                                        <Text
                                                            block
                                                            bold
                                                            size="18px"
                                                        >{`${request.first_name} ${request.last_name}`}</Text>
                                                        <Spacer />
                                                        <Text
                                                            block
                                                            color={
                                                                Theme.PrimaryGrey
                                                            }
                                                        >
                                                            {
                                                                request.matric_number
                                                            }
                                                        </Text>
                                                        <Spacer space="20" />
                                                        <TagComp
                                                            color={
                                                                Theme.PrimaryYellow
                                                            }
                                                            status={
                                                                request.status
                                                            }
                                                        />
                                                    </div>
                                                    <div>
                                                        {!isAdmin ? (
                                                            <Button
                                                                color={
                                                                    Theme.PrimaryBlue
                                                                }
                                                                style={{
                                                                    color: Theme.PrimaryBlue,
                                                                }}
                                                                pale
                                                                onClick={() =>
                                                                    history.push(
                                                                        `/${org}/track/${request.id}`
                                                                    )
                                                                }
                                                            >
                                                                Track
                                                            </Button>
                                                        ) : (
                                                            <Button
                                                                color={
                                                                    Theme.PrimaryBlue
                                                                }
                                                                style={{
                                                                    color: Theme.PrimaryBlue,
                                                                }}
                                                                pale
                                                                onClick={() =>
                                                                    history.push(
                                                                        `/${org}/requests/${request.id}`
                                                                    )
                                                                }
                                                            >
                                                                View Details
                                                            </Button>
                                                        )}
                                                    </div>
                                                </ListHeader>
                                                <ListBody>
                                                    <List>
                                                        <span>
                                                            Year of graduation
                                                        </span>
                                                        <span>
                                                            {' '}
                                                            {request.year}
                                                        </span>
                                                    </List>
                                                    <List>
                                                        <span> Facaulty</span>
                                                        <span>
                                                            {' '}
                                                            {
                                                                request?.course
                                                                    ?.department
                                                                    ?.faculty
                                                                    ?.faculty_name
                                                            }
                                                        </span>
                                                    </List>
                                                    <List>
                                                        <span> Department</span>
                                                        <span>
                                                            {' '}
                                                            {
                                                                request.course
                                                                    .department
                                                                    .department_name
                                                            }
                                                        </span>
                                                    </List>
                                                    <List>
                                                        <span> Course</span>
                                                        <span>
                                                            {' '}
                                                            {
                                                                request.course
                                                                    .name
                                                            }
                                                        </span>
                                                    </List>
                                                </ListBody>
                                            </ListEntry>
                                        )
                                    })}
                            </ListContainer>
                        </ResponsiveTable>
                        <Box>
                            <FlexiPagination
                                pageCounts={pageSizes}
                                total={requestData.totalResults}
                                itemsDisplayed
                                pageSize={options.size}
                                onChange={handlePagination}
                                changePageSize={handlePageSize}
                                current={options.page + 1}
                            />
                        </Box>
                    </>
                ) : (
                    <>
                        <Box>
                            <EmptyState
                                type="documents"
                                title="Nothing to see here"
                                info="You do not have any requests"
                                action={
                                    <Box align="center">
                                        {!isAdmin && (
                                            <Button
                                                pad="0 15px"
                                                spaceLeft
                                                spaceRight
                                                onClick={() =>
                                                    toggleRequestModal(true)
                                                }
                                            >
                                                Make a request
                                            </Button>
                                        )}
                                    </Box>
                                }
                            />
                        </Box>{' '}
                    </>
                )}
            </ResponsiveBox>
            {requestModalOpen && (
                <RequestWizard
                    open={requestModalOpen}
                    toggleModal={toggleRequestModal}
                    services={services}
                    currentStep={currentStep}
                    setCurrentStep={setCurrentStep}
                    tourStarted={state?.run}
                />
            )}
        </>
    )
}

export default Requests

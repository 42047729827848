import React, { useCallback, useEffect } from 'react'
import styled, { css } from 'styled-components'
import { darken, transparentize } from 'polished'
import { Box, Text, Button } from 'flexibull2'
import { Theme } from '../../config/theme'
import { useDropzone } from 'react-dropzone'
import * as XLSX from 'xlsx'

const UploaderWrapper = styled.div`
    border: 1px dashed ${(props) => darken(0.1, Theme.PrimaryBorderColor)};
    border-radius: ${(props) => props.theme.SecondaryRadius};
    background: rgba(255, 255, 255, 0.6);
    padding: 30px;
    text-align: center;
    display: grid;
    grid-template-columns: max-content auto max-content;
    ${(props) =>
        props.mini &&
        css`
            width: 100%;
            max-width: 400px;
            justify-items: center;
            padding: 30px 0;
            display: inline-grid;
            grid-template-columns: 1fr;
        `}
    grid-gap: 20px;
    align-items: center;

    & li {
        list-style: none;
        display: block;
        padding: 5px;
        margin: 2px;
        border-radius: 2px;
        background: ${(props) => transparentize(0.6, Theme.PrimaryBorderColor)};
        color: ${(props) => darken(0.4, Theme.PrimaryBorderColor)};
        width: 100%;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
    }

    & .iconer {
        color: ${(props) => darken(0.2, Theme.PrimaryBorderColor)};
        display: inline-block;
        & svg {
            fill: ${(props) => darken(0.1, Theme.PrimaryBorderColor)};
        }
    }
    & .error-span {
        color: ${(props) => props.theme.PrimaryRed};
        font-size: 10px;
        padding: 5px;
        margin: 2px;
        border-radius: 2px;
        display: block;
        width: 100%;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        background: ${(props) => transparentize(0.9, props.theme.PrimaryRed)};
    }
`

const UploadedFile = (props) => {
    const { type } = props
    let image = 'flexibull-doc'
    let typo = type.split('/')
    if (typo[0] === 'image') {
        image = 'flexibull-file-image'
    }

    if (typo[0] === 'video') {
        image = 'flexibull-file-video'
    }

    if (typo[0] === 'text') {
        image = 'flexibull-doc-text'
    }

    if (typo[1] === 'pdf') {
        image = 'flexibull-file-pdf'
    }
    if (typo[1] === 'zip') {
        image = 'flexibull-file-archive'
    }

    return <i className={image} />
}

const Uploader = (props) => {
    const [base64Data, setBase64Data] = React.useState(null)
    const [fileData, setFileData] = React.useState(null)
    useEffect(() => {
        props.setData && props.setData(fileData)
        return () => {
            const reader = new FileReader()
            reader.removeEventListener(
                'load',
                () => {
                    setBase64Data(reader.result)
                },

                false
            )
        }
    }, [props.setData, props, fileData])
    const onFileChange = async (banner) => {
        if (banner !== undefined) {
            const file = banner
            const reader = new FileReader()

            reader.addEventListener(
                'load',
                () => {
                    setBase64Data(reader.result)
                },

                false
            )

            if (file) {
                setFileData(file)
                reader.readAsDataURL(file)
            }
        }
        const { isExcel } = props

        if (banner !== undefined && isExcel === false) {
            excelProvider(banner)
        }
    }

    const excelProvider = (excel) => {
        if (excel !== undefined) {
            const promise = new Promise((resolve, reject) => {
                const file = excel
                const fileReader = new FileReader()
                fileReader.readAsArrayBuffer(file)

                fileReader.onload = (e) => {
                    const bufferArray = e.target.result

                    const wb = XLSX.read(bufferArray, { type: 'buffer' })

                    const wsname = wb.SheetNames[0]

                    const ws = wb.Sheets[wsname]

                    const data = XLSX.utils.sheet_to_json(ws)

                    props.setFaculties && props.setFaculties(data)
                    props.setDepartments && props.setDepartments(data)
                }

                fileReader.onerror = (error) => {
                    reject(error)
                }
            })

            promise.then((d) => {
                props.setItems && props.setItems(d)
            })
        }
    }

    const onDrop = useCallback((acceptedFiles) => {
        onFileChange(acceptedFiles[0])

        props.setEncodedFiles && props.setEncodedFiles(acceptedFiles[0])
        props.setBase64Str && props.setBase64Str(base64Data)
        // eslint-disable-next-line
    }, [])
    const maxSize = '5242880'
    const { acceptedFiles, getRootProps, getInputProps, fileRejections } =
        useDropzone({
            multiple: false,
            onDrop,
            accept: 'image/jpeg, image/png, application/pdf, .xls,.xlsx',
            maxSize,
        })
    const isFileTooLarge =
        fileRejections.length > 0 &&
        fileRejections[0].errors[0].code === 'file-too-large'

    const files = acceptedFiles.map((file) => (
        <li key={file.path}>
            <UploadedFile type={file.type} /> {file.path.slice(0, 8)} -{' '}
            {file.size} bytes
        </li>
    ))

    const fileRejectionItems = fileRejections.map(({ file, errors }) => (
        <span key={file.path} className="error-span">
            <i className="flexibull-cancel" />
            {file.path}:
            {errors.map((e) => (
                <span key={e.code}>{e.message}</span>
            ))}
        </span>
    ))

    const thumb = {
        display: 'inline-flex',
        borderRadius: 2,
        border: '1px solid #eaeaea',
        marginBottom: 8,
        marginRight: 8,
        width: 100,
        height: 100,
        padding: 4,
        boxSizing: 'border-box',
    }

    const thumbInner = {
        display: 'flex',
        minWidth: 0,
        overflow: 'hidden',
    }

    const img = {
        display: 'block',
        width: 'auto',
        height: '100%',
    }

    return (
        <UploaderWrapper
            {...getRootProps({ className: 'dropzone' })}
            mini={props.mini}
        >
            <Box>
                <div className="iconer">
                    <input {...getInputProps()} />
                    {base64Data ? (
                        <div style={thumb}>
                            <div style={thumbInner}>
                                <img
                                    src={base64Data}
                                    style={img}
                                    alt="bannerImage"
                                />
                            </div>
                        </div>
                    ) : (
                        <svg
                            width="45.201"
                            height="45.237"
                            viewBox="0 0 45.201 45.237"
                        >
                            <g id="image" transform="translate(-0.203)">
                                <g
                                    id="Group_15629"
                                    data-name="Group 15629"
                                    transform="translate(0.203)"
                                >
                                    <g
                                        id="Group_15628"
                                        data-name="Group 15628"
                                        transform="translate(0)"
                                    >
                                        <path
                                            id="Path_8262"
                                            data-name="Path 8262"
                                            d="M452.79,5.512a2.756,2.756,0,1,0-2.756-2.756,2.759,2.759,0,0,0,2.756,2.756Zm0-4.189a1.433,1.433,0,1,1-1.433,1.433A1.435,1.435,0,0,1,452.79,1.323Z"
                                            transform="translate(-410.344)"
                                        />
                                        <path
                                            id="Path_8263"
                                            data-name="Path 8263"
                                            d="M78.03,474.726H75.84a.661.661,0,1,0,0,1.323H78.03a.661.661,0,1,0,0-1.323Z"
                                            transform="translate(-68.564 -432.84)"
                                        />
                                        <path
                                            id="Path_8264"
                                            data-name="Path 8264"
                                            d="M271.556,474.726h-2.189a.661.661,0,0,0,0,1.323h2.189a.661.661,0,0,0,0-1.323Z"
                                            transform="translate(-245.014 -432.84)"
                                        />
                                        <path
                                            id="Path_8265"
                                            data-name="Path 8265"
                                            d="M142.538,474.726h-2.189a.661.661,0,1,0,0,1.323h2.189a.661.661,0,1,0,0-1.323Z"
                                            transform="translate(-127.381 -432.84)"
                                        />
                                        <path
                                            id="Path_8266"
                                            data-name="Path 8266"
                                            d="M207.048,474.726h-2.189a.661.661,0,1,0,0,1.323h2.189a.661.661,0,0,0,0-1.323Z"
                                            transform="translate(-186.198 -432.84)"
                                        />
                                        <path
                                            id="Path_8267"
                                            data-name="Path 8267"
                                            d="M400.574,474.726h-2.189a.661.661,0,1,0,0,1.323h2.189a.661.661,0,1,0,0-1.323Z"
                                            transform="translate(-362.65 -432.84)"
                                        />
                                        <path
                                            id="Path_8268"
                                            data-name="Path 8268"
                                            d="M336.064,474.726h-2.189a.661.661,0,0,0,0,1.323h2.189a.661.661,0,1,0,0-1.323Z"
                                            transform="translate(-303.832 -432.84)"
                                        />
                                        <path
                                            id="Path_8269"
                                            data-name="Path 8269"
                                            d="M24.312,269.367a.661.661,0,0,0-1.323,0v2.189a.661.661,0,1,0,1.323,0Z"
                                            transform="translate(-20.979 -244.997)"
                                        />
                                        <path
                                            id="Path_8270"
                                            data-name="Path 8270"
                                            d="M24.312,204.859a.661.661,0,1,0-1.323,0v2.189a.661.661,0,1,0,1.323,0Z"
                                            transform="translate(-20.979 -186.181)"
                                        />
                                        <path
                                            id="Path_8271"
                                            data-name="Path 8271"
                                            d="M24.312,333.876a.661.661,0,0,0-1.323,0v2.189a.661.661,0,1,0,1.323,0Z"
                                            transform="translate(-20.979 -303.815)"
                                        />
                                        <path
                                            id="Path_8272"
                                            data-name="Path 8272"
                                            d="M23.651,397.725a.661.661,0,0,0-.661.661v2.189a.661.661,0,0,0,1.323,0v-2.189A.661.661,0,0,0,23.651,397.725Z"
                                            transform="translate(-20.979 -362.633)"
                                        />
                                        <path
                                            id="Path_8273"
                                            data-name="Path 8273"
                                            d="M24.312,75.84a.661.661,0,0,0-1.323,0V78.03a.661.661,0,1,0,1.323,0Z"
                                            transform="translate(-20.979 -68.546)"
                                        />
                                        <path
                                            id="Path_8274"
                                            data-name="Path 8274"
                                            d="M24.312,140.35a.661.661,0,1,0-1.323,0v2.189a.661.661,0,1,0,1.323,0Z"
                                            transform="translate(-20.979 -127.364)"
                                        />
                                        <path
                                            id="Path_8275"
                                            data-name="Path 8275"
                                            d="M140.35,24.312h2.189a.661.661,0,0,0,0-1.323H140.35a.661.661,0,1,0,0,1.323Z"
                                            transform="translate(-127.382 -20.961)"
                                        />
                                        <path
                                            id="Path_8276"
                                            data-name="Path 8276"
                                            d="M398.385,24.312h2.189a.661.661,0,0,0,0-1.323h-2.189a.661.661,0,1,0,0,1.323Z"
                                            transform="translate(-362.65 -20.961)"
                                        />
                                        <path
                                            id="Path_8277"
                                            data-name="Path 8277"
                                            d="M204.858,24.312h2.189a.661.661,0,1,0,0-1.323h-2.189a.661.661,0,1,0,0,1.323Z"
                                            transform="translate(-186.198 -20.961)"
                                        />
                                        <path
                                            id="Path_8278"
                                            data-name="Path 8278"
                                            d="M269.367,24.312h2.189a.661.661,0,0,0,0-1.323h-2.189a.661.661,0,0,0,0,1.323Z"
                                            transform="translate(-245.015 -20.961)"
                                        />
                                        <path
                                            id="Path_8279"
                                            data-name="Path 8279"
                                            d="M333.877,24.312h2.189a.661.661,0,1,0,0-1.323h-2.189a.661.661,0,0,0,0,1.323Z"
                                            transform="translate(-303.834 -20.961)"
                                        />
                                        <path
                                            id="Path_8280"
                                            data-name="Path 8280"
                                            d="M75.841,24.312h2.189a.661.661,0,0,0,0-1.323H75.841a.661.661,0,1,0,0,1.323Z"
                                            transform="translate(-68.565 -20.961)"
                                        />
                                        <path
                                            id="Path_8281"
                                            data-name="Path 8281"
                                            d="M476.048,269.367a.661.661,0,0,0-1.323,0v2.189a.661.661,0,0,0,1.323,0Z"
                                            transform="translate(-432.857 -244.997)"
                                        />
                                        <path
                                            id="Path_8282"
                                            data-name="Path 8282"
                                            d="M476.048,204.858a.661.661,0,1,0-1.323,0v2.189a.661.661,0,1,0,1.323,0Z"
                                            transform="translate(-432.857 -186.18)"
                                        />
                                        <path
                                            id="Path_8283"
                                            data-name="Path 8283"
                                            d="M475.387,397.724a.661.661,0,0,0-.661.661v2.189a.661.661,0,0,0,1.323,0v-2.189A.661.661,0,0,0,475.387,397.724Z"
                                            transform="translate(-432.858 -362.632)"
                                        />
                                        <path
                                            id="Path_8284"
                                            data-name="Path 8284"
                                            d="M476.048,333.875a.661.661,0,0,0-1.323,0v2.189a.661.661,0,1,0,1.323,0Z"
                                            transform="translate(-432.857 -303.814)"
                                        />
                                        <path
                                            id="Path_8285"
                                            data-name="Path 8285"
                                            d="M476.048,140.35a.661.661,0,1,0-1.323,0v2.189a.661.661,0,0,0,1.323,0Z"
                                            transform="translate(-432.857 -127.364)"
                                        />
                                        <path
                                            id="Path_8286"
                                            data-name="Path 8286"
                                            d="M476.048,75.84a.661.661,0,0,0-1.323,0V78.03a.661.661,0,1,0,1.323,0Z"
                                            transform="translate(-432.857 -68.546)"
                                        />
                                        <path
                                            id="Path_8287"
                                            data-name="Path 8287"
                                            d="M2.959,5.512A2.756,2.756,0,1,0,.2,2.756,2.759,2.759,0,0,0,2.959,5.512Zm0-4.189A1.433,1.433,0,1,1,1.526,2.756,1.435,1.435,0,0,1,2.959,1.323Z"
                                            transform="translate(-0.203)"
                                        />
                                        <path
                                            id="Path_8288"
                                            data-name="Path 8288"
                                            d="M452.79,450.238a2.756,2.756,0,1,0,2.756,2.756A2.759,2.759,0,0,0,452.79,450.238Zm0,4.189a1.433,1.433,0,1,1,1.433-1.433A1.435,1.435,0,0,1,452.79,454.427Z"
                                            transform="translate(-410.344 -410.513)"
                                        />
                                        <path
                                            id="Path_8289"
                                            data-name="Path 8289"
                                            d="M2.959,450.238a2.756,2.756,0,1,0,2.756,2.756A2.759,2.759,0,0,0,2.959,450.238Zm0,4.189a1.433,1.433,0,1,1,1.433-1.433A1.435,1.435,0,0,1,2.959,454.427Z"
                                            transform="translate(-0.203 -410.513)"
                                        />
                                        <path
                                            id="Path_8290"
                                            data-name="Path 8290"
                                            d="M93.43,69.164a2.351,2.351,0,0,0-2.348-2.348H60.867a2.351,2.351,0,0,0-2.348,2.348V71.5a.661.661,0,0,0,1.323,0V69.164a1.027,1.027,0,0,1,1.026-1.026H91.081a1.027,1.027,0,0,1,1.026,1.026V85.618l-2.771-1.744a.661.661,0,0,0-.7,1.119l3.475,2.188V97.914a1.027,1.027,0,0,1-1.026,1.026H85.986V93.8A4.7,4.7,0,0,0,84.171,90.1l-6.892-5.319,4.443-2.538a2.555,2.555,0,0,1,2.634.056l1.659,1.044a.661.661,0,1,0,.7-1.119l-1.659-1.044a3.875,3.875,0,0,0-3.995-.086l-4.921,2.811L70.24,79.35a3.881,3.881,0,0,0-4.249-.326l-6.149,3.394V74.633a.661.661,0,0,0-1.323,0V97.914a2.351,2.351,0,0,0,2.348,2.348H91.081a2.351,2.351,0,0,0,2.348-2.348ZM84.664,98.94h-23.8a1.027,1.027,0,0,1-1.026-1.026V83.93l6.788-3.747a2.559,2.559,0,0,1,2.8.215L83.363,91.149a3.366,3.366,0,0,1,1.3,2.647Z"
                                            transform="translate(-53.374 -60.921)"
                                        />
                                        <path
                                            id="Path_8291"
                                            data-name="Path 8291"
                                            d="M317.805,112.107a3.969,3.969,0,1,0-3.969-3.969,3.974,3.974,0,0,0,3.969,3.969Zm0-6.616a2.646,2.646,0,1,1-2.646,2.646A2.649,2.649,0,0,1,317.805,105.492Z"
                                            transform="translate(-286.164 -94.978)"
                                        />
                                    </g>
                                </g>
                            </g>
                        </svg>
                    )}
                </div>
            </Box>{' '}
            <Box>
                <Text color={darken(0.2, Theme.PrimaryBorderColor)}>
                    {acceptedFiles.length > 0 ? (
                        <div>
                            {files} {fileRejectionItems}
                        </div>
                    ) : isFileTooLarge ? (
                        // <div className="text-danger mt-2">File is too large.</div>
                        <Text color={Theme.PrimaryRed} size="20px">
                            File is too large.
                        </Text>
                    ) : (
                        'Drag or drop your file here.'
                    )}
                </Text>
            </Box>
            <Box>
                <Button
                    color={Theme.PrimaryBorderColor}
                    fontColor={darken(0.3, Theme.PrimaryBorderColor)}
                    iconRight={<i className="flexibull-upload" />}
                >
                    Browse File
                </Button>
            </Box>
        </UploaderWrapper>
    )
}

export default Uploader

// For Documentation of Upload Component  https://react-dropzone.js.org/

import React from 'react'
import { Text, Grid } from 'flexibull2'
import LabelComponent, { InfoComponent } from '../components/LabelComponent'
const EducationInfo = ({ course, year }) => {
    return (
        <div style={{ width: '80%', paddingTop: 20 }}>
            <Text bold size="18px" style={{ paddingLeft: 10 }}>
                Education Information
            </Text>
            <Grid default="1fr 1fr 1fr">
                <LabelComponent label="Faculty" />
                <LabelComponent label="Department" />
                <LabelComponent label="Course" />
            </Grid>
            <Grid default="1fr 1fr 1fr">
                <InfoComponent value={course?.name} />
                <InfoComponent value={course?.department?.department_name} />
                <InfoComponent
                    value={course?.department?.faculty?.faculty_name}
                />
            </Grid>
            <Grid default="1fr">
                <LabelComponent label="Year of Graduation" />
            </Grid>
            <Grid default="1fr 1fr 1fr">
                <InfoComponent value={year} />
            </Grid>
        </div>
    )
}

export default EducationInfo

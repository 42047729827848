import React, { useEffect, useState } from 'react'
import { useAuth } from '../../context/auth-context'
import { Box, Grid, Tag, Text, Button, Notify } from 'flexibull2'
import { useParams } from 'react-router-dom'
import BreadCrumbs from '../components/BreadCrumbs'
import { Theme } from '../config/theme'
import { getRequestDetails, updateRequest, uploadTranscript } from './services'
import { TOP_RIGHT, SUCCESS, ERROR_ALERT } from '../../utils/constants'
import StatusModal from './StatusModal'
import styled from 'styled-components'
import ApplicantInfo from './ApplicantInfo'
import EducationInfo from './EducationInfo'
import DeliveryInfo from './DeliveryInfo'

import PDFViewer from '../PdfViewer/index'

const DetailsHeader = styled.div`
    display: flex;
    justify-content: space-between;
    padding-bottom: 70px;
`
const HeaderLeft = styled.div`
    display: flex;
`
const HeaderRight = styled.div`
    display: flex;
    align-items: center;
`

const RequestDetails = () => {
    const [requestDetails, setRequestDetails] = useState({})
     // eslint-disable-next-line
    const [uploadStatus, setStatus] = useState('idle')
    const [modalOpen, toggleModal] = useState(false)

    const { id } = useParams()
    const handleSetData = (fileData) => {
        if (fileData) {
            setStatus('loading')
            uploadTranscript({
                id,
                fileData,
            })
                .then(() => {
                    Notify(
                        `Document Uploaded Successfully, set status to Ready`,
                        {
                            position: TOP_RIGHT,
                            status: SUCCESS,
                        }
                    )
                    toggleModal(true)
                    setStatus('done')
                })
                .catch((e) => {
                    setStatus('idle')
                    Notify(`Something went wrong`, {
                        position: TOP_RIGHT,
                        status: ERROR_ALERT,
                    })
                })
        }
    }

    useEffect(() => {
        getRequestDetails(id).then(({ data }) => {
            setRequestDetails(data)
            if (
                data.status === 'processing' ||
                data.status === 'Transcript Ready'
            ) {
                return
            } else {
                updateRequest({
                    ...data,
                    status: 'Received by Institution',
                    course_id: data.course.id,
                }).then(({ data: updatedData }) => {
                    setRequestDetails(updatedData)
                })
            }
        })
    }, [id])
    const {
        first_name: firstName,
        last_name: lastName,
        matric_number: matricNumber,
        course,
        year,
        status,
        delivery_address,
        created_at
    } = requestDetails
    function handleTranscriptStatus(value) {
        updateRequest({
            ...requestDetails,
            status: value,
            course_id: requestDetails.course.id,
        }).then(({ data: j }) => {
            setRequestDetails(j)
            toggleModal(false)
        })
    }
    const { org } = useAuth()
    return (
        <Box margin="30px">
            <BreadCrumbs
                links={[{ path: `/${org}/requests`, text: 'Requests' }]}
                last={id}
            />
            <Grid default="50% 50%" gap="0px">
                <Box pad="30px" style={{ backgroundColor: 'white' }}>
                    <DetailsHeader>
                        <HeaderLeft>
                            <Box
                                background="#abd4e3"
                                display="flex"
                                width="40px"
                                height="40px"
                                style={{
                                    alignItems: 'center',
                                    justifyContent: 'center',
                                    borderRadius: '100%',
                                }}
                            >
                                <i
                                    className="saf-user-outline"
                                    style={{ fontSize: '14px' }}
                                />
                                {`${firstName?.[0] || ''} ${
                                    lastName?.[0] || ''
                                }`}
                            </Box>
                            <div
                                style={{
                                    display: 'flex',
                                    flexDirection: 'column',
                                    paddingLeft: 20,
                                }}
                            >
                                <Text
                                    style={{ paddingBottom: '5px' }}
                                    size="16px"
                                >
                                    {`${firstName || ''} ${lastName || ''}`}
                                </Text>
                                <Tag solid color={Theme.PrimaryGrey} spaceRight>
                                    #{matricNumber}
                                </Tag>
                            </div>
                        </HeaderLeft>
                        <HeaderRight>
                            <Text style={{ paddingRight: '5px' }} size="13px">
                                Status:
                            </Text>
                            <Tag
                                solid
                                color={
                                    status === 'Transcript Ready'
                                        ? Theme.PrimaryGreen
                                        : status === 'processing'
                                        ? Theme.PrimaryYellow
                                        : status === 'Received by Institution'
                                        ? Theme.PrimaryBlue
                                        : Theme.PrimaryOrange
                                }
                                spaceRight
                            >
                                <span style={{ color: 'white' }}>{status}</span>
                            </Tag>
                            <div onClick={() => toggleModal(true)}>
                                <Button>Change Status</Button>
                            </div>
                        </HeaderRight>
                    </DetailsHeader>
                    <ApplicantInfo
                        matricNumber={matricNumber}
                        firstName={firstName}
                        lastName={lastName}
                        created_at={created_at}
                    />
                    <EducationInfo course={course} year={year} />
                    {delivery_address && (
                        <DeliveryInfo info={delivery_address} />
                    )}
                </Box>
                <Box pad="30px" style={{ backgroundColor: '#eaedf2' }}>
                    <PDFViewer handleSetData={handleSetData} />
                </Box>
                {modalOpen && (
                    <StatusModal
                        toggleModal={toggleModal}
                        open={modalOpen}
                        handleTranscriptStatus={handleTranscriptStatus}
                        id={id}
                    />
                )}
            </Grid>
        </Box>
    )
}

export default RequestDetails

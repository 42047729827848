import React from 'react'
import { Button, Table, FlexiPagination, Box } from 'flexibull2'
import { ColorBox } from '../components/Styles/ColorBox.style'
import { DropDown } from 'flexibull2/build/dropdown'

const DepartmentTable = ({
    departments,
    onDelete,
    onEdit,
    getDepartments,
    pageNumber,
    setpageNumber,
    orgId,
    setPageOptions,
    pageSize,
    pageSizes,
    pageOptions,
}) => {
    const handlePagination = (page) => {
        getDepartments(pageSize, page, orgId)
        setpageNumber(page)
    }

    const handlePageSize = (pageSize) => {
        getDepartments(pageSize.value, pageNumber, orgId)
        setPageOptions({ ...pageOptions, pageSize: pageSize.value })
    }
    return (
        <div>
            <Table style={{ paddingTop: '10px' }}>
                <table>
                    <thead>
                        <tr>
                            <th>Department Title</th>
                            <th>Short Code</th>
                            <th>Faculty</th>
                            <td>Action</td>
                        </tr>
                    </thead>
                    <tbody>
                        {departments?.data?.map((department) => (
                            <tr key={department.id}>
                                <td>
                                    {department.department_name
                                        ? department.department_name
                                        : department.name}
                                </td>
                                <td>{department.short_code}</td>
                                <td>{department?.faculty?.faculty_name}</td>
                                <td>
                                    <ColorBox>
                                        <DropDown
                                            label={
                                                <Button
                                                    pale
                                                    height={20}
                                                    style={{
                                                        border: 'none',
                                                        marginLeft: '-1.5rem',
                                                    }}
                                                >
                                                    <i className="icon-dot-3" />
                                                </Button>
                                            }
                                            trigger="hover"
                                            menuAlign="top right"
                                            menuList={[
                                                {
                                                    onClick: () =>
                                                        onEdit(department),
                                                    label: 'Edit Department',
                                                    iconLeft: 'icon-edit tblue',
                                                },
                                                {
                                                    onClick: () =>
                                                        onDelete(department.id),
                                                    label: 'Delete Department',
                                                    iconLeft: 'icon-trash tred',
                                                },
                                            ]}
                                            style={{
                                                cursor: 'pointer',
                                            }}
                                        />
                                    </ColorBox>
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            </Table>
            <Box>
                <FlexiPagination
                    pageCounts={pageSizes}
                    total={departments?.totalResults}
                    itemsDisplayed
                    pageSize={pageSize}
                    onChange={handlePagination}
                    changePageSize={handlePageSize}
                    current={pageNumber}
                />
            </Box>
        </div>
    )
}

export default DepartmentTable

import React from 'react'
import { Text, Grid } from 'flexibull2'
import LabelComponent, { InfoComponent } from '../components/LabelComponent'
import Moment from 'react-moment'
const ApplicantInfo = ({ firstName, lastName, matricNumber, created_at }) => {
    return (
        <div style={{ width: '80%' }}>
            <Text bold size="18px" style={{ paddingLeft: 10 }}>
                Applicant Information
            </Text>
            <Grid default="1fr 1fr 1fr 1fr">
                <LabelComponent label="First Name" />
                <LabelComponent label="Last Name" />
                <LabelComponent label="Date Created" />
            </Grid>
            <Grid default="1fr 1fr 1fr 1fr">
                <InfoComponent value={firstName} />
                <InfoComponent value={lastName} />
                <div style={{ paddingLeft: 10, paddingTop: 10 }}>
                    <Moment format="YYYY/MM/DD">{created_at}</Moment>
                </div>
            </Grid>
            <Grid default="1fr">
                <LabelComponent label="Matriculation Number" />
            </Grid>
            <Grid default="1fr 1fr 1fr">
                <InfoComponent value={matricNumber} />
            </Grid>
        </div>
    )
}

export default ApplicantInfo

import React, { useState } from 'react'
import {
    Grid,
    Box,
    Text,
    Button,
    Input,
    Notify,
    Tabs,
} from 'flexibull2'
import { TOP_RIGHT, ERROR_ALERT } from '../../utils/constants'
import PaymentConfiguration from './PaymentConfiguration'
import Service from './PaymentConfiguration/Service'
import { v4 as uuidv4 } from 'uuid'
import { useAtom } from 'jotai'
import { beneficiariesAtom } from './PaymentConfiguration/payment_config.store'


const Payment = ({
    setAccount,
    setCurrentStep,
    institution, 
    setInstitution,
}) => {
    const [beneficiaries, setBeneficiaries] = useAtom(beneficiariesAtom) 
    const [currentStep, setStep] = useState(4)
    
    
    const handleNext = () => {
        const payment = JSON.parse(localStorage.getItem('payments'))
        if (payment.length === 0) {
            Notify(`Please fill in all the fields and try again`, {
                position: TOP_RIGHT,
                status: ERROR_ALERT,
            })
        } else {
            if (currentStep === 4) {
                setCurrentStep(5)
            } else {
                setStep(4)
            }

            setInstitution({
                ...institution,
            })
            setAccount({ beneficiaries, payment })
        }
        return payment
    }

    const [newService, setNewService] = useState('')
    const [serviceTabs, setServiceTabs] = useState([
        {
            id: uuidv4(),
            label: 'Tracnscript Settings',
            service: 'Transcript',
            idPredefined: true,
        },
        {
            id: uuidv4(),
            label: 'Statement of result Settings',
            service: 'Statement of result',
            idPredefined: true,
        },
        {
            id: uuidv4(),
            label: 'Certificate Settings',
            service: 'Certificate',
            idPredefined: true,
        },
    ])

    const handleNewService = (e) => {
        setNewService(e.target.value)
    }

    const handleDeleteServiceFromTabs = (serviceId, service) => {
        setServiceTabs(serviceTabs.filter(({ id }) => id !== serviceId))
        setBeneficiaries(
            beneficiaries.filter(
                ({ beneficiary_type }) => beneficiary_type !== service
            )
        )
    }

    const handleAddServiceToTabs = () => {
        setServiceTabs([
            ...serviceTabs,
            {
                id: uuidv4(),
                label: `${newService} Settings`,
                service: newService,
                idPredefined: false,
            },
        ])
        setNewService('')
    }

    const handlePrevious = () => {
        setCurrentStep(currentStep - 1)
    }

    return (
        <>
            <Grid default="1fr">
                {' '}
                <Box pad="20px 0">
                    <Text size="20px" block bold>
                        Configure Payments
                    </Text>
                    <Box margin="10px 0">
                        <Text size="12px" block>
                            Configure the fees an institution charges for it's
                            transcript services
                        </Text>
                    </Box>
                    <Box align="right">
                        <Input
                            name={newService}
                            placeholder="Add new Service"
                            height="20"
                            value={newService}
                            onChange={handleNewService}
                        />
                        <Button
                            onClick={handleAddServiceToTabs}
                            height="24"
                            disabled={!newService ? true : false}
                        >
                            Add
                        </Button>
                    </Box>
                    <Tabs bgColor="transparent">
                        {serviceTabs.map(
                            ({ id, label, service, idPredefined }) => {
                                return (
                                    <div label={label} key={id}>
                                        {!idPredefined && (
                                            <Box
                                                align="right"
                                                style={{
                                                    marginBottom: '-50px',
                                                }}
                                            >
                                                <Button
                                                    onClick={() =>
                                                        handleDeleteServiceFromTabs(
                                                            id,
                                                            service
                                                        )
                                                    }
                                                    round
                                                    pale
                                                    spaceRight
                                                    icon={
                                                        <i className="flexibull-cancel" />
                                                    }
                                                />
                                            </Box>
                                        )}

                                        <Box>
                                            <h2>{service}</h2>
                                        </Box>

                                        <PaymentConfiguration
                                            service={service}
                                            serviceConponent={
                                                <Service
                                                    beneficiaryType={service}
                                                />
                                            }
                                        />
                                    </div>
                                )
                            }
                        )}
                    </Tabs>
                </Box>
            </Grid>

            <Box align="right" margin="100px 0">
                <Button
                    width={110}
                    height={30}
                    onClick={handlePrevious}
                    pale
                    pad="0 15px"
                    style={{
                        border: 'none',
                        textAling: 'center',
                        color: 'black',
                    }}
                >
                    Previous
                </Button>
                <Button
                    iconRight={<i className="flexibull-right" />}
                    width={120}
                    height={30}
                    onClick={() => handleNext()}
                    pad="0 15px"
                >
                    Next
                </Button>
            </Box>
        </>
    )
}

export default Payment

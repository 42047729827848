const arr = [
    {
        value: '044',
        label: 'ACCESS BANK PLC',
    },
    {
        value: '000',
        label: 'CENTRAL BANK OF NIGERIA',
    },
    {
        value: '023',
        label: 'CITIBANK NIG LTD',
    },
    {
        value: '063',
        label: 'DIAMOND BANK PLC',
    },
    {
        value: '050',
        label: 'ECOBANK NIGERIA PLC',
    },
    {
        value: '084',
        label: 'ENTERPRISE BANK',
    },
    {
        value: '070',
        label: 'FIDELITY BANK PLC',
    },
    {
        value: '011',
        label: 'FIRST BANK OF NIGERIA PLC',
    },
    {
        value: '214',
        label: 'FIRST CITY MONUMENT BANK PLC',
    },
    {
        value: '401',
        label: 'FSDH MERCHANT BANK LTD',
    },
    {
        value: '058',
        label: 'GUARANTY TRUST BANK PLC',
    },
    {
        value: '030',
        label: 'HERITAGE BANK',
    },
    {
        value: '301',
        label: 'JAIZ BANK PLC',
    },
    {
        value: '082',
        label: 'KEYSTONE BANK',
    },
    {
        value: '999',
        label: 'OFFLINE BANK',
    },
    {
        value: '101',
        label: 'PROVIDUS BANK PLC',
    },
    {
        value: '014',
        label: 'SKYE BANK (FORMERLY MAINSTREET',
    },
    {
        value: '076',
        label: 'SKYE BANK PLC',
    },
    {
        value: '039',
        label: 'STANBIC IBTC BANK PLC',
    },
    {
        value: '068',
        label: 'STANDARD CHARTERED BANK NIGERIA LTD',
    },
    {
        value: '232',
        label: 'STERLING BANK PLC',
    },
    {
        value: '100',
        label: 'SUNTRUST BANK NIG LTD',
    },
    {
        value: '033',
        label: 'UBA PLC',
    },
    {
        value: '032',
        label: 'UNION BANK OF NIGERIA PLC',
    },
    {
        value: '215',
        label: 'UNITY BANK PLC',
    },
    {
        value: '035',
        label: 'WEMA BANK PLC',
    },
    {
        value: '057',
        label: 'ZENITH BANK PLC',
    },
    {
        value: '501',
        label: 'FSDH MERCHANT BANK',
    },
    {
        value: '525',
        label: 'KATSINA STATE MICROFINANCE BANKS',
    },
    {
        value: '510120013',
        label: 'NPF MFB:MISSION RD, BESIDE BOMPAI POLICE STAT., KANO',
    },
    {
        value: '510120023',
        label: 'DAMBATTA MAKODA MFB',
    },
    {
        value: '510120033',
        label: 'GIDAUNIYA ALHERI MFB LIMITED',
    },
    {
        value: '510120043',
        label: 'GRASSROOT MFB LIMITED',
    },
    {
        value: '510120053',
        label: 'KANO-WEST MFB LIMITED',
    },
    {
        value: '510120063',
        label: 'RAKIB MFB LIMITED',
    },
    {
        value: '510120073',
        label: 'WOMEN DEVELOPMENT INITIATIVE MFB LIMITED',
    },
    {
        value: '510120083',
        label: 'WUDIL MFB LIMITED',
    },
    {
        value: '510120093',
        label: 'DALA BUILDING SOCIETY PLC',
    },
    {
        value: '510120103',
        label: 'BANK OF AGRICULTURE GEZAWA BRANCH',
    },
    {
        value: '510120113',
        label: 'BANK OF AGRICULTURE, MINJIBIR',
    },
    {
        value: '510120123',
        label: 'BANK OF AGRICULTURE TUDUNWADA,TUDUN WADA BRANCH',
    },
    {
        value: '510120133',
        label: 'NORTH BRIDGE MFB',
    },
    {
        value: '510120143',
        label: 'BANK OF AGRICULTURE DAMBATA',
    },
    {
        value: '510120153',
        label: 'BANK OF AGRICULTURE KANO',
    },
    {
        value: '510120163',
        label: 'KKU MICROFINANCE BANK LTD, KANO STATE',
    },
    {
        value: '510120173',
        label: 'RIMIN GADO MICROFINANCE BANK LTD, KANO STATE',
    },
    {
        value: '510120183',
        label: 'BAYERO UNIVERSITY MICROFINANCE BANK LTD',
    },
    {
        value: '510120193',
        label: 'BOMPAI MICROFINANCE BANK LIMITED',
    },
    {
        value: '510120203',
        label: 'GABASAWA MICROFINANCE BANK LIMITED',
    },
    {
        value: '510120213',
        label: 'DAL MICROFINANCE BANK LTD',
    },
    {
        value: '510120223',
        label: 'BICHI MICROFINANCE BANK LTD',
    },
    {
        value: '510120233',
        label: 'KUNCHI MICROFINANCE BANK LTD',
    },
    {
        value: '510120243',
        label: 'GARUN MALLAM MICROFINANCE BANK LTD',
    },
    {
        value: '510120253',
        label: 'TOFA MICROFINANCE BANK LTD',
    },
    {
        value: '510120263',
        label: 'KMC MICROFINANCE BANK LTD',
    },
    {
        value: '510120273',
        label: 'ROGO MICROFINANCE BANK LTD',
    },
    {
        value: '510120283',
        label: 'RANO MICROFINANCE BANK LTD',
    },
    {
        value: '510120293',
        label: 'KKU MICROFINANCE BANK LTD',
    },
    {
        value: '510120303',
        label: 'DAWAKIN KUDU MICROFINANCE BANK LTD',
    },
    {
        value: '510120313',
        label: 'GARKO MICROFINANCE BANK LTD',
    },
    {
        value: '510120323',
        label: 'TSANYAWA MICROFINANCE BANK LTD',
    },
    {
        value: '510120333',
        label: 'DAWAKIN TOFA MICROFINANCE BANK LTD',
    },
    {
        value: '510120343',
        label: 'KUSALLA MICROFINANCE BANK LTD',
    },
    {
        value: '510120353',
        label: 'KURA MICROFINANCE BANK LTD',
    },
    {
        value: '510120363',
        label: 'WRA MICROFINANCE BANK LTD',
    },
    {
        value: '510120373',
        label: 'KABO MICROFINANCE BANK LTD',
    },
    {
        value: '510120383',
        label: 'MAKODA MICROFINANCE BANK LTD',
    },
    {
        value: '510120393',
        label: 'GEZAWA MICROFINANCE BANK LTD',
    },
    {
        value: '510120403',
        label: 'GAYA MICROFINANCE BANK LTD',
    },
    {
        value: '510120413',
        label: 'MINJIBIR MICROFINANCE BANK LTD',
    },
    {
        value: '510120423',
        label: 'SUMAILA MICROFINANCE BANK LTD',
    },
    {
        value: '510120433',
        label: 'DOGUWA MICROFINANCE BANK LTD',
    },
    {
        value: '510120443',
        label: 'BUNKURE MICROFINANCE BANK LTD',
    },
    {
        value: '510120453',
        label: 'SHANONO MICROFINANCE BANK LTD',
    },
    {
        value: '510120463',
        label: 'TUDUN WADA MICROFINANCE BANK LTD',
    },
    {
        value: '510120473',
        label: 'KUMBOTSO MICROFINANCE BANK LTD',
    },
    {
        value: '510120483',
        label: 'BEBEJI MICROFINANCE BANK LTD',
    },
    {
        value: '510120493',
        label: 'UNGOGO MICROFINANCE BANK LTD',
    },
    {
        value: '510120503',
        label: 'TAKAI MICROFINANCE BANK LTD',
    },
    {
        value: '510120513',
        label: 'AJINGI MICROFINANCE BANK LTD',
    },
    {
        value: '511080006',
        label: 'NATIONAL HOUSING FUND, FCT ABUJA',
    },
    {
        value: '511080016',
        label: 'ASOMFB ABUJA',
    },
    {
        value: '511080026',
        label: 'NPF MFB:PLOT 1469 AHMADU BELLO WAY GARKI FCT ABUJA',
    },
    {
        value: '511080036',
        label: 'GARKI MICROFINANCE BANK LIMITED',
    },
    {
        value: '511080046',
        label: 'ABBEY SAVINGS AND LOANS LTD - ABUJA',
    },
    {
        value: '511080056',
        label: 'NIG AGRIC COOP RURAL DEV BANK - AREA 2 GARKI, ABUJA',
    },
    {
        value: '511080066',
        label: 'GRANTS MICROFINANCE BANK LIMITED',
    },
    {
        value: '511080076',
        label: 'INFINITY HOMES LIMITED',
    },
    {
        value: '511080086',
        label: 'CONSUMER MICROFINANCE BANK LIMITED',
    },
    {
        value: '511080096',
        label: 'ACE MICROFINANCE BANK LIMITED',
    },
    {
        value: '511080106',
        label: 'SAFELINE MFB: LIBREVILLE STREET, OFF AMINU KANO CRESCENT, WUSE II, ABUJA',
    },
    {
        value: '511080116',
        label: 'BARCLAYS MICROFINANCE BANK',
    },
    {
        value: '511080126',
        label: 'NPF MICRO FINANCE BANK ABUJA BRANCH II',
    },
    {
        value: '511080136',
        label: 'VISA MICROFINANCE BANK ABUJA',
    },
    {
        value: '511080146',
        label: 'ATLAS MICROFINANCE BANK FCT ABUJA',
    },
    {
        value: '511080156',
        label: 'CHALLENGE MICROFINANCE BANK LIMITED',
    },
    {
        value: '511080166',
        label: 'INFINITY TRUST SAV',
    },
    {
        value: '511080176',
        label: 'M & M MICROFINANCE BANK LIMITED',
    },
    {
        value: '511080186',
        label: 'NACRDB GARKI AREA II',
    },
    {
        value: '511080196',
        label: 'NACRDB WUSE',
    },
    {
        value: '511080206',
        label: 'POST-SERVICE SAVINGS AND LOANS LTD',
    },
    {
        value: '511080216',
        label: 'COMMON TRUST MICROFINANCE BANK LIMITED FCT ABUJA',
    },
    {
        value: '511080226',
        label: 'MMMFB MICROFINANCE BANK LIMITED ABUJA',
    },
    {
        value: '511080236',
        label: 'ABU MICROFINANCE BANK, ABUJA',
    },
    {
        value: '511080246',
        label: 'FHA MORTGAGE BANK LIMITED',
    },
    {
        value: '511080346',
        label: 'NIGERIA POLICE MORTGAGE BANK',
    },
    {
        value: '511080356',
        label: 'BANK OF AGRICULTURE LIMITED MINNA',
    },
    {
        value: '511080366',
        label: 'MINNA MICROFINANCE BANK LTD',
    },
    {
        value: '511080376',
        label: 'LAPAI MICROFINANCE BANK NIGERIA LIMITED',
    },
    {
        value: '511080386',
        label: 'LARINKPAN MICROFINANCE BANK KUTIGI',
    },
    {
        value: '511080396',
        label: 'NAISA MICROFINANCE BANK LIMITED',
    },
    {
        value: '511080406',
        label: 'BEJIN-DOKO MICROFINANCE NIGERIA LIMITED',
    },
    {
        value: '511080416',
        label: 'BMAZAZHIN MICROFINANCE BANK',
    },
    {
        value: '511080426',
        label: 'BRASS MICROFINANCE BANK BIDA',
    },
    {
        value: '511080436',
        label: 'CHUIGBE-YAJI MICROFINANCE BANK LIMITED',
    },
    {
        value: '511080446',
        label: 'DANGIZHI MICROFINANCE BANK LIMITED',
    },
    {
        value: '511080456',
        label: 'EDUMANA MICROFINANCE BANK LIMITED',
    },
    {
        value: '511080466',
        label: 'ENDWELL MICROFINANCE BANK LIMITED MINNA',
    },
    {
        value: '511080476',
        label: 'GULFE MICROFINANCE BANK RIJAU',
    },
    {
        value: '511080486',
        label: 'KOTANGORA MICROFINANCE BANK LIMITED',
    },
    {
        value: '511080496',
        label: 'PANA MICROFINANCE BANK',
    },
    {
        value: '511080506',
        label: 'TANADI MICROFINANCE BANK LIMITED WUSHISHI',
    },
    {
        value: '511080516',
        label: 'MODIYA MICROFINANCE BANK LIMITED',
    },
    {
        value: '511080526',
        label: 'MUTUAL TRUST MICROFINANCE BANK FCT',
    },
    {
        value: '511080536',
        label: 'KUNA GABA MICROFINANCE BANK',
    },
    {
        value: '511080546',
        label: 'BUSU MICROFINANCE BANK NIGERIA LIMITED',
    },
    {
        value: '511080556',
        label: 'BABBA MICROFINANCE BANK LIMITED AGAIE',
    },
    {
        value: '511080566',
        label: 'BANK OF AGRICULTURE KONTAGORA',
    },
    {
        value: '511080576',
        label: 'CRUTECH COMMUNITY MFB LTD',
    },
    {
        value: '511080586',
        label: 'YOBE INVESTMENT MICROFINANCE BANK LIMITED',
    },
    {
        value: '511080596',
        label: 'BANK OF AGRICULTURE DAMATURU',
    },
    {
        value: '511080606',
        label: 'BANK OF AGRICULTURE GASHUA',
    },
    {
        value: '511080616',
        label: 'GASHUA MICROFINANCE BANK',
    },
    {
        value: '511080626',
        label: 'NIGERIAN POLICE MORTGAGE BANK',
    },
    {
        value: '511080636',
        label: 'NIGERIAN POLICE MORTGAGE BANK',
    },
    {
        value: '511080646',
        label: 'BANK OF AGRICULTURE BIDA',
    },
    {
        value: '512170012',
        label: 'CLASSIC MFB -GODS GLORY HOUSE, 24 ONIKOLOBO RD, IBARA, ABEOKUTA',
    },
    {
        value: '512170022',
        label: 'SOLID ROCK MFB -IJEMO AGBADU ROAD, AGOKO, ABEOKUTA(17)',
    },
    {
        value: '512170032',
        label: 'LAVENDAR MFB-LAGOS RD, LAFENWA, ABEOKUTA (14)',
    },
    {
        value: '512170042',
        label: 'ABIGI MFB -MOBORODE ODOFIN ST., ABIGI IJEBU WATERSIDE(28)',
    },
    {
        value: '512170052',
        label: 'AFOTAMODI MFB­OGUNOLA AJINA ST. ISARA REMO(1)',
    },
    {
        value: '512170062',
        label: 'AIYEPE MFB -IKENNE ROAD, ODOGBOLU LGA(1)',
    },
    {
        value: '512170072',
        label: 'AJOSE MFB -EJINRIN ROAD, IJEBU SOUTH, OGUN STATE.',
    },
    {
        value: '512170082',
        label: 'AMAZING GRACE MFB -OLUWATOBILOBA HOUSE, ABEOKUTA IBADAN RD,OSIELE',
    },
    {
        value: '512170092',
        label: 'CATLAND MFB -OKE-OWODE ST., ILESE-IJEBU 9140',
    },
    {
        value: '512170102',
        label: 'COVENANT MFB -KM 10, IDIROKO ROAD, CANAAN LAND, OTA',
    },
    {
        value: '512170112',
        label: 'EKUOMBE MFB -COMMUNITY BANK RD, ILARO-YEWA SOUTH (2)',
    },
    {
        value: '512170122',
        label: 'ERUWON MFB -21, CHURCH STREET, ERUWON, IJEBU-ODE(21)',
    },
    {
        value: '512170132',
        label: 'ESO-E MFB -AKARIGBO STREET, SABO SAGAMU(224)',
    },
    {
        value: '512170142',
        label: 'APPLE MFB -AGBON STREET, ORU-IJEBU(26)',
    },
    {
        value: '512170152',
        label: 'IFONYIN MFB -IDIROKO ROAD, AJEGUNLE-ILASE, YEWA SOUTH LGA',
    },
    {
        value: '512170162',
        label: 'IMODI-IMOSAN MFB -MARKET SQUARE, IMODI-IJEBU (19)',
    },
    {
        value: '512170172',
        label: 'IJEBU IFE MFB -AJALORUN STREET, IJEBU EAST LGA(165)',
    },
    {
        value: '512170182',
        label: 'IJEBU-IMUSIN MFB -OJA, IJEBU IMUSIN, IJEBU EAST',
    },
    {
        value: '512170192',
        label: 'IKENNE MFB -TAI SOLARIN WAY, IKENNE REMO,IKENNE LGA',
    },
    {
        value: '512170202',
        label: 'ILARO POLYTECHNIC MFB -AUDIT BUILDING EAST CAMPUS, FEDERAL POLYTECHNIC, ILARO',
    },
    {
        value: '512170212',
        label: 'ILISAN MFB -OLOFIN STREET, ILISAN-REMO(30)',
    },
    {
        value: '512170222',
        label: 'IMOWO MFB -IBADAN ROAD, IJEBU ODE(51A)',
    },
    {
        value: '512170232',
        label: 'INTERLAND MFB -ATAN, ILUGUN-ALARO, IJEBU NORTH-EAST L.G.A,IJEBU ODE',
    },
    {
        value: '512170242',
        label: 'IPERU MFB -AKESAN ROAD, IPERU REMO(94)',
    },
    {
        value: '512170252',
        label: 'ITELE MFB -ALEDO QUARTERS, MOYEGESO STREET, ITELE, IJEBU EAST LGA',
    },
    {
        value: '512170262',
        label: 'IWADE MFB -IWADE HOUSE, OKE AJE MARKET, IJEBU ODE',
    },
    {
        value: '512170272',
        label: 'KARIS MFB -SEGUN OSOBA RD, AGBADO, IFO L.G.A.(66)',
    },
    {
        value: '512170282',
        label: 'LANDGOLD MFB -OKE-ARO ROAD, OKE-ARO(3)',
    },
    {
        value: '512170292',
        label: 'MAPOLY MFB -MAPOLY, OJERE CAMPUS, IBARA, ABEOKUTA',
    },
    {
        value: '512170302',
        label: 'MOLUSI MFB -APOJE ROAD, OKE AGBO, IJEBU-IGBO(67A)',
    },
    {
        value: '512170312',
        label: 'NEW IMAGE MFB -POST OFFICE BUILDING, ODEDA',
    },
    {
        value: '512170322',
        label: 'COMBINED BENEFITS MFB -YAKOYO RD, IFO LGA(2)',
    },
    {
        value: '512170332',
        label: 'OLABISI ONABANJO UNIVERSITY MFB -MINI CAMPUS, AGO IWOYE',
    },
    {
        value: '512170342',
        label: 'OMU MFB -OTUNBA GBENGA DANIEL RD. OMU IJEBU(1)',
    },
    {
        value: '512170352',
        label: 'ORISUN MFB -MELEKI STREET, OLUSEGUN OSOBA WAY, AGO­IWOYE(16)',
    },
    {
        value: '512170362',
        label: 'RIVERSIDE MFB -MARKET ROAD, ADO-ODO',
    },
    {
        value: '512170372',
        label: 'SAGAMU MFB -EWUSI STREET, SAGAMU(67)',
    },
    {
        value: '512170382',
        label: 'TRUST MFB -LOCAL GOVT. RD. OWODE-EGBA(10)',
    },
    {
        value: '512170392',
        label: 'TASUED MFB -KM 54, ABEOKUTA/IBADAN RD, ORILE ILUGUN, ODEDA LGA',
    },
    {
        value: '512170402',
        label: 'OCON SUCCESS MFB-AGBALA OKO AREA, EGBADO NORTH LGA',
    },
    {
        value: '512170412',
        label: 'UNAAB MFB -UNIVERSITY OF AGRICULTURE, ABEOKUTA',
    },
    {
        value: '512170422',
        label: 'WEST-END MFB -COMMUNITY ROAD, IGBESA, ADO ODO LGA(11)',
    },
    {
        value: '512170432',
        label: 'HONEY MFB -FURENDANO STREET, IKIJA IJEBU(8)',
    },
    {
        value: '512170442',
        label: 'AGOSASA MFB -ILUPEJU ESTATE, IPOKIA ROAD, AGOSASA, YEWA SOUTH LGA(5)',
    },
    {
        value: '512170452',
        label: 'ASTRA POLARIS MFB -OGUNMAKIN MAKET, OGUNMAKIN, OBAFEMI OWODE LGA, ABEOKUTA',
    },
    {
        value: '512170462',
        label: 'ALEKUN MFB -OLADIPO DIYA RD, ODOGBOLU(6)',
    },
    {
        value: '512170472',
        label: 'ASCENT MFB -TITILAYO MEMORIAL HOUSE, AKUTE, IFO L.G.A',
    },
    {
        value: '512170492',
        label: 'STAR MFB-SAMSON ODUGBESAN STR, OSOSA-IJEBU(B54)',
    },
    {
        value: '512170502',
        label: 'CENTAGE MFB-EJIRIN RD, IMOPE, IJEBU-ODE(16)',
    },
    {
        value: '512170522',
        label: 'COMMUNITY BANK IPOKIA',
    },
    {
        value: '512170542',
        label: 'GATEWAY SAVINGS & LOANS',
    },
    {
        value: '512170552',
        label: 'BANK OF AGRICULTURE ABIGI',
    },
    {
        value: '512170562',
        label: 'BANK OF AGRICULTURE AYETORO',
    },
    {
        value: '512170572',
        label: 'BANK OF AGRICULTURE IMEKO',
    },
    {
        value: '512170582',
        label: 'BANK OF AGRICULTURE ABEOKUTA',
    },
    {
        value: '512170592',
        label: 'OGUN MFB',
    },
    {
        value: '512170602',
        label: 'BANK OF AGRICULTURE MFB-OTTA',
    },
    {
        value: '512170612',
        label: 'BANK OF AGRICULTURE ODEDA',
    },
    {
        value: '512170622',
        label: 'BANK OF AGRICULTURE AGO-IWOYE',
    },
    {
        value: '512170632',
        label: 'BANK OF AGRICULTURE SAGAMU',
    },
    {
        value: '512170652',
        label: 'INTEGRATED MFB',
    },
    {
        value: '512170662',
        label: 'IROLU MFB',
    },
    {
        value: '512170672',
        label: 'ALEKUN MICROFINANCE BANK,OGUN',
    },
    {
        value: '512170682',
        label: 'IJEBU IFE MICROFINANCE BANK LIMITED',
    },
    {
        value: '512170692',
        label: 'SAPIDA MICROFINANCE BANK LTD',
    },
    {
        value: '512170702',
        label: 'TASUED MICROFINANCE BANK LTD, IJEBU ODE OGUN STATE',
    },
    {
        value: '512170712',
        label: 'AL-HAYAT MICROFINANCE BANK',
    },
    {
        value: '512170722',
        label: 'EKUOMBE MICROFINANCE BANK, OGUN STATE',
    },
    {
        value: '512170732',
        label: 'ILARO POLY MICROFINANCE BANK',
    },
    {
        value: '512170742',
        label: 'NAGARTA MICROFINANCE BANK, OGUN STATE',
    },
    {
        value: '512170752',
        label: 'GATEWAY MICRO FINANCE BANK, OGUN STATE',
    },
    {
        value: '513210013',
        label: 'NPF MFB:NDABA RD,MINIOKORO POLICE STA., RUMUOGBA,P/H',
    },
    {
        value: '513210023',
        label: 'COASTLINE MICRO FINANCE BANK',
    },
    {
        value: '513210033',
        label: 'IWOAMA MICROFINANCE BANK, RIVERS STATE',
    },
    {
        value: '513210043',
        label: 'COSMOPOLITAN MICROFINANCE BANK, RIVERS STATE',
    },
    {
        value: '514040003',
        label: 'NPF MFB:AREA A POLICE COMMAND, SAPELE RD BENIN-CITY',
    },
    {
        value: '514040013',
        label: 'UROMI MICROFINANCE BANK LIMITED',
    },
    {
        value: '514040023',
        label: 'IVIE MICROFINANCE BANK PLC',
    },
    {
        value: '514040033',
        label: 'UDA MICROFINANCE BANK LIMITED EDO STATE',
    },
    {
        value: '514040043',
        label: 'ABOVE ONLY MICROFINANCE BANK',
    },
    {
        value: '515150013',
        label: 'LAGOS BUILDING INVESTMENT CO. MFB -ALAUSA',
    },
    {
        value: '515150023',
        label: 'SKYE MORTGAGE - PLOT 6 CBD ALAUSA, IKEJA LAGOS',
    },
    {
        value: '515150033',
        label: 'UNION HOMES, LAGOS',
    },
    {
        value: '515150043',
        label: 'NPF MFB:OBALENDE BRANCH, LAGOS',
    },
    {
        value: '515150053',
        label: 'NPF MFB:H/O BRANCH OBALENDE, LAGOS',
    },
    {
        value: '515150063',
        label: 'NPF MFB:POLICE COLLEGE OBA AKINJOBI ROAD IKEJA LAGOS',
    },
    {
        value: '515150073',
        label: 'SPECS MFB, LAGOS',
    },
    {
        value: '515150078',
        label: 'ADDOSSER MICROFINANCE BANK LIMITED,LAGOS',
    },
    {
        value: '515150088',
        label: 'FMM/MTN MM POOL ACCOUNT',
    },
    {
        value: '515150098',
        label: 'MTN MOBILE MONEY ACCOUNT',
    },
    {
        value: '515150099',
        label: 'RESORT MICROFINANCE BANK LAGOS',
    },
    {
        value: '515150101',
        label: 'ASHA MICROFINANCE BANK LAGOS STATE',
    },
    {
        value: '515150109',
        label: 'AB MICROFINANCE BANK LTD',
    },
    {
        value: '515150111',
        label: 'ENTERPRISE MICROFINANCE BANK LIMITED',
    },
    {
        value: '515150119',
        label: 'ABBEY MICROFINANCE BANK LTD',
    },
    {
        value: '515150121',
        label: 'OFFICE ADDRESS: NO1 MARKUDI ROAD LAFIA, NASARAWA STATE',
    },
    {
        value: '515150129',
        label: 'ADKOLM-EMERALD MICROFINANCE BANK LIMITED',
    },
    {
        value: '515150131',
        label: 'KEYSTONE MICROFINANCE BANK LIMITED',
    },
    {
        value: '515150141',
        label: 'MAYFIELD FINANCE LIMITED LAGOS STATE',
    },
    {
        value: '515150149',
        label: 'ENTERPRISE MICROFINANCE BANK LIMITED',
    },
    {
        value: '515150151',
        label: 'MORECAB COMPANY LIMITED LAGOS STATE',
    },
    {
        value: '515150161',
        label: 'NEW PRUDENTIAL BUILDING SOCIETY',
    },
    {
        value: '515150171',
        label: 'SKYFIELD SAVINGS',
    },
    {
        value: '515150181',
        label: 'SKYFIELD SAVINGS',
    },
    {
        value: '515150191',
        label: 'UBA MICROFINANCE BANK LIMITED',
    },
    {
        value: '515150201',
        label: 'SEED CAPITA MICROFINANCE BANK,LAGOS STATE',
    },
    {
        value: '515150210',
        label: 'PENIEL MICROFINANCE BANK LIMITED',
    },
    {
        value: '515150220',
        label: 'STERLING NIP',
    },
    {
        value: '515150230',
        label: 'FFS MICROFINANCE BANK',
    },
    {
        value: '515150240',
        label: 'RELIANCE MICROFINANCE BANK, LAGOS',
    },
    {
        value: '516290013',
        label: 'NPF MFB:OSHOGBO-ILOBU RD, OKEFIA, OSOGBO',
    },
    {
        value: '516290023',
        label: 'OBAFEMI AWOLOWO UNIVERSITY MICROFINANCE BANK',
    },
    {
        value: '516290033',
        label: 'IKOYI-OSUN MFB,IKOYI OSUN AREA',
    },
    {
        value: '516290043',
        label: 'INSIGHT MFB OSUN STATE',
    },
    {
        value: '516290053',
        label: 'ODUDWA MICROFINANCE BANK,OSUN STATE',
    },
    {
        value: '517150014',
        label: 'ORITA BASHORUN MFB -BASHORUN, IBADAN',
    },
    {
        value: '517150024',
        label: 'MULTIVEST MFB -OJO, IBADAN',
    },
    {
        value: '517150034',
        label: 'IGBOORA MFB -IGBOORA, IBADAN',
    },
    {
        value: '517150044',
        label: 'PACESETTERS MFB -OJO, IBADAN',
    },
    {
        value: '517150054',
        label: 'CIVICMFB - AGODI-GATE, IBADAN',
    },
    {
        value: '517150064',
        label: 'OLOGBON MFB, IBADAN',
    },
    {
        value: '517150074',
        label: 'CREST MFB, IBADAN',
    },
    {
        value: '517150084',
        label: 'AYETE MFB LTD, IBADAN',
    },
    {
        value: '517150094',
        label: 'AKESAN MFB LTD, IBADAN',
    },
    {
        value: '517150104',
        label: 'OPP ASEYIN PALACE, ISEYIN LGA, IBADAN',
    },
    {
        value: '517150114',
        label: 'ODO-ARO QUARTERS, OGBOMOSHO ROAD, OYO',
    },
    {
        value: '517150124',
        label: 'HOSPITAL ROAD, SANGO ERUWA - AGBENI, IBADAN',
    },
    {
        value: '517150134',
        label: '38 AGBOKOTO STR, IBADAN',
    },
    {
        value: '517150144',
        label: 'IKOYI ILE MFB, IBADAN',
    },
    {
        value: '517150154',
        label: 'M.B.A MFB, IBADAN',
    },
    {
        value: '517150164',
        label: 'CUSTODIAN MFB, IBADAN',
    },
    {
        value: '517150174',
        label: 'OJA-TESAN MFB, IBADAN',
    },
    {
        value: '517150184',
        label: 'IPAPO MFB, IBADAN',
    },
    {
        value: '517150194',
        label: 'SEPETERI MFB, IBADAN',
    },
    {
        value: '517150204',
        label: 'CARETAKER MFB, IBADAN',
    },
    {
        value: '517150214',
        label: 'ONIBU-ORE MFB, IBADAN',
    },
    {
        value: '517150224',
        label: 'FIRST INDEX MICROFINANCE BANK LIMITED',
    },
    {
        value: '517150234',
        label: 'OLU-BASIRI MICROFINANCE BANK LIMITED',
    },
    {
        value: '517150244',
        label: 'UNIBADAN MICROFINANCE BANK',
    },
    {
        value: '518150014',
        label: 'YOBESAVING & LOANS',
    },
    {
        value: '518150024',
        label: 'BANK OF AGRICULTURE, DAMATURU YOBE',
    },
    {
        value: '518150034',
        label: 'FEDERAL MORTGAGE BANK YOBE',
    },
    {
        value: '518150044',
        label: 'GASHUA MFB',
    },
    {
        value: '518150054',
        label: 'BANK OF AGRICULTURE - POTISKUM, YOBE STATE',
    },
    {
        value: '518150064',
        label: 'NIGERIA AGRICULTURAL CO-OPERATIVE AND RURAL DEVPT BANK LTD',
    },
    {
        value: '519290019',
        label: 'ZION MICROFINANCE BANK',
    },
    {
        value: '519290029',
        label: 'BANK OF AGRICULTURE MAKURDI',
    },
    {
        value: '520150010',
        label: '23, OLOFA WAY, OFFA, KWARA STATE',
    },
    {
        value: '520150020',
        label: '10 AHMADU BELLO WAY, GRA ILORIN',
    },
    {
        value: '520150030',
        label: 'BALOGUN FULANI MFB',
    },
    {
        value: '520150040',
        label: 'YERU-OKIN MFB',
    },
    {
        value: '520150050',
        label: 'SINCERE MFB',
    },
    {
        value: '520150060',
        label: 'OSI MFB',
    },
    {
        value: '520150070',
        label: 'ILOFA MFB',
    },
    {
        value: '520150080',
        label: 'MAGAJI NGERI MFB',
    },
    {
        value: '520150090',
        label: 'ILUDUN-ORO',
    },
    {
        value: '520150100',
        label: 'IBOLO MFB, KWARA STATE',
    },
    {
        value: '520150110',
        label: 'HERITAGE MFB, KWARA STATE',
    },
    {
        value: '520150120',
        label: 'OMU ARAN MFB, KWARA STATE',
    },
    {
        value: '520150130',
        label: 'AJIKOBI MFB, KWARA STATE',
    },
    {
        value: '520150140',
        label: 'BALOGUN GAMBARI MFB, KWARA STATE',
    },
    {
        value: '520150150',
        label: 'ILORIN MFB, KWARA STATE',
    },
    {
        value: '520150160',
        label: 'JAMAL MFB, KWARA STATE',
    },
    {
        value: '520150170',
        label: 'IJOMU ORO MFB, KWARA STATE',
    },
    {
        value: '520150180',
        label: 'APEX MFB, KWARA STATE',
    },
    {
        value: '521150010',
        label: 'IKOLE MICROFINANCE BANK, IKOLE-EKITI',
    },
    {
        value: '521150020',
        label: 'SUNBEAM MICROFINANCE BANK LTD, EKITI',
    },
    {
        value: '521150030',
        label: 'ARAMOKO MFB LIMITED, EKITI',
    },
    {
        value: '522150010',
        label: 'JIGAWA SAVINGS AND LOANS',
    },
    {
        value: '522150020',
        label: 'MAIGATARI MICROFINANCE BANK LTD',
    },
    {
        value: '522150030',
        label: 'DUTSE MICROFINANCE BANK LTD',
    },
    {
        value: '522150040',
        label: 'JIGAWA SAVINGS AND LOANS DUTSE',
    },
    {
        value: '522150050',
        label: 'JIGAWA SAVINGS AND LOANS LTD KAZAURE',
    },
    {
        value: '522150060',
        label: 'JIGAWA SAVINGS AND LOANS LTD, RINGIM',
    },
    {
        value: '522150070',
        label: 'JIGAWA SAVINGS AND LOANS LTD, MAIGATARI',
    },
    {
        value: '522150080',
        label: 'NEW WORLD MICROFINANCE BANK LTD',
    },
    {
        value: '522150090',
        label: 'KHB MICROFINANCE BANK PLC',
    },
    {
        value: '522150100',
        label: 'RINGIRIM MICROFINANCE BANK',
    },
    {
        value: '522150110',
        label: 'BABURA MICROFINANCE BANK',
    },
    {
        value: '522150120',
        label: 'KAUGAMA MICRO FINANCE BANK LTD',
    },
    {
        value: '522150130',
        label: 'KRGYMFB MICRO FINANCE BANK LTD KAZAURE',
    },
    {
        value: '522150140',
        label: 'HADEJIA MICROFINANCE BANK LTD, JIGAWA STATE',
    },
    {
        value: '522150150',
        label: 'TAURA MICROFINANCE BANK LTD, JIGAWA STATE',
    },
    {
        value: '523150014',
        label: 'HAMDA MICRO FINANCE BANK',
    },
    {
        value: '523150024',
        label: 'BARNAWA MICROFINANCE BANK',
    },
    {
        value: '523150034',
        label: 'ASO SAVINGS AND LOANS, KADUNA',
    },
    {
        value: '523150044',
        label: 'LEGACY MFB KADUNA',
    },
    {
        value: '524000001',
        label: 'NPF SOKOTO MICROFINANCE BANK LIMITED',
    },
    {
        value: '524150001',
        label: 'NAGARTA MFB SOKOTO',
    },
    {
        value: '525120004',
        label: 'NORTH CAPITAL MFB,KATSINA',
    },
    {
        value: '525120005',
        label: 'GAREWA MFB KATSINA',
    },
    {
        value: '525120006',
        label: 'HINACHE MFB KATSINA',
    },
    {
        value: '525120016',
        label: 'IVIE MICROFINANCE BANK',
    },
    {
        value: '525120026',
        label: 'SABON YELWA MICROFINANCE BANK LIMITED',
    },
    {
        value: '525120036',
        label: 'ALBASU MICROFINANCE BANK LTD,KANO STATE',
    },
    {
        value: '525120046',
        label: 'BAGWAI MICROFINANCE BANK LTD',
    },
    {
        value: '526120002',
        label: 'ALL WORKERS MICROFINANCE BANK - OWERRI',
    },
    {
        value: '526120012',
        label: 'BAKASI MICROFINANCE BANK',
    },
    {
        value: '527120002',
        label: 'AACB MICROFINANCE BANK LIMITED',
    },
    {
        value: '527120012',
        label: 'FEDETH MICROFINANCE BANK',
    },
    {
        value: '527120022',
        label: 'KEYSTONE MICROFINANCE BANK LIMITED',
    },
    {
        value: '527120032',
        label: 'NASSARAWA MICROFINANCE BANK LIMITED',
    },
    {
        value: '527120042',
        label: 'NCWS MICRO FINANCE',
    },
    {
        value: '527120052',
        label: 'NICE MICROFINANCE BANK LIMITED',
    },
    {
        value: '527120062',
        label: 'POST SERVICE SAVINGS AND LOANS LTD',
    },
    {
        value: '527120072',
        label: 'MICROCRED MICROFINANCE BANK LIMITED',
    },
    {
        value: '527120082',
        label: 'ALEX FINANCE & TRUST LIMITED',
    },
    {
        value: '527120092',
        label: 'FIRST BOND FINANCE',
    },
    {
        value: '527120102',
        label: 'FORWARD MICROFINANCE BANK LIMITED',
    },
    {
        value: '527120112',
        label: 'PEOPLES MICROFINANCE BANK LIMITED',
    },
    {
        value: '528130004',
        label: 'EDUEK MICROFINANCE BANK - UYO',
    },
    {
        value: '529120002',
        label: 'KEBBI HOME SAVINGS - YELWA-YAURI',
    },
    {
        value: '530120002',
        label: 'DEC ENUGU MFB LTD',
    },
    {
        value: '530120012',
        label: 'OGIGE MICROFINANCE BANK LIMITED',
    },
    {
        value: '531130002',
        label: 'FIRST ROYAL MICROFINANCE BANK LIMITED',
    },
    {
        value: '531130012',
        label: 'SOLACE MICROFINANCE BANK LIMITED',
    },
    {
        value: '532020001',
        label: 'KABBA MICROFINANCE BANK LIMITED',
    },
    {
        value: '532020010',
        label: 'OKENGWE MICROFINANCE BANK LIMITED,KOGI STATE',
    },
    {
        value: '532020020',
        label: 'AKWENGWU MICROFINANCE BANK,KOGI STATEE',
    },
    {
        value: '532020030',
        label: 'GAINS MFB LTRD',
    },
    {
        value: '532020040',
        label: 'OVIDI MFB LTRD',
    },
    {
        value: '532020050',
        label: 'UNYOGBA MICROFINANCE BANK, KOGI',
    },
    {
        value: '532020060',
        label: 'KOGI SAVINGS AND LOANS LTD, KOGI STATE',
    },
    {
        value: '532020070',
        label: 'IYAMOYE MICROFINANCE BANK, IYAMOFE',
    },
    {
        value: '532020080',
        label: 'UNYOGBA MICROFINANCE BANK LTD, AYINGBA',
    },
    {
        value: '532020090',
        label: 'OKENGWE MICROFINANCE BANK, OZUJA',
    },
    {
        value: '532020100',
        label: 'PROGRESSIVE LINK MFB, ISANLU',
    },
    {
        value: '532020110',
        label: 'ODU MICROFINANCE BANK, DEKINA',
    },
    {
        value: '532020120',
        label: 'MOPA MICROFINANCE BANK, OKEDIGBA',
    },
    {
        value: '532020130',
        label: 'KOGI SAVINGS AND LOANS LTD, PAPARANDA SQUARE',
    },
    {
        value: '532020140',
        label: 'KOGI SAVINGS AND LOANS LTD, KABBA',
    },
    {
        value: '532020150',
        label: 'OHON MICROFINANCE BANK LTD',
    },
    {
        value: '532020160',
        label: 'EGBE MICROFINANCE BANK PLC',
    },
    {
        value: '532020170',
        label: 'SOLIDBASE MICROFINANCE BANK',
    },
    {
        value: '532020180',
        label: 'GBEDE MICROFINANCE BANK',
    },
    {
        value: '532020190',
        label: 'BANK OF AGRIC KABBA',
    },
    {
        value: '532020200',
        label: 'OKENGWE MICROFINANCE BANK, OZUJA WARD',
    },
    {
        value: '532020210',
        label: 'BANK OF AGRICULTURE LTD, ANYIGBA',
    },
    {
        value: '532020220',
        label: 'CONFLUENCE MICROFINANCE BANK',
    },
    {
        value: '532020221',
        label: 'ULTIMATE BENEFIT INTEGRATED MFB',
    },
    {
        value: '532020222',
        label: 'KOGI MICROFINANCE BANK PLC',
    },
    {
        value: '532020230',
        label: 'EARNWELL MICROFINANCE BANK LIMITED',
    },
    {
        value: '532020240',
        label: 'BANK OF AGRICULTURE LTD, LOKOJA',
    },
    {
        value: '532020250',
        label: 'AJEKO MICROFINANCE BANK',
    },
    {
        value: '532020260',
        label: 'AMURO MICROFINANCE BANK LIMITED',
    },
    {
        value: '532020270',
        label: 'BANK OF AGRICULTURE LTD AYETORO GBEDE IGUMU LGA',
    },
    {
        value: '533020001',
        label: 'NPF PORTHARCOURT MICROFINANCE BANK LIMITED',
    },
    {
        value: '533020010',
        label: 'U & C MICROFINANCE BANK, RIVERS STATE',
    },
    {
        value: '534020001',
        label: 'NPF KANO MICROFINANCE BANK LIMITED',
    },
    {
        value: '535020001',
        label: 'NPF OSOGBO MICROFINANCE BANK LIMITED',
    },
    {
        value: '536000001',
        label: 'NPF ONITSHA MICROFINANCE BANK LIMITED',
    },
    {
        value: '537000001',
        label: 'NPF AKURE MICROFINANCE BANK LIMITED',
    },
    {
        value: '538000001',
        label: 'OAKLAND MFB OKA-AKOKO',
    },
    {
        value: '538000010',
        label: 'EKIMOGUN MFB ONDO',
    },
    {
        value: '538000020',
        label: 'OREDEGBE MFB OKE-ARO, AKURE',
    },
    {
        value: '538000030',
        label: 'IJARE MFB IJARE',
    },
    {
        value: '538000040',
        label: 'OROKE MFB AKUNGBA AKOKO',
    },
    {
        value: '538000050',
        label: 'OKEAGBE MFB OKEAGBE AKOKO',
    },
    {
        value: '538000060',
        label: 'NEWAGE MFB LTD, IKARE AKOKO',
    },
    {
        value: '538000070',
        label: 'AJUTA MICROFINANCE BANK',
    },
    {
        value: '538000080',
        label: 'AOGO MFB ONDO',
    },
    {
        value: '538000090',
        label: 'ILUTITUN MFB',
    },
    {
        value: '538000100',
        label: 'KOREDE MFB',
    },
    {
        value: '538000110',
        label: 'LAYELU MFB, ODEAYE',
    },
    {
        value: '538000120',
        label: 'COMMUNITY BANK ITA-OGBOLU, ONDO STATE',
    },
    {
        value: '538000130',
        label: 'COMMUNITY BANK ILARO-MOKIN, ONDO STATE',
    },
    {
        value: '538000140',
        label: 'COOPERATIVE SAVINGS AND LOANS, ONDO STATE',
    },
    {
        value: '538000150',
        label: 'BANK OF AGRICULTURE ALAGBAKA, ONDO STATE',
    },
    {
        value: '538000160',
        label: 'BANK OF AGRICULTURE IKARE, ONDO STATE',
    },
    {
        value: '538000170',
        label: 'ODUDUWA COMMUNITY BANK , ONDO STATE',
    },
    {
        value: '538000180',
        label: 'ILEOLUJI MFB',
    },
    {
        value: '539120012',
        label: 'ADAMAWA HOMES AND SAVINGS',
    },
    {
        value: '539120022',
        label: 'STANDARD MICRO-FINANCE BANK, YOLA',
    },
    {
        value: '539120032',
        label: 'UMMAH MICRO-FINANCE BANK, YOLA',
    },
    {
        value: '540000010',
        label: 'UGBOJU MICROFINANCE BANK',
    },
    {
        value: '540000020',
        label: 'BANK OF AGRICULTURE LIMITED, ZAKI-BIAM',
    },
    {
        value: '540000030',
        label: 'APA MICROFINANCE BANK',
    },
    {
        value: '540000040',
        label: 'BANK OF AGRICULTURE LIMITED, MAKURDI',
    },
    {
        value: '540000050',
        label: 'BANK OF AGRICULTURE LIMITED, OTUKPOI',
    },
    {
        value: '540000060',
        label: 'OKPOGA MFB',
    },
    {
        value: '540000070',
        label: 'OTUKPO MFB',
    },
    {
        value: '540000080',
        label: 'GBOKO MFB',
    },
    {
        value: '541000010',
        label: 'GOMBE MICRO FINANCE BANK',
    },
    {
        value: '541000020',
        label: 'JEWEL COOPS MICRO FINANCE BANK',
    },
    {
        value: '541000030',
        label: 'NACRDB BAJOGA',
    },
    {
        value: '541000040',
        label: 'NACRDB BILLIRI',
    },
    {
        value: '541000050',
        label: 'NACRDB GOMBE',
    },
    {
        value: '541000060',
        label: 'SHONGOM MICRO FINANCE BANK',
    },
    {
        value: '541000070',
        label: 'SUNTRUST SAVINGS & LOAN',
    },
    {
        value: '541000080',
        label: 'TANGALE MICRO FINANCE BANK',
    },
    {
        value: '541000090',
        label: 'YANKARI SAVINGS & LOANS',
    },
    {
        value: '541000100',
        label: 'MADOBI MICROFINANCE BANK, KANO STATE',
    },
    {
        value: '541000110',
        label: 'BUBAYARO MICROFINANCE BANK LTD GOMBE',
    },
    {
        value: '541000120',
        label: 'DUKKU MICROFINANCE BANK LTD',
    },
    {
        value: '541000130',
        label: 'NAFADA MICROFINANCE BANK LTD',
    },
    {
        value: '541000140',
        label: 'BILLIRI MICROFINANCE BANK LTD',
    },
    {
        value: '541000150',
        label: 'AKKO MICROFINANCE BANK LTD',
    },
    {
        value: '541000160',
        label: 'BIMA MICROFINANCE BANK LTD',
    },
    {
        value: '541000170',
        label: 'FUNAKAYE MICROFINANCE BANK LTD BAJOGA',
    },
    {
        value: '541000180',
        label: 'SPS MICROFINANCE BANK, GOMBE',
    },
    {
        value: '541000190',
        label: 'BALANGA MICROFINANCE BANK LTD, GOMBE STATE',
    },
    {
        value: '542150004',
        label: 'NASARAWA MFB',
    },
    {
        value: '542150010',
        label: 'AMBA MICROFINANCE BANK LTD',
    },
    {
        value: '542150014',
        label: 'MAYFRESH MORTGAGE BANK',
    },
    {
        value: '542150024',
        label: 'BANK OF AGRICULTURE LTD NASARAWA',
    },
    {
        value: '542150034',
        label: 'FORTIS MICROFINANCE BANK PLC',
    },
    {
        value: '542150044',
        label: 'ADA MICROFINANCE BANK LTD',
    },
    {
        value: '542150054',
        label: 'BOAKWANGA',
    },
    {
        value: '542150064',
        label: 'FEDPOLY MICROFINANCE BANK LIMITED',
    },
    {
        value: '542150074',
        label: 'FCE OBUDU MICROFINANCE BANK',
    },
    {
        value: '542150084',
        label: 'FORTIS MICROFINANCE BANK PLC, ABAJI BRANCH',
    },
    {
        value: '542150094',
        label: 'BANK OF AGRICULTURE LTD, ABAJI',
    },
    {
        value: '542150604',
        label: 'KHB MICROFINANCE BANK PLC',
    },
    {
        value: '543000001',
        label: 'BORGU MICROFINANCE BANK LTD MINNA NIGER STATE',
    },
    {
        value: '544000001',
        label: 'UNICAL MICORFINANCE BANK, CALABAR',
    },
    {
        value: '545000004',
        label: 'DADINKOWA MICROFINANCE BANK',
    },
    {
        value: '545000014',
        label: 'BANK OF AGRICULTURE,PLATEAU',
    },
    {
        value: '545000024',
        label: 'WASE MICRO FINANCE BANK',
    },
    {
        value: '545000034',
        label: 'YENENG MICROFINANCE BANK',
    },
    {
        value: '545000044',
        label: 'HIGHLAND MICROFINANCE BANK',
    },
    {
        value: '545000054',
        label: 'BANK PLAZA NO. 2, MISSION ROAD, P. O. BOX 283, MANGU, PLATEAU STATE',
    },
    {
        value: '545000064',
        label: '90, SOLOMON LAR WAY, NEAR COCA COLA DEPOT LANGTANG',
    },
    {
        value: '545000074',
        label: 'LIGHT MICROFINANCE BANK',
    },
    {
        value: '545000084',
        label: 'BOKKOS MICROFINANCE BANK, PLATEAU STATE',
    },
    {
        value: '545000094',
        label: 'FIRST LOWLAND MICROFINANCE BANK',
    },
    {
        value: '550000001',
        label: 'ALL WORKERS MICROFINANCE BANK LTD,IMO STATE',
    },
    {
        value: '550000011',
        label: 'ALVANA MICROFINANCE BANK',
    },
    {
        value: '551000001',
        label: 'MICHIKA MICROFINANCE BANK LIMITED',
    },
    {
        value: '552000001',
        label: 'UNIUYO MICROFINANCE BANK LIMITED',
    },
    {
        value: '553000005',
        label: 'NINGI MICROFINANCE BANK',
    },
    {
        value: '553000015',
        label: 'KATAGUM MICROFINANCE BANK',
    },
    {
        value: '553000025',
        label: 'GUDDIRI MICROFINANCE BANK',
    },
    {
        value: '553000035',
        label: 'BAUCHI INVESTMENT CORPORATION MICROFINANCE BANK',
    },
    {
        value: '553000045',
        label: 'GARU MICROFINANCE BANK',
    },
    {
        value: '553000055',
        label: 'BANK OF AGRICULTURE BAUCHI',
    },
    {
        value: '553000065',
        label: 'BANK OF AGRICULTURE JAMAARE',
    },
    {
        value: '553000075',
        label: 'YANKARI SAVINGS AND LOANS LIMITED',
    },
    {
        value: '553000085',
        label: 'GAMAWA MICROFINANCE BANK',
    },
    {
        value: '553000095',
        label: 'MISAU MICROFINANCE BANK',
    },
    {
        value: '553000105',
        label: 'CFA MICROFINANCE BANK',
    },
    {
        value: '553000115',
        label: 'ATBU MICROFINANCE BANK',
    },
    {
        value: '554000001',
        label: 'CALABAR MICROFINANCE BANK LIMITED',
    },
    {
        value: '554000010',
        label: 'EKONDO MICROFINANCE BANK LTD',
    },
    {
        value: '555000001',
        label: 'SUNBEAM MICROFINANCE BANK',
    },
    {
        value: '556000001',
        label: 'SHINKAFI MICROFINANCE BANK LIMITED',
    },
    {
        value: '556000011',
        label: 'BUNGUDU MICRO FINANCE',
    },
    {
        value: '556000021',
        label: 'KWATASHI MICRO FINANCE',
    },
    {
        value: '556000031',
        label: 'NAMODA MICRO FINANCE',
    },
    {
        value: '556000041',
        label: 'ZURMI MICRO FINANCE',
    },
    {
        value: '556000051',
        label: 'NIG.AGRIC CO-OP & RURAL DEV.BANK LTD.',
    },
    {
        value: '557000001',
        label: 'OLOFIN MICROFINANCE BANK LIMITED',
    },
    {
        value: '558000001',
        label: 'UMUCHUKWU MICROFINANCE BANK LIMITED',
    },
    {
        value: '558000011',
        label: 'UVUOMA MICROFINANCE BANK LIMITED',
    },
    {
        value: '558000021',
        label: 'MICHEAL OKPARA UNIVERSITY OF AGRICULTURE MICROFINANCE BANK LIMITED, ABIA',
    },
    {
        value: '559000004',
        label: 'GOLDENFUNDS MICROFIFNANCE BANK LTD',
    },
    {
        value: '559000014',
        label: 'CHIDERA MICRO-FINANCE BANK',
    },
    {
        value: '559000024',
        label: 'OHHA MICRO-FINANCE BANK',
    },
    {
        value: '559000034',
        label: 'OROKAM MICRO-FINANCE BANK',
    },
    {
        value: '559000044',
        label: 'OKPOGA MICRO-FINANCE BANK',
    },
    {
        value: '559000054',
        label: 'URBAN MICROFINANCE BANK',
    },
    {
        value: '559000064',
        label: 'UMUCHINEMERE MICROFINANCE BANK',
    },
    {
        value: '560000003',
        label: 'OKO MICROFINANCE BANK LIMITED, ANAMBRA',
    },
    {
        value: '560000013',
        label: 'ISUOFIA MICROFINANCE BANK LIMITED, ANAMBRA',
    },
    {
        value: '560000023',
        label: 'EQUINOX MICRO FINANCE BANK, ANAMBRA STATE',
    },
    {
        value: '561000012',
        label: 'POLYUWANA MICROFINANCE BANK',
    },
    {
        value: '561000022',
        label: 'MONARCH MICROFINANCE BANK EBONYI STATE',
    },
    {
        value: '590000001',
        label: 'POCKET MONEY',
    },
    {
        value: '592401010',
        label: 'BANK OF AGRICULTURE MICROFINANCE, GUSAU',
    },
    {
        value: '511080016001',
        label: 'ASO SAVINGS',
    },
]
export default arr

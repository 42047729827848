import React from 'react'
import { Text, Grid } from 'flexibull2'
import LabelComponent, { InfoComponent } from '../components/LabelComponent'
const DeliveryInfo = ({ info }) => {
    const { country, phone_number, postal_code, state, street_address, town } =
        info
    return (
        <div style={{ width: '80%', paddingTop: 20 }}>
            <Text bold size="18px" style={{ paddingLeft: 10 }}>
                Delivery Information
            </Text>
            <Grid default="1fr">
                <LabelComponent label="Delivery Option" />
            </Grid>
            <Grid default="1fr 1fr 1fr">
                <InfoComponent value="Physical address" />
            </Grid>
            <Grid default="1fr 1fr 1fr">
                <LabelComponent label="Country" />
                <LabelComponent label="P.O. Box" />
                <LabelComponent label="Phone Number" />
            </Grid>
            <Grid default="1fr 1fr 1fr">
                <InfoComponent value={country} />
                <InfoComponent value={postal_code} />
                <InfoComponent value={phone_number} />
            </Grid>
            <Grid default="1fr">
                <LabelComponent label="Contact Address" />
            </Grid>
            <Grid default="1fr 1fr 1fr">
                <InfoComponent
                    value={`${street_address || ''} ${town || ''} ${
                        state || ''
                    }`}
                />
            </Grid>
        </div>
    )
}

export default DeliveryInfo

import * as React from 'react'
import PropTypes from 'prop-types'
import {
    Box,
    Text,
    DashCard,
    CardSection,
} from 'flexibull2'
import { useAtom } from 'jotai'
import {
    beneficiariesAtom,
} from './payment_config.store'

const PaymentConfiguration = ({
    service,
    serviceConponent,
}) => {
    const [beneficiaries] = useAtom(beneficiariesAtom) 

    const sumBeneficiariesAmount = () => {
        return beneficiaries
            .filter(({ beneficiary_type }) => service === beneficiary_type)
            .map(({ amount }) => parseInt(amount))
            .reduce((acc, curr) => {
                return acc + curr
            }, 0)
    }
    return (
        <>
            {serviceConponent}
            <Box pad="10px 0">
                <Text>
                    This is the total amount applicants pay when requesting
                </Text>
            </Box>
            <Box>
                <DashCard icon iconColor="tomato">
                    <CardSection left>
                        Total amount
                        <Text
                            bold
                            block
                            size="20px"
                            style={{ marginTop: '15px' }}
                        >
                            {service} fee
                        </Text>
                    </CardSection>
                    <CardSection bottomRight>
                        <Box align="right">
                            <Text bold block size="20px">
                                ₦{sumBeneficiariesAmount()}
                            </Text>
                        </Box>
                    </CardSection>
                </DashCard>
            </Box>
        </>
    )
}

PaymentConfiguration.propTypes = {
    service: PropTypes.string.isRequired,
    alternateName: PropTypes.element.isRequired,
    serviceConponent: PropTypes.element.isRequired,
}
export default PaymentConfiguration

import React, { useEffect, useContext, useState } from 'react'
import { useAuth } from '../../../context/auth-context'
import {
    Box,
    Grid,
    Text,
    Button,
    Tabs,
    Input,
    Spacer,
    Notify,
    Select,
} from 'flexibull2'
import { LightText } from '../../components/Styles/LightText.style'
import { Divider } from '../../components/Styles/Divider.js'
import EditSchool from './EditSchool'
import { FacultyDetails } from './FacultyDetails'
import { DepartmentDetails } from './DepartmentDetails'
import { CourseDetails } from './CourseDetails'
import { Admin } from './Admin'
import InstitutionContext from '../../../context/institution/institutionContext'
import Service from './Service'
import { FlexiColor } from '../../components/colorpicker'
import { Theme } from '../../config/theme'
import { ERROR_ALERT, TOP_RIGHT } from '../../../utils/constants'
import AddAdmin from './AddAdmin'
import BreadCrumbs from '../../components/BreadCrumbs'
import Paylog from '../../instituteConfig/Paylog'
import { getRoleId } from './services'
import Merchant from './Merchant'

const Details = ({
    match: {
        params: { id },
    },
}) => {
    const [update, setUpdate] = useState(false)
    const [open, setOpen] = useState(false)
    const [userRoleId, setUserRoleID] = useState('')
    const [openService, setOpenService] = useState(false)
    const institutionContext = useContext(InstitutionContext)
    const [payment, setPayment] = useState({
        transcript_fee: '',
        delivery_fee: '',
    })

    const {
        getFees,
        updateFees,
        fees,
        current,
        onProgress,
        getInstitutionByName,
        updateInstitutions,
        loading,
        fetchPayment,
        payments,
        updatePayment,
        institution,
        addPayment,
        setBeneficiary,
        beneficiary,
        deletePayment,
    } = institutionContext
    const { transcript_fee, delivery_fee } = payment
    useEffect(() => {
        getFees()
        getInstitutionByName(id)
        fetchPayment(id)
        getRoleId(id).then(({ data: { entities } }) => {
            const { id } = entities[0]

            setUserRoleID(id)
        })
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    useEffect(() => {
        if (fees) {
            const { transcript_fee, delivery_fee } = fees
            setPayment({
                transcript_fee: transcript_fee,
                delivery_fee: delivery_fee,
            })
        }
        const res = []
        localStorage.setItem('courses', JSON.stringify(res))
        localStorage.setItem('faculties', JSON.stringify(res))
        localStorage.setItem('departments', JSON.stringify(res))
    }, [fees])

    // eslint-disable-next-line
    const onSavePayment = (e) => {
        e.preventDefault()
        if (transcript_fee === '' || delivery_fee === '') {
            Notify(`Please fill in all the fields and try again`, {
                position: TOP_RIGHT,
                status: ERROR_ALERT,
            })
        } else {
            const data = { transcript_fee, delivery_fee, id: fees?.id }
            updateFees(data)
        }
    }

    const loadingText = 'loading...'

    const { org } = useAuth()
    const colors = [
        '#D9E3F0',
        '#F47373',
        '#697689',
        '#37D67A',
        '#2CCCE4',
        '#555555',
        '#dce775',
        '#ff8a65',
        '#ba68c8',
        '#6b48ff',
        '#FA0026',
        '#42361E',
        '#2b9464',
        '#33363b',
        '#0072BB',
        '#ef5285',
        '#003a44',
        '#06565b',
        '#86269b',
        '#cc0063',
        '#4C6085',
        '#f9a11b',
        '#fdc23e',
    ]

    return (
        <div>
            <Box maxWidth="1100px">
                <Box pad="0 30px">
                    <BreadCrumbs
                        links={[
                            {
                                path: `/${org}/institutions`,
                                text: 'Institutions',
                            },
                        ]}
                        last={current?.short_name}
                    />
                </Box>
                <Box pad="20px 50px">
                    <Grid default="1fr  3fr">
                        <Box
                            pad="5px"
                            style={{ marginTop: '-1em', marginRight: '-2rem' }}
                        >
                            <img
                                style={{ borderRadius: '50%' }}
                                src={current?.logo_url}
                                width="150px"
                                height="150px"
                                alt="user"
                            />
                        </Box>

                        <Box style={{ marginLeft: '-2rem' }} relative>
                            <Grid default="1fr 1fr 1fr 1fr">
                                <Box>
                                    <LightText bold>
                                        <Text size="15px">
                                            {' '}
                                            Institution's Name
                                        </Text>
                                    </LightText>
                                    <Box margin="5px 0">
                                        <Text size="15px" bold>
                                            {loading
                                                ? loadingText
                                                : current?.full_name}
                                        </Text>
                                    </Box>
                                </Box>
                                <Box>
                                    <LightText bold>
                                        <Text size="15px">
                                            {' '}
                                            Institution Short Name
                                        </Text>
                                    </LightText>
                                    <Box margin="5px 0">
                                        <Text size="15px" bold>
                                            {loading
                                                ? loadingText
                                                : current?.short_name}
                                        </Text>
                                    </Box>
                                </Box>
                                <Box>
                                    <LightText bold>
                                        <Text size="15px">
                                            {' '}
                                            Institution Location
                                        </Text>
                                    </LightText>
                                    <Box margin="5px 0">
                                        <Text size="15px" bold>
                                            {loading
                                                ? loadingText
                                                : current?.location}
                                        </Text>
                                    </Box>
                                </Box>
                                <Box>
                                    <Box margin="5px 0">
                                        <Box>
                                            <Button
                                                pale
                                                spaceRight
                                                color={Theme.PrimaryBlue}
                                                fontColor={Theme.PrimaryBlue}
                                                iconRight={
                                                    <i className="icon-pencil" />
                                                }
                                                onClick={() => setUpdate(true)}
                                            >
                                                Edit
                                            </Button>
                                        </Box>
                                    </Box>
                                </Box>
                            </Grid>
                            <Box margin="20px 0">
                                <Grid default="1fr 3fr ">
                                    <Box>
                                        <LightText bold>
                                            <Text size="15px">
                                                {' '}
                                                Institution Email
                                            </Text>
                                        </LightText>
                                        <Box margin="5px 0">
                                            <Text size="15px" bold>
                                                {loading
                                                    ? loadingText
                                                    : current?.email_address}
                                            </Text>
                                        </Box>
                                    </Box>
                                    <Box>
                                        <LightText bold>
                                            <Text size="15px">
                                                {' '}
                                                Institution Phone Number
                                            </Text>
                                        </LightText>
                                        <Box margin="5px 0">
                                            <Text size="15px" bold>
                                                {loading
                                                    ? loadingText
                                                    : current?.phone_number}
                                            </Text>
                                        </Box>
                                    </Box>
                                </Grid>
                            </Box>
                            <Box margin="20px 0">
                                <Grid default="1fr">
                                    <Box>
                                        <LightText bold>
                                            <Text size="15px">
                                                {' '}
                                                Institution Physical Address
                                            </Text>
                                        </LightText>
                                        <Box margin="5px 0">
                                            <Text size="15px" bold>
                                                {loading
                                                    ? loadingText
                                                    : current?.physical_address}
                                            </Text>
                                        </Box>
                                    </Box>
                                </Grid>
                            </Box>
                        </Box>
                    </Grid>
                </Box>
                <Divider></Divider>
                <Box style={{ marginLeft: '17rem' }} relative>
                    <Grid default="auto max-content max-content">
                        <Box margin="20px 0" maxWidth="700px">
                            <Text bold block uppercase>
                                Theme Color
                            </Text>
                            <small>
                                The color selected will be the color theme that
                                represents the institution
                            </small>
                            <Spacer />
                            <FlexiColor
                                colors={colors}
                                color={Theme.PrimaryColor}
                                handleChange={(color) => console.log(color)}
                            />
                        </Box>
                    </Grid>
                </Box>
                <Divider></Divider>
            </Box>
            <Box pad="50px">
                <Tabs bgColor="transparent">
                    <div label="Faculties">
                        <FacultyDetails orgId={id} institution={institution} />
                    </div>

                    <div label="Departments">
                        <DepartmentDetails orgId={id} />
                    </div>

                    <div label="Courses">
                        <CourseDetails orgId={id} />
                    </div>
                    <div label="Users">
                        <Admin setOpen={setOpen} />
                    </div>
                    <div label="Payment">
                        <Paylog
                            id={id}
                            payments={payments}
                            updatePayment={updatePayment}
                            addPayment={addPayment}
                            loading={loading}
                            setBeneficiary={setBeneficiary}
                            fetchPayment={fetchPayment}
                            beneficiary={beneficiary}
                            deletePayment={deletePayment}
                        />
                    </div>
                    <div label="Timeframe">
                        <Box
                            background="#fff"
                            pad="50px"
                            margin="30px 0 0 0"
                            width="40vw"
                        >
                            <Text bold size="25px">
                                Transcript Processing Timeframe
                            </Text>
                            <Box margin="30px 0">
                                <Text size="14px">
                                    This is the maximum amount of time it should
                                    take to complete transcript request
                                </Text>
                            </Box>
                            <Grid default="1fr 1fr">
                                <Box margin="50px 0">
                                    <Input
                                        label="Quantity"
                                        name="Quantity"
                                        width="50%"
                                        type="number"
                                    />
                                </Box>
                                <Box
                                    margin="50px 0"
                                    style={{
                                        marginLeft: '-9em',
                                    }}
                                >
                                    <Select
                                        name="location"
                                        label="Options"
                                        spaceBottom
                                        options={[
                                            { label: 'Days', value: 'Days' },
                                            { label: 'Weeks', value: 'Weeks' },
                                        ]}
                                    />
                                </Box>
                            </Grid>
                            <Box>
                                <Button
                                    width={120}
                                    height={46}
                                    progress={onProgress}
                                    disabled={onProgress}
                                    iconRight={
                                        <i className="flexibull-angle-right" />
                                    }
                                >
                                    {onProgress ? 'Updating' : 'Save'}
                                </Button>
                            </Box>
                        </Box>
                    </div>
                    <div label="Services">
                        <Service
                            orgId={id}
                            open={openService}
                            setOpen={setOpenService}
                        />
                    </div>
                    <div label="Merchant">
                        <Merchant
                            orgId={id}
                            open={openService}
                            setOpen={setOpenService}
                        />
                    </div>
                </Tabs>
            </Box>

            <EditSchool
                update={update}
                onClose={() => setUpdate(false)}
                current={current}
                updateInstitutions={updateInstitutions}
            />
            <AddAdmin
                open={open}
                setOpen={() => setOpen(false)}
                roleId={userRoleId}
                school={id}
            />
        </div>
    )
}

export default Details

import React, { useReducer } from 'react'
import axios from 'axios'
import { ENDPOINT } from '../../utils/httpClient'
import InstitutionContext from './institutionContext'
import InstitutionReducer from './institutionReducer'
import { Notify } from 'flexibull2'
import { TOP_RIGHT, ERROR_ALERT, SUCCESS } from '../../utils/constants'
import {
    GET_INSTITUTIONS,
    CREATE_INSTITUTION,
    DELETE_INSTITUTION,
    SET_LOADING,
    ERROR,
    SET_CURRENT,
    SET_STATUS,
    MODAL_STATUS,
    CLEAR_STATUS,
    GET_FEES,
    SAVE_FEES,
    UPDATE_FEES,
    GET_INSTITUTION,
    GET_PAYMENTS,
    GET_SUPPORT_DETAILS,
    SET_BENEFICIARY,
    STOP_LOADING,
    GET_SERVICES,
    DELETE_SERVICE,
    GET_MERCHANT,
} from '../types'

const CourseState = (props) => {
    const initialState = {
        institutions: null,
        fees: null,
        supportDetails: [],
        loading: false,
        current: null,
        status: null,
        modalStats: false,
        onProgress: false,
        error: null,
        payments: null,
        beneficiary: null,
        services: null,
        merchants: null,
    }

    const [state, dispatch] = useReducer(InstitutionReducer, initialState)

    const getInstitutionByName = async (name) => {
        try {
            setLoading()
            const res = await axios.get(`${ENDPOINT}/institutions/${name}`)
            dispatch({
                type: GET_INSTITUTION,
                payload: res.data,
            })
        } catch (error) {
            Notify(`Failed to fetch institution please try again`, {
                position: TOP_RIGHT,
                status: ERROR_ALERT,
            })
        }
    }
    const getInstitutions = async (page, size) => {
        const finalPage = page - 1
        try {
            setLoading()
            const res = await axios.get(
                `${ENDPOINT}/institutions?page=${finalPage}&size=${size}`
            )
            dispatch({
                type: GET_INSTITUTIONS,
                payload: res.data,
            })
        } catch (error) {
            Notify(`Failed to fetch institutions please try again`, {
                position: TOP_RIGHT,
                status: ERROR_ALERT,
            })
        }
    }

    const searchInstitutions = async (query) => {
         try {
            setLoading()
            const res = await axios.get(
                `${ENDPOINT}/institutions?search=${query}`
            )
            dispatch({
                type: GET_INSTITUTIONS,
                payload: res.data,
            })
        } catch (error) {
            Notify(`Failed to fetch institutions please try again`, {
                position: TOP_RIGHT,
                status: ERROR_ALERT,
            })
        }
    }
    const updateInstitutionActiveStatus = async (org, short_name, data) => {
        setLoading()
        try {
            await axios.put(
                `${ENDPOINT}/institutions/${short_name}/toggle-status?active=${data}`,
                // { active: data },
                {
                    // params: { active: data },
                    headers: {
                        'X-ORG-ID': org,
                    },
                }
            )
            Notify(`Institution active status updated successfully`, {
                position: TOP_RIGHT,
                status: SUCCESS,
            })
        } catch (error) {
            Notify(
                `Failed to updates institution active status please try again`,
                {
                    position: TOP_RIGHT,
                    status: ERROR_ALERT,
                }
            )
        }
    }

    const fetchSupportDetails = async (org) => {
        try {
            const getSupportDetails = org
                ? axios.get(`${ENDPOINT}/contacts`, {
                      headers: {
                          'X-ORG-ID': org,
                      },
                  })
                : axios.get(`${ENDPOINT}/contacts`)

            const res = await getSupportDetails

            setLoading()
            dispatch({
                type: GET_SUPPORT_DETAILS,
                payload: res.data,
            })
        } catch (error) {
            Notify(`Failed to fetch support details please try again`, {
                position: TOP_RIGHT,
                status: ERROR_ALERT,
            })
        }
    }
    const createSupportDetails = async (data, org) => {
        try {
            const addSupport = org
                ? axios.post(`${ENDPOINT}/contacts`, data, {
                      headers: {
                          'X-ORG-ID': org,
                      },
                  })
                : axios.post(`${ENDPOINT}/contacts`)

            await addSupport

            fetchSupportDetails(org)
            Notify(`Support Contacts added successfully`, {
                position: TOP_RIGHT,
                status: SUCCESS,
            })
        } catch (error) {
            Notify(`Failed to fetch support contacts please try again`, {
                position: TOP_RIGHT,
                status: ERROR_ALERT,
            })
        }
    }
    const fetchPayment = async (org) => {
        try {
            const getPayments = org
                ? axios.get(`${ENDPOINT}/beneficiaries`, {
                      headers: {
                          'X-ORG-ID': org,
                      },
                  })
                : axios.get(`${ENDPOINT}/beneficiaries`)

            const res = await getPayments

            setLoading()

            dispatch({
                type: GET_PAYMENTS,
                payload: res.data,
            })
        } catch (error) {
            Notify(`Failed to fetch payments please try again`, {
                position: TOP_RIGHT,
                status: ERROR_ALERT,
            })
        }
    }
    const updatePayment = async (data, org, ID) => {
        setLoading()
        try {
            await axios.put(`${ENDPOINT}/beneficiaries/${ID}`, data, {
                headers: {
                    'X-ORG-ID': org,
                },
            })
            fetchPayment(org)
            Notify(`Beneficiary updated successfully`, {
                position: TOP_RIGHT,
                status: SUCCESS,
            })
        } catch (error) {
            fetchPayment(org)
            Notify(`Failed to updates payments please try again`, {
                position: TOP_RIGHT,
                status: ERROR_ALERT,
            })
        }
    }
    const deletePayment = async (org, ID) => {
        setLoading()
        try {
            await axios.delete(`${ENDPOINT}/beneficiaries/${ID}`, {
                headers: {
                    'X-ORG-ID': org,
                },
            })
            fetchPayment(org)
            Notify(`Beneficiary deleted successfully`, {
                position: TOP_RIGHT,
                status: SUCCESS,
            })
        } catch (error) {
            fetchPayment(org)
            Notify(`Failed to Delete Beneficiary please try again`, {
                position: TOP_RIGHT,
                status: ERROR_ALERT,
            })
        }
    }
    const addPayment = async (data, org, setOpen, setBeneficiaries) => {
        if (data[0].account.accountName === '') {
            Notify('Account Name is Required', {
                position: TOP_RIGHT,
                status: ERROR_ALERT,
            })
            return
        }

        if (data[0].account.bankCode === '') {
            Notify('Bank is Required', {
                position: TOP_RIGHT,
                status: ERROR_ALERT,
            })
            return
        }

        if (data[0].account.accountNumber === '') {
            Notify('Account Number is Required', {
                position: TOP_RIGHT,
                status: ERROR_ALERT,
            })
            return
        }

        if (data[0].organisation === '') {
            Notify('Organisation is Required', {
                position: TOP_RIGHT,
                status: ERROR_ALERT,
            })
            return
        }

        if (data[0].amount === undefined) {
            Notify('Amount is Required', {
                position: TOP_RIGHT,
                status: ERROR_ALERT,
            })
            return
        }
        if (data[0].beneficiary_type === '') {
            Notify('Service Type is Required', {
                position: TOP_RIGHT,
                status: ERROR_ALERT,
            })
            return
        }

        try {
            setLoading()
            const addBeneficiary = org
                ? axios.post(`${ENDPOINT}/beneficiaries`, data, {
                      headers: {
                          'X-ORG-ID': org,
                      },
                  })
                : axios.post(`${ENDPOINT}/beneficiaries`)

            await addBeneficiary

            fetchPayment(org)
            Notify(`Beneficiary added successfully`, {
                position: TOP_RIGHT,
                status: SUCCESS,
            })
            setBeneficiaries({
                accountName: '',
                bankCode: '',
                accountNumber: '',
                organisation: '',
                deduct_from: false,
            })
            setOpen(() => false)
            stopLoading()
        } catch (error) {
            fetchPayment(org)
            Notify(`Failed to fetch payments please try again`, {
                position: TOP_RIGHT,
                status: ERROR_ALERT,
            })
            stopLoading()
        }
    }
    const updateInstitutionLogo = async (institutionData, logo) => {
        const {
            short_name,
            full_name,
            location,
            email_address,
            physical_address,
            phone_number,
            theme_color,
        } = institutionData
        const bodyFormData = new FormData()
        bodyFormData.append('short_name', short_name)
        bodyFormData.append('full_name', full_name)
        bodyFormData.append('location', location)
        bodyFormData.append('email_address', email_address)
        bodyFormData.append('physical_address', physical_address)
        bodyFormData.append('phone_number', phone_number)
        bodyFormData.append('theme_color', theme_color)
        bodyFormData.append('logo', logo)

        return axios.put(
            `${ENDPOINT}/institutions/${short_name}`,
            bodyFormData,
            {
                'Content-Type': 'multipart/form-data',
            }
        )
    }

    const createInstitution = async (FormData, base64Str) => {
        try {
            setLoading()
            const res = await axios.post(`${ENDPOINT}/configurations`, FormData)
            updateInstitutionLogo(res.data.institution, base64Str)
            dispatch({
                type: CREATE_INSTITUTION,
                payload: res,
            })
            Notify(`Institutions added successfully`, {
                position: TOP_RIGHT,
                status: SUCCESS,
            })
            getInstitutions(1, 5)
        } catch (error) {
            dispatch({
                type: ERROR,
            })
            Notify(`Failed to create institution please try again`, {
                position: TOP_RIGHT,
                status: ERROR_ALERT,
            })
        }
    }
    const getFees = async () => {
        try {
            setLoading()
            const res = await axios.get(`${ENDPOINT}/fees`)
            dispatch({
                type: GET_FEES,
                payload: res,
            })
        } catch (error) {
            Notify(`Failed to fetch fees please try again`, {
                position: TOP_RIGHT,
                status: ERROR_ALERT,
            })
        }
    }
    const getServices = async (orgId) => {
        try {
            setLoading()
            const res = await axios.get(
                `${ENDPOINT}/merchants?institution=${orgId}`,
                {
                    headers: {
                        'X-ORG-ID': orgId,
                    },
                }
            )

            dispatch({
                type: GET_SERVICES,
                payload: res.data.service_types,
            })
        } catch (error) {
            Notify(`Failed to fetch services please try again`, {
                position: TOP_RIGHT,
                status: ERROR_ALERT,
            })
        }
    }

    const getMerchant = async (orgId) => {
        try {
            setLoading()
            const res = await axios.get(
                `${ENDPOINT}/merchants?institution=${orgId}`
            )

            dispatch({
                type: GET_MERCHANT,
                payload: res.data,
            })
        } catch (error) {
            Notify(`Failed to fetch Merchnats please try again`, {
                position: TOP_RIGHT,
                status: ERROR_ALERT,
            })
        }
    }
    const saveFees = async (data) => {
        try {
            progressLoading()
            const res = await axios.post(`${ENDPOINT}/fees`, data)
            dispatch({
                type: SAVE_FEES,
                payload: res,
            })
            Notify(`fees saved successfully`, {
                position: TOP_RIGHT,
                status: SUCCESS,
            })
            getFees()
        } catch (error) {
            dispatch({
                type: ERROR,
            })
            Notify(`Failed to save fees please try again`, {
                position: TOP_RIGHT,
                status: ERROR_ALERT,
            })
        }
    }
    const updateFees = async (data) => {
        try {
            progressLoading()
            const res = await axios.put(`${ENDPOINT}/fees/${data.id}`, data)
            dispatch({
                type: UPDATE_FEES,
                payload: res,
            })
            Notify(`fees updated successfully`, {
                position: TOP_RIGHT,
                status: SUCCESS,
            })
            getFees()
        } catch (error) {
            dispatch({
                type: ERROR,
            })
            Notify(`Failed to update fees please try again`, {
                position: TOP_RIGHT,
                status: ERROR_ALERT,
            })
        }
    }
    const updateInstitutions = async (formData, short_name) => {
        try {
            setLoading()
            const res = await axios.put(
                `${ENDPOINT}/institutions/${short_name}`,
                formData
            )
            setCurrent(res.data)
            Notify(`Institutions Updated Successfully`, {
                position: TOP_RIGHT,
                status: SUCCESS,
            })
        } catch (error) {
            Notify(`Failed to edit institution please try again`, {
                position: TOP_RIGHT,
                status: ERROR_ALERT,
            })
        }
    }
    const updateService = async (data, service_type_id, orgid) => {
        try {
            setLoading()
            const res = await axios.put(
                `${ENDPOINT}/service-types/${service_type_id}`,
                data
            )
            setCurrent(res.data)
            Notify(`Service updated successful`, {
                position: TOP_RIGHT,
                status: SUCCESS,
            })
            getServices(orgid)
        } catch (error) {
            Notify(`Failed to edit service please try again`, {
                position: TOP_RIGHT,
                status: ERROR_ALERT,
            })
        }
    }

    const deleteInstitutions = async (id) => {
        try {
            setLoading()
            const res = await axios.delete(`${ENDPOINT}/institutions/${id}`)
            dispatch({
                type: DELETE_INSTITUTION,
                payload: id,
            })
            setError(res.message)
            setStatus(res.status)
            getInstitutions(1, 5)
            Notify(`Institutions Deleted Successfully`, {
                position: TOP_RIGHT,
                status: SUCCESS,
            })
        } catch (error) {
            getInstitutions(1, 5)
            Notify(`${error.response.data.message}`, {
                position: TOP_RIGHT,
                status: ERROR_ALERT,
            })
        }
    }
    const deleteService = async (current, orgid) => {
        try {
            setLoading()
            await axios.delete(`${ENDPOINT}/service-types/${current}`)
            setLoading(false)

            dispatch({
                type: DELETE_SERVICE,
                payload: current,
            })

            getServices(orgid)
            Notify(`Service deleted successful`, {
                position: TOP_RIGHT,
                status: SUCCESS,
            })
        } catch (error) {
            getServices(orgid)
            Notify(`something went wrong, please try again`, {
                position: TOP_RIGHT,
                status: ERROR_ALERT,
            })
        }
    }

    const setLoading = () => {
        dispatch({ type: SET_LOADING })
    }
    const stopLoading = () => {
        dispatch({ type: STOP_LOADING })
    }
    const progressLoading = () => {
        dispatch({ type: MODAL_STATUS })
    }

    const setCurrent = (cur) => dispatch({ type: SET_CURRENT, payload: cur })
    const setBeneficiary = (cur) =>
        dispatch({ type: SET_BENEFICIARY, payload: cur })
    const setError = (err) => dispatch({ type: ERROR, payload: err })

    const setStatus = (status) =>
        dispatch({ type: SET_STATUS, payload: status })

    const clearStatus = () => {
        dispatch({ type: CLEAR_STATUS })
    }
    const handleModalStatus = (stats) => {
        setLoading()
        dispatch({ type: MODAL_STATUS, payload: stats })
    }

    return (
        <InstitutionContext.Provider
            value={{
                institutions: state.institutions,
                fees: state.fees,
                payments: state.payments,
                getInstitutionByName,
                getInstitutions,
                getFees,
                updateFees,
                createInstitution,
                saveFees,
                deleteInstitutions,
                loading: state.loading,
                current: state.current,
                status: state.status,
                modalStats: state.modalStats,
                onProgress: state.onProgress,
                setStatus,
                setCurrent,
                handleModalStatus,
                clearStatus,
                updateInstitutions,
                progressLoading,
                setLoading,
                fetchPayment,
                updatePayment,
                addPayment,
                fetchSupportDetails,
                createSupportDetails,
                supportDetails: state.supportDetails,
                setBeneficiary,
                beneficiary: state.beneficiary,
                services: state.services,
                merchants: state.merchants,
                stopLoading,
                getServices,
                updateService,
                deleteService,
                deletePayment,
                updateInstitutionActiveStatus,
                getMerchant,
                searchInstitutions
            }}
        >
            {props.children}
        </InstitutionContext.Provider>
    )
}

export default CourseState

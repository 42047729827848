import React, { useState } from 'react';
import {
    Modal,
    ModalBody,
    Text,
    Box,
    Input,
    Button,
    Notify,
    Grid,
    Select
} from 'flexibull2';
import { TOP_RIGHT, ERROR_ALERT } from '../../../utils/constants';


const EditService = ({
    open,
    loading,
    current,
    setEdit,
    services,
    updateService,
    orgid
}) => {
    const [values, setValues] = useState({
        service_type_id: null,
        merchant_id: null,
    })
    const [service, setService] = React.useState(null)
    React.useEffect(() => {
        if (current) {
            const { merchant_id, service_type, service_type_id } = current
            setValues({
                service_type_id,
                merchant_id,
                service_type,
            })
            setService({
                value: service_type,
                label: service_type,
            })
        }
    }, [current])


    const { merchant_id, service_type_id } = values;

    let Values = []

    services &&
        services.service_types?.map((service) =>
            Values.push({
                label: service.service_type,
                value: service.service_type,
            })
        )


    const handleValues = (e) => {
        setValues({ ...values, [e.target.name]: e.target.value })
    }

    const handleType = (service) => {
        setService(service)
    }

    const onUpdateService = async (e) => {
        e.preventDefault()
        if (merchant_id === '' || service_type_id === '') {
            Notify(`Please fill in all the fields and try again`, {
                position: TOP_RIGHT,
                status: ERROR_ALERT,
            })
        } else {
            const data = {
                merchant_id,
                service_type_id,
                service_type_name: service?.value
            }
            updateService(data, service_type_id, orgid)
            setEdit(false)
        }
    }
    return (
        <div>
            <Modal outerClick open={open}>
                <ModalBody style={{ background: '#fff' }} width="570px">
                    <Box pad="20px 40px 0" >
                        <Box style={{
                            marginBottom: "2rem"
                        }}>
                            <Text size="22px" block>
                                Edit service
                            </Text>
                        </Box>
                        <Box >
                            <Box margin="25px 0">
                                <Select spaceBottom label="Service Type Name"
                                    onChange={handleType}
                                    placeholder="Select Service Type"
                                    options={Values}
                                    value={service}
                                    width={240}
                                />
                            </Box>
                            <Box >
                                <Grid default="1fr 1fr">
                                    <Box pad=" 0 0 0">
                                        <Input
                                            label="Service ID"
                                            name="service_type_id"
                                            placeholder="Enter Service ID"
                                            spaceBottom="15px"
                                            block
                                            value={service_type_id}
                                            onChange={handleValues}
                                        />
                                    </Box>
                                    <Box pad=" 0 0 0">
                                        <Input
                                            label="Merchant ID"
                                            name="merchant_id"
                                            placeholder="Enter Merchant ID"
                                            spaceBottom="15px"
                                            block
                                            value={merchant_id}
                                            onChange={handleValues}
                                        />
                                    </Box>
                                </Grid>
                            </Box>
                        </Box>

                    </Box>
                    <Box pad="0px 30px 40px 0">
                        <Grid default="2fr 1fr 1fr">
                            <Box
                                style={{
                                    marginLeft: '20px',
                                    marginTop: '-10px',
                                }}
                            ></Box>
                            <Box align="right">
                                <Button
                                    style={{
                                        border: 'none',
                                        textAling: 'center',
                                        color: '#000',
                                    }}
                                    width={90}
                                    height={30}
                                    onClick={() => setEdit(false)}
                                    pale
                                    pad="0 20px"
                                >
                                    Cancel
                                </Button>
                            </Box>
                            <Box align="right">
                                <Button
                                    width={120}
                                    height={30}
                                    pad="0 15px"
                                    spaceRight
                                    onClick={onUpdateService}
                                    progress={loading}
                                    disabled={loading}
                                    iconRight={
                                        <i className="flexibull-angle-right" />
                                    }
                                >
                                    {loading ? 'Updating' : 'Update'}
                                </Button>
                            </Box>
                        </Grid>
                    </Box>
                </ModalBody>
            </Modal>
        </div>
    )
}

export default EditService;

const DefaultColor = '#CFAE26'
const makeNewTheme = (color) => {
    localStorage.setItem('e_trans_theme', color)
}

let PrimaryTheme = localStorage.getItem('e_trans_theme')

if (
    localStorage.getItem('e_trans_theme') === null ||
    localStorage.getItem('e_trans_theme') === 'null' ||
    localStorage.getItem('e_trans_theme') === ''
) {
    makeNewTheme(DefaultColor)
}

export const Theme = {
    DefaultColor,
    PrimaryColor: PrimaryTheme ? PrimaryTheme : DefaultColor,
    PrimaryGrey: '#9D9DB7',
    PrimaryBackground: '#FAFAFA',
    PrimaryGreen: '#4DCCBD',
    PrimaryMint: '#95fd35',
    PrimaryRed: '#E15554',
    PrimaryYellow: '#F2C02E',
    PrimaryOrange: '#FFA200',
    PrimaryBlue: '#3158D8',
    SecondaryColor: '#be7efc',
    TetiaryColor: '#ecdbfe',
    PrimaryFontColor: '#373737',
    PrimaryRadius: '3px',
    SecondaryRadius: '5px',
    PrimaryBorderColor: '#D8D8E3',
    PrimaryFade: '#F5F5F7',
    PrimaryInputOutline: '#9D9DB7',
}

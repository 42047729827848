import * as React from 'react'
import { Box, Grid, Input, Checkbox, Select } from 'flexibull2'
import usePaymentConfigurationHook from './payment_configuration.hooks'
import bankOptions from '../banks'




const FieldSets = () => {
    const { singleBeneficiary, setSingleBeneficiary, handleChange } =
        usePaymentConfigurationHook()
    const { accountName, accountNumber, amount, deduct_from } =
        singleBeneficiary
    return (
        <Box margin="15px 0">
            <Grid default="1fr 1fr 1fr 1fr 1fr 1fr" gap="20px">
                <Input
                    type="text"
                    name="accountName"
                    label="Account Name"
                    spaceTop
                    width="170px"
                    value={accountName}
                    onChange={handleChange}
                />
                <Select
                    spaceRight
                    label="Bank"
                    name="defaultAccount"
                    block
                    spaceTop
                    required
                    options={bankOptions}
                    onChange={handleChange}
                />
                <Input
                    type="number"
                    name="accountNumber"
                    label="Account Number"
                    spaceTop
                    width="170px"
                    value={accountNumber}
                    onChange={handleChange}
                />
                <Input
                    type="number"
                    name="amount"
                    block
                    label="Amount"
                    spaceTop
                    value={amount}
                    onChange={handleChange}
                />
                <Box
                    style={{
                        marginLeft: '2rem',
                        marginTop: '1.5rem',
                    }}
                >
                    <Checkbox
                        onChange={() =>
                            setSingleBeneficiary({
                                ...singleBeneficiary,
                                deduct_from: !deduct_from,
                            })
                        }
                        checked={deduct_from}
                    />
                </Box>
            </Grid>
        </Box>
    )
}


export default FieldSets

import React from 'react'

import { Box, Button, Table, FlexiPagination } from 'flexibull2'
import { ColorBox } from '../components/Styles/ColorBox.style'
import { DropDown } from 'flexibull2/build/dropdown'

const CourseTable = ({
    courses,
    onDelete,
    onEdit,
    getCourses,
    pageNumber,
    setpageNumber,
    orgId,
    setPageOptions,
    pageSize,
    pageSizes,
    pageOptions,
}) => {
    const handlePagination = (page) => {
        getCourses(pageSize, page, orgId)
        setpageNumber(page)
    }

    const handlePageSize = (pageSize) => {
        getCourses(pageSize.value, pageNumber, orgId)
        setPageOptions({ ...pageOptions, pageSize: pageSize.value })
    }

    return (
        <div>
            <Box>
                <Box>
                    <Table style={{ paddingTop: '10px' }}>
                        <table>
                            <thead>
                                <tr>
                                    <th>Course Title</th>
                                    <th>Short Code</th>
                                    <th>Department Title</th>
                                    <th>Faculty</th>
                                    <th>Action</th>
                                </tr>
                            </thead>
                            <tbody>
                                {courses?.data.map((course) => (
                                    <tr key={course?.id}>
                                        <td>{course?.name}</td>
                                        <td>{course?.short_code}</td>
                                        <td>
                                            {
                                                course?.department
                                                    ?.department_name
                                            }
                                        </td>
                                        <td>
                                            {
                                                course.department?.faculty
                                                    .faculty_name
                                            }
                                        </td>
                                        <td>
                                            <ColorBox>
                                                <DropDown
                                                    label={
                                                        <Button
                                                            pale
                                                            height={20}
                                                            style={{
                                                                border: 'none',
                                                            }}
                                                        >
                                                            <i className="icon-dot-3" />
                                                        </Button>
                                                    }
                                                    trigger="hover"
                                                    menuAlign="top right"
                                                    menuList={[
                                                        {
                                                            onClick: () =>
                                                                onEdit(course),
                                                            label: 'Edit Course',
                                                            iconLeft:
                                                                'icon-edit tblue',
                                                        },
                                                        {
                                                            onClick: () =>
                                                                onDelete(
                                                                    course.id
                                                                ),
                                                            label: 'Delete Course',
                                                            iconLeft:
                                                                'icon-trash tred',
                                                        },
                                                    ]}
                                                    style={{
                                                        cursor: 'pointer',
                                                    }}
                                                />
                                            </ColorBox>
                                        </td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    </Table>
                </Box>
                <Box>
                    <FlexiPagination
                        pageCounts={pageSizes}
                        total={courses?.totalResults}
                        itemsDisplayed
                        pageSize={pageSize}
                        onChange={handlePagination}
                        changePageSize={handlePageSize}
                        current={pageNumber}
                    />
                </Box>
            </Box>
        </div>
    )
}

export default CourseTable

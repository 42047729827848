export const validate = (data) => {
    const regexp =
        /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-]).{8,}$/
    const formKeys = Object.keys(data)
    let errorObj = {}
    let errorCount = 0
    for (let i = 0; i < formKeys.length; i++) {
        if (formKeys[i] === 'password_confirm') {
            if (data[formKeys[i]] !== data.password) {
                errorCount += 1
                errorObj[formKeys[i]] = `passwords do not match`
                break
            } else {
                if (!regexp.test(data.password)) {
                    errorCount += 1
                    errorObj[
                        formKeys[i]
                    ] = `password should have a minimum of 8 characters, 1 lower case, 1 upper case, 1 number and 1 special char`
                    break
                }
            }
        }
        if (!data[formKeys[i]]) {
            errorCount += 1
            errorObj[formKeys[i]] = `${formKeys[i]} is required`
        } else {
            errorObj[formKeys[i]] = ''
        }
    }
    return { errorObj, errorCount }
}

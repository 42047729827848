import React from 'react'
import {
    Modal,
    ModalBody,
    Text,
    Box,
    Input,
    Button,
    Notify,
    Grid,
    Select
} from 'flexibull2'
import { TOP_RIGHT, ERROR_ALERT } from '../../utils/constants'

const EditCourseFromStore = ({
    open,
    loading,
    current,
    editCourseFromStore,
    setOpen,
    faculties,
    departments,
}) => {
    const [course, setCourse] = React.useState({
        name: '',
        short_code: '',
    })
    
    const [department, setDepartment] = React.useState(null)
    React.useEffect(() => {
        if (current) {
            const { course_name, department_name, short_code } = current
            setCourse({
                name: course_name,
                short_code,
            })

            setDepartment({
                value: department_name,
                label: department_name,
            })
        }
    }, [current])
    const { name, short_code } = course

    let Dept = []
    departments &&
        departments?.map((department) =>
            Dept.push({
                label: department?.department_name,
                subText: department?.short_code,
            })
        )

    const handleChange = (e) => {
        const { name, value } = e.target
        setCourse({ ...course, [name]: value })
    }


    const handleDepartment = (department) => {
        setDepartment(department)
    }

    const onClose = () => {
        setOpen(false)
    }

    const onUpdateDepartment = (e) => {
        e.preventDefault()
        if (name === '' || short_code === '') {
            Notify(`Please fill in all the fields and try again`, {
                position: TOP_RIGHT,
                status: ERROR_ALERT,
            })
        } else {
    
            const data = {
                course_name: name,
                short_code,
                department_name: department.value,
            }

            editCourseFromStore(data,current)
            setCourse({ name: '', short_code: '' })
        }
        setOpen(false)
    }
    return (
        <div>
            <Modal outerClick open={open}>
                <ModalBody style={{ background: '#fff' }} width="570px">
                    <Box pad="20px 40px 0">
                        <Box margin="20px 0 20px 0">
                            <Text size="22px" block>
                                Edit Course
                            </Text>
                        </Box>
                        <Grid default="1fr 1fr">
                            <Box margin="20px 0">
                                <Select
                                    value={department}
                                    onChange={handleDepartment}
                                    elementOptions={Dept}
                                    label="Department"
                                    placeholder="Select Department"
                                    spaceBottom
                                    spaceTop
                                    block
                                />
                            </Box>
                        </Grid>
                        <Grid default="1fr 1fr">
                            <Box margin="0px 0px 25px 0px">
                                <Input
                                    label="Course Title"
                                    name="name"
                                    value={name}
                                    onChange={handleChange}
                                    placeholder="Course Title"
                                    spaceBottom="45px"
                                    width="280px"
                                />
                            </Box>
                            <Box pad=" 0 0 0">
                                <Input
                                    label="Course Short Code"
                                    name="short_code"
                                    value={short_code}
                                    onChange={handleChange}
                                    placeholder="Course Short Code"
                                    width="200px"
                                    spaceBottom="45px"
                                />
                            </Box>
                        </Grid>
                    </Box>
                    <Box pad="0px 30px 40px 0">
                        <Grid default="2fr 1fr 1fr">
                            <Box
                                style={{
                                    marginLeft: '20px',
                                    marginTop: '-10px',
                                }}
                            ></Box>
                            <Box align="right">
                                <Button
                                    style={{
                                        border: 'none',
                                        textAling: 'center',
                                        color: '#000',
                                    }}
                                    width={90}
                                    height={30}
                                    onClick={onClose}
                                    pale
                                    pad="0 20px"
                                >
                                    Cancel
                                </Button>
                            </Box>
                            <Box align="right">
                                <Button
                                    width={120}
                                    height={30}
                                    pad="0 15px"
                                    spaceRight
                                    onClick={onUpdateDepartment}
                                    progress={loading}
                                    disabled={loading}
                                    iconRight={
                                        <i className="flexibull-angle-right" />
                                    }
                                >
                                    {loading ? 'Updating' : 'Update'}
                                </Button>
                            </Box>
                        </Grid>
                    </Box>
                </ModalBody>
            </Modal>
        </div>
    )
}

export default EditCourseFromStore

import React, { useState, useEffect } from 'react'
import { Box, Text, Grid, Input, Checkbox, Button, Select } from 'flexibull2'
import { Theme } from '../config/theme'
import bankOptions from './banks'
import { getServices } from '../Requests/services'

const FreshField = ({
    setPayments,
    beneficiariesArray,
    setLoading,
    org,
    addPayment,
    payments,
    setOpen,
}) => {
    const [accountItems, addAccountItem] = useState([FreshField])
    const [info, setInfo] = useState(true)
    const [modifiedField, setModifiedField] = useState({})

    return (
        <Box
            style={{
                position: 'relative',
            }}
        >
            <Box
                pad="0px 0 0"
                style={{
                    position: 'relative',
                }}
            >
                <Box pad="5px 0">
                    <Box
                        style={{
                            position: 'absolute',
                            top: '5.9rem',
                            right: '11rem',
                        }}
                    >
                        <Text size="0.9em" bold>
                            {/* DEDUCTIBLE */}
                        </Text>
                    </Box>

                    <AccountItems
                        addAccountItem={addAccountItem}
                        accountItems={accountItems}
                        setPayments={setPayments}
                        info={info}
                        setInfo={setInfo}
                        setModifiedField={setModifiedField}
                        modifiedField={modifiedField}
                        beneficiariesArray={beneficiariesArray}
                        setLoading={setLoading}
                        org={org}
                        addPayment={addPayment}
                        payments={payments}
                        setOpen={setOpen}
                    />
                </Box>
                {/* <Box pad="0px 0 0 0" align="right">
                    {info && (
                        <Box pad="30px 0" align="right">
                            <Button
                                //     width={140}
                                pad="0 5px 0 10px"
                                onClick={() =>
                                    addAccountItem([...accountItems, FieldSets])
                                }
                                iconLeft={<i className="flexibull-plus" />}
                                spaceRight
                                spaceLeft
                                color={Theme.PrimaryOrange}
                                iconRight={<i className="flexibull-plus" />}
                            >
                                Add New
                            </Button>
                        </Box>
                    )}
                </Box> */}
            </Box>
        </Box>
    )
}

function AccountItems({
    accountItems,
    addAccountItem,
    setPayments,
    info,
    setInfo,
    modifiedField,
    setModifiedField,
    beneficiariesArray,
    setLoading,
    org,
    addPayment,
    payments,
    setOpen,
}) {
    return (
        <Box pad="5px 0">
            {accountItems.map((item, index) => (
                <FieldSets
                    accountItems={accountItems}
                    addAccountItem={addAccountItem}
                    item={item}
                    index={index}
                    setPayments={setPayments}
                    info={info}
                    setInfo={setInfo}
                    setModifiedField={setModifiedField}
                    modifiedField={modifiedField}
                    beneficiariesArray={beneficiariesArray}
                    setLoading={setLoading}
                    org={org}
                    addPayment={addPayment}
                    payments={payments}
                    setOpen={setOpen}
                />
            ))}
        </Box>
    )
}

// function deleteField(index, accountItems, addAccountItem) {
//     accountItems.pop(index)
//     addAccountItem([...accountItems])
// }

function FieldSets({
    index,
    accountItems,
    addAccountItem,
    setPayments,
    info,
    setInfo,
    setModifiedField,
    modifiedField,
    beneficiariesArray,
    setLoading,
    org,
    addPayment,
    payments,
    setOpen,
}) {
    useEffect(() => {
        if (localStorage.getItem('payments') === null) {
            const res = []
            localStorage.setItem('payments', JSON.stringify(res))
        }
        // eslint-disable-next-line
    }, [])

    const [services, setServices] = useState(null)

    useEffect(() => {
        getServices(org).then(({ data }) => {
            if (data) {
                const res = data.map((dt) => {
                    return { label: dt.service_type, value: dt.service_type }
                })
                setServices(res)
            }
        })
    }, [org])

    const [beneficiaries, setBeneficiaries] = useState({
        accountName: '',
        bankCode: '',
        accountNumber: '',
        organisation: '',
        deduct_from: false,
    })
    const handleDeductFromChange = (event) => {
        const { checked } = event.target
        setBeneficiaries((prevState) => ({
            ...prevState,
            deduct_from: checked,
        }))
    }

    const [type, setType] = useState('')

    const { accountName, accountNumber, amount, bankCode, deduct_from } =
        beneficiaries

    const handleChange = (e) => {
        if (e.value) {
            const { value } = e
            setBeneficiaries({ ...beneficiaries, bankCode: value })
            setInfo(false)
        } else {
            e.target.reset = false
            const { name, value } = e.target
            setBeneficiaries({ ...beneficiaries, [name]: value })
            setInfo(false)
            setModifiedField({ ...beneficiaries, [name]: value })
        }
    }
    const handleType = (type) => {
        setType(type?.value)
    }

    const saveToAccount = () => {
        const data = [
            {
                account: {
                    accountName,
                    accountNumber,
                    bankCode,
                    organisation: org,
                },
                amount,
                deduct_from,
                beneficiary_type: type,
            },
        ]
        addPayment(data, org, setOpen, setBeneficiaries)
    }

    return (
        <Box
            style={{
                marginBottom: '1rem',
            }}
        >
            <Box>
                <Grid default="1fr 1fr 1fr 1fr 1fr 1fr 1fr" gap="20px">
                    <Input
                        type="text"
                        name="accountName"
                        label="Account Name"
                        spaceTop
                        width="150px"
                        value={accountName}
                        onChange={handleChange}
                    />
                    <Select
                        spaceRight
                        label="Bank"
                        name="defaultAccount"
                        block
                        spaceTop
                        required
                        options={bankOptions}
                        onChange={handleChange}
                        style={{
                            width: '150px',
                        }}
                    />
                    <Input
                        type="number"
                        name="accountNumber"
                        label="Account Number"
                        spaceTop
                        width="150px"
                        value={accountNumber}
                        onChange={handleChange}
                    />
                    <Input
                        type="number"
                        name="amount"
                        block
                        label="Amount"
                        spaceTop
                        value={amount}
                        onChange={handleChange}
                        style={{
                            width: '150px',
                        }}
                    />
                    <Box>
                        <Select
                            spaceRight
                            label="Select Service"
                            name="type"
                            block
                            spaceTop
                            required
                            options={services}
                            style={{
                                width: '150px',
                            }}
                            onChange={handleType}
                        />
                    </Box>
                    <Box
                        style={{
                            // marginLeft: '2rem',
                            marginTop: '1.5rem',
                            marginLeft: '-1rem',
                        }}
                    >
                        <div>
                            <Checkbox
                                onChange={handleDeductFromChange}
                                checked={deduct_from}
                                label="Deductible"
                            />
                        </div>
                    </Box>
                    {/* 
                    <Box
                        style={{
                            marginTop: '1.5rem',
                            textAling: 'center',
                            marginLeft: '-3rem',
                        }}
                    >
                        <Button
                            color={Theme.PrimaryRed}
                            onClick={() =>
                                deleteField(index, accountItems, addAccountItem)
                            }
                            icon={<i className="icon-trash"></i>}
                            round
                            small
                        />
                    </Box> */}

                    <Box
                        style={{
                            position: 'absolute',
                            bottom: '-3.5rem',
                            right: '-1em',
                        }}
                    >
                        <Button
                            color={Theme.PrimaryGreen}
                            onClick={saveToAccount}
                            block
                        >
                            Save
                        </Button>
                    </Box>
                </Grid>
            </Box>
        </Box>
    )
}

export default FreshField

export const GET_FACULTIES = 'GET_FACULTIES'
export const DELETE_FACULTY = 'DELETE_FACULTY'
export const EDIT_FACULTY = 'EDIT_FACULTY'
export const CREATE_FACULTY = 'CREATE_FACULTY'
export const SEARCH_FACULTIES = 'SEARCH_FACULTIES'
export const SET_LOADING = 'SET_LOADING'
export const STOP_LOADING = 'STOP_LOADING'
export const SET_CURRENT = 'SET_CURRENT'
export const SET_BENEFICIARY = 'SET_BENEFICIARY'
export const SET_STATUS = 'SET_STATUS'
export const CLEAR_STATUS = 'CLEAR_STATUS'
export const MODAL_STATUS = 'MODAL_STATUS'
export const ERROR = 'ERROR'
export const RESPONSE_ERROR = 'RESPONSE_ERROR'
export const GET_DEPARTMENTS = 'GET_DEPARTMENTS'
export const DELETE_DEPARTMENT = 'DELETE_DEPARTMENT'
export const EDIT_DEPARTMENT = 'EDIT_DEPARTMENT'
export const CREATE_DEPARTMENT = 'CREATE_DEPARTMENT'
export const SEARCH_DEPARTMENT = 'SEARCH_DEPARTMENT'
export const GET_COURSES = 'GET_COURSES'
export const CREATE_COURSE = 'CREATE_COURSE'
export const DELETE_COURSE = 'DELETE_COURSE'
export const EDIT_COURSE = 'EDIT_COURSE'
export const SEARCH_COURSE = 'SEARCH_COURSE'
export const GET_INSTITUTIONS = 'GET_INSTITUTIONS'
export const GET_INSTITUTION = 'GET_INSTITUTION'
export const CREATE_INSTITUTION = 'CREATE_INSTITUTION'
export const DELETE_INSTITUTION = 'DELETE_INSTITUTION'
export const EDIT_INSTITUTION = 'EDIT_INSTITUTION'
export const SEARCH_INSTITUTION = 'SEARCH_INSTITUTION'
export const GET_FEES = 'GET_FEES'
export const GET_PAYMENTS = 'GET_PAYMENTS'
export const GET_SUPPORT_DETAILS = 'GET_SUPPORT_DETAILS'
export const UPDATE_PAYMENTS = 'UPDATE_PAYMENTS'
export const ADD_PAYMENTS = 'ADD_PAYMENTS'
export const SAVE_FEES = 'SAVE_FEES'
export const UPDATE_FEES = 'UPDATE_FEES'
export const GET_SERVICES = 'GET_SERVICES'
export const GET_MERCHANT = 'GET_MERCHANT'
export const UPDATE_SERVICE = 'UPDATE_SERVICE'
export const DELETE_SERVICE = 'DELETE_SERVICE'

export const GET_ALL_FACULTIES = 'GET_ALL_FACULTIES'
export const GET_ALL_DEPARTMENTS = 'GET_ALL_DEPARTMENTS'
export const GET_ALL_COURSES = 'GET_ALL_COURSES'

//local store

export const GET_FACULTIES_FROM_STORE = 'GET_FACULTIES_FROM_STORE'
export const DELETE_FACULTY_FROM_STORE = 'DELETE_FACULTY_FROM_STORE'
export const EDIT_FACULTY_FROM_STORE = 'EDIT_FACULTY_FROM_STORE'
export const SAVE_FACULTY_TO_STORE = 'SAVE_FACULTY_TO_STORE'

export const GET_DEPARTMENTS_FROM_STORE = 'GET_DEPARTMENTS_FROM_STORE'
export const DELETE_DEPARTMENTS_FROM_STORE = 'DELETE_DEPARTMENTS_FROM_STORE'
export const EDIT_DEPARTMENT_FROM_STORE = 'EDIT_DEPARTMENT_FROM_STORE'
export const SAVE_DEPARTMENT_TO_STORE = 'SAVE_DEPARTMENT_TO_STORE'

export const GET_COURSES_FROM_STORE = 'GET_COURSES_FROM_STORE '
export const SAVE_COURSES_TO_STORE = 'SAVE_COURSES_TO_STORE'

import { useEffect, Fragment, useCallback } from 'react'
import { useAtom } from 'jotai'
import { Box, Layout, SideBarToogle, Text, SideLink, Button } from 'flexibull2'
import { Theme } from './config/theme'
import { useAuth } from '../context/auth-context'
import { Switch, Route } from 'react-router-dom'
import style from 'styled-components'
import Profile from './Profile'
import Track from './Track'
import Transactions from './Transactions'
import Payments from './Payments'
import PaymentStatus from './PaymentStatus'
import Requests from './Requests'
import RequestDetails from './RequestDetails'

import SAFLogo from '../assets/safcert-logo.svg'

import Customer from './Customer'
import Details from './Customer/Details/Details'
import Configurations from './instituteConfig'

import InstituteDashboard from './instituteDashboard'
import { PendingRequest } from './Requests/PendingRequest'
import IdleDialog from './components/IdleDialog'

import { isTimeoutAtom, showModalAtom } from '../common/global.store'
import IdleTimer from '../utils/idleTime'
const Logout = style.a`
`

const applicantMenuItems = [
    {
        name: 'requests',
        icon: 'icon-ticket',
        label: 'Requests',
        navlink: '/requests',
        roles: [],
    },
    {
        name: 'Transactions',
        icon: 'icon-credit-card',
        label: 'Transactions',
        navlink: '/transactions',
        roles: [],
    },
    {
        name: 'profile',
        icon: 'icon-user-outline',
        label: 'Profile',
        navlink: '/profile',
        roles: [],
    },
]

const flexiMenuItems = [
    {
        name: 'dashboard',
        icon: 'icon-ticket',
        label: 'Dashboard',
        navlink: '/institution-dashboard',
        roles: [],
    },
    {
        name: 'customers',
        icon: 'icon-users-outline',
        label: 'Customers',
        navlink: '/institutions',
        roles: [],
    },
    {
        name: 'Transactions',
        icon: 'icon-credit-card',
        label: 'Transactions',
        navlink: '/transactions',
        roles: [],
    },
    {
        name: 'profile',
        icon: 'icon-user-outline',
        label: 'Profile',
        navlink: '/profile',
        roles: [],
    },
]

const menuItems = [
    {
        name: 'dashboard',
        icon: 'icon-ticket',
        label: 'Dashboard',
        navlink: '/institution-dashboard',
        roles: [],
    },
    {
        name: 'requests',
        icon: 'icon-ticket',
        label: 'Requests',
        navlink: '/requests',
        roles: [],
    },
    {
        name: 'payments',
        icon: 'icon-credit-card',
        label: 'Financial Report',
        navlink: '/payments',
        roles: [],
    },
    {
        name: 'Transactions',
        icon: 'icon-credit-card',
        label: 'Transactions',
        navlink: '/transactions',
        roles: [],
    },
    {
        name: 'configurations',
        icon: 'icon-users-outline',
        label: 'Configurations',
        navlink: '/configurations',
        roles: [],
    },
    {
        name: 'profile',
        icon: 'icon-user-outline',
        label: 'Profile',
        navlink: '/profile',
        roles: [],
    },
]

function Dashboard() {
    const { logout, data, org, setIsAdmin } = useAuth()
    const [isTimeout, setIsTimeout] = useAtom(isTimeoutAtom)
    // eslint-disable-next-line no-unused-vars
    const [showModal, setShowModal] = useAtom(showModalAtom)

    let menus = []
    if (org === 'flexisaf') {
        menus = flexiMenuItems
    } else if (org !== 'flexisaf' && data?.roles?.length < 1) {
        menus = applicantMenuItems
    } else if (org !== 'flexisaf' && data?.roles?.length > 0) {
        menus = menuItems
        setIsAdmin(true)
    }
    const allowedMenus = menus.map((item) => {
        return {
            ...item,
            navlink: `/${org}${item.navlink}`,
        }
    })

    const handleTimer = useCallback(
        () =>
            new IdleTimer({
                timeout: 900,
                onTimeout: () => {
                    setShowModal(true)
                },
                onExpired: () => {
                    setShowModal(true)
                },
            }),
        [setShowModal]
    )

    useEffect(() => {
        handleTimer()
        return () => {
            handleTimer().cleanUp()
            setIsTimeout(false)
            setShowModal(false)
        }
    }, [handleTimer, isTimeout, setIsTimeout, setShowModal])

    const handleResume = useCallback(() => {
        handleTimer().cleanUp()
        setShowModal(false)
        setIsTimeout(false)
    }, [handleTimer, setIsTimeout, setShowModal])

    const handleLogout = useCallback(() => {
        handleTimer().cleanUp()
        setShowModal(false)
        setIsTimeout(true)
        logout()
    }, [handleTimer, logout, setIsTimeout, setShowModal])

    return (
        <Layout
            theme={Theme}
            withTopNav
            withSideNav
            topNavProps={{
                background: '#F5F5F7',
                elevate: 'none',
                scrollable: true,
            }}
            sideNavOpenWidth="250px"
            sideNavProps={{
                float: false,
                background: '#FFF',
                brandHeight: '50px',
                brand: (
                    <SideBarToogle>
                        <Box margin="0 0 0 20px">
                            <img src={SAFLogo} alt="institution" height="40" />
                        </Box>
                    </SideBarToogle>
                ),
                menuList: allowedMenus,
            }}
            sideNavContent={
                <Fragment>
                    <IdleDialog
                        handleLogout={
                            <Button
                                pale
                                onClick={() => handleLogout()}
                                style={{ marginRight: '10px' }}
                            >
                                Log me out
                            </Button>
                        }
                        handleResume={
                            <Button onClick={() => handleResume()}>
                                Resume
                            </Button>
                        }
                    />
                    <Box
                        style={{
                            position: 'absolute',
                            bottom: '20px',
                            left: '0',
                        }}
                    >
                        <SideLink>
                            <Logout
                                onClick={() => logout()}
                                style={{ cursor: 'pointer' }}
                            >
                                <i className="icon-logout" />{' '}
                                <span>Logout</span>
                            </Logout>
                        </SideLink>
                    </Box>
                </Fragment>
            }
            topNavContent={
                <Box
                    display="flex"
                    align="right"
                    pad="20px"
                    margin="0 10px 0 0"
                    width="100%"
                    style={{ background: '#F5F5F7' }}
                >
                    <Box
                        margin="0 30px"
                        display="flex"
                        style={{
                            alignItems: 'center',
                            justifyContent: 'space-between',
                        }}
                    ></Box>
                    <Box display="flex" style={{ alignItems: 'center' }}>
                        <Box margin="0 10px 0 0 ">
                            <Box
                                background={Theme.PrimaryGrey}
                                display="flex"
                                width="40px"
                                height="40px"
                                style={{
                                    alignItems: 'center',
                                    justifyContent: 'center',
                                    borderRadius: '100%',
                                }}
                            >
                                <i
                                    className="icon-user-outline"
                                    style={{ fontSize: '18px' }}
                                />
                            </Box>
                        </Box>
                        <Box>
                            <Text block>
                                {`${data?.firstName} ${data.lastName}`}
                            </Text>
                        </Box>
                    </Box>
                </Box>
            }
        >
            <Switch>
                <Box pad="100px 0 0 0">
                    <Route
                        exact
                        path={`/${org}/profile`}
                        component={(props) => <Profile {...props} />}
                    />
                    <Route
                        exact
                        path={`/${org}/track/:id`}
                        component={(props) => <Track {...props} />}
                    />
                    <Route
                        exact
                        path={`/${org}/transactions`}
                        component={(props) => <Transactions {...props} />}
                    />
                    <Route
                        exact
                        path={`/${org}/payments`}
                        component={(props) => <Payments {...props} />}
                    />
                    <Route
                        exact
                        path={`/${org}/remita-callback`}
                        component={(props) => <PaymentStatus {...props} />}
                    />
                    <Route
                        exact
                        path={`/${org}/requests`}
                        component={(props) => <Requests {...props} />}
                    />
                    <Route
                        exact
                        path={`/${org}/requests/:id`}
                        component={(props) => <RequestDetails {...props} />}
                    />
                    <Route
                        exact
                        path={`/${org}/institutions`}
                        component={(props) => <Customer {...props} />}
                    />
                    <Route exact path="/institution/:id" component={Details} />
                    <Route
                        exact
                        path="/institution-dashboard"
                        component={InstituteDashboard}
                    />
                    <Route
                        exact
                        path={`/${org}/pending`}
                        component={PendingRequest}
                    />
                    <Route
                        exact
                        path={`/${org}/institutions/:id`}
                        component={Details}
                    />
                    <Route
                        exact
                        path={`/${org}/configurations`}
                        component={Configurations}
                    />
                    <Route
                        exact
                        path={`/${org}/institution-dashboard`}
                        component={InstituteDashboard}
                    />
                </Box>
            </Switch>
        </Layout>
    )
}

export default Dashboard

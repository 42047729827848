import { atom } from 'jotai'
import { atomWithStorage } from 'jotai/utils'

export const initialBeneficiaryAtom = atom({
    accountName: '',
    bankCode: '',
    accountNumber: '',
    organisation: '',
    beneficiary_type: '',
    deduct_from: false,
    tempId: null,
    amount: 0,
    is_international: false
})

export const beneficiariesAtom = atomWithStorage('payments', [])

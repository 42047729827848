import { Button } from 'flexibull2/build/button'
import { EmptyState } from 'flexibull2/build/emptystates'
import React, { useEffect } from 'react'
import './styles.css'

const PDFJS = window.pdfjsLib

function PDFViewer({ handleSetData }) {
    const [pdf, setPdf] = React.useState('')
    const [width, setWidth] = React.useState(0)
    const [image, setImage] = React.useState('')
    const [height, setHeight] = React.useState(0)
    // eslint-disable-next-line
    const [currentPage, setCurrentPage] = React.useState(1)
    // eslint-disable-next-line
    const [pdfRendering, setPdfRendering] = React.useState(false)
    const [pageRendering, setPageRendering] = React.useState('')

    async function showPdf(event) {
        try {
            setPdfRendering(true)
            const file = event.target.files[0]
            const uri = URL.createObjectURL(file)
            var _PDF_DOC = await PDFJS.getDocument({ url: uri })
            setPdf(_PDF_DOC)
            setPdfRendering(false)
            document.getElementById('file-to-upload').value = ''
            handleSetData(file)
        } catch (error) {
            alert(error.message)
        }
    }

    async function renderPage() {
        setPageRendering(true)

        var page = await pdf.getPage(currentPage)

        var viewport = page.getViewport(currentPage)
        // eslint-disable-next-line
        var render_context = {
            canvasContext: document
                .querySelector('#pdf-canvas')
                .getContext('2d'),
            viewport: viewport,
        }
        setWidth(viewport.width)
        setHeight(viewport.height)
        await page.render(render_context)

        var canvas = document.getElementById('pdf-canvas')
        var img = canvas.toDataURL('image/png')
        setImage(img)
        setPageRendering(false)
    }

    useEffect(() => {
        pdf && renderPage()
        // eslint-disable-next-line
    }, [pdf, currentPage])

    return (
        <div>
            <div id="pdf-main-container">
                <div id="pdf-loader">
                    {!image && (
                        <EmptyState
                            type="documents"
                            title="Empty Document"
                            info="You have not select any document, Please upload a new document"
                        />
                    )}
                </div>
                <div id="pdf-contents">
                    <div id="image-convas-row">
                        <canvas
                            id="pdf-canvas"
                            width={width}
                            height={height}
                            style={{
                                display: 'none',
                            }}
                        ></canvas>
                        {image && (
                            <img
                                id="image-generated"
                                src={image}
                                alt="pdfImage"
                                style={{ width: '430px', height: '600px' }}
                            />
                        )}
                    </div>
                    <div id="page-loader" hidden={!pageRendering}>
                        Loading page ...
                    </div>
                </div>
                <div
                    style={{
                        marginTop: '2rem',
                        marginBottom: '2rem',
                    }}
                >
                    <div
                        className="uploader"
                        onClick={() =>
                            document.getElementById('file-to-upload').click()
                        }
                    >
                        <div className="uploader-text">
                            <b
                                style={{
                                    color: 'blue',
                                }}
                            >
                                Add
                            </b>{' '}
                            file* or drag and drop file here
                        </div>
                        <div>
                            <Button
                                width={135}
                                height={33}
                                round
                                onClick={() =>
                                    document
                                        .getElementById('file-to-upload')
                                        .click()
                                }
                            >
                                upload
                            </Button>
                            <input
                                type="file"
                                id="file-to-upload"
                                accept="application/pdf"
                                hidden
                                onChange={showPdf}
                            />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default PDFViewer

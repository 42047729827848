import React from 'react'
import { Modal, ModalBody, ModalFooter, Box, Button, Text } from 'flexibull2'
import { Theme } from '../../../pages/config/theme'

const DialogBox = (props) => {
    const {
        okText = 'Ok',
        okType,
        cancelText = 'Cancel',
        loading ,
        onConfirm,
        onCancel,
        children,
        visible,
        width = '400px',
    } = props
    let btnType

    switch (okType) {
        case 'danger':
            btnType = Theme.PrimaryRed
            break
        default:
            btnType = Theme.PrimaryBlue
    }

    return (
        <Modal outerClick open={visible} >
            <ModalBody width={width} style={{background:"#fff"}}>
                <Box pad="25px" align="center" style={{
                    fontWeight:'300',
                    lineHeight:' 1.6',
                    // textTransform: 'capitalize'
                }}>
                    <Text size="0.8rem">{children}</Text>
                </Box>
                <ModalFooter>
                    <Box pad="10px 25px" align="right">
                        <Button
                            // spaceRight
                            color="transparent"
                            fontColor="rgba(0,0,0,0.8)"
                            iconLeft={<i className="flexibull-angle-left" />}
                            onClick={onCancel}
                            style={{marginTop:"-0.2rem"}}
                        >
                            {cancelText}
                        </Button>
                        <Button
                            onClick={onConfirm}
                            iconRight={<i className="flexibull-angle-right" />}
                            color={btnType}
                            progress={loading}
                            width={100}
                            height={30}
                        >
                            {okText}
                        </Button>
                    </Box>
                </ModalFooter>
            </ModalBody>
        </Modal>
    )
}

export default DialogBox

import React from 'react'
import { Modal, ModalBody, Box } from 'flexibull2'
import FreshField from '../Customer/FreshField'
import { Text } from 'flexibull2/build/typo'
import { Button } from 'flexibull2/build/button'

const AddBeneficiary = ({
    open,
    setOpen,
    loading,
    addPayment,
    payments,
    org,
}) => {
    return (
        <div>
            <Modal outerClick open={open}>
                <ModalBody style={{ background: '#fff' }} width="70%">
                    <Box margin="10px 0 10px 0" pad="20px 40px 0">
                        <Text size="22px" block>
                            Add New Payment
                        </Text>
                    </Box>
                    <Box pad="10px 40px 0" margin="10px 0 20px 0">
                        <FreshField
                            loading={loading}
                            org={org}
                            addPayment={addPayment}
                            payments={payments}
                            setOpen={setOpen}
                        />
                    </Box>
                    <Box align="" pad="0 20px 0" margin="0 0 30px 0">
                        <Button
                            style={{
                                border: 'none',
                                textAling: 'center',
                                color: '#fff',
                                background:'red'
                            }}
                            width={90}
                            height={30}
                            onClick={() => setOpen(false)}
                            
                            pad="0 20px"
                        >
                            Close
                        </Button>
                    </Box>
                </ModalBody>
            </Modal>
        </div>
    )
}

export default AddBeneficiary

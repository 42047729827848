import React, { useContext, useEffect } from 'react'
import { Box, Grid, Text, Button, PageTitle } from 'flexibull2'
import { useAuth } from '../../context/auth-context'
import InstitutionContext from '../../context/institution/institutionContext'
import { LightText } from '../components/Styles/LightText.style'
import { Theme } from '../config/theme'
import EditProfile from './EditProfile'

import styled from 'styled-components'


const ProfileContrainer = styled.div`
    display: flex;
    flex: 1;
    justify-content: space-between;
    margin: 20px;
`

const ProfileForm = () => {
    const { data } = useAuth()
   
    const institutionContext = useContext(InstitutionContext)

    const [open, setOpen] = React.useState(false)

    const phone = data?.contacts.find(
        (contact) => contact?.contactType === 'PHONE_NUMBER'
    )

    const { fetchSupportDetails, supportDetails } = institutionContext

    useEffect(() => {
        fetchSupportDetails()
        // eslint-disable-next-line
    }, [])
    return (
        <Box maxWidth="1366px">
            <PageTitle>Profile</PageTitle>

            <ProfileContrainer>
                <div>
                    <Box pad="0 50px">
                        <Grid default="1fr">
                            <Box style={{ marginLeft: '-2rem' }} relative>

                                <Grid default="1fr 1fr 1fr">
                                    <Box>
                                        <LightText bold>
                                            <Text size="15px"> First Name</Text>
                                        </LightText>
                                        <Box margin="5px 0">
                                            <Text size="15px" bold>
                                                {data?.firstName}
                                            </Text>
                                        </Box>
                                    </Box>
                                    <Box>
                                        <LightText bold>
                                            <Text size="15px"> Last Name</Text>
                                        </LightText>
                                        <Box margin="5px 0">
                                            <Text size="15px" bold>
                                                {data?.lastName}
                                            </Text>
                                        </Box>
                                    </Box>
                                    <Box>
                                        <LightText bold>
                                            <Text size="15px"> Other Names</Text>
                                        </LightText>
                                        <Box margin="5px 0">
                                            <Text size="15px" bold>
                                                {data?.othernames
                                                    ? data.othernames
                                                    : null}
                                            </Text>
                                        </Box>
                                    </Box>
                                </Grid>
                                <Box margin="20px 0">
                                    <Grid default="1fr 2fr ">
                                        <Box>
                                            <LightText bold>
                                                <Text size="15px"> Email Address</Text>
                                            </LightText>
                                            <Box margin="5px 0">
                                                <Text size="15px" bold>
                                                    {data?.username}
                                                </Text>
                                            </Box>
                                        </Box>
                                        <Box>
                                            <LightText bold>
                                                <Text size="15px"> Phone Number</Text>
                                            </LightText>
                                            <Box margin="5px 0">
                                                <Text size="15px" bold>
                                                    {phone?.contact || data?.phone_number}
                                                </Text>
                                            </Box>
                                        </Box>
                                    </Grid>
                                </Box>
                            </Box>
                        </Grid>

                        <EditProfile
                            open={open}
                            onClose={() => setOpen(false)}
                            data={data}
                        />
                    </Box>
                </div>
                <div>
                    <Box>
                        <Button
                            pale
                            spaceRight
                            color={Theme.PrimaryBlue}
                            fontColor={Theme.PrimaryBlue}
                            iconRight={<i className="icon-pencil" />}
                            onClick={() => setOpen(true)}
                        >
                            Edit
                        </Button>
                    </Box></div>
            </ProfileContrainer>


            {supportDetails.length > 0 && (
                <Box pad="50px 50px">
                    <h3>Support</h3>

                    <Grid default="1fr 1fr">
                        {supportDetails.map((r) => {
                            return (
                                <>
                                    <Box>
                                        <LightText bold>
                                            <Text size="15px"> Phone</Text>
                                        </LightText>
                                        <Box margin="5px 0">
                                            <Text size="15px" bold>
                                                {r?.phone}
                                            </Text>
                                        </Box>
                                    </Box>
                                    <Box>
                                        <LightText bold>
                                            <Text size="15px"> Email</Text>
                                        </LightText>
                                        <Box margin="5px 0">
                                            <Text size="15px" bold>
                                                {r?.email}
                                            </Text>
                                        </Box>
                                    </Box>
                                </>
                            )
                        })}
                    </Grid>
                </Box>
            )}
        </Box>
    )
}

export default ProfileForm

import { useEffect, useState } from 'react'
import { useAtom } from 'jotai'
import {
    initialBeneficiaryAtom,
    beneficiariesAtom,
} from './payment_config.store'
import { v4 as uuidv4 } from 'uuid'

const usePaymentConfigurationHook = (
    beneficiaryType
) => {
    const [singleBeneficiary, setSingleBeneficiary] = useAtom(
        initialBeneficiaryAtom
    )
    const [beneficiaries, setBeneficiaries] = useAtom(beneficiariesAtom) 
    const [beneficiariesCpoy, setBeneficiariesCpoy] = useState([])
    const [inputs, setInputs] = useState({})

    useEffect(() => {
        if (localStorage.getItem('payments') === null) {
            const res = []
            localStorage.setItem('payments', JSON.stringify(res))
        }
    }, [])

    useEffect(
        () =>
            setBeneficiariesCpoy(
                beneficiaries.map((b) => ({ ...b, isDisable: true }))
            ),
        [beneficiaries]
    )

    const handleDeleteBeneficiary = (id) =>
        setBeneficiaries(beneficiaries.filter(({ tempId }) => tempId !== id))

    const beneficiarySelectionHelper = (id) => {
        const filterById = ({ tempId }) => tempId === id
        const selectedBeneficiary = beneficiaries.find(filterById)
        const selectedBeneficiaryIndex = beneficiaries.findIndex(filterById)
        delete selectedBeneficiary.isDisable
        return {
            selectedBeneficiary,
            selectedBeneficiaryIndex,
        }
    }

    const handleSaveUpdatedBeneficiary = (id) => {
        const { selectedBeneficiary, selectedBeneficiaryIndex } =
            beneficiarySelectionHelper(id)
         
        const newBeneficiariesCopy = [...beneficiariesCpoy]
        newBeneficiariesCopy[selectedBeneficiaryIndex] = {
            ...inputs,
        }
        setInputs(selectedBeneficiary)
        setBeneficiaries(newBeneficiariesCopy)
        setBeneficiariesCpoy(
            beneficiaries.map((b) => ({ ...b, isDisable: true }))
        )
        setInputs({})
    }

    const handleUpdateBeneficiary = ({ id, isDisable }) => {
        const { selectedBeneficiary, selectedBeneficiaryIndex } =
            beneficiarySelectionHelper(id)
        const newBeneficiariesCopy = [...beneficiariesCpoy]
        newBeneficiariesCopy[selectedBeneficiaryIndex] = {
            ...selectedBeneficiary,
            isDisable,
        }
        setBeneficiariesCpoy(newBeneficiariesCopy)
        setInputs(selectedBeneficiary)
    }

    const handleUpdateBeneficiaryCopyOnChange = (e) => {
        if (e.value) {
            const { value } = e
            setInputs((values) => ({ ...values, bankCode: value }))
        }
        else {
            e.target.reset = false
             const target = e.target
             const value =
                 target.type === 'checkbox' ? target.checked : target.value
             const name = target.name
            setInputs((values) => ({ ...values, [name]: value }))
        }
    }

    const resetSingleBeneficiary = (index) => {
        setSingleBeneficiary(
            (beneficiaries[index] = {
                accountName: '',
                bankCode: '',
                accountNumber: '',
                organisation: '',
                beneficiary_type: '',
                deduct_from: false,
                tempId: null,
                amount: 0,
            })
        )
    }

    const handleAddBeneficiary = () => {
        delete singleBeneficiary.isDisable
        const newBeneficiaries = [
            ...beneficiaries,
            {
                ...singleBeneficiary,
                beneficiary_type: beneficiaryType,
                tempId: uuidv4(),
            },
        ]
        setBeneficiaries(newBeneficiaries)
        resetSingleBeneficiary(0) 
    }

    const handleChange = (e) => {
        if (e.value) {
            const { value } = e
            setSingleBeneficiary({
                ...singleBeneficiary,
                bankCode: value,
            })
        } else {
            e.target.reset = false
            const { name, value } = e.target
            setSingleBeneficiary({
                ...singleBeneficiary,
                [name]: value,
            })
        }
    }

    const deleteField = (index, accountItems, addAccountItem) => {
        accountItems.pop(index)
        addAccountItem([...accountItems])
    }

    return {
        deleteField,
        singleBeneficiary,
        setSingleBeneficiary,
        handleChange,
        handleDeleteBeneficiary,
        handleAddBeneficiary,
        handleUpdateBeneficiaryCopyOnChange,
        handleUpdateBeneficiary,
        handleSaveUpdatedBeneficiary,
        inputs,
        setInputs,
        beneficiaries,
        beneficiariesCpoy,
        setBeneficiariesCpoy,
    }
}

export default usePaymentConfigurationHook

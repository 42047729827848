import React, { useState, useEffect, useContext } from 'react'
import { Grid, Box, Input, Button, Text, Loader } from 'flexibull2'
import DepartmentContext from '../../../context/Department/departmentContext'
import DialogBox from '../../components/dialogbox/DialogBox'
import { LightText } from '../../components/Styles/LightText.style'
import EditDepartment from '../EditDepartment'
import DepartmentTable from '../DepartmentTable'
import FacultyContext from '../../../context/Faculty/facultyContext'
import CreateDepartmentModal from '../../../pages/Customer/CreateDepartmentModal'
import { Theme } from '../../config/theme'
import ExcelUploaderDepartment from '../ExcelUploaderDepartment'
import { DYNAMIC } from '../../../utils/constants'

export const DepartmentDetails = ({ orgId }) => {
    const [open, setOpen] = useState(false)
    const [openEdit, setEdit] = useState(false)
    const [openDialog, setOpenDialog] = useState(false)
    const [pageNumber, setpageNumber] = useState(1)
    const [openExcel, setOpenExcel] = useState(false)
    const departmentContext = useContext(DepartmentContext)
    const facultyContext = useContext(FacultyContext)

    const pageSizes = [
        { label: '3 Rows', value: 3 },
        { label: '5 Rows', value: 5 },
        { label: '10 Rows', value: 10 },
        { label: '15 Rows', value: 15 },
        { label: '20 Rows', value: 20 },
    ]
    const [pageOptions, setPageOptions] = useState({
        pageSize: 5,
    })
    const { pageSize } = pageOptions

    const {
        departments,
        getDepartments,
        createDepartment,
        deleteDepartment,
        loading,
        setCurrent,
        current,
        status,
        progressLoading,
        updateDepartment,
        onProgress,
        setLoading,
        unZipDepartmentsFromExcelToServer,
    } = departmentContext
    const { faculties, getAllFaculties, all_faculties } = facultyContext
    useEffect(() => {
        getDepartments(pageSize, 1, orgId);
        getAllFaculties(10000, 1, orgId);
        // eslint-disable-next-line
    }, [])

    const onDelete = (id) => {
        setOpenDialog(true)
        setCurrent(id)
    }
    const onEdit = (department) => {
        setEdit(true)
        setCurrent(department)
    }
    const handleOnConfirm = () => {
        deleteDepartment(current, orgId)
        setOpenDialog(false)
    }

    return (
        <div>
            <Grid default="1fr 2fr">
                <Box pad="25px 0">
                    <Input
                        name="search"
                        type="search"
                        placeholder="search"
                        block
                    />
                </Box>
                <Box pad="20px 0" align="right">
                    <Button
                        width={140}
                        onClick={() => setOpen(true)}
                        pad="0 1px"
                        spaceLeft
                    >
                        Add Department
                    </Button>
                    <Button
                        width={140}
                        pad="0 1px"
                        onClick={() => setOpenExcel(true)}
                        spaceLeft
                        pale
                    >
                        Upload Excel
                    </Button>
                </Box>
            </Grid>
            {loading ? (
                <Box vAlign height={`calc(100vh - 200px)`}>
                    <Box margin="0 auto" align="center">
                        <Loader color={Theme.PrimaryColor} />
                    </Box>
                </Box>
            ) : (
                <DepartmentTable
                    setOpen={setOpen}
                    departments={departments}
                    onDelete={onDelete}
                    onEdit={onEdit}
                    pageSizes={pageSizes}
                    pageNumber={pageNumber}
                    setpageNumber={setpageNumber}
                    getDepartments={getDepartments}
                    orgId={orgId}
                    pageSize={pageSize}
                    setPageOptions={setPageOptions}
                    pageOptions={pageOptions}
                />
            )}
            <CreateDepartmentModal
                open={open}
                setOpen={setOpen}
                faculties={faculties}
                createDepartment={createDepartment}
                loading={loading}
                progressLoading={progressLoading}
                onProgress={onProgress}
                setLoading={setLoading}
                status={status}
                all_faculties={all_faculties}
            />
            <DialogBox
                visible={openDialog}
                okType="danger"
                okText="Confirm"
                onConfirm={handleOnConfirm}
                onCancel={() => setOpenDialog(false)}
            >
                {' '}
                <LightText>
                    <Text size="14px">
                        Are you sure you want to delete this department?.
                        <br /> this action cannot be undone.
                    </Text>
                </LightText>{' '}
            </DialogBox>
            <EditDepartment
                open={openEdit}
                onClose={() => setEdit(false)}
                current={current}
                setEdit={setEdit}
                faculties={faculties}
                updateDepartment={updateDepartment}
            />
            <ExcelUploaderDepartment
                open={openExcel}
                setOpen={setOpenExcel}
                unZipDepartmentsFromExcelToServer={
                    unZipDepartmentsFromExcelToServer
                }
                loading={loading}
                progressLoading={progressLoading}
                onProgress={onProgress}
                setLoading={setLoading}
                status={status}
                type={DYNAMIC}
                all_faculties={all_faculties}
                orgId={orgId}
            />
        </div>
    )
}

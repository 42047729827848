import React, { useState, useRef, useEffect } from 'react'
import { darken, transparentize } from 'polished'
import { SwatchesPicker } from 'react-color'
import styled from 'styled-components'
import { Theme } from '../../config/theme'
import PropTypes from 'prop-types'

const ColorWrapper = styled.div`
    display: inline-block;
    vertical-align: middle;
    position: relative;
    & .colorshow {
        position: absolute;
        display: inline-block;
        top: ${(props) => parseInt(props.size) * 1.6}px;
        right: 0;
        z-index: 10;
    }
`
const MiniSelected = styled.div`
    display: inline-flex;
    vertical-align: bottom;
    margin: 0 50px 0 0;
    width: ${(props) => parseInt(props.size) * 1.5}px;
    height: ${(props) => parseInt(props.size) * 1.5}px;
    border-radius: ${(props) => props.theme.PrimaryRadius};
    background: ${(props) => props.color};
    border: 2px solid ${(props) => darken(0.15, props.color)};
`
const MiniColor = styled.div`
    display: inline-flex;
    align-items: center;
    vertical-align: bottom;
    justify-content: center;
    margin: 3px;
    width: ${(props) => parseInt(props.size) * 1.2}px;
    height: ${(props) => props.size};
    border-radius: ${(props) => props.theme.PrimaryRadius};
    background: ${(props) => props.color};
    border: 1px solid ${(props) => props.color};
    color: #fff;
    overflow: hidden;
    /* transition: all 0.1s linear; */
    cursor: pointer;
    &:hover {
        border: 1px solid white;
        box-shadow: 0 2px 5px
            ${(props) => transparentize(0.6, darken(0.1, props.color))};
    }
    &.act {
        box-shadow: 0 2px 5px ${(props) => transparentize(0.6, props.color)};
        &:after {
            content: 'L';
            display: block;
            transform: rotateZ(45deg) rotateY(180deg);
            font-size: 12px;
        }
    }
`
const MiniCPlus = styled.div`
    display: inline-flex;
    align-items: center;
    justify-content: center;
    margin: 3px;
    overflow: hidden;
    width: ${(props) => parseInt(props.size) * 1.2}px;
    height: ${(props) => props.size};
    border-radius: ${(props) => props.theme.PrimaryRadius};
    background: #fff;
    border: 1px solid ${(props) => props.theme.PrimaryBorderColor};
    /* transition: ${(props) => props.theme.PrimaryTransition}; */
    cursor: pointer;
    color: ${(props) => darken(0.1, props.theme.PrimaryBorderColor)};
    &:hover {
        box-shadow: 0 2px 5px
            ${(props) => transparentize(0.2, props.theme.PrimaryBorderColor)};
    }
`
export const FlexiColor = (props) => {
    const [themeColor, setThemeColor] = useState(props.color)
    const [moreColor, setMoreColor] = useState(false)
    useEffect(() => {
        props.handleChange(themeColor)
    }, [themeColor, props])
    const node = useRef()
    const handleClick = (e) => {
        if (node.current && node.current.contains(e.target)) {
            return
        }
        setMoreColor(false)
    }
    useEffect(() => {
        document.addEventListener('mousedown', handleClick)
        return () => {
            document.removeEventListener('mousedown', handleClick)
        }
    })
    return (
        <ColorWrapper ref={node} size={props.size}>
            <MiniSelected color={themeColor} size={props.size} />
            {props.colors.map((elem) => (
                <MiniColor
                    key={`color-swatch-${elem}`}
                    color={elem}
                    size={props.size}
                    className={themeColor === elem ? 'act' : ''}
                    onClick={() => setThemeColor(elem)}
                />
            ))}
            <MiniCPlus
                size={props.size}
                onClick={() => setMoreColor(!moreColor)}
            >
                +
            </MiniCPlus>
            {moreColor && (
                <div className="colorshow">
                    <SwatchesPicker
                        color={themeColor}
                        onChangeComplete={(color) => setThemeColor(color.hex)}
                    />
                </div>
            )}
        </ColorWrapper>
    )
}
FlexiColor.defaultProps = {
    size: '30px',
    color: Theme.PrimaryColor,
    colors: [
        Theme.PrimaryColor,
        '#5184EB',
        '#38C2FF',
        '#2AEAD4',
        '#90E3BA',
        '#59D973',
        '#A4D013',
        '#EF9822',
        '#EF6122',
        '#EA2A8A',
        '#F450F4',
        '#C28FFC',
        '#989898',
    ],
}
FlexiColor.propTypes = {
    colors: PropTypes.array,
    color: PropTypes.string,
    size: PropTypes.string,
}

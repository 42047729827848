import * as React from 'react'
import { useAtom } from 'jotai'
import { Theme } from '../../../pages/config/theme'
import {
    Layout,
    Box,
    Modal,
    ModalBody,
    ModalFooter,
    // ModalClose,
    Text,
} from 'flexibull2'
import { showModalAtom } from '../../../common/global.store'
import ShowCounter from './CountdownTimer'

const IdleDialog = ({ handleLogout, handleResume }) => {
    const [showModal] = useAtom(showModalAtom)
    const FIVE_MINUTES_IN_MS = 300000
    const NOW_IN_MS = new Date().getTime()
    const dateTimeAfterFiveMunites = NOW_IN_MS + FIVE_MINUTES_IN_MS

    return (
        <Layout theme={Theme}>
            <Box pad="20px" style={{ width: '50%' }}>
                <Modal
                    outerclick
                    // onClose={() => handleResume()}
                    open={showModal}
                >
                    <ModalBody width="35vw">
                        {/* <ModalClose onClick={handleResume}>
                            &times;
                        </ModalClose> */}
                        <Box pad="20px" style={{ width: '90%' }}>
                            <Box style={{ padding: '20px' }} align="center">
                                <Box pad="20px">
                                    <Text size="24px" bold>
                                        Are you there?
                                    </Text>
                                </Box>
                                <Box
                                    style={{
                                        textAlign: 'center',
                                        margin: '20px',
                                        padding: '20px',
                                    }}
                                >
                                    <Text size="14px">
                                        Due to inactivity, you would be logged
                                        out of the system in the next{' '}
                                    </Text>
                                </Box>
                                <Box pad="20px">
                                    <Text
                                        bold={true}
                                        size="20px"
                                        color="#C1B237"
                                    >
                                        <ShowCounter
                                            targetDate={
                                                dateTimeAfterFiveMunites
                                            }
                                        />
                                    </Text>
                                </Box>
                            </Box>
                        </Box>
                        <ModalFooter>
                            <Box pad="20px" align="right">
                                {handleLogout}
                                {handleResume}
                            </Box>
                        </ModalFooter>
                    </ModalBody>
                </Modal>
            </Box>
        </Layout>
    )
}

export default IdleDialog

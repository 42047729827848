import styled from 'styled-components'

 export const ListContainer = styled.div`
    position: static;
    padding: 0px;
    box-sizing: border-box;
    background: white;
     
    

    @media (min-width: 560px) {
        display: none;
 
    }
`
  
export const ListEntry = styled.div`
    padding: 0;
    margin: 0;
    position: sticky;
    top: 0px;

    
`
export const ListHeader = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-bottom: 1px solid rgb(240, 240, 240);
    border-top: 1px solid rgb(240, 240, 240);
    padding: 10px 20px;
    margin: 0; 
    position: sticky;
    top: 0px;
    background: white;
    z-index: 2;

    
` 
export const ListBody = styled.div`
    padding: 20px;
    background: white;
    

`
export const List = styled.div`
    list-style-type: none;
    display: flex;
    justify-content: space-between;
    padding: 10px 0;

    span {
        font-weight: 600;
        font-size: 14px;
        margin-bottom: 20px;

        &:first-child {
            font-weight: 400;
        }
    }
    

`

 
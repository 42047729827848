import React, { useState, useEffect, useContext } from 'react'
import {
    Box,
    Grid,
    Table,
    Spacer,
    Text,
    Tag,
    Select,
    Button,
    Loader,
    FlexiPagination,
    Input,
} from 'flexibull2'
import { ENDPOINT } from '../../utils/httpClient'
import { Theme } from '../config/theme'
import { requeryTransaction } from './services'
import { DetailsModal } from './DetailsModal'
import useDebounce from '../../utils/useDebounce'
import usePagination from '../../utils/usePagination'
import InstitutionContext from '../../context/institution/institutionContext'
import { useAuth } from '../../context/auth-context'
import {
    ListContainer,
    ListEntry,
    ListHeader,
    ListBody,
    List,
} from '../components/ListContainer/ListContainer'
import styled from 'styled-components'
import { capitalize } from '../../utils/Capitalize'
import { getServices } from '../Requests/services'

const ResponsiveTable = styled(Table)`
    @media (max-width: 560px) {
        & table {
            display: none;
        }
    }
`

const ReducePaddingBox = styled(Box)`
    @media (max-width: 560px) {
        padding: 30px 10px;
    }
`

const Transactions = () => {
    const [reference, setReference] = useState('')
    const [detailModalOpen, setDetailModalOpen] = useState(false)
    const [loadingRequery, setLoadingRequery] = useState(null)
    const { org } = useAuth()

    const isSuperAdmin = org === 'flexisaf'
    useEffect(() => {
        if (isSuperAdmin) {
            getInstitutions(1, 100)
        }
        // eslint-disable-next-line
    }, [])
    const institutionContext = useContext(InstitutionContext)
    const { getInstitutions, institutions } = institutionContext

    const [activeDetail, setActiveDetail] = useState({
        transcript_request: {},
    })
    // const [sort, setSort] = useState(null)

    const { data, options, setOptions, pageSizes, loading } = usePagination(
        `${ENDPOINT}/payments`,
        'all'
    )

    const debouncedMatric = useDebounce(reference, 1000)
    useEffect(() => {
        if (debouncedMatric) {
            setOptions({
                ...options,
                transaction_reference: debouncedMatric,
            })
        } else {
            setOptions({
                size: 10,
                page: 0,
                request_type: 'all',
            })
        }
        //eslint-disable-next-line
    }, [debouncedMatric])

    const handlePagination = (page) => {
        setOptions({
            ...options,
            page: page - 1,
        })
    }
    const handlePageSize = (pageSize) => {
        setOptions({
            ...options,
            size: pageSize.value,
        })
    }
    const handleRequery = (reference) => {
        setLoadingRequery(reference)
        requeryTransaction(reference)
            .then(() => {
                setLoadingRequery(null)
                setOptions({
                    ...options,
                    size: 10,
                    page: 0,
                })
            })
            .catch(() => {
                setLoadingRequery(null)
            })
    }
    const handleFilterChange = (name, option) => {
        setOptions({
            ...options,
            page: 0,
            [name]: option.value,
        })
    }
    function handleReferenceChange(e) {
        let val = e.target.value
        setReference(val)
    }
    const institutionOptions = institutions?.data.map((inst) => {
        return {
            label: inst.full_name,
            value: inst.short_name,
        }
    })

    const handleSort = (name, option) => {
        // setSort(option?.value)
        setOptions({
            ...options,
            page: 0,
            [name]: option?.value,
        })
    }
    const [servicesFilter, setServicesFilter] = useState(null)
    useEffect(() => {
        getServices(org).then(({ data }) => {
            if (data) {
                const res = data.map((dt) => {
                    return {
                        label: dt.service_type
                            .toLowerCase()
                            .replace(/\b\w/g, (l) => l.toUpperCase()),
                        value: dt.service_type,
                    }
                })
                setServicesFilter(res)
            }
        })
    }, [org])
    return (
        <>
            <Box margin="-20px 0 0 0">
                <Grid default="1fr">
                    {loading ? (
                        <Box vAlign height={`calc(100vh - 200px)`}>
                            <Box width="200px" margin="0 auto" align="center">
                                <Loader color={Theme.PrimaryColor} />
                            </Box>
                        </Box>
                    ) : (
                        <>
                            <Box pad="0px 30px" style={{ marginTop: 20 }}>
                                <Grid>
                                    <Box>
                                        <Input
                                            onChange={handleReferenceChange}
                                            type="text"
                                            placeholder="Search by Reference"
                                            value={reference}
                                        />
                                    </Box>
                                    <Box>
                                        <Select
                                            placeholder="Status"
                                            onChange={(e) =>
                                                handleFilterChange('status', e)
                                            }
                                            value={
                                                options.status
                                                    ? {
                                                          label: options.status,
                                                          value: options.status,
                                                      }
                                                    : null
                                            }
                                            options={[
                                                {
                                                    label: 'Transaction Pending',
                                                    value: 'Transaction Pending',
                                                },
                                                {
                                                    label: 'Transaction Approved',
                                                    value: 'Transaction Approved',
                                                },
                                            ]}
                                        />
                                    </Box>
                                    {isSuperAdmin && (
                                        <Box>
                                            <Select
                                                placeholder="Institution"
                                                onChange={(e) =>
                                                    handleFilterChange(
                                                        'organisation',
                                                        e
                                                    )
                                                }
                                                value={
                                                    options.organisation
                                                        ? {
                                                              label: options.organisation,
                                                              value: options.organisation,
                                                          }
                                                        : null
                                                }
                                                options={
                                                    institutionOptions || []
                                                }
                                            />
                                        </Box>
                                    )}

                                    <Box>
                                        <Select
                                            placeholder="Request Type"
                                            onChange={(e) =>
                                                handleFilterChange(
                                                    'request_type',
                                                    e
                                                )
                                            }
                                            value={
                                                options.request_type
                                                    ? {
                                                          label: options.request_type,

                                                          value: options.request_type,
                                                      }
                                                    : null
                                            }
                                            options={servicesFilter}
                                        />
                                    </Box>
                                    <Box>
                                        <Select
                                            placeholder="Sort By"
                                            onChange={(e) =>
                                                handleSort('sort_direction', e)
                                            }
                                            value={
                                                options.sort_direction
                                                    ? {
                                                          label: options.sort_direction,

                                                          value: options.sort_direction,
                                                      }
                                                    : null
                                            }
                                            options={[
                                                {
                                                    label: 'Ascending',
                                                    value: 'asc',
                                                },
                                                {
                                                    label: 'Descending',
                                                    value: 'desc',
                                                },
                                            ]}
                                        />
                                    </Box>
                                </Grid>
                            </Box>
                            <ReducePaddingBox pad="30px">
                                <ResponsiveTable striped>
                                    <table>
                                        <thead>
                                            <tr>
                                                <th>Applicant Name</th>
                                                <th>Matric Number</th>
                                                <th>Reference</th>
                                                <th>Date Paid</th>
                                                <th>Status</th>
                                                <th>Actions</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {data.data &&
                                                data?.data.map((item) => {
                                                    const {
                                                        transcript_request,
                                                        reference,
                                                        created_at,
                                                    } = item
                                                    const {
                                                        first_name,
                                                        last_name,
                                                        matric_number,
                                                    } = transcript_request || {}
                                                    return (
                                                        <tr>
                                                            <td>{`${capitalize([
                                                                first_name,
                                                                ...first_name,
                                                            ])} ${capitalize([
                                                                last_name,
                                                                ...last_name,
                                                            ])}`}</td>
                                                            <td>
                                                                {matric_number.toUpperCase()}
                                                            </td>
                                                            <td>{reference}</td>
                                                            <td>
                                                                {new Date(
                                                                    created_at
                                                                )
                                                                    .toISOString()
                                                                    .slice(
                                                                        0,
                                                                        20
                                                                    )}
                                                            </td>
                                                            <td>
                                                                <div
                                                                    style={{
                                                                        display:
                                                                            'flex',
                                                                        alignItems:
                                                                            'center',
                                                                    }}
                                                                    onClick={() => {}}
                                                                >
                                                                    <Tag
                                                                        solid
                                                                        color={
                                                                            item.status ===
                                                                            'Transaction Approved'
                                                                                ? Theme.PrimaryGreen
                                                                                : item.status ===
                                                                                  'Transaction Pending'
                                                                                ? Theme.PrimaryOrange
                                                                                : Theme.PrimaryRed
                                                                        }
                                                                        spaceRight
                                                                    >
                                                                        {item.status ||
                                                                            'Pending'}
                                                                    </Tag>
                                                                </div>
                                                            </td>
                                                            <td>
                                                                <Button
                                                                    pale
                                                                    width={100}
                                                                    height={30}
                                                                    onClick={() =>
                                                                        handleRequery(
                                                                            item.reference
                                                                        )
                                                                    }
                                                                    pad="0 1px"
                                                                    progress={
                                                                        item.reference ===
                                                                        loadingRequery
                                                                    }
                                                                >
                                                                    Requery
                                                                </Button>

                                                                <Button
                                                                    width={100}
                                                                    height={30}
                                                                    onClick={() => {
                                                                        setActiveDetail(
                                                                            item
                                                                        )
                                                                        setDetailModalOpen(
                                                                            true
                                                                        )
                                                                    }}
                                                                    pad="0 1px"
                                                                    style={{
                                                                        marginLeft: 5,
                                                                    }}
                                                                >
                                                                    View Details
                                                                </Button>
                                                            </td>
                                                        </tr>
                                                    )
                                                })}
                                        </tbody>
                                    </table>
                                </ResponsiveTable>

                                <ListContainer>
                                    {data.data &&
                                        data?.data.map((item) => {
                                            const {
                                                transcript_request,
                                                amount,
                                                reference,
                                                created_at,
                                            } = item
                                            const {
                                                organisation_id,
                                                first_name,
                                                last_name,
                                                matric_number,
                                            } = transcript_request || {}
                                            return (
                                                <ListEntry key={matric_number}>
                                                    <ListHeader>
                                                        <div>
                                                            <Text
                                                                block
                                                                bold
                                                                size="18px"
                                                            >{`${first_name} ${last_name}`}</Text>
                                                            <Spacer />
                                                            <Text
                                                                block
                                                                color={
                                                                    Theme.PrimaryGrey
                                                                }
                                                            >
                                                                {matric_number}
                                                            </Text>
                                                            <Spacer space="10" />
                                                        </div>

                                                        <Box
                                                            display="flex"
                                                            style={{
                                                                alignItems:
                                                                    'center',
                                                                justifyContent:
                                                                    'center',
                                                                flexDirection:
                                                                    'column',
                                                            }}
                                                        >
                                                            <Button
                                                                pale
                                                                width={100}
                                                                height={30}
                                                                onClick={() =>
                                                                    handleRequery(
                                                                        item.reference
                                                                    )
                                                                }
                                                                pad="0 1px"
                                                                progress={
                                                                    item.reference ===
                                                                    loadingRequery
                                                                }
                                                            >
                                                                Requery
                                                            </Button>
                                                            <Spacer></Spacer>
                                                            <Button
                                                                width={100}
                                                                height={30}
                                                                onClick={() => {
                                                                    setActiveDetail(
                                                                        item
                                                                    )
                                                                    setDetailModalOpen(
                                                                        true
                                                                    )
                                                                }}
                                                                pad="0 1px"
                                                                style={{
                                                                    marginLeft: 5,
                                                                }}
                                                            >
                                                                View Details
                                                            </Button>
                                                        </Box>
                                                    </ListHeader>
                                                    <ListBody>
                                                        <List>
                                                            <span>
                                                                Institution
                                                            </span>
                                                            <span>
                                                                {
                                                                    organisation_id
                                                                }
                                                            </span>
                                                        </List>
                                                        <List>
                                                            <span>
                                                                Amount Paid
                                                            </span>
                                                            <span>
                                                                {amount}
                                                            </span>
                                                        </List>
                                                        <List>
                                                            <span>
                                                                Reference
                                                            </span>
                                                            <span>
                                                                {reference}
                                                            </span>
                                                        </List>
                                                        <List>
                                                            <span>
                                                                Date Paid
                                                            </span>
                                                            <span>
                                                                {new Date(
                                                                    created_at
                                                                )
                                                                    .toISOString()
                                                                    .slice(
                                                                        0,
                                                                        10
                                                                    )}
                                                            </span>
                                                        </List>
                                                        <List>
                                                            <span>Status</span>
                                                            <span>
                                                                <Tag
                                                                    solid
                                                                    color={
                                                                        item.status ===
                                                                        'Transaction Approved'
                                                                            ? Theme.PrimaryGreen
                                                                            : item.status ===
                                                                              'Transaction Pending'
                                                                            ? Theme.PrimaryOrange
                                                                            : Theme.PrimaryRed
                                                                    }
                                                                    spaceRight
                                                                >
                                                                    {item.status ||
                                                                        'Pending'}
                                                                </Tag>
                                                            </span>
                                                        </List>
                                                    </ListBody>
                                                </ListEntry>
                                            )
                                        })}
                                </ListContainer>

                                <Box>
                                    <FlexiPagination
                                        pageCounts={pageSizes}
                                        total={data.totalResults}
                                        itemsDisplayed
                                        pageSize={options.size}
                                        onChange={handlePagination}
                                        changePageSize={handlePageSize}
                                        current={options.page + 1}
                                    />
                                </Box>
                            </ReducePaddingBox>
                        </>
                    )}
                </Grid>
            </Box>
            {activeDetail.amount && (
                <DetailsModal
                    open={detailModalOpen}
                    onClose={setDetailModalOpen}
                    details={activeDetail}
                />
            )}
        </>
    )
}

export default Transactions

import React, { useReducer } from 'react'
import axios from 'axios'
import { ENDPOINT } from '../../utils/httpClient'
import DepartmentContext from './departmentContext'
import DepartmentReducer from './departmentReducer'
import { Notify } from 'flexibull2'
import { TOP_RIGHT, ERROR_ALERT, SUCCESS } from '../../utils/constants'

import {
    GET_DEPARTMENTS,
    DELETE_DEPARTMENT,
    EDIT_DEPARTMENT,
    SET_LOADING,
    SET_CURRENT,
    SET_STATUS,
    CLEAR_STATUS,
    MODAL_STATUS,
    ERROR,
    CREATE_DEPARTMENT,
    GET_DEPARTMENTS_FROM_STORE,
    GET_ALL_DEPARTMENTS,
} from '../types'

const DepartmentState = (props) => {
    const initialState = {
        departments: null,
        departmentsStore: null,
        loading: false,
        current: null,
        status: null,
        modalStats: false,
        onProgress: false,
        error: null,
        all_departments: null
    }

    const [state, dispatch] = useReducer(DepartmentReducer, initialState)

    const getDepartments = async (size, page, org) => {
        const finalPage = page - 1

        try {
            setLoading()
            const getDepartmentsPromise = org
                ? axios.get(
                      `${ENDPOINT}/departments?size=${size}&page=${finalPage}`,
                      {
                          headers: {
                              'X-ORG-ID': org,
                          },
                      }
                  )
                : axios.get(
                      `${ENDPOINT}/departments?size=${size}&page=${finalPage}`
                  )

            const res = await getDepartmentsPromise

            dispatch({
                type: GET_DEPARTMENTS,

                payload: res.data,
            })

            return res;
        } catch (error) {
            Notify(`Failed to fetch departments please try again`, {
                position: TOP_RIGHT,

                status: ERROR_ALERT,
            })
        }
    }
    const getAllDepartments = async (size, page, org) => {
        const finalPage = page - 1

        try {
            setLoading()
            const getDepartmentsPromise = org
                ? axios.get(
                      `${ENDPOINT}/departments?size=${size}&page=${finalPage}`,
                      {
                          headers: {
                              'X-ORG-ID': org,
                          },
                      }
                  )
                : axios.get(
                      `${ENDPOINT}/departments?size=${size}&page=${finalPage}`
                  )

            const res = await getDepartmentsPromise

            dispatch({
                type: GET_ALL_DEPARTMENTS,
                payload: res.data,
            })

            return res;
        } catch (error) {
            Notify(`Failed to fetch departments please try again`, {
                position: TOP_RIGHT,

                status: ERROR_ALERT,
            })
        }
    }

    const createDepartment = async (data) => {
        try {
            progressLoading()

            const res = await axios.post(`${ENDPOINT}/departments`, data)

            dispatch({
                type: CREATE_DEPARTMENT,

                payload: res.data,
            })

            Notify(`Department added successfully`, {
                position: TOP_RIGHT,

                status: SUCCESS,
            })
        } catch (error) {
            dispatch({
                type: ERROR,
            })

            Notify(`Failed to create faculty please try again`, {
                position: TOP_RIGHT,

                status: ERROR_ALERT,
            })
        }
    }
    const unZipDepartmentsFromExcelToServer = async (data, org, id) => {
        data.forEach(function (department) {
            department.faculty_id = id
        })

        try {
            setLoading()

            const res = await axios.post(
                `${ENDPOINT}/faculties/${id}/departments`,
                data
            )

            dispatch({
                type: CREATE_DEPARTMENT,

                payload: res.data,
            })

            Notify(`Department added successfully`, {
                position: TOP_RIGHT,

                status: SUCCESS,
            })
            getDepartments(5, 1, org)
        } catch (error) {
            dispatch({
                type: ERROR,
            })

            Notify(`Failed to create faculty please try again`, {
                position: TOP_RIGHT,

                status: ERROR_ALERT,
            })
        }
    }

    const getDepartmentsFromStore = async () => {
        try {
            setLoading()
            const res = JSON.parse(localStorage.getItem('departments'))
            dispatch({
                type: GET_DEPARTMENTS_FROM_STORE,
                payload: res,
            })
        } catch (error) {
            Notify(`Failed to fetch departments please try again`, {
                position: TOP_RIGHT,
                status: ERROR_ALERT,
            })
        }
    }
    const saveDepartmentToStore = async (departments) => {
        try {
            let tasks = JSON.parse(localStorage.getItem('departments'))
            if (tasks !== null) {
                const original = tasks.map((t) => {
                    return t
                })
                let newArray
                newArray = original.concat(departments)
                newArray = [departments, ...original]

                localStorage.setItem('departments', JSON.stringify(newArray))
            } else {
                localStorage.setItem('departments', JSON.stringify(departments))
            }
            getDepartmentsFromStore()
        } catch (error) {
            console.log(error)
        }
    }
    const editDepartmentFromStore = async (department, current) => {
        try {
            let tasks
            if (localStorage.getItem('departments') === null) {
                tasks = []
            } else {
                tasks = JSON.parse(localStorage.getItem('departments'))
            }
            const index = tasks.findIndex(function (task) {
                return task.department_name === current.department_name
            })

            if (~index) {
                tasks[index] = department
                localStorage.setItem('departments', JSON.stringify(tasks))
                getDepartmentsFromStore()
            }
        } catch (error) {
            console.log(error)
        }
    }

    const unZipDepartmentsFromExcel = async (departments) => {
        try {
            let tasks = JSON.parse(localStorage.getItem('departments'))
            if (tasks !== null) {
                const original = tasks.map((t) => {
                    return t
                })

                let newArray
                newArray = original.concat(departments)
                console.log(newArray)
                localStorage.setItem('departments', JSON.stringify(newArray))
            } else {
                localStorage.setItem('departments', JSON.stringify(departments))
            }
            getDepartmentsFromStore()
        } catch (error) {
            console.log(error)
        }
    }

    const deleteDepartmentFromStore = async (name) => {
        try {
            let tasks
            if (localStorage.getItem('departments') === null) {
                tasks = []
            } else {
                tasks = JSON.parse(localStorage.getItem('departments'))
            }

            const res = tasks.filter((task) => task.department_name !== name)
            localStorage.setItem('departments', JSON.stringify(res))
            getDepartmentsFromStore()
        } catch (error) {
            console.log(error)
        }
    }
    const updateDepartment = async (data) => {
        try {
            setLoading()
            const res = await axios.put(
                `${ENDPOINT}/departments/${data.id}`,
                data
            )
            dispatch({
                type: EDIT_DEPARTMENT,
                payload: res.data,
            })
            Notify(`Department Updated Successfully`, {
                position: TOP_RIGHT,
                status: SUCCESS,
            })
        } catch (error) {
            Notify(`Failed to edit department please try again`, {
                position: TOP_RIGHT,
                status: ERROR_ALERT,
            })
        }
    }
    // Remove department
    const deleteDepartment = async (id, org) => {
        try {
            setLoading()
            const res = await axios.delete(`${ENDPOINT}/departments/${id}`)
            dispatch({
                type: DELETE_DEPARTMENT,
                payload: id,
            })
            setError(res.message)
            setStatus(res.status)
            getDepartments(5, 1, org)
            Notify(`Department Deleted Successfully`, {
                position: TOP_RIGHT,
                status: SUCCESS,
            })
        } catch (error) {
            getDepartments(5, 1, org)
            Notify(`${error.response.data.message}`, {
                position: TOP_RIGHT,
                status: ERROR_ALERT,
            })
        }
    }

    const setLoading = () => {
        dispatch({ type: SET_LOADING })
    }
    const progressLoading = () => {
        dispatch({ type: MODAL_STATUS })
    }

    const setCurrent = (cur) => dispatch({ type: SET_CURRENT, payload: cur })
    const setError = (err) => dispatch({ type: ERROR, payload: err })

    const setStatus = (status) =>
        dispatch({ type: SET_STATUS, payload: status })

    const clearStatus = () => {
        dispatch({ type: CLEAR_STATUS })
    }
    const handleModalStatus = (stats) => {
        setLoading()
        dispatch({ type: MODAL_STATUS, payload: stats })
    }

    return (
        <DepartmentContext.Provider
            value={{
                departments: state.departments,
                getDepartments,
                createDepartment,
                deleteDepartment,
                loading: state.loading,
                current: state.current,
                status: state.status,
                modalStats: state.modalStats,
                onProgress: state.onProgress,
                setStatus,
                setCurrent,
                handleModalStatus,
                clearStatus,
                updateDepartment,
                progressLoading,
                setLoading,
                getDepartmentsFromStore,
                saveDepartmentToStore,
                departmentsStore: state.departmentsStore,
                unZipDepartmentsFromExcel,
                editDepartmentFromStore,
                deleteDepartmentFromStore,
                unZipDepartmentsFromExcelToServer,
                getAllDepartments,
                all_departments: state.all_departments
            }}
        >
            {props.children}
        </DepartmentContext.Provider>
    )
}

export default DepartmentState

import React, { useState, useContext, useEffect } from 'react'
import { Box, Grid, Text, Button, EmptyState, Loader, Table } from 'flexibull2'
import CreateCourseOnWizard from './CreateCourseOnWizard'
import ExcelUploaderCourse from './ExcelUploaderCourse'
import EditCourseFromStore from './EditCourseFromStore'
import CourseContext from '../../context/Course/courseContext'

import DialogBox from '../components/dialogbox/DialogBox'
import { LightText } from '../components/Styles/LightText.style'

import { Theme } from '../config/theme'
import { ColorBox } from '../components/Styles/ColorBox.style'
import { DropDown } from 'flexibull2/build/dropdown'
import { LOCAL } from '../../utils/constants'

const CourseStoreUI = ({ setCurrentStep, onNext, onPrevious }) => {
    const [open, setOpen] = React.useState(false)

    const [openDialog, setOpenDialog] = useState(false)

    const courseContext = useContext(CourseContext)
    const [currentStep, setStep] = useState(3)
    const [openExcel, setOpenExcel] = useState(false)
    const [edit, setEdit] = useState(false)

    const {
        deleteCourses,
        loading,
        current,
        progressLoading,
        onProgress,
        setLoading,
        saveCourseToStore,
        setCurrent,
        editCourseFromStore,
        unZipCoursesFromExcel,
        deleteCourseFromStore,
    } = courseContext
    const departments = JSON.parse(localStorage.getItem('departments'))
    const faculties = JSON.parse(localStorage.getItem('faculties'))
    const courses = JSON.parse(localStorage.getItem('courses'))
    useEffect(() => {
        if (localStorage.getItem('courses') !== null) {
            const res = []
            localStorage.setItem('courses', JSON.stringify(res))
        }
        setStep(3)
        // eslint-disable-next-line
    }, [])


    const handleOnConfirm = () => {
        deleteCourses(current)
        setOpenDialog(false)
    }

    let courseContent

    const handleNext = () => {
        if (currentStep === 3) {
            setCurrentStep(4)
        } else {
            setStep(3)
        }
    }
    const handlePrevious = () => {
        setCurrentStep(currentStep - 1)
    }

    const onDelete = (name) => {
        deleteCourseFromStore(name)
    }
    const onEdit = (faculty) => {
        setEdit(true)
        setCurrent(faculty)
    }

    if (loading) {
        return (
            <Box vAlign height={`calc(100vh - 200px)`}>
                <Box margin="0 auto" align="center">
                    <Loader color={Theme.PrimaryColor} />
                </Box>
            </Box>
        )
    }

    if (!courses?.length) {
        courseContent = (
            <Box vAlign height={`calc(100vh - 200px)`}>
                <Box margin="0 auto" align="center">
                    <EmptyState
                        type="sms"
                        title="Empty Course"
                        info="You can choose to add the courses manually or do
                        it via an excel file"
                        action={
                            <Box align="center">
                                <Button
                                    pale
                                    width={140}
                                    height={30}
                                    onClick={() => setOpen(true)}
                                    pad="0 1px"
                                    spaceLeft
                                    spaceRight
                                >
                                    Add Manually
                                </Button>
                                <Button
                                    style={{
                                        border: 'none',
                                        textAling: 'center',
                                        color: 'blue',
                                    }}
                                    iconRight={
                                        <i className="flexibull-right" />
                                    }
                                    width={160}
                                    height={30}
                                    spaceRight
                                    pale
                                    onClick={() => setOpenExcel(true)}
                                    pad="0 10px"
                                >
                                    Upload Excel File
                                </Button>
                            </Box>
                        }
                    />
                </Box>
            </Box>
        )
    } else {
        courseContent = (
            <Box maxWidth="850px">
                <Grid default="1fr 2fr">
                    <Box>
                        <Text size="12px" pad="3px 5px">
                            You can choose to add the courses manually or do it
                            via an excel file
                        </Text>
                    </Box>
                    <Box align="right">
                        <Button
                            style={{
                                border: 'none',
                                textAling: 'center',
                                color: 'blue',
                            }}
                            iconRight={<i className="flexibull-right" />}
                            width={140}
                            height={30}
                            spaceRight
                            pale
                            onClick={() => setOpenExcel(true)}
                            pad="0 10px"
                        >
                            Upload Excel File
                        </Button>
                        <Button
                            pale
                            width={140}
                            onClick={() => setOpen(true)}
                            pad="0 1px"
                            spaceLeft
                        >
                            Add Course
                        </Button>
                    </Box>
                </Grid>
                <Table style={{ paddingTop: '10px' }}>
                    <table>
                        <thead>
                            <tr>
                                <th>Course Title</th>
                                <th>Department Title</th>
                                <th>Short Code</th>
                                <td>Action</td>
                            </tr>
                        </thead>
                        <tbody>
                            {courses?.map((course, idx) => (
                                <tr key={idx}>
                                    <td>
                                        {course.name
                                            ? course.name
                                            : course.course_name}
                                    </td>
                                    <td>
                                        {course.department_name
                                            ? course.department_name
                                            : course.department}
                                    </td>
                                    <td>{course.short_code}</td>
                                    <td>
                                        <ColorBox>
                                            <DropDown
                                                label={
                                                    <Button
                                                        pale
                                                        height={20}
                                                        style={{
                                                            border: 'none',
                                                            marginLeft:
                                                                '-1.5rem',
                                                        }}
                                                    >
                                                        <i className="icon-dot-3" />
                                                    </Button>
                                                }
                                                trigger="hover"
                                                menuAlign="top right"
                                                menuList={[
                                                    {
                                                        onClick: () =>
                                                            onEdit(course),
                                                        label: 'Edit Course',
                                                        iconLeft:
                                                            'icon-edit tblue',
                                                    },
                                                    {
                                                        onClick: () =>
                                                            onDelete(
                                                                course.course_name
                                                            ),
                                                        label: 'Delete Course',
                                                        iconLeft:
                                                            'icon-trash tred',
                                                    },
                                                ]}
                                                style={{
                                                    cursor: 'pointer',
                                                }}
                                            />
                                        </ColorBox>
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </Table>
            </Box>
        )
    }

    return (
        <div>
            <Grid default="1fr">
                {' '}
                <Box pad="20px 0">
                    <Text size="20px" block bold>
                        Course Settings
                    </Text>
                    <Box margin="10px 0">
                        <Text size="12px" block>
                            This section is to upload the list of courses in an
                            institution
                        </Text>
                    </Box>
                </Box>
            </Grid>

            {courseContent}

            <Box align="right" margin="100px 0">
                <Button
                    width={120}
                    height={30}
                    onClick={handlePrevious}
                    pale
                    pad="0 15px"
                    style={{
                        border: 'none',
                        textAling: 'center',
                        color: 'black',
                    }}
                >
                    Previous
                </Button>
                <Button
                    iconRight={<i className="flexibull-right" />}
                    width={120}
                    height={30}
                    onClick={handleNext}
                    pad="0 15px"
                >
                    Next
                </Button>
            </Box>
            <DialogBox
                visible={openDialog}
                okType="danger"
                okText="Confirm"
                onConfirm={handleOnConfirm}
                onCancel={() => setOpenDialog(false)}
            >
                {' '}
                <LightText>
                    <Text size="14px">
                        Are you sure you want to delete this course?.
                        <br /> this action cannot be undone.
                    </Text>
                </LightText>{' '}
            </DialogBox>
            <CreateCourseOnWizard
                open={open}
                setOpen={setOpen}
                departments={departments}
                faculties={faculties}
                saveCourseToStore={saveCourseToStore}
                setLoading={setLoading}
                onProgress={onProgress}
                progressLoading={progressLoading}
                loading={loading}
            />
            <ExcelUploaderCourse
                open={openExcel}
                setOpen={setOpenExcel}
                departments={departments}
                faculties={faculties}
                saveCourseToStore={saveCourseToStore}
                setLoading={setLoading}
                onProgress={onProgress}
                progressLoading={progressLoading}
                loading={loading}
                unZipCoursesFromExcel={unZipCoursesFromExcel}
                type={LOCAL}
            />
            <EditCourseFromStore
                open={edit}
                setOpen={setEdit}
                departments={departments}
                faculties={faculties}
                editCourseFromStore={editCourseFromStore}
                setLoading={setLoading}
                onProgress={onProgress}
                progressLoading={progressLoading}
                loading={loading}
                current={current}
            />
        </div>
    )
}

export default CourseStoreUI

import React from 'react'
import {
    Modal,
    ModalBody,
    Box,
    Input,
    Text,
    Button,
    Checkbox,
    Grid,
    Notify,
    Select,
} from 'flexibull2'
import { TOP_RIGHT, ERROR_ALERT } from '../../utils/constants'

const CreateCourseOnWizard = ({
    open,
    setOpen,
    faculties,
    departments,
    setLoading,
    saveCourseToStore,
    onProgress,
}) => {
    const [course, setCourse] = React.useState({
        course_name: '',
        short_code: '',
        faculty: null,
        department: null,
    })
    const [createAnother, setCreateAnother] = React.useState(true)
    const [departmentFilter, setDepartmentFilter] = React.useState([])
    const [isClearable, setIsClearable] = React.useState(false)
    const { course_name, short_code, faculty, department } = course
    let Values = []
    let Dept = []

    faculties &&
        faculties?.map((faculty) =>
            Values.push({
                label: faculty.name,
                subText: faculty.short_code,
                id: faculty.name,
            })
        )

    const filterDepartment = (faculty) => {
        if (faculty) {
            setIsClearable(true)
            let res =
                departments &&
                departments?.filter((dep) => dep.faculty_name === faculty.value)
            setDepartmentFilter(res)
        }
    }

    if (departmentFilter.length > 0) {
        departmentFilter?.map((dep) =>
            Dept.push({
                label: dep.department_name ? dep.department_name : dep.name,
                subText: dep.short_code,
                id: dep.name,
                did: dep.id,
            })
        )
    }

    const handleChange = (e) => {
        const { name, value } = e.target
        setCourse({ ...course, [name]: value })
    }
    const onCreateAnother = (e) => {
        setOpen(true)
        setCreateAnother(!createAnother)
    }

    const handleFaculty = (faculty) => {
        setCourse({ ...course, faculty })
        filterDepartment(faculty)
    }
    const handleDepartment = (department) => {
        setCourse({ ...course, department })
    }

    const onCreateCourse = (e) => {
        e.preventDefault()
        if (course_name === '' || short_code === '' || department === null) {
            Notify(`Please fill in all the fields and try again`, {
                position: TOP_RIGHT,
                status: ERROR_ALERT,
            })
        } else {
            const { label: { props: { id } = {} } = {} } = department
            const data = { course_name, short_code, department_name: id }
            saveCourseToStore(data)
            setCourse({
                course_name: '',
                short_code: '',
                faculty: null,
                department: null,
            })
        }
        if (createAnother === false) {
            setOpen(false)
        }
    }
    return (
        <>
            <Modal outerClick open={open}>
                <ModalBody style={{ background: '#fff' }} width="570px">
                    <Box pad="20px 40px 0">
                        <Box margin="20px 0 20px 0">
                            <Text size="22px" block>
                                Add Course
                            </Text>
                        </Box>
                        <Grid default="1fr 1fr">
                            <Box margin="20px 0">
                                <Select
                                    value={faculty}
                                    onChange={handleFaculty}
                                    elementOptions={Values}
                                    label="Faculty"
                                    placeholder="Select Faculty"
                                    spaceBottom
                                    spaceTop
                                    block
                                    isClearable={isClearable}
                                />
                            </Box>
                            <Box margin="20px 0">
                                <Select
                                    value={department}
                                    onChange={handleDepartment}
                                    elementOptions={Dept}
                                    label="Department"
                                    placeholder="Select Department"
                                    spaceBottom
                                    spaceTop
                                    block
                                />
                            </Box>
                        </Grid>
                        <Grid default="1fr 1fr">
                            <Box margin="0px 0px 25px 0px">
                                <Input
                                    label="Course Title"
                                    name="course_name"
                                    value={course_name}
                                    placeholder="Course Title"
                                    spaceBottom="45px"
                                    width="280px"
                                    onChange={handleChange}
                                />
                            </Box>
                            <Box pad=" 0 0 0">
                                <Input
                                    label="Short Code"
                                    name="short_code"
                                    value={short_code}
                                    placeholder="Short Code"
                                    width="200px"
                                    spaceBottom="45px"
                                    onChange={handleChange}
                                />
                            </Box>
                        </Grid>
                    </Box>
                    <Box pad="0px 30px 40px 0">
                        <Grid default="2fr 1fr 1fr">
                            <Box
                                style={{
                                    marginLeft: '20px',
                                    marginTop: '-10px',
                                }}
                            >
                                <Checkbox
                                    label="Create another"
                                    spaceLeft
                                    onChange={onCreateAnother}
                                    checked={createAnother}
                                />
                            </Box>
                            <Box align="right">
                                <Button
                                    style={{
                                        border: 'none',
                                        textAling: 'center',
                                        color: '#000',
                                    }}
                                    width={90}
                                    onClick={() => setOpen(false)}
                                    pale
                                    pad="0 20px"
                                >
                                    Cancel
                                </Button>
                            </Box>
                            <Box align="right">
                                <Button
                                    width={120}
                                    pad="0 15px"
                                    spaceRight
                                    onClick={onCreateCourse}
                                    progress={onProgress}
                                    disabled={onProgress}
                                    iconRight={
                                        <i className="flexibull-angle-right" />
                                    }
                                >
                                    {onProgress ? 'Creating' : 'Submit'}
                                </Button>
                            </Box>
                        </Grid>
                    </Box>
                </ModalBody>
            </Modal>
        </>
    )
}

export default CreateCourseOnWizard

import * as React from 'react'
import FacultyState from './Faculty/facultyState'
import DepartmentState from './Department/departmentState'
import CourseState from './Course/courseState'
import InstitutionState from './institution/institutionState'
const MainContext = React.createContext()

const MainProvider = (props) => {
    const [main, updateMain] = React.useState({
        paymentInfo: {},
    })
    return (
        <FacultyState>
            <DepartmentState>
                <CourseState>
                    <InstitutionState>
                        <MainContext.Provider
                            value={{ main, updateMain }}
                            {...props}
                        />
                    </InstitutionState>
                </CourseState>
            </DepartmentState>
        </FacultyState>
    )
}
const useMain = () => React.useContext(MainContext)

export { useMain, MainProvider }

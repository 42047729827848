import React, { useState } from 'react'
import { Box, Grid, Input, Button, Notify } from 'flexibull2'
import { TOP_RIGHT, ERROR_ALERT } from '../../utils/constants'

const Admin = ({ setSchoolAdmin, setCurrentStep }) => {
    const [admin, setAdmin] = useState({
        first_name: '',
        last_name: '',
        email: '',
        username: '',
    })
    const [currentStep, setStep] = useState(5)
    const { last_name, first_name, email } = admin
    const handleAdmin = (e) => {
        const { name, value } = e.target
        setAdmin({ ...admin, [name]: value })
    }
    const handleNext = () => {
        if (first_name === '' || last_name === '' || email === '') {
            Notify(`Please fill in all the fields and try again`, {
                position: TOP_RIGHT,
                status: ERROR_ALERT,
            })
        } else {
            setSchoolAdmin({ ...admin, username: admin.email })
            if (currentStep === 5) {
                setCurrentStep(6)
            } else {
                setStep(5)
            }
        }
    }
    const handlePrevious = () => {
        setCurrentStep(currentStep - 1)
    }
    return (
        <>
            <Grid default="1fr 1fr">
                <Box>
                    <Box pad="10px 0 0" maxWidth="500px">
                        <Grid default="1fr 1fr" lr="1fr 1fr">
                            <Input
                                height="39px"
                                style={{ fontSize: '14px' }}
                                type="text"
                                name="first_name"
                                value={first_name}
                                spaceTop
                                onChange={handleAdmin}
                                label="First Name"
                            />
                            <Input
                                height="39px"
                                type="text"
                                name="last_name"
                                value={last_name}
                                spaceTop
                                onChange={handleAdmin}
                                label="Last Name"
                            />
                        </Grid>
                        <Grid
                            default="1fr 1fr"
                            lr="1fr 1fr"
                            columnGap="10px"
                            margin="20px 0"
                        >
                            <Input
                                height="39px"
                                type="text"
                                name="email"
                                value={email}
                                spaceTop
                                onChange={handleAdmin}
                                label="Email Adress"
                            />
                            <Input
                                height="39px"
                                type="text"
                                name="username"
                                value={email}
                                spaceTop
                                disabled
                                label="Username"
                            />
                        </Grid>
                        <Box align="right" margin="200px 0">
                            <Button
                                width={110}
                                height={30}
                                onClick={handlePrevious}
                                pale
                                pad="0 15px"
                                style={{
                                    border: 'none',
                                    textAling: 'center',
                                    color: 'black',
                                }}
                            >
                                Previous
                            </Button>
                            <Button
                                iconRight={<i className="flexibull-right" />}
                                width={120}
                                height={30}
                                onClick={handleNext}
                                pad="0 15px"
                            >
                                Next
                            </Button>
                        </Box>
                    </Box>
                </Box>
            </Grid>
        </>
    )
}

export default Admin

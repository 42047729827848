import React, { useState, useContext, useEffect } from 'react'
import { Box, Grid, Text, Button, Loader, EmptyState, Table } from 'flexibull2'
import CreateDepartmentOnWizard from './CreateDepartmentOnWizard'
import ExcelUploaderDepartment from './ExcelUploaderDepartment'
import EditDepartmentFromStore from './EditDepartmentFromStore'
import DepartmentContext from '../../context/Department/departmentContext'
import DialogBox from '../components/dialogbox/DialogBox'
import { LightText } from '../components/Styles/LightText.style'
import { Theme } from '../config/theme'
import { ColorBox } from '../components/Styles/ColorBox.style'
import { DropDown } from 'flexibull2/build/dropdown'
import { LOCAL } from '../../utils/constants'

const DepartmentStoreUI = ({ setCurrentStep, onNext, onPrevious }) => {
    const [open, setOpen] = React.useState(false)
    const [openDialog, setOpenDialog] = useState(false)
    const departmentContext = useContext(DepartmentContext)
    const [currentStep, setStep] = useState(2)
    // eslint-disable-next-line
    const [edit, setEdit] = useState(false)
    const [openExcel, setOpenExcel] = useState(false)

    const {
        saveDepartmentToStore,
        deleteDepartment,
        loading,
        current,
        status,
        progressLoading,
        setCurrent,
        onProgress,
        setLoading,
        unZipDepartmentsFromExcel,
        editDepartmentFromStore,
        deleteDepartmentFromStore,
    } = departmentContext

    const departments = JSON.parse(localStorage.getItem('departments'))
    const faculties = JSON.parse(localStorage.getItem('faculties'))
    useEffect(() => {
        if (localStorage.getItem('departments') !== null) {
            const res = []
            localStorage.setItem('departments', JSON.stringify(res))
        }
        setStep(2)

        // eslint-disable-next-line
    }, [])

    const handleOnConfirm = () => {
        deleteDepartment(current)
        setOpenDialog(false)
    }

    const handleNext = () => {
        if (currentStep === 2) {
            setCurrentStep(3)
        } else {
            setStep(2)
        }
    }
    const handlePrevious = () => {
        setCurrentStep(currentStep - 1)
    }

    const onDelete = (name) => {
        deleteDepartmentFromStore(name)
    }
    const onEdit = (department) => {
        setEdit(true)
        setCurrent(department)
    }

    let departmentContent

    if (loading) {
        return (
            <Box vAlign height={`calc(100vh - 200px)`}>
                <Box margin="0 auto" align="center">
                    <Loader color={Theme.PrimaryColor} />
                </Box>
            </Box>
        )
    }

    if (!departments?.length) {
        departmentContent = (
            <Box vAlign height={`calc(100vh - 200px)`}>
                <Box margin="0 auto" align="center">
                    <EmptyState
                        type="sms"
                        title="Empty Department"
                        info="You can choose to add the departments manually or do
                        it via an excel file"
                        action={
                            <Box align="center">
                                <Button
                                    pale
                                    width={140}
                                    height={30}
                                    onClick={() => setOpen(true)}
                                    pad="0 1px"
                                    spaceLeft
                                    spaceRight
                                >
                                    Add Manually
                                </Button>
                                <Button
                                    style={{
                                        border: 'none',
                                        textAling: 'center',
                                        color: 'blue',
                                    }}
                                    iconRight={
                                        <i className="flexibull-right" />
                                    }
                                    width={170}
                                    height={30}
                                    spaceRight
                                    pale
                                    onClick={() => setOpenExcel(true)}
                                    pad="0 10px"
                                >
                                    Upload Excel File
                                </Button>
                            </Box>
                        }
                    />
                </Box>
            </Box>
        )
    } else {
        const DepartmentTableContent = (
            <div>
                <Grid default="1fr 2fr">
                    <Box>
                        <Text size="12px" pad="3px 5px">
                            You can choose to add the departments manually or do
                            it via an excel file
                        </Text>
                    </Box>
                    <Box align="right">
                        <Button
                            style={{
                                border: 'none',
                                textAling: 'center',
                                color: 'blue',
                            }}
                            iconRight={<i className="flexibull-right" />}
                            width={140}
                            height={30}
                            spaceRight
                            pale
                            onClick={() => setOpenExcel(true)}
                            pad="0 10px"
                        >
                            Upload Excel File
                        </Button>
                        <Button
                            pale
                            width={140}
                            onClick={() => setOpen(true)}
                            pad="0 1px"
                            spaceLeft
                        >
                            Add Department
                        </Button>
                    </Box>
                </Grid>
                <Table style={{ paddingTop: '10px' }}>
                    <table>
                        <thead>
                            <tr>
                                <th>Department Title</th>
                                <th>Faculty Title</th>
                                <th>Short Code</th>
                                <th>Action</th>
                            </tr>
                        </thead>
                        <tbody>
                            {departments?.map((department) => (
                                <tr key={department.id}>
                                    <td>
                                        {department.department_name
                                            ? department.department_name
                                            : department.name}
                                    </td>
                                    <td>{department.faculty_name}</td>
                                    <td>{department.short_code}</td>
                                    <td>
                                        <ColorBox>
                                            <DropDown
                                                label={
                                                    <Button
                                                        pale
                                                        height={20}
                                                        style={{
                                                            border: 'none',
                                                            marginLeft:
                                                                '-1.5rem',
                                                        }}
                                                    >
                                                        <i className="icon-dot-3" />
                                                    </Button>
                                                }
                                                trigger="hover"
                                                menuAlign="top right"
                                                menuList={[
                                                    {
                                                        onClick: () =>
                                                            onEdit(department),
                                                        label: 'Edit Department',
                                                        iconLeft:
                                                            'icon-edit tblue',
                                                    },
                                                    {
                                                        onClick: () =>
                                                            onDelete(
                                                                department.department_name
                                                            ),
                                                        label: 'Delete Department',
                                                        iconLeft:
                                                            'icon-trash tred',
                                                    },
                                                ]}
                                                style={{
                                                    cursor: 'pointer',
                                                }}
                                            />
                                        </ColorBox>
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </Table>
            </div>
        )
        departmentContent = DepartmentTableContent
    }

    return (
        <div>
            <Grid default="1fr">
                {' '}
                <Box pad="20px 0">
                    <Text size="20px" block bold>
                        Department Settings
                    </Text>
                    <Box margin="10px 0">
                        <Text size="12px" block>
                            This section is to upload the list of department in
                            an institution
                        </Text>
                    </Box>
                </Box>
            </Grid>
            <Box maxWidth="850px" margin="25px 0">
                {departmentContent}

                <Box align="right" margin="100px 0">
                    <Button
                        width={110}
                        height={30}
                        onClick={handlePrevious}
                        pale
                        pad="0 15px"
                        style={{
                            border: 'none',
                            textAling: 'center',
                            color: 'black',
                        }}
                    >
                        Previous
                    </Button>
                    <Button
                        iconRight={<i className="flexibull-right" />}
                        width={120}
                        height={30}
                        onClick={handleNext}
                        pad="0 15px"
                    >
                        Next
                    </Button>
                </Box>
            </Box>
            <CreateDepartmentOnWizard
                open={open}
                setOpen={setOpen}
                faculties={faculties}
                saveDepartmentToStore={saveDepartmentToStore}
                loading={loading}
                progressLoading={progressLoading}
                onProgress={onProgress}
                setLoading={setLoading}
                status={status}
            />
            <ExcelUploaderDepartment
                open={openExcel}
                setOpen={setOpenExcel}
                unZipDepartmentsFromExcel={unZipDepartmentsFromExcel}
                saveDepartmentToStore={saveDepartmentToStore}
                loading={loading}
                progressLoading={progressLoading}
                onProgress={onProgress}
                setLoading={setLoading}
                status={status}
                type={LOCAL}
            />
            <EditDepartmentFromStore
                faculties={faculties}
                open={edit}
                setOpen={setEdit}
                editDepartmentFromStore={editDepartmentFromStore}
                loading={loading}
                progressLoading={progressLoading}
                onProgress={onProgress}
                setLoading={setLoading}
                status={status}
                current={current}
            />
            <DialogBox
                visible={openDialog}
                okType="danger"
                okText="Confirm"
                onConfirm={handleOnConfirm}
                onCancel={() => setOpenDialog(false)}
            >
                {' '}
                <LightText>
                    <Text size="14px">
                        Are you sure you want to delete this department?.
                        <br /> this action cannot be undone.
                    </Text>
                </LightText>{' '}
            </DialogBox>
        </div>
    )
}

export default DepartmentStoreUI

import React, { useContext } from 'react'
import { Box, Grid, Text, Button, Table } from 'flexibull2'
import BannerCard from '../components/BannerCard'
import { LightText } from '../components/Styles/LightText.style'
import { Divider } from '../components/Styles/Divider.js'
import InstitutionContext from '../../context/institution/institutionContext'
import { ColorBox } from '../components/Styles/ColorBox.style'
import { DropDown } from 'flexibull2/build/dropdown'
// const flexisafAccounts = ['4017904612', '0360883515', '6020067886']
const flexisafAccounts = ['4017904612', '0647261757']
const Preview = ({
    institution,
    admin,
    address,
    encodedFiles,
    base64Str,
    onClose,
    account,
    schoolAdmin,
    setCurrentStep,
}) => {
    const institutionContext = useContext(InstitutionContext)
    const { createInstitution } = institutionContext

    const faculties = JSON.parse(localStorage.getItem('faculties')) || []
    const departments = JSON.parse(localStorage.getItem('departments')) || []
    const courses = JSON.parse(localStorage.getItem('courses')) || []

    const { payment } = account
    const onSubmit = (event) => {
        let accountList = JSON.parse(localStorage.getItem('payments'))

        const account = accountList.map((lists) => {
            return {
                ...lists,
            }
        })
        const beneficiariesObject = []
        // eslint-disable-next-line
        account.forEach((acc) => {
            const accVals = {
                accountName: acc.accountName,
                accountNumber: acc.accountNumber,
                bankCode: acc.bankCode,
            }
            const newAccount = {
                ...accVals,
                organisation: flexisafAccounts.includes(acc.accountNumber)
                    ? 'flexisaf'
                    : institution.shortName,
            }
            beneficiariesObject.push({
                account: newAccount,
                amount: acc.amount,
                deduct_from: acc.deduct_from,
                beneficiary_type: acc.beneficiary_type,
            })
        })
        // if (!payment[1].is_international) {
        //     payment.splice(1, 1)
        // }

        const filterEmptyFields = payment.filter(
            (pay) => pay.request_fee !== ''
        ).map((payment) => {
            payment['document_type'] = payment['beneficiary_type']
            delete payment['beneficiary_type']
            return payment
        })
        const data = {
            institution,
            fees: filterEmptyFields,
            beneficiaries: beneficiariesObject,
            admin: schoolAdmin,
            faculties,
            departments,
            courses,
        }

        createInstitution(data, base64Str)
        localStorage.removeItem('payments')
        localStorage.removeItem('faculties')
        localStorage.removeItem('departments')
        localStorage.removeItem('courses')
        onClose()
    }

    const handlePrevious = () => {
        setCurrentStep(5)
    }

    return (
        <div>
            <Grid default="1fr">
                {' '}
                <Box pad="20px 10px">
                    <Text size="20px" block bold>
                        Preview & Submit
                    </Text>
                    <Box margin="10px 0">
                        <Text size="12px" block>
                            This section is to go over all the info you have
                            added to prevent misinformation and errors
                        </Text>
                    </Box>
                </Box>
            </Grid>
            <Box margin="-250px 0 0 0">
                <BannerCard />
                <Box
                    background="#FFF"
                    width="95%"
                    style={{
                        boxShadow: '0px 1px 7px 3px rgba(245,245,247,1)',
                        borderRadius: '10px',
                        zIndex: '2',
                        border: '1px solid #f5f5f7',
                        paddingBottom: '20px',
                    }}
                >
                    <Box pad=" 20px 60px">
                        <h4>SECTION 1: BASIC DEATILS</h4>
                    </Box>
                    <Box>
                        <Box pad="0px 50px">
                            <Grid default="3fr">
                                <Box margin="0 0 0 ">
                                    <Grid default="1fr 1fr 1fr">
                                        <Box>
                                            <LightText bold>
                                                <Text size="15px">
                                                    {' '}
                                                    Institution's Name
                                                </Text>
                                            </LightText>
                                            <Box margin="5px 0">
                                                <Text size="15px" bold>
                                                    {institution.fullName}
                                                </Text>
                                            </Box>
                                        </Box>
                                        <Box>
                                            <LightText bold>
                                                <Text size="15px">
                                                    {' '}
                                                    Institution Short Name
                                                </Text>
                                            </LightText>
                                            <Box margin="5px 0">
                                                <Text size="15px" bold>
                                                    {institution.shortName}
                                                </Text>
                                            </Box>
                                        </Box>
                                        <Box>
                                            <LightText bold>
                                                <Text size="15px">
                                                    {' '}
                                                    Institution Location
                                                </Text>
                                            </LightText>
                                            <Box margin="5px 0">
                                                <Text size="15px" bold>
                                                    {institution.location}
                                                </Text>
                                            </Box>
                                        </Box>
                                    </Grid>
                                    <Box margin="20px 0">
                                        <Grid default="1fr 2fr ">
                                            <Box>
                                                <LightText bold>
                                                    <Text size="15px">
                                                        {' '}
                                                        Institution Email
                                                    </Text>
                                                </LightText>
                                                <Box margin="5px 0">
                                                    <Text size="15px" bold>
                                                        {
                                                            institution.emailAddress
                                                        }
                                                    </Text>
                                                </Box>
                                            </Box>
                                            <Box>
                                                <LightText bold>
                                                    <Text size="15px">
                                                        {' '}
                                                        Institution Phone Number
                                                    </Text>
                                                </LightText>
                                                <Box margin="5px 0">
                                                    <Text size="15px" bold>
                                                        {
                                                            institution.phoneNumber
                                                        }
                                                    </Text>
                                                </Box>
                                            </Box>
                                        </Grid>
                                    </Box>
                                    <Box margin="20px 0">
                                        <Grid default="1fr">
                                            <Box>
                                                <LightText bold>
                                                    <Text size="15px">
                                                        {' '}
                                                        Institution Physical
                                                        Address
                                                    </Text>
                                                </LightText>
                                                <Box margin="5px 0">
                                                    <Text size="15px" bold>
                                                        {
                                                            institution.physicalAddress
                                                        }
                                                    </Text>
                                                </Box>
                                            </Box>
                                        </Grid>
                                    </Box>
                                </Box>
                            </Grid>
                        </Box>
                    </Box>
                    <Divider></Divider>
                    <Box pad=" 20px 60px">
                        <h4>SECTION 2: FACULTY SETTINGS</h4>
                    </Box>
                    {
                        <Box pad=" 0px 60px">
                            <Text>Total Number of Faculties</Text>
                            <h4>{faculties?.length}</h4>
                        </Box>
                    }
                    <Box style={{ marginLeft: '70px' }} maxWidth="700px">
                        <Table style={{ paddingTop: '10px' }}>
                            <table>
                                <thead>
                                    <tr>
                                        <th>Faculty Title</th>
                                        <th>Short Code</th>
                                        <td>Action</td>
                                    </tr>
                                </thead>
                                <tbody>
                                    {faculties?.map((faculty) => (
                                        <tr key={faculty.id}>
                                            <td>{faculty.name}</td>
                                            <td>{faculty.short_code}</td>
                                            <td>
                                                <ColorBox>
                                                    <DropDown
                                                        label={
                                                            <Button
                                                                pale
                                                                height={20}
                                                                style={{
                                                                    border: 'none',
                                                                    marginLeft:
                                                                        '-1.5rem',
                                                                }}
                                                            >
                                                                <i className="icon-dot-3" />
                                                            </Button>
                                                        }
                                                        trigger="hover"
                                                        menuAlign="top left"
                                                        style={{
                                                            cursor: 'pointer',
                                                        }}
                                                    />
                                                </ColorBox>
                                            </td>
                                        </tr>
                                    ))}
                                </tbody>
                            </table>
                        </Table>
                    </Box>
                    <Divider style={{ marginBottom: '1em' }}></Divider>
                    <Box pad=" 20px 60px">
                        <h4>SECTION 3: DEPARTMENT SETTINGS</h4>
                    </Box>
                    {
                        <Box pad=" 0px 60px">
                            <Text>Total Number of Departments</Text>
                            <h4>{departments?.length}</h4>
                        </Box>
                    }
                    <Box style={{ marginLeft: '70px' }} maxWidth="700px">
                        <Table style={{ paddingTop: '10px' }}>
                            <table>
                                <thead>
                                    <tr>
                                        <th>Department Title</th>
                                        <th>Short Code</th>
                                        <th>Faculty</th>
                                        <td>Action</td>
                                    </tr>
                                </thead>
                                <tbody>
                                    {departments?.map((department) => (
                                        <tr key={department.department_name}>
                                            <td>
                                                {department.department_name}
                                            </td>
                                            <td>{department.short_code}</td>
                                            <td>{department?.faculty_id}</td>
                                            <td>
                                                <ColorBox>
                                                    <DropDown
                                                        label={
                                                            <Button
                                                                pale
                                                                height={20}
                                                                style={{
                                                                    border: 'none',
                                                                    marginLeft:
                                                                        '-1.5rem',
                                                                }}
                                                            >
                                                                <i className="icon-dot-3" />
                                                            </Button>
                                                        }
                                                        trigger="hover"
                                                        menuAlign="top right"
                                                        style={{
                                                            cursor: 'pointer',
                                                        }}
                                                    />
                                                </ColorBox>
                                            </td>
                                        </tr>
                                    ))}
                                </tbody>
                            </table>
                        </Table>{' '}
                    </Box>
                    <Box>
                        <Box maxWidth="1000px" margin="25px 0" pad="0px 50px">
                            <Grid default="1fr"></Grid>
                        </Box>
                    </Box>

                    <Divider style={{ marginBottom: '1em' }}></Divider>
                    <Box pad=" 20px 60px">
                        <h4>SECTION 4: COURSE SETTINGS</h4>
                    </Box>
                    {
                        <Box pad=" 0px 60px">
                            <Text>Total Number of Courses</Text>
                            <h4>{courses?.length}</h4>
                        </Box>
                    }

                    <Box style={{ marginLeft: '70px' }} maxWidth="700px">
                        <Table style={{ paddingTop: '10px' }}>
                            <table>
                                <thead>
                                    <tr>
                                        <th>Course Title</th>
                                        <th>Short Code</th>
                                        <th>Department Title</th>

                                        <th>Action</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {courses?.map((course) => (
                                        <tr key={course?.course_name}>
                                            <td>{course?.course_name}</td>
                                            <td>{course?.short_code}</td>
                                            <td>{course?.department_name}</td>

                                            <td>
                                                <ColorBox>
                                                    <DropDown
                                                        label={
                                                            <Button
                                                                pale
                                                                height={20}
                                                                style={{
                                                                    border: 'none',
                                                                }}
                                                            >
                                                                <i className="icon-dot-3" />
                                                            </Button>
                                                        }
                                                        trigger="hover"
                                                        menuAlign="top right"
                                                        style={{
                                                            cursor: 'pointer',
                                                        }}
                                                    />
                                                </ColorBox>
                                            </td>
                                        </tr>
                                    ))}
                                </tbody>
                            </table>
                        </Table>
                    </Box>
                    <Divider></Divider>
                    <Box pad=" 20px 60px">
                        <h4>SECTION 5: INSTITUTION ADMIN</h4>
                    </Box>
                    <Box>
                        {
                            <Box pad="0px 0px 50px 50px">
                                <Grid default="1fr  3fr">
                                    <Box
                                        pad="5px"
                                        style={{
                                            display: 'block',
                                            textAlign: 'center',
                                            width: '50%',
                                        }}
                                    >
                                        <img
                                            style={{
                                                borderRadius: '50%',
                                                textAlign: 'center',
                                            }}
                                            src=""
                                            width="150px"
                                            height="150px"
                                            alt="user"
                                        />
                                    </Box>

                                    <Box margin="0 0 0 ">
                                        <Grid default="1fr 1fr 1fr">
                                            <Box>
                                                <LightText bold>
                                                    <Text size="15px">
                                                        {' '}
                                                        Admin First Name
                                                    </Text>
                                                </LightText>
                                                <Box margin="5px 0">
                                                    <Text size="15px" bold>
                                                        {
                                                            schoolAdmin?.first_name
                                                        }
                                                    </Text>
                                                </Box>
                                            </Box>
                                            <Box>
                                                <LightText bold>
                                                    <Text size="15px">
                                                        {' '}
                                                        Admin Last Name
                                                    </Text>
                                                </LightText>
                                                <Box margin="5px 0">
                                                    <Text size="15px" bold>
                                                        {schoolAdmin?.last_name}
                                                    </Text>
                                                </Box>
                                            </Box>
                                        </Grid>
                                        <Box margin="20px 0">
                                            <Grid default="1fr 2fr ">
                                                <Box>
                                                    <LightText bold>
                                                        <Text size="15px">
                                                            {' '}
                                                            Admin Email
                                                        </Text>
                                                    </LightText>
                                                    <Box margin="5px 0">
                                                        <Text size="15px" bold>
                                                            {schoolAdmin?.email}
                                                        </Text>
                                                    </Box>
                                                </Box>
                                                <Box>
                                                    <LightText bold>
                                                        <Text size="15px">
                                                            {' '}
                                                            Admin Username
                                                        </Text>
                                                    </LightText>
                                                    <Box margin="5px 0">
                                                        <Text size="15px" bold>
                                                            {
                                                                schoolAdmin?.username
                                                            }
                                                        </Text>
                                                    </Box>
                                                </Box>
                                            </Grid>
                                        </Box>
                                    </Box>
                                </Grid>
                            </Box>
                        }
                    </Box>
                </Box>
            </Box>
            <Box style={{ marginBottom: '5em' }}>
                <Box pad="40px 30px 40px 0" align="right">
                    <Grid default=" 1fr 1fr">
                        <Box align="right">
                            <Button
                                style={{
                                    border: 'none',
                                    textAling: 'center',
                                    color: '#000',
                                    marginRight: '-23em',
                                }}
                                width={90}
                                height={30}
                                onClick={handlePrevious}
                                pale
                                pad="0 20px"
                            >
                                Previous
                            </Button>
                        </Box>
                        <Box align="right">
                            <Button
                                width={120}
                                height={30}
                                pad="0 15px"
                                spaceRight
                                onClick={onSubmit}
                            >
                                Save & Close
                            </Button>
                        </Box>
                    </Grid>
                </Box>
            </Box>
        </div>
    )
}

export default Preview

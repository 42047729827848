import React, { useState } from 'react'
import {
    Modal,
    ModalBody,
    Text,
    Box,
    Input,
    Button,
    ModalFooter,
    Grid,
    TextArea,
    Select
} from 'flexibull2'
import { Theme } from '../../config/theme'
import validator from 'validator'

const EditSchool = ({ update, onClose, current,updateInstitutions,loading }) => {
    const [institution, setInstitution] = React.useState({
        full_name: '',
        short_name: '',
        email_address: '',
        phone_number: ''
    })

    const [physical_address, setPhysicalAddress] = React.useState('')
    const [location, setLocation] = React.useState(null)

    const {
        full_name,
        short_name,
        email_address,
        phone_number,
    } = institution
    React.useEffect(() => {
        if (current) {
            setInstitution({
                full_name: current?.full_name,
                short_name: current?.short_name,
                email_address: current?.email_address,
                phone_number: current?.phone_number,
            })
            setPhysicalAddress(current?.physical_address)
            setLocation({value: current?.location,label: current?.location})
        }
    }, [current])

    const [errorMessage, setErrorMessage] = useState(false)

    const handleChange = (e) => {
        const { name, value } = e.target
        if (
            (name === 'phone_number' &&
                validator.isMobilePhone(value) === false) ||
            value.length !== 11
        ) {
            setErrorMessage(true)
        } else {
            setErrorMessage(false)
        }
        setInstitution({ ...institution, [name]: value })
    }

    const handleLocation = (location) => {
        setLocation(location)
    }
    const handleAdress = (e) => {
        setPhysicalAddress(e.target.value)
    }

    const onUpdateInstitution = () => {
        const logo_url = null
        const theme_color = null
        const formData = new FormData()
        const request_processing_timeframe = null
        const locationVal = location.value

               
        formData.append('location', locationVal)
        formData.append('logo_url', logo_url)
        formData.append('full_name', full_name)
        formData.append('short_name', short_name)
        formData.append('theme_color', theme_color) 
        formData.append('phone_number', phone_number)
        formData.append('email_address', email_address)
        formData.append('physical_address', physical_address)
        formData.append('request_processing_timeframe', request_processing_timeframe)


        updateInstitutions(formData, short_name)
        onClose()
   }

    return (
        <div>
            <Modal outerClick open={update}>
                <ModalBody style={{ background: '#fff' }} width="570px">
                    <Box pad="10px 50px 0">
                        <Box margin="20px 0 40px 0">
                            <Text size="20px" block>
                                Edit Institution
                            </Text>
                        </Box>
                        <Grid default="1fr">
                            <Box>
                                <Input
                                    value={full_name}
                                    name="full_name"
                                    onChange={handleChange}
                                    label="Institution's Name"
                                    spaceBottom
                                    spaceTop
                                    block
                                />
                            </Box>
                        </Grid>
                        <Grid default="1fr 1fr">
                            <Box>
                                <Input
                                    value={short_name}
                                    name="short_name"
                                    onChange={handleChange}
                                    label="Institution Short  Name
                                    "
                                    spaceBottom
                                    spaceTop
                                    block
                                    disabled
                                />
                            </Box>
                            <Box>
                                <Input
                                    value={email_address}
                                    name="email_address"
                                    onChange={handleChange}
                                    label="Institution Email
                                    "
                                    type="email_address"
                                    spaceBottom
                                    spaceTop
                                    block
                                />
                            </Box>
                            <Box>
                                <Input
                                    value={phone_number}
                                    name="phone_number"
                                    onChange={handleChange}
                                    label="Institution Phone Number
                                    "
                                    spaceBottom
                                    spaceTop
                                    block
                                    type="phone"
                                    error={errorMessage ? "please enter a valid phone number" : false}
                                />
                            </Box>
                            <Box>
                                <Select
                                    value={location}
                                    name="location"
                                    onChange={handleLocation}
                                    label="Institution location"
                                    type="location"
                                    spaceBottom
                                    spaceTop
                                    block
                                    elementOptions={[
                                        {
                                            iconLeft: 'flexibull-clock',
                                            label: 'London',
                                            subText: 'UK-England',
                                        },
                                        {
                                            iconLeft: 'flexibull-clock',
                                            label: 'FCT',
                                            subText: 'Abuja Nigeria',
                                        },
                                    ]}
                                />
                            </Box>
                        </Grid>
                        <Grid default="1fr"></Grid>
                        <Grid default="1fr">
                            <Box>
                                <TextArea
                                    label="Institution Physical Address
                                    "
                                    spaceBottom
                                    spaceTop
                                    block
                                    style={{
                                        borderRadius: '19.5px',
                                    }}
                                    onChange={handleAdress}
                                    value={physical_address}
                                ></TextArea>
                            </Box>
                        </Grid>
                    </Box>
                    <ModalFooter>
                        <Box pad="20px ">
                            <Grid default="2fr 1fr 1fr">
                                <Box
                                    style={{
                                        marginLeft: '20px',
                                        marginTop: '-10px',
                                    }}
                                ></Box>
                                <Box align="right">
                                    <Button
                                        style={{
                                            border: 'none',
                                            textAling: 'center',
                                            color: '#000',
                                        }}
                                        width={90}
                                        height={30}
                                        onClick={onClose}
                                        pale
                                        pad="0 20px"
                                    >
                                        Cancel
                                    </Button>
                                </Box>
                                <Box align="right">
                                    <Button
                                        width={120}
                                        height={30}
                                        pad="0 15px"
                                        spaceRight
                                        color={Theme.PrimaryBlue}
                                        onClick={onUpdateInstitution}
                                        progress={loading}
                                        disabled={loading}
                                        iconRight={
                                            <i className="flexibull-angle-right" />
                                        }
                                    >
                                        {loading ? 'Updating' : 'Update'}
                                    </Button>
                                </Box>
                            </Grid>
                        </Box>
                    </ModalFooter>
                </ModalBody>
            </Modal>
        </div>
    )
}

export default EditSchool

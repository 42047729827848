import * as React from 'react'
import { useHistory } from 'react-router-dom'
import qs from 'query-string'
import axios, {
    UMS_ENDPOINT,
    ENDPOINT,
    GOOGLE_AUTH_ENDPOINT,
} from '../utils/httpClient'
import Cookies from 'js-cookie'
const AuthContext = React.createContext()
function AuthProvider(props) {
    const history = useHistory()
    function getUserFromLocalStorage() {
        return JSON.parse(localStorage.getItem('e_trans_user')) || {}
    }
    const [data, setData] = React.useState(getUserFromLocalStorage)
    const [isAdmin, setIsAdmin] = React.useState(false)
    const [theme, setTheme] = React.useState('')
    const [org, setOrg] = React.useState(localStorage.getItem('e_trans_org'))

    // eslint-disable-next-line
    const [isAuthed, setIsAuthed] = React.useState(false)
    React.useEffect(() => {
        const { transfer_code } = qs.parse(window.location.search)
        if (transfer_code) {
            axios
                .post(
                    `${GOOGLE_AUTH_ENDPOINT}/auth/products/36163e9e-1d43-4bf6-b0d3-83bfa7da097c/organisations/${org}/transfer`,
                    {
                        code: transfer_code,
                    }
                )
                .then(async ({ data }) => {
                    const { user, access_token } = data

                    await Cookies.set('e_trans_token', access_token)
                    await Cookies.set('refreshToken', data.refresh_token)
                    // await localStorage.setItem('e_trans_token', access_token)
                    await localStorage.setItem(
                        'e_trans_user',
                        JSON.stringify(user)
                    )
                    setData(data.user)
                    if (data.user.roles.length > 0) {
                        setIsAdmin(true)
                    }
                    history.push(
                        data.user.roles.length < 1
                            ? org === 'flexisaf'
                                ? `${org}/profile`
                                : `${org}/requests`
                            : `${org}/profile`
                    )
                })
        } else {
            if (isAuthenticated() && window.location.pathname === '/') {
                history.push(`/${org}/profile`)
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isAuthed])

    const login = async (loginData) => {
        const response = await axios.post(
            `${UMS_ENDPOINT}/${org}/login`,
            loginData
        )
        const { data } = response
        await Cookies.set('e_trans_token', data.access_token)
        await Cookies.set('refreshToken', data.refresh_token)
        await localStorage.setItem('e_trans_user', JSON.stringify(data.user))
        setData(data.user)
        if (data.user.roles.length > 0) {
            setIsAdmin(true)
        }
        history.push(
            data.user.roles.length < 1
                ? org === 'flexisaf'
                    ? `${org}/profile`
                    : `${org}/requests`
                : `${org}/profile`
        )
    } // make a login request

    React.useEffect(() => {
        axios.get(`${ENDPOINT}/institutions/${org}`).then(({ data }) => {
            if (data.theme_color) {
                if (data.theme_color !== 'null') {
                    localStorage.setItem('e_trans_theme', data.theme_color)
                    setTheme(data.theme_color)
                }
            } else {
                localStorage.setItem('e_trans_theme', '')
            }
        })
        // eslint-disable-next-line
    }, [])

    const isAuthenticated = () => Boolean(Cookies.get('e_trans_token'))
    const register = () => {} // register the user
    const logout = () => {
        Cookies.remove('e_trans_token')
        localStorage.removeItem('e_trans_user')
        history.replace(`/${org}`)
        window.location.reload()
    } // clear the token in localStorage and the user data

    return (
        <AuthContext.Provider
            value={{
                isAdmin,
                setIsAdmin,
                data,
                setData,
                isAuthenticated,
                login,
                logout,
                register,
                org,
                setOrg,
                theme,
            }}
            {...props}
        />
    )
}
const useAuth = () => React.useContext(AuthContext)
export { AuthProvider, useAuth }
// the UserProvider in user-context.js is basically:
// const UserProvider = props => (
//   <UserContext.Provider value={useAuth().data.user} {...props} />
// )
// and the useUser hook is basically this:
// const useUser = () => React.useContext(UserContext)

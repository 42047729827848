import { Notify } from 'flexibull2/build/notify';
import { ERROR_ALERT, SUCCESS, TOP_RIGHT } from '../../../utils/constants';
import axios, { UMS_ENDPOINT } from '../../../utils/httpClient'

export const resend = async (params, tempUser, setLoading) => {
   
    const { org } = params
    const { email } = tempUser
    const data = {
        username: email,
        verification_type: 'LINK',
    }

    const resendEmail = async () => {
        try {
            setLoading(true);
            await axios.post(`${UMS_ENDPOINT}/${org}/resend-confirm`, data);
            setLoading(false)
            Notify(`Email resend successfully.`, {
                position: TOP_RIGHT,
                status: SUCCESS,
            });
        } catch (error) {
            setLoading(false)
            Notify(`Failed to resend email, please try again`, {
                position: TOP_RIGHT,
                status: ERROR_ALERT,
            });
        }
    }
    await resendEmail();
}

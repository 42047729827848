import React, { useEffect } from 'react'
import { useParams } from 'react-router-dom'
import Dashboard from '../pages/Dashboard'
import axios from 'axios'
import { ENDPOINT } from '../utils/httpClient'

const Authenticated = () => {
    const { org, theme } = useParams()

    useEffect(() => {
        axios.get(`${ENDPOINT}/institutions/${org}`).then(({ data }) => {
            localStorage.setItem('e_trans_theme', data.theme_color || '')
        })
        // eslint-disable-next-line
    }, [])
    return <Dashboard theme={theme} />
}

export default Authenticated

import React from 'react'
import {
    Modal,
    ModalBody,
    Box,
    Input,
    Text,
    Button,
    Checkbox,
    Notify,
} from 'flexibull2'
import { Grid } from 'flexibull2/build/layout'
import { TOP_RIGHT, ERROR_ALERT } from '../../utils/constants'

const CreateFacultyOnwizard = ({
    open ,
    setOpen,
    saveFacultyToStore,
    loading,
    setLoading,
    status, 
    onProgress,
    shortName,
    getFacultiesFromStore
}) => {
    const [faculty, setFaculty] = React.useState({
        name: '',
        short_code: '',
    })
   
    const [createAnother, setCreateAnother] = React.useState(true)

    const { name, short_code } = faculty

    const handleChange = (e) => {
        const { name, value } = e.target
        setFaculty({ ...faculty, [name]: value })
    }
    const onCreateAnother = (e) => {
        setOpen(true)
        setCreateAnother(!createAnother)
    }

    const onCreateFaculty = (e) => {
        e.preventDefault()
        if (name === '' || short_code === '') {
            Notify(`Please fill in all the fields and try again`, {
                position: TOP_RIGHT,
                status: ERROR_ALERT,
            })
        } else {
            const data = { name, short_code }
           
            saveFacultyToStore(data)
             
        }
        if (createAnother === false) {
            setOpen(false)
        }
        setFaculty({ name: '', short_code: '' })
       
    }


    return (
        <>
            <Modal outerClick open={open}>
                <ModalBody style={{ background: '#fff' }} width="570px">
                    <Box pad="20px 40px 0">
                        <Box margin="20px 0 70px 0">
                            <Text size="22px" block>
                                Add Faculty 
                            </Text>
                        </Box>
                        <Grid default="1fr 1fr">
                            <Box margin="0px 0px 25px 0px">
                                <Input
                                    label="Faculty Title"
                                    name="name"
                                    value={name}
                                    onChange={handleChange}
                                    placeholder="Faculty Title"
                                    spaceBottom="45px"
                                    width="280px"
                                />
                            </Box>
                            <Box pad=" 0 0 0">
                                <Input
                                    label="Faculty Short Code"
                                    name="short_code"
                                    value={short_code}
                                    onChange={handleChange}
                                    placeholder="Faculty Short Code"
                                    width="200px"
                                    spaceBottom="45px"
                                />
                            </Box>
                        </Grid>
                    </Box>
                    <Box pad="0px 30px 40px 0">
                        <Grid default="2fr 1fr 1fr">
                            <Box
                                style={{
                                    marginLeft: '20px',
                                    marginTop: '-10px',
                                }}
                            >
                                <Checkbox
                                    label="Create another"
                                    elevate="high"
                                    checked={createAnother}
                                    spaceLeft
                                    onChange={onCreateAnother}
                                />
                            </Box>
                            <Box align="right">
                                <Button
                                    style={{
                                        border: 'none',
                                        textAling: 'center',
                                        color: '#000',
                                    }}
                                    width={90}
                                    onClick={() => setOpen(false)}
                                    pale
                                    pad="0 20px"
                                >
                                    Cancel
                                </Button>
                            </Box>
                            <Box align="right">
                                <Button
                                    width={120}
                                    height={30}
                                    pad="0 15px"
                                    spaceRight
                                    onClick={onCreateFaculty}
                                    progress={onProgress}
                                    disabled={onProgress}
                                    iconRight={
                                        <i className="flexibull-angle-right" />
                                    }
                                >
                                    {onProgress ? 'Creating' : 'Submit'}
                                </Button>
                            </Box>
                        </Grid>
                    </Box>
                </ModalBody>
            </Modal>
        </>
    )
}

export default CreateFacultyOnwizard

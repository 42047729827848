import { Notify } from 'flexibull2/build/notify';
import { ERROR_ALERT, SUCCESS, TOP_RIGHT } from '../../../utils/constants';
import axios, { UMS_ENDPOINT } from '../../../utils/httpClient'

export const ORG = localStorage.getItem('e_trans_org')
export const resetRequest = async (data, setLoading, org, history) => {
    try {
        setLoading(true);
        await axios.post(`${UMS_ENDPOINT}/${ORG}/password/reset-request`, data);
        setLoading(false)
    Notify(`We sent a recovery link to you at ${data?.username}`, {
            position: TOP_RIGHT,
            status: SUCCESS,
        })
        history.push(`${org}/`);
    } catch (error) {
        setLoading(false)
        Notify(`Failed to reset password, please try again`, {
            position: TOP_RIGHT,
            status: ERROR_ALERT,
        });
    }
}

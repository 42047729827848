import axios from 'axios'
import { Notify } from 'flexibull2/build/notify'
import { ERROR_ALERT, TOP_RIGHT } from './constants'
import Cookies from 'js-cookie'

const environment = process.env.REACT_APP_ENV


let ENDPOINT = ''
let URLPORT = ''
let UMS_ENDPOINT = ''
let GOOGLE_AUTH_ENDPOINT = ''
let UMS_USER_ENDPOINT = ''
let UMS_ROLE_ID_ENDPOINT = ''
let UMS_USER_PROFILE_ENDPOINT = ''
if (environment === 'development') {
    ENDPOINT = 'https://api.safcerts.flexisafapps-dev.com'
    URLPORT = 'https://safcerts.flexisafapps-dev.com/'
    GOOGLE_AUTH_ENDPOINT = 'https://api.ums.flexisafapps-dev.com'
    UMS_ENDPOINT =
        'https://api.ums.flexisafapps-dev.com/auth/products/36163e9e-1d43-4bf6-b0d3-83bfa7da097c/organisations'
    UMS_USER_ENDPOINT =
        'https://api.ums.flexisafapps-dev.com/users/products/36163e9e-1d43-4bf6-b0d3-83bfa7da097c/organisations'
    UMS_ROLE_ID_ENDPOINT =
        'https://api.ums.flexisafapps-dev.com/products/36163e9e-1d43-4bf6-b0d3-83bfa7da097c/organisations/'
    UMS_USER_PROFILE_ENDPOINT =
        'https://api.ums.flexisafapps-dev.com/users/me'
} else if (environment === 'staging') {
    ENDPOINT = 'https://api.safcerts.flexisafapps-stage.com'
    URLPORT = 'https://safcerts.flexisafapps-stage.com/'
    GOOGLE_AUTH_ENDPOINT = 'https://api.ums.flexisafapps-stage.com'
    UMS_ENDPOINT =
        'https://api.ums.flexisafapps-stage.com/auth/products/36163e9e-1d43-4bf6-b0d3-83bfa7da097c/organisations'
    UMS_USER_ENDPOINT =
        'https://api.ums.flexisafapps-stage.com/users/products/36163e9e-1d43-4bf6-b0d3-83bfa7da097c/organisations'
    UMS_ROLE_ID_ENDPOINT =
        'https://api.ums.flexisafapps-stage.com/products/36163e9e-1d43-4bf6-b0d3-83bfa7da097c/organisations/'
    UMS_USER_PROFILE_ENDPOINT =
        'https://api.ums.flexisafapps-stage.com/users/me'
} else if (environment === 'production') {
    ENDPOINT = 'https://api.safcerts.com'
    URLPORT = 'https://app.safcerts.com/'
    GOOGLE_AUTH_ENDPOINT = 'https://ums-api.component.flexisaf.com'
    UMS_ENDPOINT =
        'https://ums-api.component.flexisaf.com/auth/products/36163e9e-1d43-4bf6-b0d3-83bfa7da097c/organisations'
    UMS_USER_ENDPOINT =
        'https://ums-api.component.flexisaf.com/users/products/36163e9e-1d43-4bf6-b0d3-83bfa7da097c/organisations'
    UMS_ROLE_ID_ENDPOINT =
        'https://ums-api.component.flexisaf.com/products/36163e9e-1d43-4bf6-b0d3-83bfa7da097c/organisations/'
    UMS_USER_PROFILE_ENDPOINT =
        'https://ums-api.component.flexisaf.com/users/me'
}
const _ORG = localStorage.getItem('e_trans_org')
const token = Cookies.get('e_trans_token')

const instance = axios.create({
    baseURL: ENDPOINT,
    timeout: 1000,
    headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        'X-ORG-ID': _ORG,
        Authorization: token ? `bearer ${token}` : '',
    },
})

const getLocalAccessToken = () => Cookies.get('e_trans_token')
instance.interceptors.request.use(
    (config) => {
        const token = getLocalAccessToken()
        if (token) {
            config.headers['x-access-token'] = token
        }
        return config
    },
    (error) => {
        return Promise.reject(error)
    }
)
instance.interceptors.response.use(
    (res) => res,
    async (err) => {
        const originalConfig = err.config
        if (err.response) {
            if (err.response.status === 401 && !originalConfig._retry) {
                originalConfig._retry = true
                try {
                    window.location.assign(`/${_ORG}`)
                    Notify('Your session has expired please login again', {
                        position: TOP_RIGHT,
                        status: ERROR_ALERT,
                    })
                } catch (_error) {
                    if (_error.response && _error.response.data) {
                        return Promise.reject(_error.response.data)
                    }
                    return Promise.reject(_error)
                }
            }
            if (err.response.status === 403 && err.response.data) {
                Notify('Your session has expired please login again', {
                    position: TOP_RIGHT,
                    status: ERROR_ALERT,
                })
                return Promise.reject(err.response.data)
            }
            return Promise.reject(err)
        }
    }
)

export const ORG = localStorage.getItem('e_trans_org')
export {
    ENDPOINT,
    UMS_ENDPOINT,
    UMS_USER_ENDPOINT,
    URLPORT,
    GOOGLE_AUTH_ENDPOINT,
    UMS_ROLE_ID_ENDPOINT,
    UMS_USER_PROFILE_ENDPOINT,
}

export default axios

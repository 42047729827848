import axios, { ENDPOINT } from '../../../utils/httpClient'

export const getTranscripts = (allOptions) => {
    return axios.get(`${ENDPOINT}/requests?${allOptions.toString()}`)
}

export const getMerchant = async (org) => {
    const {
        data: { merchant_id },
    } = await axios.get(`${ENDPOINT}/merchants?institution=${org}`)

    return merchant_id
}

export const getServices = (org) => {
    const results = getMerchant(org).then((id) => {
        return axios.get(`${ENDPOINT}/service-types?merchant=${id}`)
    })

    return results
}

import React, { useContext, useState, useEffect } from 'react'
import { Box, Grid, Text, Button, EmptyState, Table } from 'flexibull2'
import CreateFacultyOnwizard from './CreateFacultyOnwizard'
import EditFacultyFromStore from './EditFacultyOnStore'
import ExcelUploader from './ExcelUploader'
import FacultyContext from '../../context/Faculty/facultyContext'
import DialogBox from '../components/dialogbox/DialogBox'
import { LightText } from '../components/Styles/LightText.style'

import { ColorBox } from '../components/Styles/ColorBox.style'
import { DropDown } from 'flexibull2/build/dropdown'
import { LOCAL } from '../../utils/constants'
const FacultyStoreUi = ({ setCurrentStep, currentPage, total, shortName }) => {
    const [open, setOpen] = useState(false)
    const [edit, setEdit] = useState(false)
    const [openExcel, setOpenExcel] = useState(false)
    const [openDialog, setOpenDialog] = useState(false)
    const facultyContext = useContext(FacultyContext)
    const [currentStep, setStep] = useState(1)
    const {
        getFaculties,
        deleteFaculty,
        current,
        status,
        progressLoading,
        onProgress,
        setLoading,
        saveFacultyToStore,
        deleteFacultyFromStore,
        editFacultyFromStore,
        setCurrent,
        unZipFacultiesFromExcel
    } = facultyContext
    const faculties = JSON.parse(localStorage.getItem('faculties'))

    useEffect(() => {
        getFaculties(5, 1)
        if (localStorage.getItem('faculties') !== null) {
            const res = []
            localStorage.setItem('faculties', JSON.stringify(res))
        }
        setStep(1)

        // eslint-disable-next-line
    }, [])

    const handleOnConfirm = () => {
        deleteFaculty(current)
        setOpenDialog(false)
    }

    const handleNext = () => {
        // setCurrentStep(2)
        if (currentStep === 1) {
            setCurrentStep(2)
        } else {
            setStep(1)
        }
    }
    const handlePrevious = () => {
        setCurrentStep(currentStep - 1)
    }

    const onDelete = (name) => {
        deleteFacultyFromStore(name)
    }
    const onEdit = (faculty) => {
        setEdit(true)
        setCurrent(faculty)
      
    }

    let facultyContent

    if (!faculties?.length) {
        facultyContent = (
            <Box vAlign height={`calc(100vh - 200px)`}>
                <Box margin="0 auto" align="center">
                    <EmptyState
                        type="sms"
                        title="Empty Faculty"
                        info="You have not created any faculty, You can choose to add the faculties manually or do
                        it via an excel file"
                        action={
                            <Box align="center">
                                <Button
                                    pale
                                    width={140}
                                    height={30}
                                    onClick={() => setOpen(true)}
                                    pad="0 1px"
                                    spaceLeft
                                    spaceRight
                                >
                                    Add Manually
                                </Button>
                                <Button
                                    style={{
                                        border: 'none',
                                        textAling: 'center',
                                        color: 'blue',
                                    }}
                                    iconRight={
                                        <i className="flexibull-right" />
                                    }
                                    width={160}
                                    height={30}
                                    spaceRight
                                    onClick={() => setOpenExcel(true)}
                                    pale
                                    pad="0 20px"
                                >
                                    Upload Excel File
                                </Button>
                            </Box>
                        }
                    />
                </Box>
            </Box>
        )
    } else {
        const FacultyTableContent = (
            <div>
                <Grid default="1fr 2fr">
                    <Box>
                        <Text size="12px" pad="3px 5px">
                            You can choose to add the faculties manually or do
                            it via an excel file
                        </Text>
                    </Box>
                    <Box align="right">
                        <Button
                            style={{
                                border: 'none',
                                textAling: 'center',
                                color: 'blue',
                            }}
                            iconRight={<i className="flexibull-right" />}
                            width={170}
                             onClick={() => setOpenExcel(true)}
                            spaceRight
                            pale
                            pad="0 10px"

                        >
                            Upload Excel File
                        </Button>
                        <Button
                            pale
                            width={140}
                            height={30}
                            onClick={() => setOpen(true)}
                            pad="0 1px"
                            spaceLeft
                        >
                            Add Faculty
                        </Button>
                    </Box>
                </Grid>
                <Table style={{ paddingTop: '10px' }}>
                    <table>
                        <thead>
                            <tr>
                                <th>Faculty Title</th>
                                <th>Short Code</th>
                                <td>Action</td>
                            </tr>
                        </thead>
                        <tbody>
                            {faculties?.map((faculty) => (
                                <tr key={faculty.id}>
                                    <td>{faculty.name}</td>
                                    <td>{faculty.short_code}</td>
                                    <td>
                                        <ColorBox>
                                            <DropDown
                                                label={
                                                    <Button
                                                        pale
                                                        height={20}
                                                        style={{
                                                            border: 'none',
                                                            marginLeft:
                                                                '-1.5rem',
                                                        }}
                                                    >
                                                        <i className="icon-dot-3" />
                                                    </Button>
                                                }
                                                trigger="hover"
                                                menuAlign="top right"
                                                      menuList={[
                                                        {
                                                            onClick: () =>
                                                                onEdit(faculty),
                                                            label: 'Edit Faculty',
                                                            iconLeft:
                                                                'icon-edit tblue',
                                                        },
                                                        {
                                                            onClick: () =>
                                                                onDelete(
                                                                    faculty.name
                                                                ),
                                                            label: 'Delete Faculty',
                                                            iconLeft:
                                                                'icon-trash tred',
                                                        },
                                                    ]}
                                                style={{
                                                    cursor: 'pointer',
                                                }}
                                            />
                                        </ColorBox>
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </Table>
            </div>
        )
        facultyContent = FacultyTableContent
    }

    return (
        <div>
            <Grid default="1fr">
                {' '}
                <Box pad="20px 0">
                    <Text size="20px" block bold>
                        Faculty Settings
                    </Text>
                    <Box margin="10px 0">
                        <Text size="12px" block>
                            This section is to upload the list of faculties in
                            an institution
                        </Text>
                    </Box>
                </Box>
            </Grid>
            <Box maxWidth="850px" margin="25px 0">
                <Grid default="1fr">{facultyContent}</Grid>
                <Box align="right" margin="100px 0">
                    <Button
                        width={110}
                        height={30}
                        onClick={handlePrevious}
                        pale
                        pad="0 15px"
                        style={{
                            border: 'none',
                            textAling: 'center',
                            color: 'black',
                        }}
                    >
                        Previous
                    </Button>
                    <Button
                        iconRight={<i className="flexibull-right" />}
                        width={120}
                        height={30}
                        onClick={handleNext}
                        pad="0 15px"
                    >
                        Next
                    </Button>
                </Box>
            </Box>
            <CreateFacultyOnwizard
                open={open}
                setOpen={setOpen}
                shortName={shortName}
                saveFacultyToStore={saveFacultyToStore}
                progressLoading={progressLoading}
                onProgress={onProgress}
                setLoading={setLoading}
                status={status}
            />
            <EditFacultyFromStore 
                open={edit}
                setOpen={setEdit}
                shortName={shortName}
                saveFacultyToStore={saveFacultyToStore}
                progressLoading={progressLoading}
                onProgress={onProgress}
                setLoading={setLoading}
                status={status}
                editFacultyFromStore={editFacultyFromStore}
                current={current}
            />
             <ExcelUploader 
                open={openExcel}
                setOpen={setOpenExcel}
                shortName={shortName}
                saveFacultyToStore={saveFacultyToStore}
                progressLoading={progressLoading}
                onProgress={onProgress}
                setLoading={setLoading}
                status={status}
                editFacultyFromStore={editFacultyFromStore}
                current={current}
                unZipFacultiesFromExcel={unZipFacultiesFromExcel}
                type={LOCAL}
            />
            <DialogBox
                visible={openDialog}
                okType="danger"
                okText="Confirm"
                onConfirm={handleOnConfirm}
                onCancel={() => setOpenDialog(false)}
            >
                {' '}
                <LightText>
                    <Text size="14px">
                        Are you sure you want to delete this faculty?.
                        <br /> this action cannot be undone.
                    </Text>
                </LightText>{' '}
            </DialogBox>
        </div>
    )
}

export default FacultyStoreUi

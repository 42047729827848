import React, { useReducer } from 'react'
import axios from 'axios'
import { ENDPOINT } from '../../utils/httpClient'
import CourseContext from './courseContext'
import CourseReducer from './courseReducer'
import { Notify } from 'flexibull2'
import { TOP_RIGHT, ERROR_ALERT, SUCCESS } from '../../utils/constants'

import {
    GET_COURSES,
    DELETE_COURSE,
    EDIT_COURSE,
    SET_LOADING,
    SET_CURRENT,
    SET_STATUS,
    CLEAR_STATUS,
    MODAL_STATUS,
    ERROR,
    CREATE_COURSE,
    GET_COURSES_FROM_STORE,
} from '../types'

const CourseState = (props) => {
    const initialState = {
        courses: null,
        coursesStore: null,
        loading: false,
        current: null,
        status: null,
        modalStats: false,
        onProgress: false,
        error: null,
    }

    const [state, dispatch] = useReducer(CourseReducer, initialState)
    const getCourses = async (size, page, org) => {
        const finalPage = page - 1
        try {
            setLoading()
            const getCoursesPromise = org
                ? axios.get(
                      `${ENDPOINT}/courses?size=${size}&page=${finalPage}`,
                      {
                          headers: {
                              'X-ORG-ID': org,
                          },
                      }
                  )
                : axios.get(
                      `${ENDPOINT}/courses?size=${size}&page=${finalPage}`
                  )

            const res = await getCoursesPromise

            dispatch({
                type: GET_COURSES,

                payload: res.data,
            })
        } catch (error) {
            Notify(`Failed to fetch courses please try again`, {
                position: TOP_RIGHT,

                status: ERROR_ALERT,
            })
        }
    }

    const createCourses = async (data) => {
        try {
            progressLoading()

            const res = await axios.post(`${ENDPOINT}/courses`, data)

            dispatch({
                type: CREATE_COURSE,

                payload: res.data,
            })

            Notify(`Courses added successfully`, {
                position: TOP_RIGHT,

                status: SUCCESS,
            })
        } catch (error) {
            dispatch({
                type: ERROR,
            })

            Notify(`Failed to create course please try again`, {
                position: TOP_RIGHT,

                status: ERROR_ALERT,
            })
        }
    }
    const unZipCoursesFromExcelToServer = async (data, org, id) => {
        data.forEach(function (course) {
            course.department_id = id
        })
        try {
            setLoading()

            const res = await axios.post(
                `${ENDPOINT}/departments/${id}/courses`,
                data
            )

            dispatch({
                type: CREATE_COURSE,

                payload: res.data,
            })

            Notify(`Courses added successfully`, {
                position: TOP_RIGHT,

                status: SUCCESS,
            })
            getCourses(5, 1, org)
        } catch (error) {
            dispatch({
                type: ERROR,
            })

            Notify(`Failed to create course please try again`, {
                position: TOP_RIGHT,

                status: ERROR_ALERT,
            })
        }
    }

    const getCoursesFromStore = async () => {
        try {
            setLoading()
            const res = JSON.parse(localStorage.getItem('courses'))
            dispatch({
                type: GET_COURSES_FROM_STORE,
                payload: res,
            })
        } catch (error) {
            Notify(`Failed to fetch courses please try again`, {
                position: TOP_RIGHT,
                status: ERROR_ALERT,
            })
        }
    }
    const saveCourseToStore = async (courses) => {
        try {
            let tasks = JSON.parse(localStorage.getItem('courses'))
            if (tasks !== null) {
                const original = tasks.map((t) => {
                    return t
                })
                let newArray
                newArray = original.concat(courses)
                newArray = [courses, ...original]

                localStorage.setItem('courses', JSON.stringify(newArray))
            } else {
                localStorage.setItem('courses', JSON.stringify(courses))
            }
            getCoursesFromStore()
        } catch (error) {
            console.log(error)
        }
    }
    const editCourseFromStore = async (course, current) => {
        try {
            let tasks
            if (localStorage.getItem('courses') === null) {
                tasks = []
            } else {
                tasks = JSON.parse(localStorage.getItem('courses'))
            }
            const index = tasks.findIndex(function (task) {
                return task.course_name === current.course_name
            })

            if (~index) {
                tasks[index] = course
                localStorage.setItem('courses', JSON.stringify(tasks))
                getCoursesFromStore()
            }
        } catch (error) {
            console.log(error)
        }
    }

    const unZipCoursesFromExcel = async (courses) => {
        try {
            let tasks = JSON.parse(localStorage.getItem('courses'))
            if (tasks !== null) {
                const original = tasks.map((t) => {
                    return t
                })

                let newArray
                newArray = original.concat(courses)
                console.log(newArray)
                localStorage.setItem('courses', JSON.stringify(newArray))
            } else {
                localStorage.setItem('courses', JSON.stringify(courses))
            }
            getCoursesFromStore()
        } catch (error) {
            console.log(error)
        }
    }

    const deleteCourseFromStore = async (name) => {
        try {
            let tasks
            if (localStorage.getItem('courses') === null) {
                tasks = []
            } else {
                tasks = JSON.parse(localStorage.getItem('courses'))
            }

            const res = tasks.filter((task) => task.course_name !== name)
            localStorage.setItem('courses', JSON.stringify(res))
            getCoursesFromStore()
        } catch (error) {
            console.log(error)
        }
    }
    const updateCourses = async (data) => {
        try {
            setLoading()
            const res = await axios.put(`${ENDPOINT}/courses/${data.id}`, data)
            dispatch({
                type: EDIT_COURSE,
                payload: res.data,
            })
            Notify(`Courses Updated Successfully`, {
                position: TOP_RIGHT,
                status: SUCCESS,
            })
        } catch (error) {
            Notify(`Failed to edit course please try again`, {
                position: TOP_RIGHT,
                status: ERROR_ALERT,
            })
        }
    }
    // Remove course
    const deleteCourses = async (id, org) => {
        try {
            setLoading()
            const res = await axios.delete(`${ENDPOINT}/courses/${id}`)
            dispatch({
                type: DELETE_COURSE,
                payload: id,
            })
            setError(res.message)
            setStatus(res.status)
            getCourses(5, 1, org)
            Notify(`Courses Deleted Successfully`, {
                position: TOP_RIGHT,
                status: SUCCESS,
            })
        } catch (error) {
            getCourses(5, 1, org)
            Notify(`${error.response.data.message}`, {
                position: TOP_RIGHT,
                status: ERROR_ALERT,
            })
        }
    }

    const setLoading = () => {
        dispatch({ type: SET_LOADING })
    }
    const progressLoading = () => {
        dispatch({ type: MODAL_STATUS })
    }

    const setCurrent = (cur) => dispatch({ type: SET_CURRENT, payload: cur })
    const setError = (err) => dispatch({ type: ERROR, payload: err })

    const setStatus = (status) =>
        dispatch({ type: SET_STATUS, payload: status })

    const clearStatus = () => {
        dispatch({ type: CLEAR_STATUS })
    }
    const handleModalStatus = (stats) => {
        setLoading()
        dispatch({ type: MODAL_STATUS, payload: stats })
    }

    return (
        <CourseContext.Provider
            value={{
                courses: state.courses,
                coursesStore: state.coursesStore,
                getCourses,
                createCourses,
                deleteCourses,
                loading: state.loading,
                current: state.current,
                status: state.status,
                modalStats: state.modalStats,
                onProgress: state.onProgress,
                setStatus,
                setCurrent,
                handleModalStatus,
                clearStatus,
                updateCourses,
                progressLoading,
                setLoading,
                getCoursesFromStore,
                saveCourseToStore,
                editCourseFromStore,
                unZipCoursesFromExcel,
                deleteCourseFromStore,
                unZipCoursesFromExcelToServer,
            }}
        >
            {props.children}
        </CourseContext.Provider>
    )
}

export default CourseState

import React from 'react'
import styled from 'styled-components'
import { Theme } from '../../config/theme'
import { lighten } from 'polished'

const BgPattern = styled.div`
    width: 100vw;
    height: 100vh;
    background-color: ${Theme.PrimaryBackground};
    position: relative;
    overflow: hidden;
    z-index: 99; 
 
    &::before {
        content: '';
        position: absolute;
        width: 766px;
        height: 766px;
        background: transparent;
        border: 1px dashed ${lighten(0.3, Theme.PrimaryColor)};
        top: -320px;
        left: 580px;
        border-radius: 100%;
        z-index: -1;
    }

    &::after {
        content: '';
        position: absolute;
        width: 766px;
        height: 766px;
        background: transparent;
        border: 1px dashed ${lighten(0.3, Theme.PrimaryColor)};
        bottom: -180px;
        right: -100px;
        border-radius: 100%;
        z-index: -1;
    } 
 
    & .patterned {
        z-index: -1;
        width: 1141px;
        height: 1141px;
        border: 1px dashed ${lighten(0.3, Theme.PrimaryColor)};
        border-radius: 383px;
        transform: rotate(-44deg);
        position: absolute;
        top: -250px;
        right: -200px;
        /* z-index: -1; */
    }
`
const AuthLayout = (props) => {
    return (
        <BgPattern>
            <div className="patterned"></div>
            {props.children}
        </BgPattern>
    )
}

export default AuthLayout

import React, {useState} from 'react'
import { edit, uploadLogo } from './services'
import {
    Modal,
    ModalBody,
    Text,
    Box,
    Input,
    Button,
    ModalFooter,
    Grid,
} from 'flexibull2'
import Uploader from '../components/Styles/upload'
import { Theme } from '../config/theme'
import validator from 'validator'

const EditProfile = ({ open, onClose, data }) => {
    const [loading, setLoading] = React.useState(false)
    const [logo, setLogo] = React.useState(null)
    const [profile, setProfile] = React.useState({
        nafull_nameme: '',
        location: '',
        email_address: '',
        phone_number: '',
        physical_address: '',
    })
    const {
        full_name,
        location,
        email_address,
        phone_number,
        physical_address,
    } = profile

    React.useEffect(() => {
        if (data) {
            setProfile({
                full_name: data?.full_name,
                location: data?.location,
                email_address: data?.email_address,
                phone_number: data?.phone_number,
                physical_address: data?.physical_address,
                logo_url: data?.logo_url,
            })
        }
    }, [data])

     const [errorMessage, setErrorMessage] = useState(false)

     const handleChange = (e) => {
         const { name, value } = e.target
         if (
             (name === 'phone_number' &&
                 validator.isMobilePhone(value) === false) ||
             (name === 'phone_number' && value.length !== 11)
         ) {
             setErrorMessage(true)
         } else {
             setErrorMessage(false)
         }
        setProfile({ ...profile, [name]: value })
     }

    const handleSetData = (fileData) => {
        if (fileData) {
            setLogo(fileData)
        }
    }

    const handleSubmit = () => {
        setLoading(true)
        if (logo) {
            uploadLogo(logo, data?.short_name).then(() => {
                setLoading(false)
                onClose()
            })
        } else {
            edit(profile, data?.short_name).then(() => {
                setLoading(false)
                onClose()
            })
        }
    }

    return (
        <div>
            <Modal outerClick open={open}>
                <ModalBody style={{ background: '#fff' }} width="570px">
                    <Box pad="10px 40px 0">
                        <Box margin="10px 0 20px 0">
                            <Text size="18px" block>
                                Editing Profile
                            </Text>
                        </Box>
                        <Grid default="1fr 1fr">
                            <Box pad="20px 0px">
                                <Uploader mini setData={handleSetData} />
                            </Box>
                        </Grid>
                        <Grid default="1fr 1fr">
                            <Box>
                                <Input
                                    value={full_name}
                                    name="full_name"
                                    onChange={handleChange}
                                    label="Name"
                                    spaceBottom
                                    spaceTop
                                    block
                                />
                            </Box>
                            <Box>
                                <Input
                                    value={location}
                                    name="location"
                                    onChange={handleChange}
                                    label="Location"
                                    spaceBottom
                                    spaceTop
                                    block
                                />
                            </Box>
                        </Grid>
                        <Grid default="1fr 1fr">
                            <Box>
                                <Input
                                    value={email_address}
                                    name="email_address"
                                    onChange={handleChange}
                                    label="Email Address"
                                    type="email"
                                    spaceBottom
                                    spaceTop
                                    block
                                />
                            </Box>
                            <Box>
                                <Input
                                    value={phone_number}
                                    name="phone_number"
                                    onChange={handleChange}
                                    label="Phone Number"
                                    spaceBottom
                                    spaceTop
                                    block
                                    type="text"
                                    error={
                                        errorMessage
                                            ? 'please enter a valid phone number'
                                            : false
                                    }
                                />
                            </Box>
                        </Grid>
                        <Grid default="1fr 1fr">
                            <Box>
                                <Input
                                    value={physical_address}
                                    name="physical_address"
                                    onChange={handleChange}
                                    label="Physical Address"
                                    spaceBottom
                                    spaceTop
                                    block
                                    type="text"
                                />
                            </Box>
                        </Grid>
                    </Box>
                    <ModalFooter>
                        <Box pad="20px ">
                            <Grid default="2fr 1fr 1fr">
                                <Box
                                    style={{
                                        marginLeft: '20px',
                                        marginTop: '-10px',
                                    }}
                                ></Box>
                                <Box align="right">
                                    <Button
                                        style={{
                                            border: 'none',
                                            textAling: 'center',
                                            color: '#000',
                                        }}
                                        width={90}
                                        height={30}
                                        onClick={onClose}
                                        pale
                                        pad="0 20px"
                                    >
                                        Cancel
                                    </Button>
                                </Box>
                                <Box align="right">
                                    <Button
                                        width={120}
                                        height={30}
                                        pad="0 15px"
                                        spaceRight
                                        color={Theme.PrimaryBlue}
                                        onClick={handleSubmit}
                                        progress={loading}
                                        disabled={loading}
                                        iconRight={
                                            <i className="flexibull-angle-right" />
                                        }
                                    >
                                        {/* {loading ? 'Updating' : 'Update'} */}
                                        Update
                                    </Button>
                                </Box>
                            </Grid>
                        </Box>
                    </ModalFooter>
                </ModalBody>
            </Modal>
        </div>
    )
}

export default EditProfile

import React from 'react'
import { Text, Box } from 'flexibull2'
const LabelComponent = ({ label }) => {
    return (
        <Box pad="10px">
            <Text style={{ color: 'red' }} size="14px">
                {label}
            </Text>
        </Box>
    )
}

export const InfoComponent = ({ value }) => {
    return (
        <Box pad="10px">
            <Text children={value} />
        </Box>
    )
}
export default LabelComponent

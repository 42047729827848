import React, { useContext, useEffect, useState } from 'react'
import DialogBox from '../../components/dialogbox/DialogBox'
import { LightText } from '../../components/Styles/LightText.style'
import { Grid, Box, Input, Button, Text, Loader } from 'flexibull2'
import CourseContext from '../../../context/Course/courseContext'
import DepartmentContext from '../../../context/Department/departmentContext'
import FacultyContext from '../../../context/Faculty/facultyContext'
import CourseTable from '../CourseTable'
import EditCourse from '../EditCourse'
import CreateCourseModal from '../CreateCourseModal'
import { Theme } from '../../config/theme'
import ExcelUploaderCourse from '../ExcelUploaderCourse'
import { DYNAMIC } from '../../../utils/constants'

export const CourseDetails = ({ orgId }) => {
    const [open, setOpen] = React.useState(false)
    const [openEdit, setEdit] = useState(false)
    const [openDialog, setOpenDialog] = useState(false)
    const [pageNumber, setpageNumber] = useState(1)
    const [openExcel, setOpenExcel] = useState(false)
    const courseContext = useContext(CourseContext)
    const departmentContext = useContext(DepartmentContext)
    const facultyContext = useContext(FacultyContext)

    const pageSizes = [
        { label: '3 Rows', value: 3 },
        { label: '5 Rows', value: 5 },
        { label: '10 Rows', value: 10 },
        { label: '15 Rows', value: 15 },
        { label: '20 Rows', value: 20 },
    ]
    const [pageOptions, setPageOptions] = useState({
        pageSize: 5,
    })
    const { pageSize } = pageOptions

    const {
        courses,
        getCourses,
        createCourses,
        updateCourses,
        deleteCourses,
        loading,
        setCurrent,
        current,
        progressLoading,
        onProgress,
        setLoading,
        unZipCoursesFromExcelToServer,
    } = courseContext
    const { getAllDepartments, departments, all_departments } =
        departmentContext
    const { faculties, getAllFaculties, all_faculties } = facultyContext
    useEffect(() => {
        getCourses(pageSize, 1, orgId)
        getAllDepartments(10000, 1, orgId)
        getAllFaculties(10000, 1, orgId)

        // eslint-disable-next-line
    }, [])
    const onDelete = (id) => {
        setOpenDialog(true)
        setCurrent(id)
    }
    const onEdit = (course) => {
        setEdit(true)
        setCurrent(course)
    }
    const handleOnConfirm = () => {
        deleteCourses(current, orgId)
        setOpenDialog(false)
    }
    return (
        <div>
            <Grid default="1fr 2fr">
                <Box pad="25px 0">
                    <Input
                        name="firstname"
                        type="search"
                        placeholder="search"
                        block
                    />
                </Box>
                <Box pad="20px 0" align="right">
                    <Button
                        width={140}
                        pad="0 1px"
                        onClick={() => setOpen(true)}
                        spaceLeft
                    >
                        Add Course
                    </Button>
                    <Button
                        width={140}
                        pad="0 1px"
                        onClick={() => setOpenExcel(true)}
                        spaceLeft
                        pale
                    >
                        Upload Excel
                    </Button>
                </Box>
            </Grid>
            <Box>
                {loading ? (
                    <Box vAlign height={`calc(100vh - 200px)`}>
                        <Box margin="0 auto" align="center">
                            <Loader color={Theme.PrimaryColor} />
                        </Box>
                    </Box>
                ) : (
                    <CourseTable
                        setOpen={setOpen}
                        courses={courses}
                        onDelete={onDelete}
                        onEdit={onEdit}
                        pageNumber={pageNumber}
                        setpageNumber={setpageNumber}
                        getCourses={getCourses}
                        orgId={orgId}
                        pageSize={pageSize}
                        setPageOptions={setPageOptions}
                        pageOptions={pageOptions}
                        pageSizes={pageSizes}
                    />
                )}
            </Box>
            <DialogBox
                visible={openDialog}
                okType="danger"
                okText="Confirm"
                onConfirm={handleOnConfirm}
                onCancel={() => setOpenDialog(false)}
            >
                {' '}
                <LightText>
                    <Text size="14px">
                        Are you sure you want to delete this course?.
                        <br /> this action cannot be undone.
                    </Text>
                </LightText>{' '}
            </DialogBox>
            <CreateCourseModal
                open={open}
                setOpen={setOpen}
                departments={departments}
                faculties={faculties}
                createCourses={createCourses}
                setLoading={setLoading}
                onProgress={onProgress}
                progressLoading={progressLoading}
                loading={loading}
                all_departments={all_departments}
                all_faculties={all_faculties}
            />
            <EditCourse
                open={openEdit}
                onClose={() => setEdit(false)}
                current={current}
                setEdit={setEdit}
                faculties={faculties}
                departments={departments}
                updateCourses={updateCourses}
                all_departments={all_departments}
                all_faculties={all_faculties}
            />
            <ExcelUploaderCourse
                open={openExcel}
                setOpen={setOpenExcel}
                all_departments={all_departments}
                setLoading={setLoading}
                onProgress={onProgress}
                progressLoading={progressLoading}
                loading={loading}
                unZipCoursesFromExcelToServer={unZipCoursesFromExcelToServer}
                type={DYNAMIC}
                orgId={orgId}
            />
        </div>
    )
}

import React, { useState } from 'react'
import { edit } from './services'
import {
    Modal,
    ModalBody,
    Text,
    Box,
    Input,
    Button,
    ModalFooter,
    Grid,
} from 'flexibull2'
import { Theme } from '../config/theme'
import validator from 'validator'

const EditProfile = ({ open, onClose, data }) => {
    const [profile, setProfile] = React.useState({
        first_name: '',
        last_name: '',
        email: '',
        phone_number: '',
    })
    const { first_name, last_name, email, phone_number } = profile
    const user = JSON.parse(localStorage.getItem('e_trans_user'))

    React.useEffect(() => {
        if (data) {
            setProfile({
                first_name: data?.firstName,
                last_name: data?.lastName,
                email: data?.username,
                phone_number:
                    data?.contacts.find(
                        (contact) => contact?.contactType === 'PHONE_NUMBER'
                    )?.contact || '',
            })
        }
    }, [data])

    const [errorMessage, setErrorMessage] = useState(false)

    const handleChange = (e) => {
        const { name, value } = e.target
        if (
            (name === 'phone_number' &&
                validator.isMobilePhone(value) === false) ||
            (name === 'phone_number' && value.length !== 11)
        ) {
            setErrorMessage(true)
        } else {
            setErrorMessage(false)
        }
        setProfile({ ...profile, [name]: value })
    }

    const handleEdit = (profile) => {
        if (edit(profile)) {
            localStorage.setItem(
                'e_trans_user',
                JSON.stringify({
                    ...user,
                    firstName: first_name,
                    lastName: last_name,
                    phone_number,
                })
            )
            onClose()
            window.location.reload()
        }
    }

    return (
        <div>
            <Modal outerClick open={open}>
                <ModalBody style={{ background: '#fff' }} width="570px">
                    <Box pad="10px 40px 0">
                        <Box margin="10px 0 20px 0">
                            <Text size="18px" block>
                                Editing Profile
                            </Text>
                        </Box>
                        <Grid default="1fr 1fr">
                            <Box>
                                <Input
                                    value={first_name}
                                    name="first_name"
                                    onChange={handleChange}
                                    label="First Name"
                                    spaceBottom
                                    spaceTop
                                    block
                                />
                            </Box>
                            <Box>
                                <Input
                                    value={last_name}
                                    name="last_name"
                                    onChange={handleChange}
                                    label="Last Name"
                                    spaceBottom
                                    spaceTop
                                    block
                                />
                            </Box>
                        </Grid>
                        <Grid default="1fr 1fr">
                            <Box>
                                <Input
                                    value={email}
                                    name="email"
                                    onChange={handleChange}
                                    label="Email Address"
                                    type="email"
                                    spaceBottom
                                    disabled
                                    spaceTop
                                    block
                                />
                            </Box>
                            <Box>
                                <Input
                                    value={phone_number}
                                    name="phone_number"
                                    onChange={handleChange}
                                    label="Phone Number"
                                    spaceBottom
                                    spaceTop
                                    block
                                    type="phone"
                                    error={
                                        errorMessage
                                            ? 'please enter a valid phone number'
                                            : false
                                    }
                                />
                            </Box>
                        </Grid>
                    </Box>
                    <ModalFooter>
                        <Box pad="20px ">
                            <Grid default="2fr 1fr 1fr">
                                <Box
                                    style={{
                                        marginLeft: '20px',
                                        marginTop: '-10px',
                                    }}
                                ></Box>
                                <Box align="right">
                                    <Button
                                        style={{
                                            border: 'none',
                                            textAling: 'center',
                                            color: '#000',
                                        }}
                                        width={90}
                                        height={30}
                                        onClick={onClose}
                                        pale
                                        pad="0 20px"
                                    >
                                        Cancel
                                    </Button>
                                </Box>
                                <Box align="right">
                                    <Button
                                        width={120}
                                        height={30}
                                        pad="0 15px"
                                        spaceRight
                                        color={Theme.PrimaryBlue}
                                        onClick={() => handleEdit(profile)}
                                        //  progress={loading}
                                        //  disabled={loading}
                                        iconRight={
                                            <i className="flexibull-angle-right" />
                                        }
                                    >
                                        {/* {loading ? 'Updating' : 'Update'} */}
                                        Update
                                    </Button>
                                </Box>
                            </Grid>
                        </Box>
                    </ModalFooter>
                </ModalBody>
            </Modal>
        </div>
    )
}

export default EditProfile

import React from 'react'
import { useAtom } from 'jotai'
import axios from 'axios'
import { Switch, Route, Redirect } from 'react-router-dom'
import { Theme } from './pages/config/theme'
import { Layout } from 'flexibull2'
import UnAuthenticated from './utils/UnAuthenticated'
import Authenticated from './utils/Authenticated'
import SelectInstitution from './pages/selectInstitution'
import { useAuth } from './context/auth-context'
import './assets/fonts/fontello/css/saf.css'
import Cookies from 'js-cookie'
import './App.css'

import {isTimeoutAtom} from './common/global.store'
// import IdleDialog from './pages/components/IdleDialog'

function App() {
    const [isTimeout] = useAtom(isTimeoutAtom)
    const { isAuthenticated } = useAuth()
    const ORG = localStorage.getItem('e_trans_org')
    const token = Cookies.get('e_trans_token')
    axios.defaults.headers.common = {
        'X-ORG-ID': ORG,
        Authorization: token ? `bearer ${token}` : '',
    }

    
    return (
        <Layout theme={Theme}>
            <Switch>
                <Route
                    exact
                    path="/select-institution"
                    component={SelectInstitution}
                />
                <Route path="/:org">
                    {isAuthenticated() && isTimeout === false ? (
                        <Authenticated />
                    ) : (
                        <UnAuthenticated replace />
                    )}
                </Route>
                <Redirect to="/select-institution" />
            </Switch>
        </Layout>
    )
}

export default App

import React from 'react'
import {
    Modal,
    ModalBody,
    Text,
    Box,
    Button,
    Grid,
    Table,
    Tag,
} from 'flexibull2'

import Uploader from '../components/Styles/upload'
import { Theme } from '../config/theme'
import styled from 'styled-components'

const ResponsiveModalBody = styled(ModalBody)`
    min-height: 50vh;
    max-height: 100vh;
    overflow: auto;
`

const ExcelUploader = ({
    open,
    setOpen,
    loading,
    current,
    saveFacultyToStore,
    setEdit,
    unZipFacultiesFromExcel,
    type,
    unZipFacultiesFromExcelToServer,
    orgId,
}) => {
    // eslint-disable-next-line
    const [encodedFiles, setEncodedFiles] = React.useState([])
    // eslint-disable-next-line
    const [isExcel, setIsExcel] = React.useState(false)
    // eslint-disable-next-line
    const [items, setItems] = React.useState([])
    const [faculties, setFaculties] = React.useState([])

    const onClose = (e) => {
        setOpen(false)
        setFaculties([])
    }

    const saveToStore = () => {
        switch (type) {
            case 'dynamic':
                unZipFacultiesFromExcelToServer(faculties, orgId)
                break
            case 'local':
                unZipFacultiesFromExcel(faculties)
                break
            default:
                break
        }
        setFaculties([])
        setOpen(false)
    }

    return (
        <div>
            <Modal outerClick open={open}>
                <ResponsiveModalBody
                    style={{ background: '#fff' }}
                    width="570px"
                >
                    <Box pad="20px 40px 0">
                        <Box margin="20px 0 20px 0">
                            <Text size="22px" block>
                                Upload Excel File
                            </Text>
                        </Box>
                        <Grid default="1fr">
                            <Box margin="10px 0 70px 0">
                                {faculties.length ? (
                                    <Box>
                                        <Table style={{ paddingTop: '10px' }}>
                                            <table>
                                                <thead>
                                                    <tr>
                                                        <th>Faculty Title</th>
                                                        <th>Short Code</th>
                                                        <th>Status</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {faculties?.map(
                                                        (faculty) => (
                                                            <tr
                                                                key={
                                                                    faculty?.name
                                                                }
                                                            >
                                                                <td>
                                                                    {
                                                                        faculty?.name
                                                                    }
                                                                </td>
                                                                <td>
                                                                    {
                                                                        faculty?.short_code
                                                                    }
                                                                </td>
                                                                {faculty?.name &&
                                                                faculty?.short_code ? (
                                                                    <Tag
                                                                        font="10px"
                                                                        fontColor="#FFF"
                                                                        solid
                                                                        style={{
                                                                            width: '90px',
                                                                            marginTop:
                                                                                '20px',
                                                                        }}
                                                                        height={
                                                                            22
                                                                        }
                                                                        color={
                                                                            Theme.PrimaryGreen
                                                                        }
                                                                    >
                                                                        success
                                                                    </Tag>
                                                                ) : (
                                                                    <Tag
                                                                        font="10px"
                                                                        fontColor="#FFF"
                                                                        solid
                                                                        style={{
                                                                            width: '90px',
                                                                            marginTop:
                                                                                '10px',
                                                                        }}
                                                                        height={
                                                                            22
                                                                        }
                                                                        color={
                                                                            Theme.PrimaryRed
                                                                        }
                                                                    >
                                                                        faliled
                                                                    </Tag>
                                                                )}
                                                            </tr>
                                                        )
                                                    )}
                                                </tbody>
                                            </table>
                                        </Table>
                                    </Box>
                                ) : (
                                    <Uploader
                                        type="file"
                                        setEncodedFiles={setEncodedFiles}
                                        isExcel={isExcel}
                                        setItems={setItems}
                                        setFaculties={setFaculties}
                                    />
                                )}
                            </Box>
                        </Grid>
                    </Box>
                    <Box pad="0px 30px 40px 0">
                        <Grid default="2fr 1fr 1fr">
                            <Box
                                style={{
                                    marginLeft: '20px',
                                    marginTop: '-10px',
                                }}
                            ></Box>
                            <Box align="right">
                                <Button
                                    style={{
                                        border: 'none',
                                        textAling: 'center',
                                        color: '#000',
                                    }}
                                    width={90}
                                    height={30}
                                    onClick={onClose}
                                    pale
                                    pad="0 20px"
                                >
                                    Cancel
                                </Button>
                            </Box>
                            <Box align="right">
                                <Button
                                    width={120}
                                    height={30}
                                    pad="0 15px"
                                    spaceRight
                                    onClick={saveToStore}
                                    progress={loading}
                                    disabled={loading}
                                    iconRight={
                                        <i className="flexibull-angle-right" />
                                    }
                                >
                                    {loading ? 'Updating' : 'Upload'}
                                </Button>
                            </Box>
                        </Grid>
                    </Box>
                </ResponsiveModalBody>
            </Modal>
        </div>
    )
}

export default ExcelUploader

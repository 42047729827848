import * as React from 'react'
import { AuthProvider } from './auth-context.js'
import { MainProvider } from './main-context'
function AppProviders({ children }) {
    return (
        <AuthProvider>
            <MainProvider>{children}</MainProvider>
        </AuthProvider>
    )
}
export default AppProviders

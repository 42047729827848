import { Notify } from 'flexibull2/build/notify'
import { ERROR_ALERT, SUCCESS, TOP_RIGHT } from '../../../utils/constants'
import axios, { UMS_ENDPOINT } from '../../../utils/httpClient'

export const ORG = localStorage.getItem('e_trans_org')

export const confirm = (data) => {
    const { org } = data
    return axios.post(`${UMS_ENDPOINT}/${org}/confirm`, data)
}

export const reset = async (data, setLoading, history, org) => {
    try {
        setLoading(true);
        await axios.post(`${UMS_ENDPOINT}/${ORG}/password/reset`, data)
        setLoading(false);
        Notify(`Password set successfully`, {
            position: TOP_RIGHT,
            status: SUCCESS,
        })
        history.push(`${org}/`)
    } catch (error) {
        setLoading(false);
        Notify(`${error.response.data}`, {
            position: TOP_RIGHT,
            status: ERROR_ALERT,
        })
    }
}

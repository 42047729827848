import React from 'react'
import { Tag } from 'flexibull2'
import { Theme } from '../config/theme'

const TagComp = ({ status }) => {
    let color
    switch (status) {
        case 'Payment Pending':
            color = Theme.PrimaryOrange
            break
        case 'Payment Successful':
            color = Theme.PrimaryFontColor
            break
        case 'Received by Institution':
            color = Theme.PrimaryBlue
            break
        case 'processing':
            color = Theme.PrimaryYellow
            break
        case 'Transcript Ready':
            color = Theme.PrimaryGreen
            break
        default:
            color = Theme.PrimaryColor
    }
    return (
        <Tag
            color={color}
            spaceRight
            fontColor="#fff"
            font="10px"
            solid
            style={{
                margin: '2px',
                width: '120px',
            }}
        >
            {status}
        </Tag>
    )
}

export default TagComp

import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import { Box, Grid, Text, Button } from 'flexibull2'
import { getRequestDetails, getTranscriptUrl } from '../RequestDetails/services'
import styled from 'styled-components'
import { Theme } from '../config/theme'
import {lighten} from 'polished'


const ResponsiveBox = styled(Box)`
    @media (max-width: 560px) {
        /* display: none; */
        padding: 0;
    }
`

const List = styled.ul`
    line-height: 1.4em;
    list-style: none;
    margin: 30px 0 0;
    padding: 0;
    width: 100%;
    display: flex;

    & li {
        width: 33%;
        position: relative;
        
        &::before {
            background: ${Theme.PrimaryBorderColor};
            border: 3px solid transparent;
            border-radius: 100%;
            content: "";
            display: block;
            height: 10px;
            position: absolute;
            top: -40px; left: 0;
            width: 10px;
            transition: background 0.3s ease-in-out,
                    border 0.3s ease-in-out;
        }

        &::after {
            height: 3px;
            width: 92%;
            content: "";
            border-top: 1px dashed #d1d1d1;
            display: block;
            position: absolute;
            top: -33px; bottom: 0; left: 25px;
        }

        &:last-child {
            &::before {
                background: ${Theme.PrimaryGreen}; 
            }
            &::after {
                border: none;
            }
        }
    }
` 

const Track = () => {
    const { id } = useParams()
    const [transcriptInfo, setTranscriptInfo] = useState({})
    const [transcriptUrl, setTranscriptUrl] = useState('')
    useEffect(() => {
        getTranscriptUrl(id)
            .then(({ data }) => {
                setTranscriptUrl(data.file_url)
            })
            .catch((e) => {
                setTranscriptUrl('')
            })
    }, [id])
    useEffect(() => {
        getRequestDetails(id).then(({ data }) => {
            setTranscriptInfo(data)
        })
    }, [id])
    const { status } = transcriptInfo
    return (
        <Box margin="-20px 0 0 0">
            <Box>
                <Grid default="45% 55%">
                    <ResponsiveBox pad="50px">
                        <Box>
                            <Text size="12px">My Transcript Status</Text>
                            <Text size="20px" bold block style={{ marginTop: "10px" }}>
                                Track Transcript Status
                            </Text>
                        </Box>
                    </ResponsiveBox>
                </Grid>

                <ResponsiveBox pad="50px">
                    <List>
                        <li>
                            <Box style={{ borderRadius: "10px" }} width="80%" background={Theme.PrimaryBackground}>
                                <Box pad="16px 20px" background={Theme.PrimaryGrey} style={{ borderRadius: "10px 10px 0 0" }}>
                                    <Text size="14px" color={lighten(.5, Theme.PrimaryGrey)}>Request Successful</Text>
                                </Box>
                                <Box pad="33px 20px" style={{ borderRadius: "0 0 10px 10px", border: `1px dashed ${lighten(.2, Theme.PrimaryGrey)}` }}>
                                    <Text>
                                        Request made successfully, pending approval from your institution to begin/start the transcript generation process
                                    </Text>
                                </Box>
                            </Box>
                        </li>
                        {(status === 'processing' ||
                                status === 'Transcript Ready') && (
                        <li>
                            <Box style={{ borderRadius: "10px" }} width="80%" background={Theme.PrimaryBackground}>
                                <Box pad="16px 20px" background={Theme.PrimaryGrey} style={{ borderRadius: "10px 10px 0 0" }}>
                                    <Text size="14px" color={lighten(.5, Theme.PrimaryGrey)}>Approved & Processing</Text>
                                </Box>
                                <Box pad="33px 20px" style={{ borderRadius: "0 0 10px 10px", border: `1px dashed ${lighten(.2, Theme.PrimaryGrey)}` }}>
                                    <Text>
                                        Your request has been approved and it is being processed at the moment
                                    </Text>
                                </Box>
                            </Box>
                        </li>
                        )}
                        {status === 'Transcript Ready' && (
                        <li>
                            <Box style={{ borderRadius: "10px" }} width="80%" background={Theme.PrimaryGreen}>
                                <Box pad="16px 20px" background={lighten(.1, Theme.PrimaryGreen)} style={{ borderRadius: "10px 10px 0 0" }}>
                                    <Text size="14px" color={lighten(.5, Theme.PrimaryGrey)}>Your transcript is ready</Text>
                                </Box>
                                <Box pad="33px 20px" style={{ borderRadius: "0 0 10px 10px" }}>
                                    <Text color="#fff" block style={{ marginBottom: '20px' }}>
                                    Congratulations your transcript is ready and you can download it by clicking on the button below
                                    </Text>
                                    <a
                                        href={transcriptUrl}
                                        target="_blank"
                                        rel="noreferrer"
                                    >
                                        <Button elevate color="#FFF" fontColor={Theme.PrimaryFontColor}>
                                        Download Now
                                        </Button>
                                    </a>
                                    
                                </Box>
                            </Box>
                        </li>
                        )}
                    </List>
                </ResponsiveBox>
            </Box>
        </Box>
    )
}

export default Track

import React, { useState } from 'react'
import {
    Box,
    Grid,
    Text,
    Input,
    Button,
    Wizard,
    ModalBody,
    Modal,
    TextArea,
    Spacer,
    Notify,
} from 'flexibull2'
import Uploader from '../components/Styles/upload'

import FacultyStoreUi from './FacultyStoreUi'
import DepartmentStoreUI from './DepartmentStoreUI'
import CourseStoreUI from './CourseStoreUI'
import Preview from './Preview'
import CreateFacultOnwizard from './CreateFacultyOnwizard'

import Payment from './Payment'
import Admin from './Admin'

import { Theme } from '../config/theme'
import { FlexiColor } from '../components/colorpicker'

import { TOP_RIGHT, ERROR_ALERT } from '../../utils/constants'
import styled from 'styled-components'
import validator from 'validator'

const ResponsiveWizard = styled(Wizard)`
    & .flexi-wizard-list {
        pointer-events: none;
    }
    .required {
        color: red;
    }
`

const colors = [
    '#D9E3F0',
    '#F47373',
    '#697689',
    '#37D67A',
    '#2CCCE4',
    '#555555',
    '#dce775',
    '#ff8a65',
    '#ba68c8',
    '#6b48ff',
    '#FA0026',
    '#42361E',
    '#2b9464',
    '#33363b',
    '#0072BB',
    '#ef5285',
    '#003a44',
    '#06565b',
    '#86269b',
    '#cc0063',
    '#4C6085',
    '#f9a11b',
    '#fdc23e',
]

function CreateCustomer({ onClose, open }) {
    const [currentStep, setCurrentStep] = useState(0)
    const [base64Str, setBase64Str] = useState([])
    const [account, setAccount] = useState([])
    // eslint-disable-next-line
    const [isExcel, setIsExcel] = React.useState(true)
    /* 
    "institution": {
    "shortName": "string",
    "fullName": "string",
    "location": "string",
    "emailAddress": "string",
    "physicalAddress": "string",
    "phoneNumber": "string",
    "themeColor": "string",
    "studentCopy": true,
    "non_refundable": true
  },
    
    */

    const [institution, setInstitution] = useState({
        fullName: '',
        shortName: '',
        emailAddress: '',
        phoneNumber: '',
        location: '',
    })
    const [themeColor, setThemeColor] = useState(Theme.PrimaryColor)

    const [physicalAddress, setPhysicalAddress] = useState('')
    const [schoolAdmin, setSchoolAdmin] = useState(null)
    const { fullName, shortName, emailAddress, phoneNumber, location } =
        institution

    const basic_details = {
        themeColor,
        physicalAddress,
        fullName,
        shortName: shortName.toLowerCase(),
        emailAddress,
        phoneNumber,
        location,
    }

    const [errorMessage, setErrorMessage] = useState(false)
    
    const handleNext = () => {
        if (
            fullName === '' ||
            shortName === '' ||
            emailAddress === '' ||
            phoneNumber === '' ||
            physicalAddress === ''
        ) {
            Notify(`Please fill in all the fields and try again`, {
                position: TOP_RIGHT,
                status: ERROR_ALERT,
            })
            return
        } else {
            setCurrentStep((prevStep) => prevStep + 1)
        }

        if (currentStep === 0) {
            setCurrentStep(1)
        } else {
            setCurrentStep(0)
        }
    }
    const handlePrevious = () => {
        if (currentStep > 0) {
            setCurrentStep((prevStep) => prevStep - 1)
        }
    }
   

    const handleChange = (e) => {
        const { name, value } = e.target
        if (
            (name === 'phone_number' &&
                validator.isMobilePhone(value) === false) ||
            (name === 'phone_number' && value.length !== 11)
        ) {
            setErrorMessage(true)
        } else {
            setErrorMessage(false)
        }
        setInstitution({ ...institution, [name]: value })
    }

    const handleAdress = (e) => {
        setPhysicalAddress(e.target.value)
    }
    const handleSetData = (fileData) => {
        setBase64Str(fileData)
    }

    return (
        <Box>
            <Modal open={open} center={false}>
                <ModalBody width="95vw">
                    <ResponsiveWizard
                        onClose={onClose}
                        title="Creating a new Customer"
                        autoProceed={false}
                        view={currentStep}
                        style={{
                            fontSize: '14px',
                            background: '#fff',
                            height: '100%',
                        }}
                    >
                        <div title="Basic Details">
                            <Grid default="1fr">
                                {' '}
                                <Box pad="20px 0">
                                    <Text size="20px" block bold>
                                        Basic Details
                                    </Text>
                                    <Box margin="10px 0">
                                        <Text size="12px" block>
                                            This section is to capture the main
                                            information about the institution
                                        </Text>
                                    </Box>
                                </Box>
                            </Grid>
                            <Box style={{ marginRight: '70px' }}>
                                <Grid default="1fr 2fr">
                                    <Box pad="10px 0" margin="40px 10px">
                                        <Box
                                            style={{
                                                marginBottom: '15px',
                                                fontSize: '12px',
                                                fontWeight: '300',
                                                fontStyle: 'normal',
                                                wordSpacing: 'normal',
                                                color: '#373737',
                                            }}
                                        >
                                            <Text>Institution Logo</Text>
                                        </Box>
                                        <Uploader
                                            mini
                                            label="Institution Logo"
                                            setData={handleSetData}
                                        />
                                    </Box>
                                    <Box>
                                        <Box
                                            pad="10px 0"
                                            align="center"
                                            maxWidth="600px"
                                            margin="0 10px"
                                        ></Box>
                                        <Box
                                            pad="30px 0 0"
                                            maxWidth="600px"
                                            center
                                        >
                                            <Grid
                                                default="1fr 1fr"
                                                lr="1fr 1fr"
                                                columnGap="10px"
                                            >
                                                <Input
                                                    height="39px"
                                                    style={{ fontSize: '14px' }}
                                                    type="text"
                                                    name="fullName"
                                                    value={fullName}
                                                    spaceTop
                                                    onChange={handleChange}
                                                    label={
                                                        <Text size="10px">
                                                            Institution Name{' '}
                                                            <span className="required">
                                                                *
                                                            </span>
                                                        </Text>
                                                    }
                                                />
                                                <Input
                                                    autocapitalize="none"
                                                    height="39px"
                                                    type="text"
                                                    name="shortName"
                                                    value={shortName.toLowerCase()}
                                                    spaceTop
                                                    onChange={handleChange}
                                                    label={
                                                        <Text size="10px">
                                                            Institution Short
                                                            Name{' '}
                                                            <span className="required">
                                                                *
                                                            </span>
                                                        </Text>
                                                    }
                                                />
                                            </Grid>
                                            <Grid
                                                default="1fr 1fr"
                                                lr="1fr 1fr"
                                                columnGap="10px"
                                                margin="20px 0"
                                            >
                                                <Input
                                                    height="39px"
                                                    type="email"
                                                    name="emailAddress"
                                                    value={emailAddress}
                                                    spaceTop
                                                    onChange={handleChange}
                                                    label={
                                                        <Text size="10px">
                                                            Institution Email{' '}
                                                            <span className="required">
                                                                *
                                                            </span>
                                                        </Text>
                                                    }
                                                />
                                                <Input
                                                    height="39px"
                                                    type="phone"
                                                    name="phoneNumber"
                                                    value={phoneNumber}
                                                    spaceTop
                                                    onChange={handleChange}
                                                    label={
                                                        <Text size="10px">
                                                            Institution Phone
                                                            Number{' '}
                                                            <span className="required">
                                                                *
                                                            </span>
                                                        </Text>
                                                    }
                                                    error={
                                                        errorMessage
                                                            ? 'please enter a valid phone number'
                                                            : false
                                                    }
                                                />
                                            </Grid>
                                            <Grid
                                                default="1fr 1fr"
                                                lr="1fr"
                                                columnGap="10px"
                                            >
                                                <Box margin="10px 0">
                                                    <Input
                                                        height="39px"
                                                        type="text"
                                                        name="location"
                                                        value={location}
                                                        spaceTop
                                                        onChange={handleChange}
                                                        label={
                                                            <Text size="10px">
                                                                Location{' '}
                                                                <span className="required">
                                                                    *
                                                                </span>
                                                            </Text>
                                                        }
                                                    />
                                                </Box>
                                            </Grid>

                                            <Grid
                                                default="1fr"
                                                lr="1fr"
                                                columnGap="10px"
                                                margin="20px 0"
                                            >
                                                <TextArea
                                                    margin="20px 0"
                                                    pad="20px 0"
                                                    height="150px"
                                                    label={
                                                        <Text size="10px">
                                                            Institution Address{' '}
                                                            <span className="required">
                                                                *
                                                            </span>
                                                        </Text>
                                                    }
                                                    value={physicalAddress}
                                                    name="address"
                                                    onChange={handleAdress}
                                                    placeholder=""
                                                    block
                                                    spaceTop
                                                />
                                            </Grid>
                                            <Grid default="auto max-content max-content">
                                                <Box
                                                    margin="20px 0"
                                                    maxWidth="700px"
                                                >
                                                    <Text bold block uppercase>
                                                        Theme Color
                                                    </Text>
                                                    <small>
                                                        The color selected will
                                                        be the color theme that
                                                        represents the
                                                        institution
                                                    </small>
                                                    <Spacer />
                                                    <FlexiColor
                                                        colors={colors}
                                                        color={themeColor}
                                                        handleChange={(color) =>
                                                            setThemeColor(color)
                                                        }
                                                    />
                                                </Box>
                                            </Grid>
                                        </Box>
                                        <Box align="right" margin="50px 0">
                                            <Button
                                                iconRight={
                                                    <i className="flexibull-right" />
                                                }
                                                width={120}
                                                height={30}
                                                onClick={handleNext}
                                                pad="0 15px"
                                            >
                                                Next
                                            </Button>
                                        </Box>
                                    </Box>
                                </Grid>
                            </Box>
                        </div>
                        <div title="Faculty Settings">
                            <FacultyStoreUi
                                currentStep={currentStep}
                                setCurrentStep={setCurrentStep}
                                shortName={shortName}
                            />
                        </div>
                        <div title="Department Settings">
                            <DepartmentStoreUI
                                currentStep={currentStep}
                                setCurrentStep={setCurrentStep}
                            />
                        </div>
                        <div title="Course Settings">
                            <CourseStoreUI
                                currentStep={currentStep}
                                setCurrentStep={setCurrentStep}
                            />
                        </div>
                        <div title="Configure Payments">
                            <Payment
                                currentStep={currentStep}
                                setCurrentStep={setCurrentStep}
                                account={account}
                                setAccount={setAccount}
                                institution={institution}
                                setInstitution={setInstitution}
                            />
                        </div>
                        <div title="Add School Admin">
                            <Grid default="1fr">
                                {' '}
                                <Box pad="20px 0">
                                    <Text size="20px" block bold>
                                        Add school admin
                                    </Text>
                                    <Box margin="10px 0">
                                        <Text size="12px" block>
                                            This section is to an institution
                                            administrator
                                        </Text>
                                    </Box>
                                    <Admin
                                        schoolAdmin={schoolAdmin}
                                        setSchoolAdmin={setSchoolAdmin}
                                        currentStep={currentStep}
                                        setCurrentStep={setCurrentStep}
                                    />
                                </Box>
                            </Grid>
                        </div>
                        <div title="Preview & Submit">
                            <Preview
                                institution={basic_details}
                                base64Str={base64Str}
                                onClose={() => onClose()}
                                schoolAdmin={schoolAdmin}
                                account={account}
                                handlePrevious={handlePrevious}
                                setCurrentStep={setCurrentStep}
                            />
                        </div>
                    </ResponsiveWizard>
                </ModalBody>
                <CreateFacultOnwizard />
            </Modal>
        </Box>
    )
}

export default CreateCustomer

import React from 'react'
import { useCountdown } from './useCountdown.hooks.js'
import {
    Grid,
    Box,
    Text,
} from 'flexibull2'
import Sent from '../../assets/mail-sent.svg'

const DateTimeDisplay = ({ value }) => <Text bold>{value} seconds</Text>

const ExpiredNotice = () => {
    return (
        <div className="expired-notice">
            <span>Thanks for your patience!</span>
            <p>
                Please click on the button below to resend link if you have not
                received one.
            </p>
        </div>
    )
}
const ShowCounter = ({ seconds }) => (
    <DateTimeDisplay value={seconds} type={'Seconds'} isDanger={false} />
)

const CountdownTimer = ({ targetDate, login, resendEmail }) => {
    const [seconds] = useCountdown(targetDate)

    if (seconds <= 0) {
        return (
            <>
                <ExpiredNotice />
                <Grid default="1fr 1fr">
                    <Box margin="30px 0">{login}</Box>
                    <Box margin="30px 0">{resendEmail}</Box>
                </Grid>
            </>
        )
    } else {
        return (
            <>
                <Box align="center" margin="0 0 20px 0">
                    <img src={Sent} alt="sent icon" height="130" />
                </Box>
                <div style={{ textAlign: 'center' }}>
                    <Text
                        bold
                        block
                        size="24px"
                        style={{ marginBottom: '30px' }}
                    >
                        Activation link sent
                    </Text>
                </div>
                <Text size="14px" style={{ color: 'gray' }}>
                    Don't receive a verification link in your email? Resend link
                    in <br />
                </Text>
                <Text size="16px">
                    <ShowCounter seconds={seconds} />
                </Text>
                <Grid default="1fr 1fr">
                    <Box margin="30px 0">{login}</Box>
                </Grid>
            </>
        )
    }
}

export default CountdownTimer

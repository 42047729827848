import React, { useState } from 'react'
import { useHistory } from 'react-router-dom'
import { useAuth } from '../../context/auth-context'
import { Box, Text, Button } from 'flexibull2'
import Footer from '../components/footer'
import AuthLayout from '../components/authLayout'
import Sent from '../../assets/mail-sent.svg'
import { Grid } from 'flexibull2/build/layout'
import { resend } from './services'

const Reset = ({ location, match }) => {
    const { params } = match
    const history = useHistory()
    const { org } = useAuth()
    const tempUser = JSON.parse(localStorage.getItem('tempUser'))
    const [loading, setLoading] = useState(false)

    return (
        <>
            <AuthLayout>
                <Box
                    display="flex"
                    style={{
                        alignItems: 'center',
                        justifyContent: 'center',
                        zIndex: '20',
                    }}
                >
                    <Box
                        display="flex"
                        width="40%"
                        margin="10% auto"
                        style={{
                            alignItems: 'center',
                            justifyContent: 'center',
                            flexDirection: 'column',
                        }}
                    >
                        <Box>
                            <Box align="center" margin="0 0 20px 0">
                                <img src={Sent} alt="sent icon" height="130" />
                            </Box>
                            <div style={{ textAlign: 'center' }}>
                                <Text
                                    bold
                                    block
                                    size="24px"
                                    style={{ marginBottom: '30px' }}
                                >
                                    Activation link sent to
                                </Text>
                             
                                <Text
                                    size="18px"
                                    bold
                                    block
                                    style={{ margin: '20px 0px' }}
                                >
                                    {location.state?.email || ''}
                                </Text>
                                <Text size="14px">
                                    Do check your{' '}
                                    <span style={{ fontWeight: 'bold' }}>
                                        spam
                                    </span>{' '}
                                    folder if you do not see the mail in your{' '}
                                    <span style={{ fontWeight: 'bold' }}>
                                        inbox
                                    </span>
                                </Text>

                            </div>
                        </Box>
                        <Grid default="1fr 1fr">
                            <Box margin="30px 0">
                                <Button
                                    height={50}
                                    width={150}
                                    progress={loading}
                                    spaceRight
                                    spaceLeft
                                    iconRight={
                                        <i className="flexibull-angle-right" />
                                    }
                                    onClick={() => resend(params, tempUser, setLoading)}
                                    pale
                                >
                                    Resend Link
                                </Button>
                            </Box>
                            <Box margin="30px 0">
                                <Button
                                    height={50}
                                    width={120}
                                    onClick={() => history.push(`${org}/`)}
                                >
                                    Back to login
                                </Button>
                                {/* <Text size="16px" block style={{ margin: '10px 0px' }}>
                            go back to{' '}
                            <Link onClick={() => history.push(`${org}/`)}>
                                login
                            </Link>
                        </Text> */}
                            </Box>
                        </Grid>
                    </Box>
                </Box>
            </AuthLayout>
            <Footer />
        </>
    )
}

export default Reset

import { useEffect, useState } from 'react'
import { isTimeoutAtom, showModalAtom } from '../../../common/global.store'
import { useAuth } from '../../../context/auth-context'
import { useAtom } from 'jotai'

const useCountdown = (targetDate) => {
    const countDownDate = new Date(targetDate).getTime()
    const { logout } = useAuth()
    const [ setIsTimeout] = useAtom(isTimeoutAtom)
    const [showModal, setShowModal] = useAtom(showModalAtom)

    const [countDown, setCountDown] = useState(
        countDownDate - new Date().getTime()
    )
    const [startCountDown, setStartCountDown] = useState(false)

    // calculate time left
    const seconds = Math.floor((countDown % (1000 * 60)) / 1000)
    const minutes = Math.floor((countDown % (1000 * 60 * 60)) / (1000 * 60))

    useEffect(() => {
        if (showModal === true) setStartCountDown(true)
    }, [showModal])

    useEffect(() => {
        if (startCountDown === true) {
            const interval = setInterval(() => {
                setCountDown(countDownDate - new Date().getTime())
            }, 1000)
            if (seconds <= 0 && minutes <= 0) {
                logout()
                setIsTimeout(false)
                setShowModal(false)
                clearInterval(interval)
            }
            return () => clearInterval(interval)
        } else {
            setStartCountDown(false)
        }
    }, [countDownDate, logout, minutes, seconds, setIsTimeout, setShowModal, startCountDown])

    

    return getReturnValues(seconds, minutes)
}

const getReturnValues = (seconds, minutes) => [seconds, minutes]
export { useCountdown }

import React, { useEffect, useContext, useState } from 'react'
import styled from 'styled-components'
import { Box, Text, Button, Spacer, Select } from 'flexibull2'
import { useHistory } from 'react-router-dom'
import AuthLayout from '../components/authLayout'
import Footer from '../components/footer'
import InstitutionContext from '../../context/institution/institutionContext'
import { Theme } from '../config/theme'

const ResponsiveBox = styled(Box)`
    width: 400px;
    z-index: 3;

    @media (max-width: 1024px) {
        width: 40%;
    }
    @media (max-width: 769px) {
        width: 50%;
    }
    @media screen and (max-width: 640px) {
        width: 80%;
        margin-top: 25%;
    }
`

const ZAuthLayout = styled(AuthLayout)`
    // z-index: 999;
`

const SelectInstitution = () => {
    const history = useHistory()
    const [org, setOrg] = useState('')
    const institutionContext = useContext(InstitutionContext)

    const { getInstitutions, institutions } = institutionContext
    useEffect(() => {
        getInstitutions(1, 100)
        // eslint-disable-next-line
    }, [])

    const institutionsData = institutions?.data.map((institute) => {
        return {
            label: institute.full_name,
            value: institute.short_name,
        }
    })
    return (
        <>
            <ZAuthLayout>
                <Box
                    display="flex"
                    style={{ alignItems: 'center', justifyContent: 'center' }}
                >
                    <ResponsiveBox margin="10% auto">
                        <Box>
                            <div>
                                <Text size="28px" bold block>
                                    Select Institution
                                </Text>
                                <Spacer />
                                <Text size="12px">
                                    Search and select your institution using the
                                    dropdown menu below then use the next button
                                    to go to the login page
                                </Text>
                            </div>
                        </Box>
                        <Box pad="30px 0">
                            <Box pad="30px 0 22px">
                                <Select
                                    label={
                                        <Text
                                            capitalize
                                            size="12px"
                                            color={Theme.PrimaryGrey}
                                        >
                                            Search for Organizations/Institution
                                        </Text>
                                    }
                                    placeholder="Select Institution"
                                    height={50}
                                    spaceBottom
                                    spaceTop
                                    block
                                    options={institutionsData}
                                    onChange={(shortName) =>
                                        setOrg(shortName.value)
                                    }
                                />
                            </Box>
                            <Button
                                spaceRight
                                iconRight={
                                    <i className="flexibull-angle-right" />
                                }
                                onClick={() => history.push(`/${org}`)}
                                disabled = {org === ''}
                            >
                                Next
                            </Button>
                        </Box>
                    </ResponsiveBox>
                </Box>
            </ZAuthLayout>
            <Footer />
        </>
    )
}

export default SelectInstitution

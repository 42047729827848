import React from 'react'
import { Link } from 'react-router-dom'
import styled from 'styled-components'
const BreadCrumbWrapper = styled.div`
    margin-bottom: 20px;
    color: red;
`
const BreadCrumbs = ({ links, last }) => (
    <BreadCrumbWrapper>
        {links.map((link) => {
            return (
                <>
                    <i className="flexibull-angle-left" />
                    <Link to={link.path}>{link.text}</Link>
                </>
            )
        })}
        <span style={{ color: 'gray' }}>{` / ${last}`}</span>
    </BreadCrumbWrapper>
)

export default BreadCrumbs

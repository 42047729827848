import React, { useState } from 'react'
import { useAuth } from '../../../context/auth-context'
import {
    Modal,
    ModalBody,
    Box,
    Input,
    Text,
    Button,
    Grid,
    //     Notify,
} from 'flexibull2'
import { createUser } from './services'
// import { TOP_RIGHT, ERROR_ALERT } from '../../../utils/constants'

const AddAdmin = ({ open, setOpen, roleId, school }) => {
    const { org } = useAuth()
    const [userData, setUserData] = useState({
        first_name: '',
        last_name: '',
        email: '',
        phone: '',
        contacts: [],
    })
    const [loading, setLoading] = useState(false)
    const handleChange = (e) => {
        const { name, value } = e.target
        setUserData({
            ...userData,
            [name]: value,
        })
    }
    const submit = () => {
        const data = {
            ...userData,
            org: school ? school : org,
            contacts: [
                { contact: userData.email, contact_type: 'EMAIL' },
                // { contact: userData.phone, contact_type: 'PHONE' },
            ],
            product_id: '36163e9e-1d43-4bf6-b0d3-83bfa7da097c',
            request_password_change: true,
            username: userData.email,
            roles: [
                {
                    admin_level: 'Institution Admin',
                    id: roleId,
                },
            ],
        }
        createUser(data, setLoading, setOpen, setUserData)
        setUserData({
            first_name: '',
            last_name: '',
            email: '',
            phone: '',
            contacts: [],
        })
    }
    return (
        <div>
            <Modal outerClick open={open}>
                <ModalBody style={{ background: '#fff' }} width="500px">
                    <Box pad="10px 40px 0">
                        <Box margin="20px 0 20px 0">
                            <Text size="22px" block>
                                Add Admin
                            </Text>
                        </Box>
                        <Grid default="1fr 1fr">
                            <Box margin="0px 0px 25px 0px">
                                <Input
                                    label="Admin's First Name"
                                    name="first_name"
                                    value={userData.first_name}
                                    placeholder="Enter First Name"
                                    spaceBottom="15px"
                                    width="280px"
                                    onChange={handleChange}
                                    block
                                />
                            </Box>
                            <Box pad=" 0 0 0">
                                <Input
                                    label="Admin's Last Name"
                                    name="last_name"
                                    value={userData.last_name}
                                    placeholder="Enter Last Name"
                                    //  width="200px"
                                    spaceBottom="15px"
                                    onChange={handleChange}
                                    block
                                />
                            </Box>
                        </Grid>
                        <Grid default="1fr 1fr">
                            <Box margin="0px 0px 25px 0px">
                                <Input
                                    label="Admin's Email Address"
                                    name="email"
                                    value={userData.email}
                                    placeholder="Enter Email Address"
                                    spaceBottom="15px"
                                    width="280px"
                                    onChange={handleChange}
                                    block
                                    type="email"
                                />
                            </Box>
                            <Box pad=" 0 0 0">
                                <Input
                                    label="Admin's Phone Number"
                                    name="phone"
                                    value={userData.phone}
                                    placeholder="Enter Phone Number"
                                    //  width="200px"
                                    spaceBottom="15px"
                                    onChange={handleChange}
                                    block
                                    type="phone"
                                />
                            </Box>
                        </Grid>
                    </Box>
                    <Box pad="0px 30px 40px 0">
                        <Grid default="1fr 1fr">
                            <Box align="right">
                                <Button
                                    style={{
                                        border: 'none',
                                        textAling: 'center',
                                        color: '#000',
                                    }}
                                    width={90}
                                    onClick={() => setOpen(false)}
                                    pale
                                    pad="0 20px"
                                >
                                    Cancel
                                </Button>
                            </Box>
                            <Box align="right">
                                <Button
                                    width={120}
                                    pad="0 15px"
                                    spaceRight
                                    onClick={submit}
                                    progress={loading}
                                    //  disabled={onProgress}
                                    iconRight={
                                        <i className="flexibull-angle-right" />
                                    }
                                >
                                    Submit
                                </Button>
                            </Box>
                        </Grid>
                    </Box>
                </ModalBody>
            </Modal>
        </div>
    )
}

export default AddAdmin

import React from 'react'
import { useCountdown } from './useCountdown.hooks.js'
import {
    Text,
} from 'flexibull2'


const ShowCounter = ({ targetDate }) => {
    const [seconds, minutes] = useCountdown(targetDate)
    const handlePlurals = (value, time) => value <= 1 ? time : `${time}s`
     return (
         <Text bold color={minutes <= 0 ? '#FF4C4B' : '#43855B'}> 
             {`${minutes}${handlePlurals(
                 minutes,
                 'min'
             )} : ${seconds}${handlePlurals(seconds, 'sec')}`}
         </Text>
     )
}


export default ShowCounter

import React, { useState, useEffect } from 'react'
import {
    Box,
    Grid,
    Table,
    Loader,
    FlexiPagination,
    Select,
    FlexiBar,
    Text,
    Button,
    DashCard,
    CardSection,
    PageTitle,
} from 'flexibull2'
import { ENDPOINT } from '../../utils/httpClient'
import { getFinancials } from './services'
import { Theme } from '../config/theme'
import usePagination from '../../utils/usePagination'
import Cert from '../../assets/cert.svg'
import Transcript from '../../assets/transcript.svg'
import Statement from '../../assets/statement.svg'
import { amountValidator } from '../../utils/amountValidator'
import { FilterBox } from '../instituteDashboard'
import { Datepicker } from 'flexibull2/build/datepicker'
import axios from 'axios'
const xAxis = ['Transcript', 'Statement', 'Certificate']

const Transactions = () => {
    // eslint-disable-next-line
    const [financialObjects, setFinancialObjects] = useState({
        duration: 'YEARLY',
        durationValue: new Date().getFullYear(),
    })
    const [incomeData, setIncomeData] = useState({})
    const { data, options, setOptions, pageSizes, loading } = usePagination(
        `${ENDPOINT}/payments`,
        'all'
    )
    const [startDate, setStartDate] = useState('');
    const [endDate, setEndDate] = useState('');
    // const [durationValue, setDurationValue] = useState('');
    const [disable, setDisable] = React.useState(true);
    // eslint-disable-next-line
    const [selectedOption, setSelectedOption] = useState('');
    useEffect(() => {
        getFinancials(financialObjects).then((v) => {
            setIncomeData(v.data)
        })
       
    }, [financialObjects.duration, financialObjects])

    useEffect(() => {
        setOptions({
            ...options,
            status: 'Transaction Approved',
        })

        // eslint-disable-next-line
    }, [])
    useEffect(() => {
        if (startDate !== '' && endDate !== '') {
            setDisable(false)
        }
    }, [disable, endDate, startDate])

    const handlePagination = (page) => {
        setOptions({
            ...options,
            page: page - 1,
        })
    }
    const handlePageSize = (pageSize) => {
        setOptions({
            ...options,
            size: pageSize.value,
        })
    }

    const handleFilterChange = (val) => {
        setOptions({
            ...options,
            request_type: val.value,
        })
    }
    const ysingle = [
        {
            uv: incomeData?.TRANSCRIPT,
        },
        {
            uv: incomeData?.CERTIFICATE
        },
        {
            uv: incomeData?.RESULT_STATEMENT
        },
    ]
    const filterDate = async () => {

        let startDay = startDate?.getDate()
        let startMonth = startDate?.getMonth()
        let startYear = startDate?.getFullYear()

        function padTo2Digits(num) {
            return num.toString().padStart(2, '0');
        }


        let endDay = endDate?.getDate()
        let endMonth = endDate?.getMonth()
        let endYear = endDate?.getFullYear()

        const day = padTo2Digits(startDay)
        const mon = padTo2Digits(startMonth + 1)
        const dayend = padTo2Digits(endDay)
        const monend = padTo2Digits(endMonth + 1)


        try {
            const res = await axios.get(`${ENDPOINT}/dashboard/income/stats?start_date=${day}-${mon}-${startYear}&end_date=${dayend}-${monend}-${endYear}`)
            setIncomeData(res.data.income)
        } catch (error) {
            console.log(error);
        }

    };

    return (
        <>
            <Box pad="30px 36px">
                <PageTitle> Financial Report </PageTitle>

                <Box
                    pad="20px 0"
                    style={{
                        borderBottom: '1px dashed #d1d1d1',
                    }}
                >
                    <FilterBox>
                        <Box spaceRight>

                            <Datepicker label="Start Date" onChange={(val) => setStartDate(val)} value={startDate} />

                        </Box>
                        <Box style={{
                            marginLeft: "15px"
                        }}>

                            <Datepicker label="End Date" onChange={(val) => setEndDate(val)} value={endDate} />

                        </Box>

                        <Box style={{
                            marginLeft: "15px"
                        }}>

                            <Button style={{
                                padding: "20px 30px"
                            }}
                                disabled={disable}
                                onClick={filterDate}
                            >Apply Filter</Button>

                        </Box>
                    </FilterBox>
                </Box>
                <Box margin="50px 0">
                    <Grid default="minmax(auto, 500px) minmax(auto, 500px) minmax(auto, 500px)">
                        <DashCard
                            icon={
                                <img
                                    src={Transcript}
                                    alt="transcript icon"
                                    height="60px"
                                />
                            }
                            iconColor="tomato"
                        >
                            <CardSection left>
                                <Text
                                    bold
                                    block
                                    size="20px"
                                    style={{ marginBottom: '20px' }}
                                >
                                    ₦
                                    {amountValidator(
                                        incomeData?.TRANSCRIPT
                                    )}
                                </Text>
                                Transcript payment recieved
                            </CardSection>
                        </DashCard>

                        <DashCard
                            icon={
                                <img
                                    src={Cert}
                                    alt="certificate icon"
                                    height="60px"
                                />
                            }
                            iconColor="tomato"
                        >
                            <CardSection left>
                                <Text
                                    bold
                                    block
                                    size="20px"
                                    style={{ marginBottom: '20px' }}
                                >
                                    ₦
                                    {amountValidator(
                                        incomeData?.CERTIFICATE
                                    )}
                                </Text>
                                certificate payment recieved
                            </CardSection>
                        </DashCard>

                        <DashCard
                            icon={
                                <img
                                    src={Statement}
                                    alt="statement icon"
                                    height="60px"
                                />
                            }
                            iconColor="tomato"
                        >
                            <CardSection left>
                                <Text
                                    bold
                                    block
                                    size="20px"
                                    style={{ marginBottom: '20px' }}
                                >
                                    ₦
                                    {amountValidator(
                                        incomeData?.RESULT_STATEMENT
                                    )}
                                </Text>
                                Statement of result payment recieved
                            </CardSection>
                        </DashCard>
                    </Grid>
                </Box>

                <Box pad="80px 0">
                    <Box display="flex" margin=" 0 0 60px">
                        <Box width="50%">
                            <Text bold block size="15px">
                                Financial Trend{' '}
                            </Text>
                            <Text size="10px">Breakdown by Services</Text>
                        </Box>
                        <Box width="50%" align="right">
                            <Button>Hide chart</Button>
                        </Box>
                    </Box>
                    <FlexiBar
                        xData={xAxis}
                        yData={ysingle}
                        shade="indigo"
                        height={200}
                        xAxis
                        yAxis
                    />
                </Box>
                <Box
                    pad="10px 30px"
                    style={{
                        borderTop: '1px dashed #d1d1d1',
                        borderBottom: '1px dashed #d1d1d1',
                    }}
                >
                    <Grid default="minmax(auto, 300px) minmax(auto, 150px) minmax(auto, 150px) minmax(auto, 150px)">
                        <Select
                            placeholder="Select type"
                            options={[
                                { value: 'Certificate', label: 'Certificate' },
                                { value: 'Transcript', label: 'Transcript' },
                                {
                                    value: 'Result Statement',
                                    label: 'Result Statement',
                                },
                            ]}
                            onChange={handleFilterChange}
                            width="100%"
                        />
                    </Grid>
                </Box>
                <Box>
                    {loading ? (
                        <Box vAlign height={`calc(100vh - 200px)`}>
                            <Box width="200px" margin="0 auto" align="center">
                                <Loader color={Theme.PrimaryColor} />
                            </Box>
                        </Box>
                    ) : (
                        <>
                            <Box pad="0px 30px">
                                <Table striped>
                                    <table>
                                        <thead>
                                            <tr>
                                                <th>Payers Name</th>
                                                <th>Amount Paid</th>
                                                <th>Date Paid</th>
                                                <th>Request</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {data.data &&
                                                data?.data.map((item) => {
                                                    const {
                                                        transcript_request,

                                                        amount,
                                                        created_at,
                                                    } = item
                                                    const {
                                                        delivery_address,
                                                        first_name,
                                                        last_name,
                                                    } = transcript_request
                                                    return (
                                                        <tr>
                                                            <td>{`${first_name} ${last_name}`}</td>
                                                            <td>{amount}</td>
                                                            <td>
                                                                {new Date(
                                                                    created_at
                                                                )
                                                                    .toISOString()
                                                                    .slice(
                                                                        0,
                                                                        10
                                                                    )}
                                                            </td>
                                                            <td>
                                                                {delivery_address
                                                                    ? 'Physical Delivery'
                                                                    : 'Online Download'}
                                                            </td>
                                                        </tr>
                                                    )
                                                })}
                                        </tbody>
                                    </table>
                                </Table>
                                <Box>
                                    <FlexiPagination
                                        pageCounts={pageSizes}
                                        total={data?.totalResults}
                                        itemsDisplayed
                                        pageSize={options.size}
                                        onChange={handlePagination}
                                        changePageSize={handlePageSize}
                                        current={options.page + 1}
                                    />
                                </Box>
                            </Box>
                        </>
                    )}
                </Box>
            </Box>
        </>
    )
}

export default Transactions

import { useLocation } from 'react-router-dom'

export const extractRemitaUSerDetails = (dataObj) => {
    const userObj = {
        first_name: dataObj.firstName,
        last_name: dataObj.lastName,
        email: dataObj.contacts.filter(
            (item) => item.contactType === 'EMAIL'
        )[0]?.contact,
        phone: dataObj.contacts.filter(
            (item) => item.contactType === 'PHONE_NUMBER'
        )[0]?.contact,
    }
    return userObj
}

export const useQueryParams = () => {
    return new URLSearchParams(useLocation().search)
}

export const getYears = () => {
    let yearsOBJ = []
    let years = []
    function generateArrayOfYears() {
        let max = new Date().getFullYear()
        let min = max - 61
        // let years = []

        for (let i = max; i >= min; i--) {
            years.push(i)
        }
        return years
    }

    // let years = generateArrayOfYears()
    generateArrayOfYears().forEach((year) =>
        yearsOBJ.push({ value: year, label: year })
    )
    return yearsOBJ
}

export const getFacultyFromId = (faculties, id) => {
    return faculties.find((fac) => fac.id === id).faculty_name
}
export const getDepartmentFromId = (departments, id) => {
    return departments.find((dep) => dep.id === id).department_name
}
export const getCourseFromId = (courses, id) => {
    return courses.find((co) => co.id === id).name
}

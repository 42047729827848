import React from 'react'
import {
    Modal,
    ModalBody,
    Text,
    Box,
    Input,
    Button,
    Notify,
    Grid,
} from 'flexibull2'
import { TOP_RIGHT, ERROR_ALERT } from '../../utils/constants'

const EditFaculty = ({
    open,
    onClose,
    loading,
    current,
    updateFaculty,
    setEdit,
}) => {
    const [faculty, setFaculty] = React.useState({
        name: '',
        short_code: '',
    })
    React.useEffect(() => {
        if (current) {
            const { faculty_name, short_code } = current
            setFaculty({ name: faculty_name, short_code })
        }
    }, [current])
    const { name, short_code } = faculty

    const handleChange = (e) => {
        const { name, value } = e.target
        setFaculty({ ...faculty, [name]: value })
    }

    const onUpdateFaculty = (e) => {
        e.preventDefault()
        if (name === '' || short_code === '') {
            Notify(`Please fill in all the fields and try again`, {
                position: TOP_RIGHT,
                status: ERROR_ALERT,
            })
        } else {
            const data = { name, short_code, id: current.id }

            updateFaculty(data)
            setFaculty({ name: '', short_code: '' })
        }
        setEdit(false)
    }
    return (
        <div>
            <Modal outerClick open={open}>
                <ModalBody style={{ background: '#fff' }} width="570px">
                    <Box pad="20px 40px 0">
                        <Box margin="20px 0 70px 0">
                            <Text size="22px" block>
                                Edit Faculty
                            </Text>
                        </Box>
                        <Grid default="1fr 1fr">
                            <Box margin="0px 0px 25px 0px">
                                <Input
                                    label="Faculty Title"
                                    name="name"
                                    value={name}
                                    onChange={handleChange}
                                    placeholder="Faculty Title"
                                    spaceBottom="45px"
                                    width="280px"
                                />
                            </Box>
                            <Box pad=" 0 0 0">
                                <Input
                                    label="Faculty Short Code"
                                    name="short_code"
                                    value={short_code}
                                    onChange={handleChange}
                                    placeholder="Faculty Short Code"
                                    width="200px"
                                    spaceBottom="45px"
                                />
                            </Box>
                        </Grid>
                    </Box>
                    <Box pad="0px 30px 40px 0">
                        <Grid default="2fr 1fr 1fr">
                            <Box
                                style={{
                                    marginLeft: '20px',
                                    marginTop: '-10px',
                                }}
                            ></Box>
                            <Box align="right">
                                <Button
                                    style={{
                                        border: 'none',
                                        textAling: 'center',
                                        color: '#000',
                                    }}
                                    width={90}
                                    height={30}
                                    onClick={onClose}
                                    pale
                                    pad="0 20px"
                                >
                                    Cancel
                                </Button>
                            </Box>
                            <Box align="right">
                                <Button
                                    width={120}
                                    height={30}
                                    pad="0 15px"
                                    spaceRight
                                    onClick={onUpdateFaculty}
                                    progress={loading}
                                    disabled={loading}
                                    iconRight={
                                        <i className="flexibull-angle-right" />
                                    }
                                >
                                    {loading ? 'Updating' : 'Update'}
                                </Button>
                            </Box>
                        </Grid>
                    </Box>
                </ModalBody>
            </Modal>
        </div>
    )
}

export default EditFaculty

import axios, { ENDPOINT } from '../../../utils/httpClient'

export const getRequestDetails = (id) => {
    return axios.get(`${ENDPOINT}/requests/${id}`)
}
export const getTranscriptUrl = (id) => {
    return axios.get(`${ENDPOINT}/transcript-uploads/${id}`)
}

export const updateRequest = (data) => {
    const { id } = data
    return axios.put(`${ENDPOINT}/requests/${id}`, data)
}

export const uploadTranscript = (data) => {
    const { id, fileData } = data
    const bodyFormData = new FormData()
    bodyFormData.append('file', fileData)
    bodyFormData.append('transcript_id', id)

    return axios.post(`${ENDPOINT}/transcript-uploads`, bodyFormData, {
        'Content-Type': 'multipart/form-data',
    })
}

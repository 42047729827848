import React, { useState, useEffect, useContext } from 'react'
import { Box, Grid, Spacer, Text, Button, PageTitle } from 'flexibull2'
import { getRequestsData } from './services'
import { amountValidator } from '../../utils/amountValidator'
import { Theme } from '../config/theme'
import { lighten } from 'polished'
import { useAuth } from '../../context/auth-context'
import styled from 'styled-components'
import { Select } from 'flexibull2/build/select'
import { Datepicker } from 'flexibull2/build/datepicker'
import axios from 'axios'
import { ENDPOINT } from '../../utils/httpClient'
import { LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip } from 'recharts'
import { CardSection, DashCard } from 'flexibull2/build/dashcard'
import { getFinancials } from '../Payments/services'
import Cert from '../../assets/cert.svg'
import Transcript from '../../assets/transcript.svg'
import Statement from '../../assets/statement.svg'
import { FlexiLine } from 'flexibull2/build/visualization/linechart'
import { FlexiBar } from 'flexibull2/build/visualization/barchart'
import { useRef } from 'react'
import InstitutionContext from '../../context/institution/institutionContext'

export const FilterBox = styled.div`
    display: flex;
    flex: 1;
    margin-bottom: 20px;
`

const InstituteDashboard = ({ history }) => {
    const { org, data } = useAuth()
    const institutionContext = useContext(InstitutionContext)

    const { getInstitutions, institutions } = institutionContext
    useEffect(() => {
        getInstitutions(1, 100)
        // eslint-disable-next-line
    }, [])
    const institutionsData = institutions?.data.map((institute) => {
        return {
            label: institute.full_name,
            value: institute.short_name,
        }
    })

    let Options = useRef([
        { label: 'ALL TIME', value: 'All Time' },
        { label: 'DATE_RANGE', value: 'DATE_RANGE' },
    ])

    const [requestsData, setRequestsData] = useState({})
    // eslint-disable-next-line
    const [startDate, setStartDate] = useState('')
    const [endDate, setEndDate] = useState('')
    // const [durationValue, setDurationValue] = useState('');
    const [disable, setDisable] = React.useState(true)
    // eslint-disable-next-line
    const [selectedOption, setSelectedOption] = useState('')
    const [showRange, setShowRange] = useState(true)

    const [requestsObject, setRequestsObject] = useState({
        duration: 'All Time',
        durationValue: 0,
    })

    const [transcriptDistribution, setTranscriptDistribution] = useState()
    const [certificateDistribution, setCertificateDistribution] = useState()
    const [resultDistribution, setResultDistribution] = useState()
    const [requestDistribution, setRequestDistribution] = useState()
    const [showLine, setShowLine] = useState(true)
    const [callTrend, setCallTrend] = useState(0)
    const [split, setSplit] = useState(false)
    const [incomeData, setIncomeData] = useState({})
    // eslint-disable-next-line
    const [financialObjects, setFinancialObjects] = useState({
        duration: 'YEARLY',
        durationValue: new Date().getFullYear(),
    })
    const [filters, setFilters] = React.useState(false)
    const useDateFilter = () => {
        let startDay = startDate !== '' ? startDate?.getDate() : ''
        let startMonth = startDate !== '' ? startDate?.getMonth() : ''
        let startYear = startDate !== '' ? startDate?.getFullYear() : ''

        function padTo2Digits(num) {
            return num !== undefined ? num.toString().padStart(2, '0') : ''
        }

        let endDay = endDate !== '' ? endDate?.getDate() : ''
        let endMonth = endDate !== '' ? endDate?.getMonth() : ''
        let endYear = endDate !== '' ? endDate?.getFullYear() : ''

        const day = padTo2Digits(startDay)
        const mon = padTo2Digits(startMonth + 1)
        const dayend = padTo2Digits(endDay)
        const monend = padTo2Digits(endMonth + 1)

        return {
            day,
            mon,
            startYear,
            dayend,
            monend,
            endYear,
        }
    }

    const [institution, setInstitution] = useState('')
    useEffect(() => {
        if (requestsObject.duration === 'Institution') {
            setFilters(true)
        }
    }, [requestsObject, requestsObject.duration])
    const { day, mon, startYear, dayend, monend, endYear } = useDateFilter()
    let show = useRef(false)
    useEffect(() => {
        if (org === 'flexisaf') {
            setShowRange(true)
            show.current = true

            Options.current = [
                { label: 'ALL SCHOOLS', value: 'All Schools' },
                { label: 'INSTITUTION', value: 'Institution' },
            ]
            setRequestsObject({
                duration: 'All Schools',
                durationValue: 0,
            })
        }
        const getFacultyDistribution = async () => {
            const transcriptResponse = await axios.get(
                `${ENDPOINT}/dashboard/income/trend?request_type=transcript&start_date=01-01-2022`
            )
            const certificateResponse = await axios.get(
                `${ENDPOINT}/dashboard/income/trend?request_type=Certificate&start_date=01-01-2022`
            )
            const resultResponse = await axios.get(
                `${ENDPOINT}/dashboard/income/trend?request_type=Result Statement&start_date=01-01-2022`
            )
            const allRequests = await axios.get(
                `${ENDPOINT}/dashboard/monthly-trend?start_date=2022`
            )
            setTranscriptDistribution(transcriptResponse.data)
            setCertificateDistribution(certificateResponse.data)
            setResultDistribution(resultResponse.data)
            setRequestDistribution(allRequests.data)
        }
        if (startDate !== '' && endDate !== '') {
            setDisable(false)
        }

        getFacultyDistribution()
    }, [data, disable, endDate, org, startDate])

    useEffect(() => {
        const dateObj = {
            day,
            mon,
            startYear,
            dayend,
            monend,
            endYear,
        }
        getFinancials(financialObjects, institution, { ...dateObj }).then(
            (v) => {
                setIncomeData(v.data)
            
            }
          
        )
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [callTrend, financialObjects, institution])

    useEffect(() => {
        getRequestsData(requestsObject).then((v) => {
            setRequestsData(v.data.requests)
        })
    }, [requestsObject.duration, requestsObject])

    let transcriptData = []
    let resultData = []
    let certificateData = []
    transcriptDistribution &&
        Object.keys(transcriptDistribution).forEach((key) => {
            transcriptData.push({
                name: key,
                uv: transcriptDistribution[key],
            })
        })

    certificateDistribution &&
        Object.keys(certificateDistribution).forEach((key) => {
            resultData.push({
                name: key,
                uv: certificateDistribution[key],
            })
        })

    resultDistribution &&
        Object.keys(resultDistribution).forEach((key) => {
            certificateData.push({
                name: key,
                uv: resultDistribution[key],
            })
        })

    const filterDate = async () => {
        try {
            setCallTrend(callTrend + 1)
            setFilters(true)
            const res = await axios.get(
                `${ENDPOINT}/dashboard/requests/stats?&request_type=ALL&start_date=${day}-${mon}-${startYear}&end_date=${dayend}-${monend}-${endYear}&duration=Date Range`
            )
            setRequestsData(res.data.requests)
        } catch (error) {
            console.log(error)
            setCallTrend(false)
        }
    }

    const handleDashboardStats = async (shortName) => {
        setInstitution(shortName.value)
        setFilters(true)
        const transcriptResponse = await axios.get(
            `${ENDPOINT}/dashboard/income/trend?request_type=transcript&start_date=01-01-2022&organisation=${shortName.value}`
        )
        const certificateResponse = await axios.get(
            `${ENDPOINT}/dashboard/income/trend?request_type=Certificate&start_date=01-01-2022&organisation=${shortName.value}`
        )
        const resultResponse = await axios.get(
            `${ENDPOINT}/dashboard/income/trend?request_type=Result Statement&start_date=01-01-2022&organisation=${shortName.value}`
        )
        const allRequests = await axios.get(
            `${ENDPOINT}/dashboard/monthly-trend?start_date=2022&organisation=${shortName.value}`
        )
        const res = await axios.get(
            `${ENDPOINT}/dashboard/requests/stats?organisation=${shortName.value}`
        )
        setRequestsData(res.data.requests)
        setTranscriptDistribution(transcriptResponse.data)
        setCertificateDistribution(certificateResponse.data)
        setResultDistribution(resultResponse.data)
        setRequestDistribution(allRequests.data)
    }

    const clearFilters = () => {
        window.location.assign(window.location)
    }

    return (
        <Box pad="46px">
            <PageTitle>Dashboard</PageTitle>

            <FilterBox>
                {show.current && (
                    <Box>
                        <Select
                            label="select institution"
                            placeholder="Select Institution"
                            width={240}
                            options={institutionsData}
                            onChange={(e) => handleDashboardStats(e)}
                        />
                    </Box>
                )}

                <Box
                    style={{
                        marginLeft: '15px',
                    }}
                >
                    {showRange && (
                        <Datepicker
                            label="Start Date"
                            onChange={(val) => setStartDate(val)}
                            value={startDate}
                        />
                    )}
                </Box>
                <Box
                    style={{
                        marginLeft: '15px',
                    }}
                >
                    {showRange && (
                        <Datepicker
                            label="End Date"
                            onChange={(val) => setEndDate(val)}
                            value={endDate}
                        />
                    )}
                </Box>

                <Box
                    style={{
                        marginLeft: '15px',
                    }}
                >
                    {showRange && (
                        <Button
                            style={{
                                padding: '20px 30px',
                            }}
                            disabled={disable}
                            onClick={filterDate}
                        >
                            Apply Filter
                        </Button>
                    )}
                </Box>
                <Box
                    style={{
                        marginLeft: '15px',
                    }}
                >
                    {filters && (
                        <Button
                            style={{
                                padding: '20px 30px',
                            }}
                            onClick={clearFilters}
                            color={Theme.PrimaryRed}
                        >
                            Clear Filters
                        </Button>
                    )}
                </Box>
            </FilterBox>
            <Spacer space="10px" />

            <Grid default="minmax(auto, 500px) minmax(auto, 400px) minmax(auto, 400px)">
                <Box display="flex">
                    <Box
                        background={lighten(0.07, Theme.PrimaryBorderColor)}
                        pad="23px"
                        margin="0 20px 0 0"
                        width="50%"
                        style={{ borderRadius: '5px' }}
                    >
                        <Box pad="10px"></Box>
                        <Text block bold size="30px" color={Theme.PrimaryBlue}>
                            {requestsData.all_services_total}
                        </Text>
                        <Text>Total request recieved</Text>
                    </Box>
                    <Box width="45%">
                        <Box
                            margin="0 0 10px 0"
                            pad="23px"
                            background="#FFF"
                            width="100%"
                            style={{ borderRadius: '5px' }}
                        >
                            <Text
                                block
                                bold
                                size="15px"
                                color={Theme.PrimaryGreen}
                            >
                                {amountValidator(
                                    requestsData.certificates_total
                                )}
                            </Text>
                            <Text>Certificates Total</Text>
                        </Box>
                        <Box
                            margin="10px 0 0 0"
                            pad="23px"
                            background="#FFF"
                            width="100%"
                            style={{ borderRadius: '5px' }}
                        >
                            <Text
                                block
                                bold
                                size="15px"
                                color={Theme.PrimaryOrange}
                            >
                                {amountValidator(
                                    requestsData.result_statement_total
                                )}
                            </Text>
                            <Text>Results Total</Text>
                        </Box>
                        <Box
                            margin="10px 0 0 0"
                            pad="23px"
                            background="#FFF"
                            width="100%"
                            style={{ borderRadius: '5px' }}
                        >
                            <Text
                                block
                                bold
                                size="15px"
                                color={Theme.PrimaryOrange}
                            >
                                {amountValidator(
                                    requestsData.transcripts_total
                                )}
                            </Text>
                            <Text>Transcripts Total</Text>
                        </Box>
                    </Box>
                </Box>
                <Box
                    pad="23px"
                    background="#FFF"
                    width="100%"
                    style={{ borderRadius: '5px' }}
                >
                    <Box pad="10px"></Box>
                    <Text block bold size="30px">
                        {amountValidator(requestsData.completed_requests)}
                    </Text>
                    <Text>Completed Requests</Text>
                </Box>
                <Box
                    pad="23px"
                    background={Theme.PrimaryRed}
                    width="100%"
                    style={{ borderRadius: '5px' }}
                >
                    <Box pad="10px"></Box>
                    <Text block bold size="30px" color="#fff">
                        {amountValidator(requestsData.pending_requests)}
                    </Text>
                    <Text block color="#fff" style={{ marginBottom: '20px' }}>
                        Requests Pending
                    </Text>
                    {requestsData.pending_requests === 0 ? (
                        ''
                    ) : (
                        <Button
                            onClick={() => history.push(`/${org}/requests`)}
                            color="#FFF"
                            fontColor={Theme.PrimaryFontColor}
                        >
                            View requests
                        </Button>
                    )}
                </Box>
            </Grid>

            <Box margin="50px 0">
                <Grid default="minmax(auto, 500px) minmax(auto, 500px) minmax(auto, 500px)">
                    <DashCard
                        icon={
                            <img
                                src={Transcript}
                                alt="transcript icon"
                                height="60px"
                            />
                        }
                        iconColor="tomato"
                    >
                        <CardSection left>
                            <Text
                                bold
                                block
                                size="20px"
                                style={{ marginBottom: '20px' }}
                            >
                                ₦{amountValidator(incomeData?.TRANSCRIPT)}
                            </Text>
                            Transcript payment recieved
                        </CardSection>
                    </DashCard>

                    <DashCard
                        icon={
                            <img
                                src={Cert}
                                alt="certificate icon"
                                height="60px"
                            />
                        }
                        iconColor="tomato"
                    >
                        <CardSection left>
                            <Text
                                bold
                                block
                                size="20px"
                                style={{ marginBottom: '20px' }}
                            >
                                ₦{amountValidator(incomeData?.CERTIFICATE)}
                            </Text>
                            certificate payment recieved
                        </CardSection>
                    </DashCard>

                    <DashCard
                        icon={
                            <img
                                src={Statement}
                                alt="statement icon"
                                height="60px"
                            />
                        }
                        iconColor="tomato"
                    >
                        <CardSection left>
                            <Text
                                bold
                                block
                                size="20px"
                                style={{ marginBottom: '20px' }}
                            >
                                ₦
                                {amountValidator(
                                    incomeData?.RESULT_STATEMENT
                                )}
                            </Text>
                            Statement of result payment recieved
                        </CardSection>
                    </DashCard>
                </Grid>
            </Box>
            <Box
                style={{
                    margin: '20px 0',
                }}
            >
                {split && (
                    <Grid default="1fr 1fr 1fr">
                        <Box
                            display="inline-block"
                            pad="15px"
                            round="high"
                            background="white"
                        >
                            <Box margin=" 0 0 30px 0">
                                <Grid default="1fr ">
                                    <Box align="center ">
                                        <Text block size="10px">
                                            Payment trend for{' '}
                                            <Text bold size="12px">
                                                Transcripts
                                            </Text>
                                        </Text>
                                    </Box>
                                </Grid>
                            </Box>
                            <LineChart
                                width={300}
                                height={200}
                                data={transcriptData}
                                margin={{
                                    top: 10,
                                    right: 30,
                                    left: 0,
                                    bottom: 0,
                                }}
                                fill="#82ca9d"
                            >
                                <CartesianGrid strokeDasharray="3 3" />
                                <XAxis dataKey="name" />
                                <YAxis />
                                <Tooltip />
                                <Line
                                    connectNulls
                                    type="monotone"
                                    dataKey="uv"
                                    stroke="#8884d8"
                                    fill="#8884d8"
                                />
                            </LineChart>
                        </Box>
                        <Box
                            display="inline-block"
                            pad="15px"
                            round="high"
                            background="white"
                        >
                            <Box margin=" 0 0 30px 0">
                                <Grid default="1fr ">
                                    <Box align="center ">
                                        <Text block size="10px">
                                            Payment trend for{' '}
                                            <Text bold size="12px">
                                                Certificates
                                            </Text>
                                        </Text>
                                    </Box>
                                </Grid>
                            </Box>
                            <LineChart
                                width={300}
                                height={200}
                                data={certificateData}
                                margin={{
                                    top: 10,
                                    right: 30,
                                    left: 0,
                                    bottom: 0,
                                }}
                            >
                                <CartesianGrid strokeDasharray="3 3" />
                                <XAxis dataKey="name" />
                                <YAxis />
                                <Tooltip />
                                <Line
                                    connectNulls
                                    type="monotone"
                                    dataKey="uv"
                                    stroke="#8884d8"
                                    fill="#8884d8"
                                />
                            </LineChart>
                        </Box>
                        <Box
                            display="inline-block"
                            pad="15px"
                            round="high"
                            background="white"
                        >
                            <Box margin=" 0 0 30px 0">
                                <Grid default="1fr ">
                                    <Box align="center ">
                                        <Text block size="10px">
                                            Payment trend for{' '}
                                            <Text bold size="12px">
                                                Statement of Results
                                            </Text>
                                        </Text>
                                    </Box>
                                </Grid>
                            </Box>
                            <LineChart
                                width={300}
                                height={200}
                                data={resultData}
                                margin={{
                                    top: 10,
                                    right: 30,
                                    left: 0,
                                    bottom: 0,
                                }}
                            >
                                <CartesianGrid strokeDasharray="3 3" />
                                <XAxis dataKey="name" />
                                <YAxis />
                                <Tooltip />
                                <Line
                                    connectNulls
                                    type="monotone"
                                    dataKey="uv"
                                    stroke="#8884d8"
                                    fill="#8884d8"
                                />
                            </LineChart>
                        </Box>
                    </Grid>
                )}
            </Box>
            <Spacer space="60px" />

            <Box
                style={{
                    display: 'flex',
                    flex: 1,
                    padding: '10px',
                }}
            >
                <Box spaceRight>
                    {!showLine && (
                        <Button
                            spaceRight
                            onClick={() => setShowLine(!showLine)}
                        >
                            {' '}
                            Show Bar Chart{' '}
                        </Button>
                    )}
                    {showLine && (
                        <Button
                            spaceRight
                            onClick={() => setShowLine(!showLine)}
                        >
                            {' '}
                            Show Line Chart{' '}
                        </Button>
                    )}
                </Box>
                <Box spaceRight>
                    <Button spaceRight onClick={() => setSplit(!split)}>
                        {' '}
                        {!split
                            ? ' Show Split Line Chart'
                            : 'Hide Split Line Chart'}
                    </Button>
                </Box>
            </Box>
            <Spacer space="60px" />
            {requestDistribution !== undefined && (
                <>
                    {!showLine && (
                        <FlexiLine
                            xAxis
                            yAxis
                            xData={requestDistribution?.xaxis}
                            yData={requestDistribution?.yaxis}
                            grid="2 2"
                            height={200}
                            tooltip
                            legend
                        />
                    )}
                    {showLine && (
                        <FlexiBar
                            xAxis
                            yAxis
                            xData={requestDistribution?.xaxis}
                            yData={requestDistribution?.yaxis}
                            grid="2 2"
                            height={200}
                            tooltip
                            legend
                        />
                    )}
                </>
            )}

            <Spacer space="60px" />
        </Box>
    )
}

export default InstituteDashboard

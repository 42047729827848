import React from 'react'
import styled from 'styled-components'

const NewButton = styled.button`
    display: -webkit-inline-box;
    display: -webkit-inline-flex;
    display: -ms-inline-flexbox;
    display: inline-flex;
    overflow: hidden;
    -webkit-align-items: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    position: relative;
    vertical-align: top;
    cursor: pointer;
    height: 38px;
    border: none;
    background-color: #4dccbd;
    padding: 0 15px;
    color: #fff;
    border-radius: 3px;
    font-size: 12px;
    -webkit-transition: all 0.3s ease-out;
    transition: all 0.3s ease-out;
`
const RemitaButton = (props) => {
    const {
        amount,
        gateway_url,
        payer_email,
        payer_phone,
        rrr,
        transaction_reference,
        merchant_id,
        service_type_id,
        hash,
        payer_name,
        response_url,
    } = props
    return (
        <form id="redirectpay" action={gateway_url} method="post">
            <input type="hidden" name="merchantId" value={merchant_id} />
            <input type="hidden" name="amt" value={amount} />
            <input type="hidden" name="serviceTypeId" value={service_type_id} />
            <input type="hidden" name="hash" value={hash} />
            <input type="hidden" name="payerName" value={payer_name} />
            <input type="hidden" name="payerEmail" value={payer_email} />
            <input type="hidden" name="payerPhone" value={payer_phone} />
            <input type="hidden" name="orderId" value={transaction_reference} />
            <input type="hidden" name="responseurl" value={response_url} />
            <input type="hidden" name="rrr" value={rrr} />
            <NewButton type="submit" id="payment-button">Proceed to Pay</NewButton>
        </form>
    )
}

export default RemitaButton

import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import { useAuth } from '../../context/auth-context'
import { useHistory, withRouter } from 'react-router-dom'
import { Box, Text, Button, Input } from 'flexibull2'
import LoginSVG from '../../assets/login.svg'
import ShowPwdImg from '../../assets/show-password.svg'
import HidePwdImg from '../../assets/hide-password.svg'
import GoogleSVG from '../../assets/g.svg'
import AuthLayout from '../components/authLayout'
import { validate } from '../../utils/useValidation.js'
import { Notify } from 'flexibull2'
import { TOP_RIGHT, ERROR_ALERT } from '../../utils/constants'
import Signup from '../Signup'
import Footer from '../components/footer'
import useEnter from '../../utils/useEnter'
import { Grid } from 'flexibull2/build/layout'
import { GOOGLE_AUTH_ENDPOINT, URLPORT } from '../../utils/httpClient'
import { resend } from '../../pages/PostRegister/services'

const Link = styled.span`
    color: rgb(42, 120, 208);
    font-size: 12px;
    cursor: pointer;
`

const ResponsiveBox = styled(Box)`
    svg {
        width: 15px;
        height: 15px;
        fill: #fff;
        margin-right: 8px;
        margin-left: -20px;
        margin-top: 2px;
    }

    .or-container {
        align-items: center;
        color: #ccc;
        display: flex;
        margin: 25px 0;
    }
    .line-separator {
        background-color: #ccc;
        flex-grow: 5;
        height: 1px;
    }
    .or-label {
        flex-grow: 1;
        margin: 0 15px;
        text-align: center;
    }

    width: 400px;
    z-index: 3;

    @media (max-width: 1024px) {
        width: 40%;
    }
    @media (max-width: 769px) {
        width: 50%;
    }
    @media screen and (max-width: 640px) {
        width: 80%;
        margin-top: 25%;
    }
    .gKJIRx .flexi-input-affix-wrapper::after {
        font-family: 'flexibull-2-0';
        opacity: 0.3;
        content: ' ' !important;
        position: absolute;
        right: 10px;
        display: none !important ;
    }

    .FIJZR .flexi-input-affix-wrapper::after {
        font-family: 'flexibull-2-0';
        opacity: 0.3;
        content: '' !important;
        position: absolute;
        right: 10px;
        display: none !important ;
    }
    .space-eye {
        position: absolute;
        top: 10.6em;
        right: 10px;
        width: 20px;
        height: 20px;
        cursor: pointer;
    }
`

const ForgotPasswordBox = styled(Box)`
    @media (max-width: 375px) {
        text-align: center;
    }
`

const SmButton = styled(Button)`
    margin: 0;
    @media (max-width: 375px) {
        width: 100%;
        margin-top: 40px;
    }

    /* CSS */
`

const ZAuthLayout = styled(AuthLayout)`
    // z-index: 999;
`
export const GooogleButton = styled.div`
    img {
        font-weight: bold;
    }

    .button-17 {
        align-items: center;
        appearance: none;
        background-color: #fff;
        border-radius: 24px;
        border-style: none;
        box-shadow: rgba(0, 0, 0, 0.2) 0 3px 5px -1px,
            rgba(0, 0, 0, 0.14) 0 6px 10px 0, rgba(0, 0, 0, 0.12) 0 1px 18px 0;
        box-sizing: border-box;
        color: #3c4043;
        cursor: pointer;
        display: inline-flex;
        fill: currentcolor;
        font-family: 'Google Sans', Roboto, Arial, sans-serif;
        font-size: 14px;
        font-weight: 500;
        height: 48px;
        letter-spacing: 0.25px;
        line-height: normal;
        max-width: 100%;
        overflow: visible;
        padding: 2px 8px;
        position: relative;
        text-align: center;
        text-transform: none;
        transition: box-shadow 280ms cubic-bezier(0.4, 0, 0.2, 1),
            opacity 15ms linear 30ms,
            transform 270ms cubic-bezier(0, 0, 0.2, 1) 0ms;
        user-select: none;
        -webkit-user-select: none;
        touch-action: manipulation;
        width: 400px;
        will-change: transform, opacity;
        z-index: 0;
        margin-bottom: 1.5em;
    }

    .button-17:hover {
        background: #f6f9fe;
        color: #174ea6;
    }

    .button-17:active {
        box-shadow: 0 4px 4px 0 rgb(60 64 67 / 30%),
            0 8px 12px 6px rgb(60 64 67 / 15%);
        outline: none;
    }

    .button-17:focus {
        outline: none;
        border: 2px solid #4285f4;
    }

    .button-17:not(:disabled) {
        box-shadow: rgba(60, 64, 67, 0.3) 0 1px 3px 0,
            rgba(60, 64, 67, 0.15) 0 4px 8px 3px;
    }

    .button-17:not(:disabled):hover {
        box-shadow: rgba(60, 64, 67, 0.3) 0 2px 3px 0,
            rgba(60, 64, 67, 0.15) 0 6px 10px 4px;
    }

    .button-17:not(:disabled):focus {
        box-shadow: rgba(60, 64, 67, 0.3) 0 1px 3px 0,
            rgba(60, 64, 67, 0.15) 0 4px 8px 3px;
    }

    .button-17:not(:disabled):active {
        box-shadow: rgba(60, 64, 67, 0.3) 0 4px 4px 0,
            rgba(60, 64, 67, 0.15) 0 8px 12px 6px;
    }

    .button-17:disabled {
        box-shadow: rgba(60, 64, 67, 0.3) 0 1px 3px 0,
            rgba(60, 64, 67, 0.15) 0 4px 8px 3px;
    }

    .-or {
        color: #363a40;
        display: block;
        text-align: center;
        position: relative;
        margin-bottom: 15px;
        .-text {
            display: inline-block;
            padding: 5px 20px;
            background-color: white;
            position: relative;
            font-size: 14px;
        }
        &:before {
            content: '';
            height: 1px;
            width: 100%;
            position: absolute;
            top: 50%;
            left: 0;
            background-color: #999;
            transform: translateY(-50%);
        }
    }
`

const Login = ({ match }) => {
    const history = useHistory()
    const { login, org } = useAuth()
    const [error, setError] = React.useState({})
    const [loading, setLoading] = React.useState(false)
    const [userData, setUserData] = useState({ username: '', password: '' })
    const [signupModal, setSignupModal] = useState(false)
    const googleAuthURL = `${GOOGLE_AUTH_ENDPOINT}/oauth2/google/authorize?organization=${org}&callback_url=${URLPORT}${org}&product=36163e9e-1d43-4bf6-b0d3-83bfa7da097c`
    const [isRevealPwd, setIsRevealPwd] = useState(false)
    const { params } = match
     const tempUser = JSON.parse(localStorage.getItem('tempUser'))

    useEffect(() => {
        const makeNewTheme = (color) => {
            localStorage.setItem('e_trans_theme', color)
        }
        if (localStorage.getItem('e_trans_theme') === null) {
            makeNewTheme('#CFAE26')
        }
    }, [])

    const handleResendVerificationLink = async () => {
        window.location.assign(`/${org}/email-verification`)
        await resend(params, tempUser, setLoading)
    }

    const handleLogin = async () => {
        const { errorObj, errorCount } = validate(userData)
        setError(errorObj)
        if (!(errorCount > 0)) {
            setLoading(true)
            await login(userData)
                .then(() => {
                    setLoading(false)
                })
                .catch((err) => {
                    setLoading(false)
                    if (
                        err.response.status === 401 &&
                        err.response.data.error === 'User must be verified'
                    ) return handleResendVerificationLink()
                    Notify(err.response.data.error || 'No user found', {
                        position: TOP_RIGHT,
                        status: ERROR_ALERT,
                    })
                })
        }
    }
    function handleChange(e) {
        const newUserData = {
            ...userData,
            [e.target.name]: e.target.value,
        }
        setUserData(newUserData)
    }
    useEnter(handleLogin)

    return (
        <>
            <ZAuthLayout>
                <Box
                    display="flex"
                    style={{ alignItems: 'center', justifyContent: 'center' }}
                >
                    <ResponsiveBox margin="5% auto">
                        <Box
                            style={{
                                alignItems: 'center',
                                textAlign: 'center',
                            }}
                        >
                            <div>
                                <img alt="logo" src={LoginSVG} />
                            </div>
                            <div>
                                <Text size="14px">Welcome back</Text>
                            </div>
                        </Box>
                        <GooogleButton
                            style={{
                                marginTop: '2em',
                            }}
                        >
                            <a
                                href={`${googleAuthURL}`}
                                className="button-17"
                                role="button"
                            >
                                {' '}
                                <img
                                    style={{
                                        width: '40px',
                                    }}
                                    alt="logo"
                                    src={GoogleSVG}
                                />
                                <span
                                    style={{
                                        margin: '0 auto',
                                        fontWeight: 'bold',
                                    }}
                                >
                                    Login with Google
                                </span>
                            </a>
                            <div className="-or">
                                <span className ="-text">Or</span>
                            </div>
                        </GooogleButton>
                        <Box display="flex" style={{ alignItems: 'center' }}>
                            <div>
                                <Text
                                    size="18px"
                                    bold
                                    block
                                    spaceBottom
                                    style={{
                                        marginBottom: ' 10px',
                                    }}
                                >
                                    Sign in with
                                </Text>
                            </div>
                        </Box>
                        <Box
                            style={{
                                position: 'relative',
                            }}
                        >
                            <Input
                                name="username"
                                type="text"
                                placeholder="Enter email address"
                                label="Email Address"
                                width="100%"
                                error={error['username']}
                                onChange={handleChange}
                                spaceTop="30px"
                                spaceBottom
                            />
                            <Input
                                name="password"
                                type={isRevealPwd ? 'text' : 'password'}
                                placeholder="Enter password"
                                label="Password"
                                width="100%"
                                error={error['password']}
                                onChange={handleChange}
                                spaceTop
                                spaceBottom
                            />
                            <div className="space-eye">
                                <img
                                    title={
                                        isRevealPwd
                                            ? 'Hide password'
                                            : 'Show password'
                                    }
                                    src={isRevealPwd ? HidePwdImg : ShowPwdImg}
                                    onClick={() =>
                                        setIsRevealPwd(
                                            (prevState) => !prevState
                                        )
                                    }
                                    alt="password-visibility"
                                />
                            </div>

                            <ForgotPasswordBox align="right">
                                <Link
                                    onClick={() => history.push(`${org}/reset`)}
                                >
                                    {' '}
                                    Forgot your password?{' '}
                                </Link>
                            </ForgotPasswordBox>
                            <Box
                                style={{
                                    marginTop: '1em',
                                }}
                            >
                                <Grid default="1fr">
                                    <SmButton
                                        onClick={handleLogin}
                                        spaceRight
                                        iconRight={
                                            <i className="flexibull-angle-right" />
                                        }
                                        progress={loading}
                                        style={{
                                            fontWeight: 'bold',
                                        }}
                                    >
                                        Sign in
                                    </SmButton>
                                </Grid>
                            </Box>
                            <Box
                                margin="20px auto"
                                style={{
                                    textAlign: 'center',
                                }}
                            >
                                <Text>
                                    Don't have an account,{' '}
                                    <Link
                                        onClick={() =>
                                            setSignupModal(!signupModal)
                                        }
                                    >
                                        sign up now
                                    </Link>
                                </Text>
                            </Box>
                        </Box>
                    </ResponsiveBox>
                </Box>
            </ZAuthLayout>
            <Footer />

            {signupModal && <Signup open={signupModal} />}
        </>
    )
}

export default withRouter(Login)

import React, { useEffect, useState } from 'react'
import { Box, Grid, Text, Button, Loader } from 'flexibull2'
import { useAuth } from '../../context/auth-context'
import { useHistory } from 'react-router-dom'
import { useQueryParams } from '../../utils'
import { checkStatus } from './services'
import PaymentImage from '../../assets/payment-svg.svg'
import { Theme } from '../config/theme'

const PaymentStatus = () => {
    const { org } = useAuth()
    const history = useHistory()
    const [loading, setLoading] = useState(false)
    const [remitaResponse, setRemitaResponse] = useState({})
    const rrr = useQueryParams().get('orderID')
    useEffect(() => {
        setLoading(true)
        checkStatus(rrr)
            .then(({ data }) => {
                setLoading(false)
                setRemitaResponse(data)
            })
            .catch((e) => {
                setLoading(false)
            })
    }, [rrr])
    return (
        <Box margin="20px 0 0 0">
            {loading ? (
                <Box vAlign height={`calc(100vh - 200px)`}>
                    <Box width="200px" margin="0 auto" align="center">
                        <Loader color={Theme.PrimaryColor} />
                    </Box>
                </Box>
            ) : (
                <Box margin="10% 0 0 0">
                    <Grid
                        default="1fr"
                        style={{
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                            flexDirection: 'column',
                        }}
                    >
                        <Box margin="20px 0 0">
                            <img alt="" src={PaymentImage} height="200px" />
                        </Box>
                        <Box
                            width="30%"
                            style={{ textAlign: 'center', paddingTop: 30 }}
                        >
                            <Text size="15px">
                                {remitaResponse.message ||
                                    'Error making payment'}
                            </Text>
                        </Box>
                        <Box
                            width="30%"
                            style={{ textAlign: 'center', paddingTop: 30 }}
                        >
                            <Button
                                onClick={() => history.push(`/${org}/requests`)}
                                spaceRight
                                pale
                                iconRight={
                                    <i className="flexibull-angle-right" />
                                }
                            >
                                View Requests
                            </Button>
                        </Box>
                    </Grid>
                </Box>
            )}
        </Box>
    )
}

export default PaymentStatus

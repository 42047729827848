import React, { useEffect, useContext, useState } from 'react'
import { Link, useRouteMatch } from 'react-router-dom'
import {
    Box,
    Grid,
    Button,
    // Tag,
    Switch,
    Table,
    PageTitle,
    Input,
    FlexiPagination,
    Loader,
    Select,
    Text,
} from 'flexibull2'

import CreateCustomer from './CreateCustomer'
import { Theme } from '../config/theme'

import InstitutionContext from '../../context/institution/institutionContext'
import { NavLink } from 'react-router-dom'
import { URLPORT } from '../../utils/httpClient'
import { debounce } from '../../utils/debounce'

const Customer = ({ currentPage }) => {
    const institutionContext = useContext(InstitutionContext)
    const {
        getInstitutions,
        updateInstitutionActiveStatus,
        institutions,
        setCurrent,
        loading,
        getFees,
        searchInstitutions
    } = institutionContext
    const [showModal, setShowModal] = React.useState(false)
    const [pageOptions, setPageOptions] = useState({
        pageSize: 5,
    })
    let { path } = useRouteMatch()
    const [pageNumber, setpageNumber] = useState(1)
    // const [schoolActiveStatus, setschoolActiveStatus] = useState(true)
    // const [institutionsLocalState, setsinstitutionsLocalState] = useState([])

    const pageSizes = [
        { label: '5 Rows', value: 5 },
        { label: '10 Rows', value: 10 },
        { label: '15 Rows', value: 15 },
        { label: '20 Rows', value: 20 },
    ]


    const { pageSize } = pageOptions
    const ORG = localStorage.getItem('e_trans_org')

    const handleSchoolActiveStatus = async (id) => {
        const selectedInstitution = institutions?.data.find(
            (inst) => inst.short_name === id
        )
        await updateInstitutionActiveStatus(
            ORG,
            id,
            !selectedInstitution.active
        )
        getInstitutions(pageNumber, pageSize)
    }

    const handlePagination = (page) => {
        getInstitutions(page, pageSize)
        setpageNumber(page)
    }

    const handlePageSize = (pageSize) => {
        getInstitutions(pageNumber, pageSize.value)
        setPageOptions({ ...pageOptions, pageSize: pageSize.value })
    }

    useEffect(() => {
        getInstitutions(1, pageSize)
        getFees()
        if (localStorage.getItem('payments') !== null) {
            const res = []
            localStorage.setItem('payments', JSON.stringify(res))
        }
        const res = []
        localStorage.setItem('courses', JSON.stringify(res))
        localStorage.setItem('faculties', JSON.stringify(res))
        localStorage.setItem('departments', JSON.stringify(res))

        // eslint-disable-next-line
    }, [])

    const manageCopy = (data) => {
        navigator.clipboard.writeText(`${URLPORT}/${data}`)
    }

    const institutionSearch = debounce(function(q){
        searchInstitutions(q)
    });

    return (
        <>
            <PageTitle>Manage Institutions</PageTitle>
            <hr style={{ border: '1px solid #eeeeee' }} />
            <Box pad="10px">
                <Box style={{ margin: '0 30px' }}>
                    <Grid default="1fr 2fr">
                        <Box pad="20px 0">
                            <Grid default="1fr 1fr 1fr">
                                <Input
                                    name="search"
                                    type="search"
                                    placeholder="search"
                                    width="300px"
                                    onChange={q => institutionSearch(q.target.value)}
                                />
                                <Select
                                    placeholder="Select Status"
                                    isSearchable={false}
                                />
                                <Select
                                    placeholder="Select Location"
                                    isSearchable={false}
                                />
                            </Grid>
                        </Box>
                        <Box pad="20px 0" align="right">
                            <Button
                                pad="0 20px"
                                onClick={() => setShowModal(true)}
                            >
                                Create Institution
                            </Button>
                        </Box>
                    </Grid>
                </Box>
                <Box margin="-20px 0 0 0">
                    {loading ? (
                        <Box vAlign height={`calc(100vh - 400px)`}>
                            <Box margin="0 auto" align="center">
                                <Loader color={Theme.PrimaryColor} />
                            </Box>
                        </Box>
                    ) : (
                        <Box pad="30px">
                            <Table
                                style={{
                                    paddingTop: '10px',
                                    border: '0px',
                                    borderRadius: '10px',
                                }}
                                stripped
                            >
                                <table>
                                    <thead>
                                        <tr>
                                            <th>Institution Name</th>
                                            <th>URL - (context)</th>
                                            <th>Email</th>
                                            <th>Status</th>
                                            <th>Action</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {institutions?.data.map(
                                            (institution) => (
                                                <tr
                                                    key={
                                                        institution?.short_name
                                                    }
                                                >
                                                    <td>
                                                        {institution?.full_name}
                                                    </td>
                                                    <td>
                                                        <Link
                                                            activeStyle={{
                                                                fontWeight:
                                                                    'bold',
                                                                color: 'red',
                                                            }}
                                                            to={{
                                                                pathname: `${URLPORT}${institution?.short_name}`,
                                                            }}
                                                            target="_blank"
                                                        >
                                                            {URLPORT}
                                                            {
                                                                institution?.short_name
                                                            }
                                                        </Link>
                                                        <span
                                                            onClick={() =>
                                                                manageCopy(
                                                                    institution?.short_name
                                                                )
                                                            }
                                                            style={{
                                                                marginLeft:
                                                                    '5px',
                                                                fontWeight:
                                                                    'bold',
                                                                background:
                                                                    '#ccc',
                                                                cursor: 'pointer',
                                                                borderRadius:
                                                                    '5px',
                                                                padding: '2px',
                                                            }}
                                                        >
                                                            <i className=" icon-docs" />
                                                        </span>
                                                    </td>

                                                    <td>
                                                        {
                                                            institution?.email_address
                                                        }
                                                    </td>
                                                    <td>
                                                        {' '}
                                                        <Switch
                                                            // label={schoolActiveStatus ? "Actinated" : "Deactivated"}
                                                            color="#90B77D"
                                                            uncheckColor="#EC7272"
                                                            block
                                                            checked={
                                                                institution?.active
                                                            }
                                                            onChange={() =>
                                                                handleSchoolActiveStatus(
                                                                    institution?.short_name
                                                                )
                                                            }
                                                        />
                                                        {/* <Tag
                                                    small
                                                    fontColor="#FFF"
                                                    solid
                                                    style={{ width: '90px' }}
                                                    height={25}
                                                    color={Theme.PrimaryGreen}
                                                >
                                                    <Text size="12px">Active</Text>
                                                </Tag> */}
                                                    </td>
                                                    <td>
                                                        <Button
                                                            height={35}
                                                            // color={
                                                            //     Theme.PrimaryFontColor
                                                            // }
                                                            // fontColor="#fff"
                                                            // round
                                                            onClick={() =>
                                                                setCurrent(
                                                                    institution
                                                                )
                                                            }
                                                        >
                                                            <NavLink
                                                                style={{
                                                                    textDecoration:
                                                                        'none',
                                                                    fontWeight: 400,
                                                                }}
                                                                to={`${path}/${institution?.short_name}`}
                                                            >
                                                                <Text color="#fff">
                                                                    View
                                                                </Text>
                                                            </NavLink>
                                                        </Button>
                                                    </td>
                                                </tr>
                                            )
                                        )}
                                    </tbody>
                                </table>
                            </Table>
                            <FlexiPagination
                                pageCounts={pageSizes}
                                itemsDisplayed
                                total={institutions?.totalResults}
                                pageSize={pageSize}
                                onChange={handlePagination}
                                changePageSize={handlePageSize}
                                current={pageNumber}
                            />
                        </Box>
                    )}
                </Box>
                {showModal && (
                    <CreateCustomer
                        open={showModal}
                        onClose={() => setShowModal(false)}
                    />
                )}
            </Box>
        </>
    )
}

export default Customer

import React from 'react'
import {
    Modal,
    ModalBody,
    Text,
    Box,
    Input,
    Button,
    ModalFooter,
    Grid,
} from 'flexibull2'
import { Theme } from '../config/theme'

const Support = ({ open, onClose, createSupportDetails, org }) => {
    const [profile, setProfile] = React.useState({
        email: '',
        phone: '',
    })
    const [loading, setLoading] = React.useState(false)

    const { email, phone } = profile

    const handleChange = (e) => {
        const { name, value } = e.target
        setProfile({ ...profile, [name]: value })
    }

    const handleSubmit = () => {
        setLoading(true)
        createSupportDetails(profile, org).then(() => {
            setLoading(false)
            onClose()
        })
    }

    return (
        <div>
            <Modal outerClick open={open}>
                <ModalBody style={{ background: '#fff' }} width="570px">
                    <Box pad="10px 40px 0">
                        <Box margin="10px 0 20px 0">
                            <Text size="18px" block>
                                Create Support
                            </Text>
                        </Box>
                        <Grid default="1fr 1fr">
                            <Box>
                                <Input
                                    value={email}
                                    name="email"
                                    onChange={handleChange}
                                    label="Email"
                                    spaceBottom
                                    spaceTop
                                    block
                                />
                            </Box>
                            <Box>
                                <Input
                                    value={phone}
                                    name="phone"
                                    onChange={handleChange}
                                    label="Phone"
                                    spaceBottom
                                    spaceTop
                                    block
                                />
                            </Box>
                        </Grid>
                    </Box>
                    <ModalFooter>
                        <Box pad="20px ">
                            <Grid default="2fr 1fr 1fr">
                                <Box
                                    style={{
                                        marginLeft: '20px',
                                        marginTop: '-10px',
                                    }}
                                ></Box>
                                <Box align="right">
                                    <Button
                                        style={{
                                            border: 'none',
                                            textAling: 'center',
                                            color: '#000',
                                        }}
                                        width={90}
                                        height={30}
                                        onClick={onClose}
                                        pale
                                        pad="0 20px"
                                    >
                                        Cancel
                                    </Button>
                                </Box>
                                <Box align="right">
                                    <Button
                                        width={120}
                                        height={30}
                                        pad="0 15px"
                                        spaceRight
                                        color={Theme.PrimaryBlue}
                                        onClick={handleSubmit}
                                        progress={loading}
                                        disabled={loading}
                                        iconRight={
                                            <i className="flexibull-angle-right" />
                                        }
                                    >
                                        Create
                                    </Button>
                                </Box>
                            </Grid>
                        </Box>
                    </ModalFooter>
                </ModalBody>
            </Modal>
        </div>
    )
}

export default Support

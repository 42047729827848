import React from 'react'
import { useAuth } from '../context/auth-context'
import { Redirect, Route, Switch, useParams } from 'react-router-dom'
import Login from '../pages/Login'
import Signup from '../pages/Signup'
import Reset from '../pages/Reset'
import ResetRequest from '../pages/ResetRequest'
import PostRegister from '../pages/PostRegister'
import EmailSent from '../pages/EmailSent'
import Confirm from '../pages/Confirm'
import axios from 'axios'
import { ENDPOINT } from './httpClient'
import EmailVerification from '../pages/EmailVerification'

const UnAuthenticated = () => {
    const { setOrg } = useAuth()
    const { org } = useParams()
    localStorage.setItem('e_trans_org', org)
    axios.get(`${ENDPOINT}/institutions/${org}`).then(({ data }) => {
        if (
            data?.theme_color === 'null' ||
            localStorage.getItem('e_trans_theme') === null
        ) {
            localStorage.setItem('e_trans_theme', '#2b9464')
        } else {
            localStorage.setItem(
                'e_trans_theme',
                data.theme_color ? data.theme_color : '#2b9464'
            )
        }
    })
    setOrg(org)
    return (
        <Switch>
            <Route exact path="/:org" component={Login} />
            <Route exact path="/:org/signup" component={Signup} />
            <Route exact path="/:org/reset" component={Reset} />
            <Route exact path="/:org/reset-request" component={ResetRequest} />
            <Route exact path="/:org/post-register" component={PostRegister} />
            <Route exact path="/:org/email-sent" component={EmailSent} />
            <Route
                exact
                path="/:org/email-verification"
                component={EmailVerification}
            />
            <Route
                exact
                path="/:org/confirm"
                component={(props) => <Confirm {...props} />}
            />
            <Redirect to={`/${org}`} />
        </Switch>
    )
}

export default UnAuthenticated

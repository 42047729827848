import { useState } from 'react'

export const useRegex = (initialState = null) => {
    const [regexError, setRegexError] = useState(initialState)

    const checkRegex = (prop, value, regex) => {
        !value.match(regex)
            ? setRegexError(`Invalid ${prop}`)
            : setRegexError(null)
    }

    return [regexError, checkRegex]
}

import {
    GET_FACULTIES,
    DELETE_FACULTY,
    CREATE_FACULTY,
    EDIT_FACULTY,
    SET_LOADING,
    ERROR,
    SET_CURRENT,
    SET_STATUS,
    MODAL_STATUS,
    CLEAR_STATUS,
    STOP_LOADING, 
    GET_FACULTIES_FROM_STORE,
    SAVE_FACULTY_TO_STORE,
    GET_ALL_FACULTIES
} from '../types'


//eslint-disabled-next-line


const FacultyReducer = (state, action) => {
    switch (action.type) {
        case GET_FACULTIES:
            return {
                ...state,
                faculties: action.payload,
                loading: false,
            }
        case GET_ALL_FACULTIES:
            return {
                ...state,
                all_faculties: action.payload,
                loading: false,
            }
        case CREATE_FACULTY:
            return {
                ...state,
                faculties: {
                    ...state.faculties,
                    data: [action.payload, ...state.faculties.data],
                },
                loading: false,
                onProgress: false,
            }
        case GET_FACULTIES_FROM_STORE:
            return {
                ...state,
                facultyStore: action.payload,
                loading: false,
            }
        case SAVE_FACULTY_TO_STORE:
            return {
                ...state,
                facultyStore: {
                    ...state.facultyStore,
                    data: [action.payload, ...state.facultyStore.data],
                },
                loading: false,
                onProgress: false,
            }
        case EDIT_FACULTY:
            return {
                ...state,
                faculties: {
                    ...state.faculties,
                    data: state.faculties.data.map((faculty) =>
                        faculty.id === action.payload.id
                            ? action.payload
                            : faculty
                    ),
                },
                loading: false,
            }
        case DELETE_FACULTY:
            return {
                ...state,
                data: state.faculties.data.filter(
                    (faculty) => faculty.id !== action.payload
                ),
                loading: false,
            }
        case ERROR:
            return {
                ...state,
                error: action.payload,
                loading: false,
            }
        case SET_LOADING:
            return {
                ...state,
                loading: true,
            }
        case STOP_LOADING:
            return {
                ...state,
                loading: false,
            }
        case SET_CURRENT:
            return {
                ...state,
                current: action.payload,
                loading: false,
            }
        case SET_STATUS:
            return {
                ...state,
                status: action.payload,
                loading: false,
            }
        case CLEAR_STATUS:
            return {
                ...state,
                status: null,
                loading: false,
            }
        case MODAL_STATUS:
            return {
                ...state,
                modalStats: action.payload,
                onProgress: true,
                loading: false,
            }

        default:
            return state
    }
}

export default FacultyReducer;
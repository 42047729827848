import { Button } from 'flexibull2/build/button'
import { Box } from 'flexibull2/build/layout'
import { Loader } from 'flexibull2/build/loader'
import { FlexiPagination, Table } from 'flexibull2/build/table'
import { Tag } from 'flexibull2/build/tags'
import { useAuth } from '../../context/auth-context'
import { capitalize } from '../../utils/Capitalize'
import { ENDPOINT } from '../../utils/httpClient'
import usePagination from '../../utils/usePagination'
import { Theme } from '../config/theme'


export const PendingRequest = ({ history }) => {
     const { org, isAdmin } = useAuth()
     const {
          data: requestData,
          options,
          setOptions,
          pageSizes,
          loading,
     } = usePagination(`${ENDPOINT}/requests`, 'all', 'Payment Pending')
     if (loading) {
          return (
               <Box vAlign height={`calc(100vh - 200px)`}>
                    <Box margin="0 auto" align="center">
                         <Loader color={Theme.PrimaryColor} />
                    </Box>
               </Box>
          )
     }

     const handlePagination = (page) => {
          setOptions({
               ...options,
               page: page - 1,
          })
     }


     const handlePageSize = (pageSize) => {
          setOptions({
               ...options,
               size: pageSize.value,
          })
     }
     return (
          <Box pad="20px">
               <Table>
                    <table>
                         <thead>
                              <tr>
                                   <th>Applicant Name</th>
                                   <th>Matriculation Number</th>
                                   <th>Year Of Graduation</th>
                                   <th>Faculty</th>
                                   <th>Department</th>
                                   <th>Course</th>
                                   <th>Type</th>
                                   <th>Action</th>
                              </tr>
                         </thead>
                         <tbody>
                              {requestData?.data
                                   .map(pending => (
                                        <tr key={pending?.id}>
                                             <td>
                                                  {capitalize([
                                                       pending.first_name,
                                                       ...pending.first_name,
                                                  ])}{' '}
                                                  {capitalize([
                                                       pending.last_name,
                                                       ...pending.last_name,
                                                  ])}
                                             </td>
                                             <td>
                                                  {pending.matric_number.toUpperCase()}
                                             </td>
                                             <td>{pending.year}</td>
                                             <td>
                                                  {
                                                       pending?.course
                                                            ?.department
                                                            ?.faculty
                                                            ?.faculty_name
                                                  }
                                             </td>
                                             <td>
                                                  {
                                                       pending.course
                                                            .department
                                                            .department_name
                                                  }
                                             </td>
                                             <td>
                                                  {pending.course.name}
                                             </td>
                                             <td>
                                                  <Tag>
                                                       {
                                                            pending.request_type
                                                       }
                                                  </Tag>
                                             </td>

                                             <td>
                                                  {!isAdmin ? (
                                                       <Button
                                                            color={
                                                                 Theme.PrimaryBlue
                                                            }
                                                            style={{
                                                                 color: Theme.PrimaryBlue,
                                                            }}
                                                            pale
                                                            onClick={() =>
                                                                 history.push(
                                                                      `/${org}/track/${pending.id}`
                                                                 )
                                                            }
                                                       >
                                                            Track
                                                       </Button>
                                                  ) : (
                                                       <Button
                                                            color={
                                                                 Theme.PrimaryBlue
                                                            }
                                                            style={{
                                                                 color: Theme.PrimaryBlue,
                                                            }}
                                                            pale
                                                            onClick={() =>
                                                                 history.push(
                                                                      `/${org}/requests/${pending.id}`
                                                                 )
                                                            }
                                                       >
                                                            View Details
                                                       </Button>
                                                  )}
                                             </td>
                                        </tr>
                                   ))}

                         </tbody>
                    </table>
               </Table>
               <Box>
                    <FlexiPagination
                         pageCounts={pageSizes}
                         total={requestData.totalResults}
                         itemsDisplayed
                         pageSize={options.size}
                         onChange={handlePagination}
                         changePageSize={handlePageSize}
                         current={options.page + 1}
                    />
               </Box>
          </Box>
     )
}

import React from 'react'
import {
    Modal,
    ModalBody,
    Text,
    Box,
    Button,
    Grid,
    Table,
    Tag,
} from 'flexibull2'

import Uploader from '../components/Styles/upload'
import { Theme } from '../config/theme'
import styled from 'styled-components'
import { Select } from 'flexibull2/build/select'

const ResponsiveModalBody = styled(ModalBody)`
    min-height: 50vh;
    max-height: 100vh;
    overflow: auto;
`

const ExcelUploaderCourse = ({
    open,
    setOpen,
    loading,
    current,
    saveFacultyToStore,
    setEdit,
    unZipCoursesFromExcel,
    unZipCoursesFromExcelToServer,
    orgId,
    all_departments,
    type,
}) => {
    // eslint-disable-next-line
    const [encodedFiles, setEncodedFiles] = React.useState([])
    // eslint-disable-next-line
    const [isExcel, setIsExcel] = React.useState(false)
    // eslint-disable-next-line
    const [items, setItems] = React.useState([])
    const [courses, setFaculties] = React.useState([])
    const [ID, setID] = React.useState()

    const [department, setDepartment] = React.useState([])

    const onClose = (e) => {
        setOpen(false)
        setFaculties([])
    }

    let Dept = []

    all_departments &&
        all_departments?.data?.map((department) =>
            Dept.push({
                label: department.department_name,
                subText: department.short_code,
                id: department.department_name,
                did: department.id,
            })
        )

    const saveToStore = () => {
        switch (type) {
            case 'dynamic':
                unZipCoursesFromExcelToServer(courses, orgId, ID)
                break
            case 'local':
                unZipCoursesFromExcel(courses)
                break
            default:
                break
        }

        setFaculties([])
        setOpen(false)
    }
    const handleDepartment = (department) => {
        setDepartment(department)
        const {
            label: {
                props: { did },
            },
        } = department
        setID(did)
    }

    return (
        <div>
            <Modal outerClick open={open}>
                <ResponsiveModalBody
                    style={{ background: '#fff' }}
                    width="600px"
                >
                    <Box pad="20px 40px 0">
                        <Box margin="20px 0 20px 0">
                            <Text size="22px" block>
                                Upload Excel File
                            </Text>
                        </Box>
                        {type === 'dynamic' ? (
                            <Box margin="40px 0 5px 0">
                                <Select
                                    value={department}
                                    onChange={handleDepartment}
                                    elementOptions={Dept}
                                    label="Select Attached Department"
                                    placeholder="Select Department"
                                    spaceBottom
                                    spaceTop
                                    block
                                />
                            </Box>
                        ) : (
                            ''
                        )}
                        <Grid default="1fr">
                            <Box margin="10px 0 70px 0">
                                {courses.length ? (
                                    <Box>
                                        <Table style={{ paddingTop: '10px' }}>
                                            <table>
                                                <thead>
                                                    <tr>
                                                        <th>Course Title</th>

                                                        <th>Short Code</th>
                                                        <th>Status</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {courses?.map((course) => (
                                                        <tr
                                                            key={
                                                                course?.course_name
                                                            }
                                                        >
                                                            <td>
                                                                {course.course_name ||
                                                                    course.name}
                                                            </td>

                                                            <td>
                                                                {
                                                                    course?.short_code
                                                                }
                                                            </td>
                                                            {course?.short_code ? (
                                                                <Tag
                                                                    font="10px"
                                                                    fontColor="#FFF"
                                                                    solid
                                                                    style={{
                                                                        width: '70px',
                                                                        marginTop:
                                                                            '20px',
                                                                        marginRight:
                                                                            '10px',
                                                                    }}
                                                                    height={22}
                                                                    color={
                                                                        Theme.PrimaryGreen
                                                                    }
                                                                >
                                                                    success
                                                                </Tag>
                                                            ) : (
                                                                <Tag
                                                                    font="10px"
                                                                    fontColor="#FFF"
                                                                    solid
                                                                    style={{
                                                                        width: '70px',
                                                                        marginTop:
                                                                            '10px',
                                                                        marginRight:
                                                                            '10px',
                                                                    }}
                                                                    height={22}
                                                                    color={
                                                                        Theme.PrimaryRed
                                                                    }
                                                                >
                                                                    faliled
                                                                </Tag>
                                                            )}
                                                        </tr>
                                                    ))}
                                                </tbody>
                                            </table>
                                        </Table>
                                    </Box>
                                ) : (
                                    <Uploader
                                        type="file"
                                        setEncodedFiles={setEncodedFiles}
                                        isExcel={isExcel}
                                        setItems={setItems}
                                        setFaculties={setFaculties}
                                    />
                                )}
                            </Box>
                        </Grid>
                    </Box>
                    <Box pad="0px 30px 40px 0">
                        <Grid default="2fr 1fr 1fr">
                            <Box
                                style={{
                                    marginLeft: '20px',
                                    marginTop: '-10px',
                                }}
                            ></Box>
                            <Box align="right">
                                <Button
                                    style={{
                                        border: 'none',
                                        textAling: 'center',
                                        color: '#000',
                                    }}
                                    width={90}
                                    height={30}
                                    onClick={onClose}
                                    pale
                                    pad="0 20px"
                                >
                                    Cancel
                                </Button>
                            </Box>
                            <Box align="right">
                                <Button
                                    width={120}
                                    height={30}
                                    pad="0 15px"
                                    spaceRight
                                    onClick={saveToStore}
                                    progress={loading}
                                    disabled={loading}
                                    iconRight={
                                        <i className="flexibull-angle-right" />
                                    }
                                >
                                    {loading ? 'Updating' : 'Upload'}
                                </Button>
                            </Box>
                        </Grid>
                    </Box>
                </ResponsiveModalBody>
            </Modal>
        </div>
    )
}

export default ExcelUploaderCourse

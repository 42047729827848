import { useState, useEffect } from 'react'
import axios from 'axios'
import { Notify } from 'flexibull2/build/notify'
import { TOP_RIGHT, ERROR_ALERT } from './constants'
const usePagination = (url, requestType, status) => {
    const pageSizes = [
        { label: '5 Rows', value: 5 },
        { label: '10 Rows', value: 10 },
        { label: '15 Rows', value: 15 },
        { label: '20 Rows', value: 20 },
    ]
    const [data, setData] = useState({
        data: [],
    })
    const [loading, setLoading] = useState(false)
    const [options, setOptions] = useState({
        size: 10,
        page: 0,
        request_type: requestType || 'all',
    })
    useEffect(() => {
        if (status !== undefined) {
            setOptions({
                ...options,
                status: status
            })
        }
        // eslint-disable-next-line
    }, [status])

    useEffect(() => {
        setLoading(true)
        const stringifyParams = new URLSearchParams(options)
        axios(`${url}?${stringifyParams.toString()}`)
            .then((data) => {
                const modData = {
                    ...data.data,
                    data: data.data.data,
                }
                setData(modData)
                setLoading(false)
            })
            .catch((err) => {  
                Notify(`${err}`, {
                    position: TOP_RIGHT,
                    status: ERROR_ALERT,
                })
                setLoading(false)
            })
        // eslint-disable-next-line
    }, [options, url])

    return {
        data,
        setOptions,
        pageSizes,
        options,
        loading,
    }
}

export default usePagination

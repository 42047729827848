import React, { useState } from 'react'
import {
    Modal,
    ModalBody,
    Text,
    Box,
    Input,
    Button,
    Grid,
} from 'flexibull2'
import { Checkbox } from 'flexibull2/build/checkbox'
import { Select } from 'flexibull2/build/select'
import bankOptions from '../Customer/banks'
import { getBankname } from './Paylog'


const EditBeneficiary = ({
    open,
    setOpen,
    loading,
    current,
    updateFaculty,
    setEdit,
    beneficiary,
    updatePayment,
}) => {
    React.useEffect(() => {
        if (beneficiary && beneficiary) {
            const {
                amount,
                accountName,
                accountNumber,
                bankCode,
                id,
                innerID,
                deduct_from,
                beneficiary_type,
                org,
            } = beneficiary && beneficiary
            setBeneficiaries({
                accountName,
                accountNumber,
                amount,
                bankCode: { value: bankCode, label: getBankname(bankCode) },
                deduct_from,
                beneficiary_type,
                org,
                innerID,
                id,
            })
        }
    }, [beneficiary])
    const [beneficiaries, setBeneficiaries] = useState({
        accountName: '',
        bankCode: '',
        accountNumber: '',
        organisation: '',
        beneficiary_type: '',
        deduct_from: false,
        org: null,
        innerID: null,
        id: null,
        amount: '',
    })
    const {
        accountName,
        accountNumber,
        amount,
        bankCode,
        deduct_from,
        org,
        innerID,
        id,
        beneficiary_type
    } = beneficiaries


    const handleChange = (e) => {
        if (e.value) {
            const { value, label } = e
            setBeneficiaries({
                ...beneficiaries,
                bankCode: { value: value, label: label },
            })
        } else {
            e.target.reset = false
            const { name, value } = e.target
            setBeneficiaries({ ...beneficiaries, [name]: value })
        }
    }
    const handleCheckBox = () => {
        setBeneficiaries({
            ...beneficiaries,
            deduct_from: !deduct_from,
        })
    }
    const UpdateBeneficiaries = () => {
        const data = {
            id,
            account: {
                accountName,
                accountNumber,
                bankCode: bankCode.value,
                organisation: org,
                id:innerID,
            },
            amount,
            deduct_from,
            beneficiary_type,
        }
        updatePayment(data, org, id)
        setOpen(() => false)
    }
    return (
        <div>
            <Modal outerClick open={open}>
                <ModalBody style={{ background: '#fff' }} width="60%">
                    <Box pad="20px 40px 0">
                        <Box margin="20px 0 50px 0">
                            <Text size="22px" block>
                                Edit Beneficiary
                            </Text>
                        </Box>
                        <Box margin="20px 0 50px 0">
                            <Grid
                                default="1fr 1fr 1fr 1fr 1fr 1fr 1fr"
                                gap="20px"
                            >
                                <Input
                                    type="text"
                                    name="accountName"
                                    label="Account Name"
                                    spaceTop
                                    width="170px"
                                    value={accountName}
                                    onChange={handleChange}
                                />
                                <Select
                                    spaceRight
                                    label="Bank"
                                    name="defaultAccount"
                                    block
                                    spaceTop
                                    required
                                    options={bankOptions}
                                    onChange={handleChange}
                                    value={bankCode}
                                    style={{
                                        width: '150px',
                                    }}
                                />
                                <Input
                                    type="number"
                                    name="accountNumber"
                                    label="Account Number"
                                    spaceTop
                                    width="150px"
                                    value={accountNumber}
                                    onChange={handleChange}
                                />
                                <Input
                                    type="number"
                                    name="amount"
                                    block
                                    label="Amount"
                                    spaceTop
                                    value={amount}
                                    onChange={handleChange}
                                    style={{
                                        width: '150px',
                                    }}
                                />

                                <Box
                                    style={{
                                        marginLeft: '1rem',
                                        marginTop: '1.7rem',
                                    }}
                                >
                                    <div>
                                        <Checkbox
                                            onChange={handleCheckBox}
                                            checked={deduct_from}
                                            label="Deductible"
                                        />
                                    </div>
                                </Box>
                            </Grid>
                        </Box>
                    </Box>
                    <Box pad="0px 30px 40px 0">
                        <Grid default="1fr  1fr">
                            <Box align="right">
                                <Button
                                    style={{
                                        border: 'none',
                                        textAling: 'center',
                                        color: '#000',
                                        marginRight: '-16rem',
                                    }}
                                    width={90}
                                    height={30}
                                    onClick={() => setOpen(false)}
                                    pale
                                    pad="0 20px"
                                >
                                    Cancel
                                </Button>
                            </Box>
                            <Box align="right">
                                <Button
                                    width={120}
                                    height={30}
                                    pad="0 15px"
                                    // spaceRight
                                    onClick={UpdateBeneficiaries}
                                    progress={loading}
                                    disabled={loading}
                                    iconRight={
                                        <i className="flexibull-angle-right" />
                                    }
                                >
                                    {loading ? 'Updating' : 'Update'}
                                </Button>
                            </Box>
                        </Grid>
                    </Box>
                </ModalBody>
            </Modal>
        </div>
    )
}

export default EditBeneficiary

import styled from 'styled-components'
import { Text } from 'flexibull2'

export const LightText = styled(Text)`
    margin-bottom: 15px;
    font-size: 12px;
    font-weight: 300;
    font-style: normal;
    word-spacing: normal;
    color: #373737;
`

import {
    GET_COURSES,
    CREATE_COURSE,
    DELETE_COURSE,
    EDIT_COURSE,
    SET_LOADING,
    ERROR,
    SET_CURRENT,
    SET_STATUS,
    MODAL_STATUS,
    CLEAR_STATUS,
    GET_COURSES_FROM_STORE,
    SAVE_COURSES_TO_STORE
} from '../types'


//eslint-disabled-next-line


const CourseReducer = (state, action) => {
    switch (action.type) {
        case GET_COURSES:
            return {
                ...state,
                courses: action.payload,
                loading: false,
            }
        case CREATE_COURSE:
            return {
                ...state,
                courses: {
                    ...state.courses,
                    data: [action.payload, ...state.courses.data],
                },
                loading: false,
                onProgress: false,
            }

        case GET_COURSES_FROM_STORE:
            return {
                ...state,
                coursesStore: action.payload,
                loading: false,
            }
        case SAVE_COURSES_TO_STORE:
            return {
                ...state,
                coursesStore: {
                    ...state.coursesStore,
                    data: [action.payload, ...state.coursesStore.data],
                },
                loading: false,
                onProgress: false,
            }
        case EDIT_COURSE:
            return {
                ...state,
                courses: {
                    ...state.courses,
                    data: state.courses.data.map((course) =>
                        course.id === action.payload.id
                            ? action.payload
                            : course
                    ),
                },
                loading: false,
            }
        case DELETE_COURSE:
            return {
                ...state,
                data: state.courses.data.filter(
                    (course) => course.id !== action.payload
                ),
                loading: false,
            }
        case ERROR:
            return {
                ...state,
                error: action.payload,
                loading: false,
            }
        case SET_LOADING:
            return {
                ...state,
                loading: true,
            }
        case SET_CURRENT:
            return {
                ...state,
                current: action.payload,
                loading: false,
            }
        case SET_STATUS:
            return {
                ...state,
                status: action.payload,
                loading: false,
            }
        case CLEAR_STATUS:
            return {
                ...state,
                status: null,
                loading: false,
            }
        case MODAL_STATUS:
            return {
                ...state,
                modalStats: action.payload,
                onProgress: true,
                loading: false,
            }

        default:
            return state
    }
}

export default CourseReducer;
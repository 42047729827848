import React, { useEffect, useState } from 'react'
import { Box, Grid } from 'flexibull2/build/layout'
// import AutoFields from '../Customer/AutoFields'
import { Theme } from '../config/theme'
// import FreshField from '../Customer/FreshField'
import { Loader } from 'flexibull2/build/loader'
import { Button } from 'flexibull2/build/button'
import { Table } from 'flexibull2/build/table'
import arr from '../Customer/banks'
import { Tag } from 'flexibull2/build/tags'
import { Input } from 'flexibull2/build/input'
import { ColorBox } from '../components/Styles/ColorBox.style'
import EditBeneficiary from './EditBeneficiary'
import AddBeneficiary from './AddBeneficiary'
import { amountValidator } from '../../utils/amountValidator'
import { DropDown } from 'flexibull2/build/dropdown'
import DialogBox from '../components/dialogbox/DialogBox'
import { LightText } from '../components/Styles/LightText.style'
import { Text } from 'flexibull2/build/typo'

export const getBankname = (code) => {
    const res = arr?.filter((bank) => bank?.value === code)
    return res[0]?.label
}
const Paylog = ({
    id,
    payments,
    addPayment,
    updatePayment,
    loading,
    fetchPayment,
    setBeneficiary,
    beneficiary,
    deletePayment
}) => {
    useEffect(() => {
        fetchPayment(id)
        // eslint-disable-next-line
    }, [])

    const [open, setOpen] = useState(false)
    const [add, setAdd] = useState(false)
    const [openDialog, setOpenDialog] = useState(false)
    const [current, setCurrent] = useState(null)

    // function createField(field) {
    //     return (
    //         <AutoFields
    //             payments={field}
    //             loading={loading}
    //             updatePayment={updatePayment}
    //             addPayment={addPayment}
    //             org={id}
    //         />
    //     )
    // }
    const totalTranscript =
        payments &&
        payments.filter((pay) => pay.beneficiary_type === 'transcript')
    let TotalTranscript = 0
    totalTranscript &&
        totalTranscript.forEach((pay) => {
            return (TotalTranscript += pay.amount)
        })

    const totalCertificate =
        payments &&
        payments.filter((pay) => pay.beneficiary_type === 'Certificate')
    let TotalCertificate = 0
    totalCertificate &&
        totalCertificate.forEach((pay) => {
            return (TotalCertificate += pay.amount)
        })

    const totalResult =
        payments &&
        payments.filter((pay) => pay.beneficiary_type === 'Result Statement')
    let TotalResult = 0
    totalResult &&
        totalResult.forEach((pay) => {
            return (TotalResult += pay.amount)
        })

    // function createFields(payments) {
    //     return payments?.map(createField)
    // }

    const updateBeneficiaries = (...args) => {
        if (args) {
            setOpen(true)
            setBeneficiary(args[0])
        }
    }
    const handleDelete = (...args) => {
        if (args) {
            setOpenDialog(true)
            setCurrent(args)
        }
    }
   
    const handleConfirm = () => {
        deletePayment(current[0], current[1])
        setOpenDialog(false)
    }


    return (
        <div>
            <Box>
                <Grid default="1fr 2fr">
                    <Box>
                        <Input
                            name="firstname"
                            type="search"
                            placeholder="search"
                            block
                        />
                    </Box>
                    <Box align="right">
                        <Button
                            width={140}
                            pad="0 1px"
                            onClick={() => setAdd(true)}
                            spaceLeft
                        >
                            Add Payment
                        </Button>
                    </Box>
                </Grid>
            </Box>
            {loading ? (
                <Box vAlign height={`calc(100vh - 200px)`}>
                    <Box margin="0 auto" align="center">
                        <Loader color={Theme.PrimaryColor} />
                    </Box>
                </Box>
            ) : (
                <>
                    {/* <td>
                        <Box pad="25px 0">{createFields(payments)}</Box>
                    </td> */}
                    <Table>
                        <table>
                            <thead>
                                <tr>
                                    <th>Account Name</th>
                                    <th>
                                        <Box
                                            style={{
                                                marginLeft: '3.7rem',
                                            }}
                                        >
                                            Bank
                                        </Box>
                                    </th>
                                    <th>
                                        <Box
                                            style={{
                                                marginLeft: '-0.8rem',
                                            }}
                                        >
                                            Account Number
                                        </Box>
                                    </th>
                                    <th>Amount</th>
                                    <th>
                                        <Box
                                            style={{
                                                marginLeft: '-0.5rem',
                                            }}
                                        >
                                            Beneficiary Type
                                        </Box>
                                    </th>
                                    <th>Deductible</th>
                                    <th>Action</th>
                                </tr>
                            </thead>
                            <tbody>
                                {payments?.map(
                                    ({
                                        id,
                                        amount,
                                        beneficiary_type,
                                        deduct_from,
                                        account,
                                    }) => (
                                        <tr key={id}>
                                            <td>
                                                {account.accountName.toUpperCase()}
                                            </td>
                                            <td>
                                                {getBankname(account.bankCode)}
                                            </td>
                                            <td>{account.accountNumber}</td>
                                            <td>
                                                ₦{' '}
                                                {amountValidator(amount)}
                                            </td>
                                            <td>
                                                <Tag
                                                    autocolor
                                                    small
                                                    fontColor="#FFF"
                                                    dashed
                                                    style={{
                                                        width: '80px',
                                                        height: '20px',
                                                    }}
                                                    color={Theme.PrimaryGreen}
                                                >
                                                    {beneficiary_type ===
                                                        'Result Statement'
                                                        ? beneficiary_type.slice(
                                                            0,
                                                            6
                                                        )
                                                        : beneficiary_type
                                                            .charAt(0)
                                                            .toUpperCase() +
                                                        beneficiary_type.slice(
                                                            1
                                                        )}
                                                </Tag>
                                            </td>
                                            <td>
                                                <Box
                                                    style={{
                                                        marginLeft: '1.2rem',
                                                    }}
                                                >
                                                    {deduct_from ? 'YES' : 'NO'}
                                                </Box>
                                            </td>
                                            <td>
                                                <ColorBox>
                                                    <DropDown
                                                        label={
                                                            <Button
                                                                pale
                                                                height={20}
                                                                style={{
                                                                    border: 'none',
                                                                }}
                                                            >
                                                                <i className="icon-dot-3" />
                                                            </Button>
                                                        }
                                                        trigger="hover"
                                                        menuAlign="top right"
                                                        menuList={[
                                                            {
                                                                onClick: () =>
                                                                    updateBeneficiaries(
                                                                        {
                                                                            accountName:
                                                                                account.accountName,
                                                                            amount,
                                                                            accountNumber:
                                                                                account.accountNumber,
                                                                            bankCode:
                                                                                account.bankCode,
                                                                            id,
                                                                            beneficiary_type,
                                                                            deduct_from,
                                                                            innerID:
                                                                                account.id,
                                                                            org: account.organisation,
                                                                        }
                                                                    )
                                                                ,
                                                                label: 'Edit Beneficiary',
                                                                iconLeft:
                                                                    'icon-edit tblue',
                                                            },
                                                            {
                                                                onClick: () =>
                                                                    handleDelete(
                                                                        account.organisation,
                                                                        id
                                                                    ),
                                                                label: 'Delete Beneficiary',
                                                                iconLeft:
                                                                    'icon-trash tred',
                                                            },
                                                        ]}
                                                        style={{
                                                            cursor: 'pointer',
                                                        }}
                                                    />
                                                </ColorBox>
                                            </td>
                                        </tr>
                                    )
                                )}
                            </tbody>
                        </table>
                    </Table>
                    <AddBeneficiary
                        loading={loading}
                        org={id}
                        addPayment={addPayment}
                        payments={payments}
                        open={add}
                        setOpen={setAdd}
                    />
                    <EditBeneficiary
                        open={open}
                        setOpen={setOpen}
                        beneficiary={beneficiary}
                        updatePayment={updatePayment}
                    />
                    <DialogBox
                        visible={openDialog}
                        okType="danger"
                        okText="Confirm"
                        onConfirm={handleConfirm}
                        onCancel={() => setOpenDialog(false)}
                    >
                        {' '}
                        <LightText>
                            <Text size="14px">
                                Are you sure you want to delete this beneficiary?.
                                <br /> this action cannot be undone.
                            </Text>
                        </LightText>{' '}
                    </DialogBox>
                </>
            )}
        </div>
    )
}

export default Paylog

import React, { useState } from 'react'
import { Box, Text, Button  } from 'flexibull2'
import AuthLayout from '../components/authLayout'
import EmailIcon from '../../assets/mailicon.svg'
import Footer from '../components/footer'
import SafcertLogo from '../../assets/safcert-logo.svg'
import { useHistory } from 'react-router-dom'
import { useAuth } from '../../context/auth-context'
import { resend } from '../PostRegister/services'
import CountdownTimer from './CountdownTimer'

import styled from 'styled-components'

const ResponsiveBox = styled(Box)`
    z-index: 3;
    @media (max-width: 1024px) {
        width: 30%;
        z-index: 9;
    }

    @media (max-width: 768px) {
        width: 40%;
    }

    @media screen and (max-width: 640px) {
        width: 80%;
        margin-top: 45%;
    }
`

const EmailVerification = ({ match }) => {
    const history = useHistory()
    const { org } = useAuth()
    const [loading, setLoading] = useState(false)
    const { params } = match
    const tempUser = JSON.parse(localStorage.getItem('tempUser'))

    const SIXTY_SECONDS_IN_MS = 60000
    const NOW_IN_MS = new Date().getTime()
    const dateTimeAfterThreeDays = NOW_IN_MS + SIXTY_SECONDS_IN_MS

    return (
        <>
            <AuthLayout>
                <Box
                    display="flex"
                    style={{ alignItems: 'center', justifyContent: 'center' }}
                >
                    <ResponsiveBox margin="10% auto">
                        <Box
                            style={{
                                alignItems: 'center',
                                textAlign: 'center',
                                marginBottom: '5rem',
                            }}
                        >
                            <div>
                                <img alt="logo" src={SafcertLogo} />
                            </div>
                        </Box>
                        <Box style={{ display: 'flex' }}>
                            <div style={{ paddingRight: 20 }}>
                                <img alt="" src={EmailIcon} />
                            </div>
                            <div>
                                <Text size="16px">
                                    Email address not verified
                                </Text>
                                <Text size="28px" bold block>
                                    Verify your email to continue
                                </Text>
                            </div>
                        </Box>

                        <Box pad="30px 0" style={{ maxWidth: 400 }}>
                            <Text size="14px">
                                You need to verify your email address before you
                                can login. Follow the link sent to{' '}
                                <Text bold style={{ color: '#D0AE26' }}>
                                    {tempUser?.email || ''}
                                </Text>
                                .
                            </Text>
                        </Box>
                        <Box
                            pad="30px 0"
                            style={{
                                maxWidth: 400,
                                textAlign: 'center',
                                color: 'gray',
                            }}
                        >
                            
                            <CountdownTimer
                                targetDate={dateTimeAfterThreeDays}
                                login={
                                    <Button
                                        height={50}
                                        width={120}
                                        onClick={() => history.push(`${org}/`)}
                                        pale
                                    >
                                        Back to login
                                    </Button>
                                }
                                resendEmail={
                                    <Button
                                        height={50}
                                        width={150}
                                        progress={loading}
                                        spaceRight
                                        spaceLeft
                                        onClick={() =>
                                            resend(params, tempUser, setLoading)
                                        }
                                    >
                                        Resend Link
                                    </Button>
                                }
                            />
                        </Box>
                    </ResponsiveBox>
                </Box>
            </AuthLayout>
            <Footer />
        </>
    )
}

export default EmailVerification

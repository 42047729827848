import React, { useState } from 'react'
import { Modal, ModalBody, Box, Input, Text, Button, Grid } from 'flexibull2'

import { ERROR_ALERT, TOP_RIGHT, SUCCESS } from '../../../utils/constants'
import { Notify } from 'flexibull2/build/notify'
import { AddServiceType } from './services'

const AddService = ({ open, setOpen, getServices, orgId }) => {
    const [values, setValues] = useState({
        service_type_id: null,
        service_type_name: null,
        merchant_id: null,
        error: null,
    })
    const [loading, setLoading] = useState(false)

    const submit = async () => {
        if (values.service_type_name === null) {
            Notify('Please add a service name', {
                position: TOP_RIGHT,
                status: ERROR_ALERT,
            })
            return
        }
        if (values.service_type_id === null) {
            Notify('Service ID is Required', {
                position: TOP_RIGHT,
                status: ERROR_ALERT,
            })
            return
        }
        if (values.merchant_id === null) {
            Notify('Merchant ID is Required', {
                position: TOP_RIGHT,
                status: ERROR_ALERT,
            })
            return
        }
        try {
            setLoading(true)
            await AddServiceType({
                merchant_id: values.merchant_id?.trim(),
                service_type_id: values.service_type_id?.trim(),
                service_type_name: values.service_type_name?.trim(),
                org: orgId,
            })
            setLoading(false)
            getServices(orgId)
            Notify(`Service added successful`, {
                position: TOP_RIGHT,
                status: SUCCESS,
            })
            setValues({
                service_type_id: null,
                service_type_name: null,
                merchant_id: null,
                error: null,
            })
            setOpen(false)
        } catch (error) {
            console.log(error)
            setLoading(false)
            if (
                error.response &&
                error.response.status === 400 &&
                error.response.data &&
                error.response.data.errors
            ) {
                const validationErrors = error.response.data.errors

                for (const validationError of validationErrors) {
                    Notify(`${validationError.defaultMessage}`, {
                        position: TOP_RIGHT,
                        status: ERROR_ALERT,
                    })
                }
            } else {
                console.error(error)
                setLoading(false)
                Notify(`Something went wrong`, {
                    position: TOP_RIGHT,
                    status: ERROR_ALERT,
                })
            }
        }
    }
    const handleValues = (e) => {
        setValues({ ...values, [e.target.name]: e.target.value })
    }

    return (
        <div>
            <Modal outerClick open={open}>
                <ModalBody style={{ background: '#fff' }} width="550px">
                    <Box pad="20px 40px 0">
                        <Box
                            style={{
                                marginBottom: '2rem',
                            }}
                        >
                            <Text size="22px" block>
                                Add Service
                            </Text>
                        </Box>
                        <Grid default="1fr">
                            <Box
                                style={{
                                    marginBottom: '2rem',
                                }}
                            >
                                <Input
                                    label="Service Name"
                                    name="service_type_name"
                                    placeholder="Enter Service Name"
                                    spaceBottom="15px"
                                    block
                                    onChange={handleValues}
                                    width={100}
                                />
                            </Box>
                        </Grid>
                        <Grid default="1fr 1fr">
                            <Box pad=" 0 0 0">
                                <Input
                                    label="Service ID"
                                    name="service_type_id"
                                    placeholder="Enter Service ID"
                                    spaceBottom="15px"
                                    block
                                    onChange={handleValues}
                                />
                            </Box>
                            <Box pad=" 0 0 0">
                                <Input
                                    label="Merchant ID"
                                    name="merchant_id"
                                    placeholder="Enter Merchant ID"
                                    spaceBottom="15px"
                                    block
                                    onChange={handleValues}
                                />
                            </Box>
                        </Grid>
                    </Box>
                    <Box pad="0px 30px 40px 0">
                        <Box align="right">
                            <Button
                                style={{
                                    border: 'none',
                                    textAling: 'center',
                                    color: '#000',
                                }}
                                width={90}
                                onClick={() => setOpen(false)}
                                pale
                                pad="0 20px"
                            >
                                Cancel
                            </Button>
                            <Button
                                width={120}
                                pad="0 15px"
                                spaceRight
                                onClick={submit}
                                iconRight={
                                    <i className="flexibull-angle-right" />
                                }
                                progress={loading}
                            >
                                Submit
                            </Button>
                        </Box>
                    </Box>
                </ModalBody>
            </Modal>
        </div>
    )
}

export default AddService

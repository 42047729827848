import axios, {  UMS_USER_PROFILE_ENDPOINT,} from '../../../utils/httpClient'

export const ORG = localStorage.getItem('e_trans_org')


export const edit = async (body) => {
    let status;
    try {
        const response = await axios.put(`${UMS_USER_PROFILE_ENDPOINT}`, body)
        status = response;
    }  catch ({message}) {
            status = message;
    }
    
    return status;
}

import React from 'react'
import { Box, Text } from 'flexibull2'
import AuthLayout from '../components/authLayout'
import EmailIcon from '../../assets/mailicon.svg'
import Footer from '../components/footer';

import styled from 'styled-components';

const ResponsiveBox = styled(Box)`
z-index: 3;
@media (max-width: 1024px) {
        width: 30%; 
        z-index:9 
    }

    @media (max-width: 768px) {
        width: 40%; 
    }

    @media screen and  (max-width: 640px) {
        width: 80%;
        margin-top: 45%
    }

`

const EmailSent = () => {
    return (
        <>
            <AuthLayout>
                <Box
                    display="flex"
                    style={{ alignItems: 'center', justifyContent: 'center' }}
                >
                    <ResponsiveBox margin="10% auto">
                        <Box style={{ display: 'flex' }}>
                            <div style={{ paddingRight: 20 }}>
                                <img alt="" src={EmailIcon} />
                            </div>
                            <div>
                                <Text size="16px">Link sent</Text>
                                <Text size="28px" bold block>
                                    Check your email
                                </Text>
                            </div>
                        </Box>

                        <Box pad="30px 0">
                            <Text>
                                An email reset link has been sent to abu@gmail.com.
                                follow it to complete the process
                            </Text>
                        </Box>
                    </ResponsiveBox>
                </Box>
            </AuthLayout>
            <Footer />
        </>
    )
}

export default EmailSent

import {
    GET_DEPARTMENTS,
    CREATE_DEPARTMENT,
    DELETE_DEPARTMENT,
    EDIT_DEPARTMENT,
    SET_LOADING,
    ERROR,
    SET_CURRENT,
    SET_STATUS,
    MODAL_STATUS,
    CLEAR_STATUS,
    GET_DEPARTMENTS_FROM_STORE,
    SAVE_DEPARTMENT_TO_STORE,
    GET_ALL_DEPARTMENTS
} from '../types'


//eslint-disabled-next-line


const DepartmentReducer = (state, action) => {
    switch (action.type) {
        case GET_DEPARTMENTS:
            return {
                ...state,
                departments: action.payload,
                loading: false,
            }
        case GET_ALL_DEPARTMENTS:
            return {
                ...state,
                all_departments: action.payload,
                loading: false,
            }
        case CREATE_DEPARTMENT:
            return {
                ...state,
                departments: {
                    ...state.departments,
                    data: [action.payload, ...state.departments.data],
                },
                loading: false,
                onProgress: false,
            }
        case GET_DEPARTMENTS_FROM_STORE:
            return {
                ...state,
                departmentsStore: action.payload,
                loading: false,
            }
        case SAVE_DEPARTMENT_TO_STORE:
            return {
                ...state,
                departmentsStore: {
                    ...state.departmentsStore,
                    data: [action.payload, ...state.departmentsStore.data],
                },
                loading: false,
                onProgress: false,
            }
        case EDIT_DEPARTMENT:
            return {
                ...state,
                departments: {
                    ...state.departments,
                    data: state.departments.data.map((department) =>
                        department.id === action.payload.id
                            ? action.payload
                            : department
                    ),
                },
                loading: false,
            }
        case DELETE_DEPARTMENT:
            return {
                ...state,
                data: state.departments.data.filter(
                    (department) => department.id !== action.payload
                ),
                loading: false,
            }
        case ERROR:
            return {
                ...state,
                error: action.payload,
                loading: false,
            }
        case SET_LOADING:
            return {
                ...state,
                loading: true,
            }
        case SET_CURRENT:
            return {
                ...state,
                current: action.payload,
                loading: false,
            }
        case SET_STATUS:
            return {
                ...state,
                status: action.payload,
                loading: false,
            }
        case CLEAR_STATUS:
            return {
                ...state,
                status: null,
                loading: false,
            }
        case MODAL_STATUS:
            return {
                ...state,
                modalStats: action.payload,
                onProgress: true,
                loading: false,
            }

        default:
            return state
    }
}

export default DepartmentReducer;
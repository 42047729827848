import React, { useState } from 'react'
import { Modal, ModalBody, Box, Input, Text, Button, Grid } from 'flexibull2'

import { TOP_RIGHT, SUCCESS, ERROR_ALERT } from '../../../utils/constants'
import { Notify } from 'flexibull2/build/notify'
import { AddNewMerchant } from './services'

const AddMerchant = ({ open, setOpen, getMerchant, orgId }) => {
    const [values, setValues] = useState({
        api_key: null,
        merchant_id: null,
        error: null,
    })
    const [loading, setLoading] = useState(false)

    const submit = async () => {
        try {
            setLoading(true)
            await AddNewMerchant({
                merchant_id: values.merchant_id,
                api_key: values.api_key,
                institution: orgId,
            })
            setLoading(false)
            getMerchant(orgId)
            Notify(`merchant added successful`, {
                position: TOP_RIGHT,
                status: SUCCESS,
            })
        } catch (error) {
            Notify(`${error.response.data.errors[0].defaultMessage}`, {
                position: TOP_RIGHT,
                status: ERROR_ALERT,
            })
            setLoading(false)
        }
        setOpen(false)
    }
    const handleValues = (e) => {
        setValues({ ...values, [e.target.name]: e.target.value })
    }

    return (
        <div>
            <Modal outerClick open={open}>
                <ModalBody style={{ background: '#fff' }} width="550px">
                    <Box pad="20px 40px 0">
                        <Box
                            style={{
                                marginBottom: '2rem',
                            }}
                        >
                            <Text size="22px" block>
                                Add Merchant
                            </Text>
                        </Box>
                        <Grid default="1fr 1fr">
                            <Box pad=" 0 0 0">
                                <Input
                                    label="Api Key"
                                    name="api_key"
                                    placeholder="Enter Api Key"
                                    spaceBottom="15px"
                                    block
                                    onChange={handleValues}
                                />
                            </Box>
                            <Box pad=" 0 0 0">
                                <Input
                                    label="Merchant ID"
                                    name="merchant_id"
                                    placeholder="Enter Merchant ID"
                                    spaceBottom="15px"
                                    block
                                    onChange={handleValues}
                                />
                            </Box>
                        </Grid>
                    </Box>
                    <Box pad="0px 30px 40px 0">
                        <Box align="right">
                            <Button
                                style={{
                                    border: 'none',
                                    textAling: 'center',
                                    color: '#000',
                                }}
                                width={90}
                                onClick={() => setOpen(false)}
                                pale
                                pad="0 20px"
                            >
                                Cancel
                            </Button>
                            <Button
                                width={120}
                                pad="0 15px"
                                spaceRight
                                onClick={submit}
                                iconRight={
                                    <i className="flexibull-angle-right" />
                                }
                                progress={loading}
                            >
                                Submit
                            </Button>
                        </Box>
                    </Box>
                </ModalBody>
            </Modal>
        </div>
    )
}

export default AddMerchant

import React, { useState } from 'react'
import { useHistory } from 'react-router-dom'
import { useAuth } from '../../context/auth-context'
import { Box, Text, Button, Input, Notify } from 'flexibull2'
import { TOP_RIGHT, ERROR_ALERT } from '../../utils/constants'
import AuthLayout from '../components/authLayout'
import ResetIcon from '../../assets/reset.svg'
import Footer from '../components/footer'
import { resetRequest } from './services'
import styled from 'styled-components'
import { Theme } from '../config/theme'
import validator from 'validator'

const ResponsiveBox = styled(Box)`
    z-index: 3;

    @media (max-width: 1024px) {
        width: 30%;
        z-index: 9;
    }

    @media (max-width: 768px) {
        width: 40%;
    }

    @media screen and (max-width: 640px) {
        width: 80%;
        margin-top: 25%;
    }
`

const Reset = () => {
    const { org } = useAuth()

    const history = useHistory()
    const [email, setEmail] = useState('')
    const [errors, setErrors] = useState('')
    const [loading, setLoading] = useState(false)
    function handleChange(e) {
        var email = e.target.value

        if (validator.isEmail(email)) {
            setErrors('')
        } else {
            setErrors('invalid email address, please enter a valid email.!')
        }
        setEmail(e.target.value.trim(''))
    }
    function submit() {
        if (errors) {
            Notify(`Please enter a valid email address`, {
                position: TOP_RIGHT,
                status: ERROR_ALERT,
            })
            return
        } else {
            const data = {
                username: email,
            }
            resetRequest(data, setLoading, org, history)
        }
    }
    return (
        <>
            <AuthLayout>
                <Box
                    display="flex"
                    style={{ alignItems: 'center', justifyContent: 'center' }}
                >
                    <ResponsiveBox margin="10% auto">
                        <Box display="flex" style={{ alignItems: 'center' }}>
                            <div style={{ paddingRight: 20 }}>
                                <img alt="" src={ResetIcon} />
                            </div>
                            <div>
                                <Text size="14px">Forgotten password</Text>
                                <Text size="24px" bold block>
                                    Reset your password
                                </Text>
                            </div>
                        </Box>

                        <Box pad="30px 0">
                            <Box pad="30px 0 0">
                                <Input
                                    name=""
                                    type="email"
                                    placeholder="Enter email address"
                                    label="Email Address"
                                    width="100%"
                                    value={email}
                                    onChange={handleChange}
                                    spaceTop
                                    required
                                />
                                <Box
                                    pad="8px 0"
                                    style={{
                                        color: 'red',
                                        fontSize: '10px',
                                    }}
                                >
                                    {errors && errors}
                                </Box>
                            </Box>

                            <Button
                                spaceRight
                                iconLeft={
                                    <i className="flexibull-angle-left" />
                                }
                                onClick={() => history.push(`${org}/`)}
                                color={Theme.PrimaryGrey}
                                fontColor={Theme.PrimaryGrey}
                                pale
                            >
                                Cancel
                            </Button>
                            <Button
                                spaceRight
                                iconRight={
                                    <i className="flexibull-angle-right" />
                                }
                                onClick={submit}
                                progress={loading}
                            >
                                Send
                            </Button>
                        </Box>
                    </ResponsiveBox>
                </Box>
            </AuthLayout>
            <Footer />
        </>
    )
}

export default Reset

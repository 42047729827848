import {
    GET_INSTITUTIONS,
    CREATE_INSTITUTION,
    EDIT_INSTITUTION,
    DELETE_INSTITUTION,
    SET_LOADING,
    ERROR,
    SET_CURRENT,
    SET_STATUS,
    MODAL_STATUS,
    CLEAR_STATUS,
    GET_FEES,
    SAVE_FEES,
    GET_INSTITUTION,
    UPDATE_FEES,
    GET_PAYMENTS,
    UPDATE_PAYMENTS,
    GET_SUPPORT_DETAILS,
    SET_BENEFICIARY,
    GET_SERVICES,
    UPDATE_SERVICE,
    DELETE_SERVICE,
    GET_MERCHANT,
} from '../types'

//eslint-disabled-next-line

const InstitutionReducer = (state, action) => {
    switch (action.type) {
        case GET_INSTITUTION:
            return {
                ...state,
                current: action.payload,
                loading: false,
            }
        case GET_INSTITUTIONS:
            return {
                ...state,
                institutions: action.payload,
                loading: false,
            }
        case GET_PAYMENTS:
            return {
                ...state,
                payments: action.payload,
                loading: false,
            }
        case GET_SERVICES:
            return {
                ...state,
                services: action.payload,
                loading: false,
            }
        case GET_MERCHANT:
            return {
                ...state,
                merchants: action.payload,
                loading: false,
            }
        case GET_FEES:
            return {
                ...state,
                fees: action.payload,
                loading: false,
            }

        case GET_SUPPORT_DETAILS:
            return {
                ...state,
                supportDetails: action.payload,
                loading: false,
            }
        case SAVE_FEES:
            return {
                ...state,
                fees: {
                    ...state.fees,
                    fees: [action.payload, ...state.fees],
                },
                loading: false,
                onProgress: false,
            }
        case UPDATE_SERVICE:
            return {
                ...state,
                services: action.payload,
                loading: false,
                onProgress: false,
            }
        case UPDATE_FEES:
            return {
                ...state,
                fees: action.payload,
                loading: false,
                onProgress: false,
            }
        case UPDATE_PAYMENTS:
            return {
                ...state,
                payments: {
                    ...state.payments,
                    data: state.payments.map((pay) =>
                        pay.id === action.payload.id ? action.payload : pay
                    ),
                },
                loading: false,
                onProgress: false,
            }
        case CREATE_INSTITUTION:
            return {
                ...state,
                institutions: {
                    ...state.institutions,
                    data: [action.payload, ...state.institutions.data],
                },
                loading: false,
                onProgress: false,
            }
        case EDIT_INSTITUTION:
            return {
                ...state,
                institutions: {
                    ...state.institutions,
                    institutions: state.institutions.map((institution) =>
                        institution.id === action.payload.id
                            ? action.payload
                            : institution
                    ),
                },
                loading: false,
            }
        case DELETE_INSTITUTION:
            return {
                ...state,
                data: state.institutions.data.filter(
                    (institution) => institution.id !== action.payload
                ),
                loading: false,
            }
        case DELETE_SERVICE:
            return {
                ...state,
                data: state.services.filter(
                    (service) => service.service_type_id !== action.payload
                ),
                loading: false,
            }
        case ERROR:
            return {
                ...state,
                error: action.payload,
                loading: false,
            }
        case SET_LOADING:
            return {
                ...state,
                loading: true,
            }
        case SET_CURRENT:
            return {
                ...state,
                current: action.payload,
                loading: false,
            }
        case SET_BENEFICIARY:
            return {
                ...state,
                beneficiary: action.payload,
                loading: false,
            }
        case SET_STATUS:
            return {
                ...state,
                status: action.payload,
                loading: false,
            }
        case CLEAR_STATUS:
            return {
                ...state,
                status: null,
                loading: false,
            }
        case MODAL_STATUS:
            return {
                ...state,
                modalStats: action.payload,
                onProgress: true,
                loading: false,
            }

        default:
            return state
    }
}

export default InstitutionReducer

import styled from 'styled-components'
import { Box } from 'flexibull2'


export const ColorBox = styled(Box)`
  & .tred{
    color: ${props=> props.theme.PrimaryRed};
  }
  & .tgreen{
    color: ${props=> props.theme.PrimaryGreen};
  }
  & .tblue{
    color: ${props=> props.theme.PrimaryBlue};
  }
  & .tcolor{
    color: ${props=> props.theme.PrimaryColor};
  }
  & .tgrey{
    color: ${props=> props.theme.PrimaryGrey};
  }
`;

export const AlertBox = styled(Box)`
   background-color: #e91e63;
   color: #fff;
   padding: 20px 10px;
   box-shadow: -1px 2px 5px 0px rgba(0,0,0,0.75);
   border-radius: 4px 4px 4px 4px;
   max-width: 860px;


   &.alert-container {
   position: relative;
   max-width: 800px;
   margin: 0 auto;
   }
   &.small-text {
       font-size: 10px;
       text-transform: lowercase;
       font-family: Times, serif;
   }
  
`;
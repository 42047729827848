import React from 'react'
import { Theme } from '../config/theme'
import { Modal, ModalBody, Box, Text, Grid, Button, Table } from 'flexibull2'
import LabelComponent, { InfoComponent } from '../components/LabelComponent'
import styled from 'styled-components'
import { amountValidator } from '../../utils/amountValidator'
import { capitalize } from '../../utils/Capitalize'

const ResponsiveModalBody = styled(ModalBody)`
    @media (max-width: 560px) {
        max-width: 96vw;
    }
`

export const DetailsModal = ({ open, onClose, details }) => {
    const { reference, created_at, transcript_request, amount, payer_phone } =
        details
    const {
        first_name,
        last_name,
        matric_number,
        organisation_id,
        fee,
        delivery_fee,
    } = transcript_request
    return (
        <Modal outerClick open={open}>
            <ResponsiveModalBody
                width="35vw"
                sidedRight
                style={{ background: '#fff' }}
            >
                <Box pad="35px">
                    <Box>
                        <Text size="23px">Transaction Details</Text>
                    </Box>

                    {/* <Box pad="30px 0px">
                        <Tag
                            color={Theme.PrimaryGrey}
                            spaceRight
                            font="12px"
                            fontColor="#FFF"
                            solid
                            style={{ margin: '2px', width: '90px' }}
                        >
                            {reference}
                        </Tag>
                    </Box> */}
                    <Box pad="30px 0px">
                        <Grid default="1fr 1fr">
                            <LabelComponent label="Applicant's name" />
                            <LabelComponent label="Matriculation Number" />
                        </Grid>
                        <Grid default="1fr 1fr">
                            <InfoComponent
                                value={`${capitalize([
                                    first_name,
                                    ...first_name,
                                ])} ${capitalize([last_name, ...last_name])}`}
                            />
                            <InfoComponent
                                value={matric_number.toUpperCase()}
                            />
                        </Grid>
                    </Box>
                    <Box>
                        <Grid default="1fr">
                            <LabelComponent label="Institution Name" />
                        </Grid>
                        <Grid default="1fr">
                            <InfoComponent value={organisation_id} />
                        </Grid>
                    </Box>
                    <Box pad="30px 0px">
                        <Grid default="1fr 1fr 1fr 1fr">
                            <LabelComponent label="Amount Paid" />
                            <LabelComponent label="Date Paid" />
                            <LabelComponent label="Reference Number" />
                            <LabelComponent label="Phone Number" />
                        </Grid>
                        <Grid default="1fr 1fr 1fr 1fr">
                            <InfoComponent
                                value={`₦${' '}${amountValidator(amount)}`}
                            />
                            <InfoComponent
                                value={new Date(created_at)
                                    .toISOString()
                                    .slice(0, 10)}
                            />
                            <InfoComponent value={reference} />
                            <InfoComponent value={payer_phone} />
                        </Grid>
                    </Box>
                    <Box>
                        <Table>
                            <table>
                                <thead>
                                    <tr>
                                        <th>Item Type</th>
                                        <th>Price</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td>Fee</td>
                                        <td>
                                            ₦
                                            {`${
                                                fee === null
                                                    ? 0
                                                    : amountValidator(fee)
                                            }`}
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>Delivery Fee</td>
                                        <td>
                                            ₦
                                            {`${
                                                delivery_fee === null
                                                    ? 0
                                                    : amountValidator(
                                                          delivery_fee
                                                      )
                                            }`}
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </Table>
                    </Box>
                    <Box align="right" pad>
                        <Button
                            color={Theme.PrimaryGrey}
                            fontColor="#000"
                            onClick={() => onClose(false)}
                        >
                            Cancel
                        </Button>
                    </Box>
                </Box>
            </ResponsiveModalBody>
        </Modal>
    )
}

import React, { useState, useContext, useEffect } from 'react'
import { Grid, Box, Input, Button, Text, Loader } from 'flexibull2'
import FacultyTable from '../FacultyTable'
import FacultyContext from '../../../context/Faculty/facultyContext'
import EditFaculty from '../EditFaculty'
import DialogBox from '../../components/dialogbox/DialogBox'
import { LightText } from '../../components/Styles/LightText.style'
import CreateFacultyModal from '../CreateFacultyModal'
import { Theme } from '../../config/theme'
import ExcelUploader from '../ExcelUploader'
import { DYNAMIC } from '../../../utils/constants'

export const FacultyDetails = ({ orgId, institution }) => {
    const [open, setOpen] = useState(false)
    const [openEdit, setEdit] = useState(false)
    const [openDialog, setOpenDialog] = useState(false)
    const [pageNumber, setpageNumber] = useState(1)
    const [openExcel, setOpenExcel] = useState(false)
    const pageSizes = [
        { label: '3 Rows', value: 3 },
        { label: '5 Rows', value: 5 },
        { label: '10 Rows', value: 10 },
        { label: '15 Rows', value: 15 },
        { label: '20 Rows', value: 20 },
    ]
    const [pageOptions, setPageOptions] = useState({
        pageSize: 5,
    })
    const { pageSize } = pageOptions

    const facultyContext = useContext(FacultyContext)

    const {
        faculties,
        getFaculties,
        createFaculty,
        deleteFaculty,
        loading,
        setCurrent,
        current,
        status,
        progressLoading,
        updateFaculty,
        onProgress,
        setLoading,
        unZipFacultiesFromExcelToServer,
    } = facultyContext

    useEffect(() => {
        getFaculties(pageSize, 1, orgId)
        // eslint-disable-next-line
    }, [])
    const onDelete = (id) => {
        setOpenDialog(true)
        setCurrent(id)
    }
    const onEdit = (faculty) => {
        setEdit(true)
        setCurrent(faculty)
    }
    const handleOnConfirm = () => {
        deleteFaculty(current, orgId)
        setOpenDialog(false)
    }
    return (
        <div>
            <Grid default="1fr 2fr">
                <Box pad="25px 0">
                    <Input
                        name="firstname"
                        type="search"
                        placeholder="search"
                        block
                    />
                </Box>
                <Box pad="20px 0" align="right">
                    <Button
                        width={140}
                        pad="0 1px"
                        onClick={() => setOpen(true)}
                        spaceLeft
                    >
                        Add Faculty
                    </Button>
                    <Button
                        width={140}
                        pad="0 1px"
                        onClick={() => setOpenExcel(true)}
                        spaceLeft
                        pale
                    >
                        Upload Excel
                    </Button>
                </Box>
            </Grid>
            {loading ? (
                <Box vAlign height={`calc(100vh - 200px)`}>
                    <Box margin="0 auto" align="center">
                        <Loader color={Theme.PrimaryColor} />
                    </Box>
                </Box>
            ) : (
                <FacultyTable
                    setOpen={setOpen}
                    faculties={faculties}
                    onDelete={onDelete}
                    onEdit={onEdit}
                    pageNumber={pageNumber}
                    setpageNumber={setpageNumber}
                    getFaculties={getFaculties}
                    orgId={orgId}
                    pageSize={pageSize}
                    setPageOptions={setPageOptions}
                    pageOptions={pageOptions}
                    pageSizes={pageSizes}
                    institution={institution}
                />
            )}
            <CreateFacultyModal
                open={open}
                setOpen={setOpen}
                createFaculty={createFaculty}
                progressLoading={progressLoading}
                onProgress={onProgress}
                setLoading={setLoading}
                status={status}
                orgId={orgId}
            />
            <DialogBox
                visible={openDialog}
                okType="danger"
                okText="Confirm"
                onConfirm={handleOnConfirm}
                onCancel={() => setOpenDialog(false)}
            >
                {' '}
                <LightText>
                    <Text size="14px">
                        Are you sure you want to delete this faculty?.
                        <br /> this action cannot be undone.
                    </Text>
                </LightText>{' '}
            </DialogBox>
            <EditFaculty
                open={openEdit}
                onClose={() => setEdit(false)}
                current={current}
                updateFaculty={updateFaculty}
                setEdit={setEdit}
            />
            <ExcelUploader
                open={openExcel}
                setOpen={setOpenExcel}
                unZipFacultiesFromExcelToServer={
                    unZipFacultiesFromExcelToServer
                }
                type={DYNAMIC}
                orgId={orgId}
            />
        </div>
    )
}

import React, { useState, useEffect } from 'react'
import {
    Box,
    Button,
    Wizard,
    ModalBody,
    Modal,
    ModalClose,
    CardSelector,
} from 'flexibull2'
import styled from 'styled-components'
import { Text } from 'flexibull2/build/typo'
import InformationPayment from './InformationPayment'
import Transcript from '../../assets/transcript.svg'
import Payment from './Payment'
const ResponsiveBox = styled(Box)`
    @media (max-width: 560px) {
        padding: 0;
    }
`
export const Previous = styled(Button)`
    position: absolute;
    margin-top: -0.2%;
    right: 6%;
    background-color: #fff;
    color: grey;
    border: none;
    font-weight: 500;
    border-radius: none !important;
    :hover {
        background: none !important;
        border-bottom: 0.1px solid yellow;
    }
`

const MobileWizard = styled(Wizard)`
    & .flexi-wizard-list {
        pointer-events: none;
    }
    @media only screen and (max-width: 600px) {
        & .flexi-wizard-wrapper {
            grid-template-columns: 20px auto;
        }
        & .flexi-wizard-left {
            .flexi-wizard-progress {
                width: 20px;
                transform: translateX(-10px);
                & .flexi-wizard-list {
                    pointer-events: none;
                }
                &:before {
                    display: none;
                }
            }
        }
    }
`

export const RequestWizard = ({
    toggleModal,
    services,
    currentStep,
    setCurrentStep,
    tourStarted,
}) => {
    const [requestType, setRequestType] = useState(null)
    useEffect(() => {
        setCurrentStep(0)
    }, [setCurrentStep])

    const handleNext = () => {
        setCurrentStep((prevStep) => prevStep + 1)
    }
    const handlePrevious = () => {
        if (currentStep > 0) {
            setCurrentStep((prevStep) => prevStep - 1)
        }
    }
    const handleChange = (val) => {
        setCurrentStep(0)
        setRequestType(val)
    }
    return (
        <Modal open={true} center={false}>
            <ModalBody width="97vw">
                <ModalClose onClick={() => toggleModal(false)}>
                    &times;
                </ModalClose>
                <MobileWizard
                    width="auto"
                    title="Document Request"
                    autoProceed={false}
                    view={currentStep}
                    style={{
                        fontSize: '14px',
                        background: '#fff',
                        height: '100%',
                    }}
                >
                    <div title="Select Service">
                        <Box align="center">
                            <Box
                                style={{
                                    marginTop: '4rem',
                                }}
                            >
                                <Box>
                                    <Text size="15px" bold>
                                        Select Service Type
                                    </Text>
                                </Box>
                                <Box>
                                    <Text size="10px" block>
                                        Select what type of service you like to
                                        request
                                    </Text>
                                </Box>
                            </Box>
                            <ResponsiveBox pad="20px">
                                <Box pad="50px">
                                    {services.map((service) =>  (
                                        <span id="certificate">
                                        <CardSelector
                                            size="160px"
                                            name="select"
                                            view="label"
                                            label={service}
                                            iconSize="70px"
                                            style={{
                                                fontSize: '10px',
                                            }}
                                            icon={
                                                <img
                                                    src={Transcript}
                                                    alt="icon"
                                                />
                                            }
                                            onClick={() =>
                                                handleChange(service)
                                            }
                                            type="radio"
                                            value={requestType}
                                        />
                                        </span>
                                    ))}
                                </Box>
                            </ResponsiveBox>
                            <Box style={{ marginTop: '15px' }}>
                                <Button
                                    iconRight={
                                        <i className="flexibull-angle-right" />
                                    }
                                    pad="0 20px"
                                    spaceLeft
                                    spaceRight
                                    height={33}
                                    onClick={handleNext}
                                >
                                    Next
                                </Button>
                            </Box>
                        </Box>
                    </div>
                    <div title="Information">
                        <Box>
                            <InformationPayment
                                handlePrevious={handlePrevious}
                                requestType={requestType}
                                handleNext={handleNext}
                                setCurrentStep={setCurrentStep}
                            />
                        </Box>
                    </div>
                    <div title="Payment">
                        <Payment
                            handlePrevious={handlePrevious}
                            tourStarted={tourStarted}
                        />
                    </div>
                </MobileWizard>
            </ModalBody>
        </Modal>
    )
}

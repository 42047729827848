import { Notify } from 'flexibull2/build/notify'
import { ERROR_ALERT, SUCCESS, TOP_RIGHT } from '../../../../utils/constants'
import axios, {
    UMS_USER_ENDPOINT,
    UMS_ROLE_ID_ENDPOINT,
    ENDPOINT,
} from '../../../../utils/httpClient'

import Cookies from 'js-cookie'
export const ORG = localStorage.getItem('e_trans_org')

export const createUser = async (data, setLoading, setOpen, setUserData) => {
    const token = Cookies.get('e_trans_token')
    const { org } = data

    try {
        setLoading(true)
        await axios.post(`${UMS_USER_ENDPOINT}/${org}`, data, {
            headers: {
                Authorization: `Bearer ${token}`,
            },
        })
        getUsers(org)
        setLoading(false)
        Notify(`User created successfully.`, {
            position: TOP_RIGHT,
            status: SUCCESS,
        })
        setOpen(false)
        setUserData({
            first_name: '',
            last_name: '',
            email: '',
            phone: '',
            contacts: [],
        })
    } catch (error) {
        setLoading(false)
        Notify(`Failed to create user, please try again`, {
            position: TOP_RIGHT,
            status: ERROR_ALERT,
        })
        setOpen(false)
        setUserData({
            first_name: '',
            last_name: '',
            email: '',
            phone: '',
            contacts: [],
        })
        getUsers()
    }
}

export const getUsers = (org) => {
    const token = Cookies.get('e_trans_token')
    return axios.get(`${UMS_USER_ENDPOINT}/${org}?offset=0&limit=100`, {
        headers: {
            Authorization: `Bearer ${token}`,
        },
    })
}
export const getRoleId = (org) => {
    const token = Cookies.get('e_trans_token')
    return axios.get(`${UMS_ROLE_ID_ENDPOINT}${org}/roles`, {
        headers: {
            Authorization: `Bearer ${token}`,
        },
    })
}
export const AddServiceType = (data) => {
    const { org } = data
    const createServicePromise = org
        ? axios.post(`${ENDPOINT}/service-types`, data, {
              headers: {
                  'X-ORG-ID': org,
              },
          })
        : axios.post(`${ENDPOINT}/service-types`, data)

    return createServicePromise
}
export const AddNewMerchant = (data) => {
    const { institution } = data
    const createMerchantPromise = institution
        ? axios.post(`${ENDPOINT}/merchants`, data, {
              headers: {
                  'X-ORG-ID': institution,
              },
          })
        : axios.post(`${ENDPOINT}/merchants`, data)

    return createMerchantPromise
}

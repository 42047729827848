import React, { useReducer } from 'react'
import axios from 'axios'
import { ENDPOINT } from '../../utils/httpClient'
import FacultyContext from './facultyContext'
import FacultyReducer from './facultyReducer'
import { Notify } from 'flexibull2'
import { TOP_RIGHT, ERROR_ALERT, SUCCESS } from '../../utils/constants'

import {
    GET_FACULTIES,
    DELETE_FACULTY,
    EDIT_FACULTY,
    SET_LOADING,
    SET_CURRENT,
    SET_STATUS,
    CLEAR_STATUS,
    MODAL_STATUS,
    ERROR,
    STOP_LOADING,
    CREATE_FACULTY,
    GET_FACULTIES_FROM_STORE,
    GET_ALL_FACULTIES,
} from '../types'

const FacultyState = (props) => {
    const initialState = {
        faculties: null,
        facultyStore: null,
        loading: false,
        current: null,
        status: null,
        modalStats: false,
        onProgress: false,
        error: null,
        all_faculties: null
    }

    const [state, dispatch] = useReducer(FacultyReducer, initialState)

    const getFaculties = async (size, page, org) => {
        const finalPage = page - 1

        try {
            setLoading()
            const getFacultiesPromise = org
                ? axios.get(
                    `${ENDPOINT}/faculties?size=${size}&page=${finalPage}`,
                    {
                        headers: {
                            'X-ORG-ID': org,
                        },
                    }
                )
                : axios.get(
                    `${ENDPOINT}/faculties?size=${size}&page=${finalPage}`
                )

            const res = await getFacultiesPromise

            dispatch({
                type: GET_FACULTIES,

                payload: res.data,
            })
        } catch (error) {
            Notify(`Failed to fetch faculties please try again`, {
                position: TOP_RIGHT,

                status: ERROR_ALERT,
            })
        }
    }
    const getAllFaculties = async (size, page, org) => {
        const finalPage = page - 1

        try {
            setLoading()
            const getFacultiesPromise = org
                ? axios.get(
                    `${ENDPOINT}/faculties?size=${size}&page=${finalPage}`,
                    {
                        headers: {
                            'X-ORG-ID': org,
                        },
                    }
                )
                : axios.get(
                    `${ENDPOINT}/faculties?size=${size}&page=${finalPage}`
                )

            const res = await getFacultiesPromise

            dispatch({
                type: GET_ALL_FACULTIES,

                payload: res.data,
            })
        } catch (error) {
            Notify(`Failed to fetch faculties please try again`, {
                position: TOP_RIGHT,

                status: ERROR_ALERT,
            })
        }
    }

    const createFaculty = async (data, org) => {
        try {
            progressLoading()

            const createFacultiesPromise = org
                ? axios.post(`${ENDPOINT}/faculties`, data, {
                    headers: {
                        'X-ORG-ID': org,
                    },
                })
                : axios.post(`${ENDPOINT}/faculties`, data)

            const res = await createFacultiesPromise
            // axios.post(`${ENDPOINT}/faculties`, data)

            dispatch({
                type: CREATE_FACULTY,

                payload: res.data,
            })

            Notify(`Faculty added successfully`, {
                position: TOP_RIGHT,

                status: SUCCESS,
            })
        } catch (error) {
            dispatch({
                type: ERROR,
            })

            Notify(`Failed to create faculty please try again`, {
                position: TOP_RIGHT,

                status: ERROR_ALERT,
            })
        }
    }
    const unZipFacultiesFromExcelToServer = async (data, org) => {
        data.forEach(function (faculty) {
            faculty.organisation_id = org
        })

        try {
            setLoading()

            const unzipFacultiesPromise = org
                ? axios.post(`${ENDPOINT}/faculties/bulk-create`, data, {
                    headers: {
                        'X-ORG-ID': org,
                    },
                })
                : axios.post(`${ENDPOINT}/faculties/bulk-create`, data)

            const res = await unzipFacultiesPromise

            dispatch({
                type: CREATE_FACULTY,
                payload: res.data,
            })
            getFaculties(5, 1, org)
            Notify(`Faculty added successfully`, {
                position: TOP_RIGHT,

                status: SUCCESS,
            })
        } catch (error) {
            dispatch({
                type: ERROR,
            })

            Notify(`Failed to create faculty please try again`, {
                position: TOP_RIGHT,

                status: ERROR_ALERT,
            })
        }
    }

    //Save to local storage

    const getFacultiesFromStore = async () => {
        try {
            setLoading()
            const res = JSON.parse(localStorage.getItem('faculties'))

            dispatch({
                type: GET_FACULTIES_FROM_STORE,
                payload: res,
            })
        } catch (error) {
            Notify(`Failed to fetch faculties please try again`, {
                position: TOP_RIGHT,
                status: ERROR_ALERT,
            })
        }
    }
    const saveFacultyToStore = async (faculties) => {
        try {
            let tasks = JSON.parse(localStorage.getItem('faculties'))
            if (tasks !== null) {
                const original = tasks.map((t) => {
                    return t
                })
                let newArray
                newArray = original.concat(faculties)
                newArray = [faculties, ...original]

                localStorage.setItem('faculties', JSON.stringify(newArray))
                getFacultiesFromStore()
            } else {
                localStorage.setItem('faculties', JSON.stringify(faculties))
            }
            getFacultiesFromStore()
        } catch (error) {
            console.log(error)
        }
    }
    const unZipFacultiesFromExcel = async (faculties) => {
        try {
            let tasks = JSON.parse(localStorage.getItem('faculties'))
            if (tasks !== null) {
                const original = tasks.map((t) => {
                    return t
                })

                let newArray
                newArray = original.concat(faculties)
                console.log(newArray)
                localStorage.setItem('faculties', JSON.stringify(newArray))
            } else {
                localStorage.setItem('faculties', JSON.stringify(faculties))
            }
            getFacultiesFromStore()
        } catch (error) {
            console.log(error)
        }
    }
    const updateFaculty = async (data) => {
        try {
            setLoading()
            const res = await axios.put(
                `${ENDPOINT}/faculties/${data.id}`,
                data
            )
            dispatch({
                type: EDIT_FACULTY,
                payload: res.data,
            })
            Notify(`Faculty Updated Successfully`, {
                position: TOP_RIGHT,
                status: SUCCESS,
            })
        } catch (error) {
            Notify(`Failed to edit faculty please try again`, {
                position: TOP_RIGHT,
                status: ERROR_ALERT,
            })
        }
    }
    // Remove faculty
    const deleteFaculty = async (id, org) => {
        try {
            setLoading()
            const res = await axios.delete(`${ENDPOINT}/faculties/${id}`)
            dispatch({
                type: DELETE_FACULTY,
                payload: id,
            })
            setError(res.message)
            setStatus(res.status)
            getFaculties(5, 1, org)
            Notify(`Faculty Deleted Successfully`, {
                position: TOP_RIGHT,
                status: SUCCESS,
            })
        } catch (error) {
            getFaculties(5, 1, org)
            Notify(`${error.response.data.message}`, {
                position: TOP_RIGHT,
                status: ERROR_ALERT,
            })
        }
    }
    const editFacultyFromStore = async (faculty, current) => {
        try {
            let tasks
            if (localStorage.getItem('faculties') === null) {
                tasks = []
            } else {
                tasks = JSON.parse(localStorage.getItem('faculties'))
            }
            const index = tasks.findIndex(function (task) {
                return task.name === current.name
            })

            if (~index) {
                tasks[index] = faculty
                localStorage.setItem('faculties', JSON.stringify(tasks))
                getFacultiesFromStore()
            }
            // console.log()
        } catch (error) {
            console.log(error)
        }
    }

    const deleteFacultyFromStore = async (name) => {
        try {
            let tasks
            if (localStorage.getItem('faculties') === null) {
                tasks = []
            } else {
                tasks = JSON.parse(localStorage.getItem('faculties'))
            }

            const res = tasks.filter((task) => task.name !== name)
            localStorage.setItem('faculties', JSON.stringify(res))
            getFacultiesFromStore()
        } catch (error) {
            console.log(error)
        }
    }

    const setLoading = () => {
        dispatch({ type: SET_LOADING })
    }
    const stopLoading = () => {
        dispatch({ type: STOP_LOADING })
    }
    const progressLoading = () => {
        dispatch({ type: MODAL_STATUS })
    }

    const setCurrent = (cur) => dispatch({ type: SET_CURRENT, payload: cur })
    const setError = (err) => dispatch({ type: ERROR, payload: err })

    const setStatus = (status) =>
        dispatch({ type: SET_STATUS, payload: status })

    const clearStatus = () => {
        dispatch({ type: CLEAR_STATUS })
    }
    const handleModalStatus = (stats) => {
        setLoading()
        dispatch({ type: MODAL_STATUS, payload: stats })
    }

    return (
        <FacultyContext.Provider
            value={{
                faculties: state.faculties,
                all_faculties: state.all_faculties,
                facultyStore: state.facultyStore,
                getFaculties,
                createFaculty,
                deleteFaculty,
                loading: state.loading,
                current: state.current,
                status: state.status,
                modalStats: state.modalStats,
                onProgress: state.onProgress,
                setStatus,
                setCurrent,
                handleModalStatus,
                clearStatus,
                updateFaculty,
                progressLoading,
                setLoading,
                stopLoading,
                getFacultiesFromStore,
                saveFacultyToStore,
                deleteFacultyFromStore,
                editFacultyFromStore,
                unZipFacultiesFromExcel,
                unZipFacultiesFromExcelToServer,
                getAllFaculties
            }}
        >
            {props.children}
        </FacultyContext.Provider>
    )
}

export default FacultyState

import React, { useState } from 'react'
import { useAuth } from '../../context/auth-context'
import { useLocation, useHistory } from 'react-router-dom'
import { Box, Text, Button, Input, Notify } from 'flexibull2'
import AuthLayout from '../components/authLayout'
import ResetIcon from '../../assets/reset.svg'
import { TOP_RIGHT, SUCCESS } from '../../utils/constants'
import styled from 'styled-components';

import Footer from '../components/footer'
import { reset } from './services'


const ResponsiveBox = styled(Box)`
    width: 400px;
    z-index: 3;

    @media (max-width: 1024px) {
            width: 40%;
    }
    @media (max-width: 769px) {
            width: 50%;
    }
    @media screen and  (max-width: 640px) {
            width: 80%;
            margin-top: 25%
    }
 
`

const Reset = () => {
    const history = useHistory()
    const { org } = useAuth()

    const params = new URLSearchParams(useLocation().search)
    const token = params.get('token')
    const userId = params.get('user')
    const [state, setState] = useState({
        password: '',
        confirmPassword: '',
    })

    function handleChange(e) {
        setState({
            ...state,
            [e.target.name]: e.target.value,
        })
    }
    function submit() {
        if (state.password === state.confirmPassword) {
            reset({
                password: state.password,
                token,
                user_id: userId,
            }).then(() => {
                Notify(`Password Reset Successfully`, {
                    position: TOP_RIGHT,
                    status: SUCCESS,
                })
                history.push(`${org}/`)
            })
        }
    }
    return (
        <>
            <AuthLayout>
                <Box
                    display="flex"
                    style={{ alignItems: 'center', justifyContent: 'center' }}
                >
                    <ResponsiveBox  margin="10% auto"> 
                        <Box display="flex" style={{ alignItems: "center" }}>
                            <div style={{ paddingRight: 20 }}>
                                <img alt="" src={ResetIcon} />
                            </div>
                            <div>
                                <Text size="16px">Forgotten password</Text>
                                <Text size="24px" bold block>
                                    Reset your password
                                </Text>
                            </div>
                        </Box>

                        <Box pad="30px 0">
                            <Box pad="30px 0 0">
                                <Input
                                    name="password"
                                    type="password"
                                    placeholder="Enter password"
                                    label="Enter Password"
                                    width="100%"
                                    value={state.password}
                                    onChange={handleChange}
                                    spaceTop
                                    spaceBottom
                                />
                            </Box>
                            <Box pad="30px 0 22px">
                                <Input
                                    name="confirmPassword"
                                    type="password"
                                    placeholder="Confirm password"
                                    label="Confirm Password"
                                    width="100%"
                                    value={state.confirmPassword}
                                    onChange={handleChange}
                                    spaceTop
                                    spaceBottom
                                />
                            </Box>
                            <Button
                                spaceRight
                                iconRight={
                                    <i className="flexibull-angle-right" />
                                }
                                onClick={submit}
                            >
                                Send
                            </Button>
                            <Button
                                spaceRight
                                iconRight={
                                    <i className="flexibull-angle-right" />
                                }
                                onClick={() => history.push('/login')}
                                pale
                            >
                                Cancel
                            </Button>
                        </Box>
                    </ResponsiveBox>
                </Box>
            </AuthLayout>
            <Footer />
        </>
    )
}

export default Reset

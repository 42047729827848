import axios, { ENDPOINT } from '../../../utils/httpClient'

export const getFinancials = (financialObject, org, ...rest) => {
    const dateKeys = () => {
        return rest.length > 0 && rest[0].startYear ? { ...rest[0] } : false
    }
    const res = dateKeys()
    const { duration, durationValue } = financialObject
    return axios.get(
        `${ENDPOINT}/dashboard/income/stats?duration=${duration}&duration_value=${durationValue}${
            org ? `&organisation=${org}` : ''
        }${
            res?.startYear
                ? `&start_date=${res.day}-${res.mon}-${res.startYear}&end_date=${res.dayend}-${res.monend}-${res.endYear}`
                : ''
        }`
    )
}

import { useEffect, useState } from 'react'

const useCountdown = (targetDate) => {
    const countDownDate = new Date(targetDate).getTime()

    const [countDown, setCountDown] = useState(
        countDownDate - new Date().getTime()
    )
    const [startCountDown, setStartCountDown] = useState(true)

    useEffect(() => {
       if(startCountDown === true) {
           const interval = setInterval(() => {
               setCountDown(countDownDate - new Date().getTime())
           }, 1000)

           return () => clearInterval(interval)
       } else {
           setStartCountDown(false)
        }
    }, [countDownDate, startCountDown])

    return getReturnValues(countDown)
}

const getReturnValues = (countDown) => {
    const seconds = Math.floor((countDown % (1000 * 60)) / 1000)

    return [seconds]
}

export { useCountdown }

import React from 'react'
import {
    Modal,
    ModalBody,
    Text,
    Box,
    Button,
    Grid,
    Table,
    Tag,
} from 'flexibull2'
import Uploader from '../components/Styles/upload'
import { Theme } from '../config/theme'
import styled from 'styled-components'
import { Select } from 'flexibull2/build/select'

const ResponsiveModalBody = styled(ModalBody)`
    min-height: 50vh;
    max-height: 100vh;
    overflow: auto;
`

const ExcelUploaderDepartment = ({
    open,
    setOpen,
    loading,
    current,
    saveFacultyToStore,
    setEdit,
    unZipDepartmentsFromExcel,
    type,
    all_faculties,
    orgId,
    unZipDepartmentsFromExcelToServer,
}) => {
    // eslint-disable-next-line
    const [encodedFiles, setEncodedFiles] = React.useState([])
    // eslint-disable-next-line
    const [isExcel, setIsExcel] = React.useState(false)
    const [ID, setID] = React.useState()
    // eslint-disable-next-line
    const [items, setItems] = React.useState([])
    const [departments, setDepartments] = React.useState([])
    const [faculty, setFacultis] = React.useState(null)

    const onClose = (e) => {
        setOpen(false)
        setDepartments([])
    }

    const saveToStore = () => {
        switch (type) {
            case 'dynamic':
                unZipDepartmentsFromExcelToServer(departments, orgId, ID)
                break
            case 'local':
                unZipDepartmentsFromExcel(departments)
                break
            default:
                break
        }
        setDepartments([])
        setOpen(false)
    }
    const handleFaculty = (faculty) => {
        setFacultis(faculty)
        const {
            label: {
                props: { fid },
            },
        } = faculty
        setID(fid)
    }

    let Values = []

    all_faculties &&
        all_faculties.data?.map((faculty) =>
            Values.push({
                label: faculty.faculty_name,
                subText: faculty.short_code,
                id: faculty.faculty_name,
                fid: faculty.id,
            })
        )

    return (
        <div>
            <Modal outerClick open={open}>
                <ResponsiveModalBody
                    style={{ background: '#fff' }}
                    width="600px"
                >
                    <Box pad="20px 40px 0">
                        <Box margin="20px 0 30px 0">
                            <Text size="22px" block>
                                Upload Excel File
                            </Text>
                        </Box>
                        {type === 'dynamic' ? (
                            <Box margin="40px 0 5px 0">
                                <Select
                                    value={faculty}
                                    onChange={handleFaculty}
                                    elementOptions={Values}
                                    label="Select Attached Faculty"
                                    placeholder="Select Faculty"
                                    spaceBottom
                                    spaceTop
                                    block
                                />
                            </Box>
                        ) : (
                            ''
                        )}
                        <Grid default="1fr">
                            <Box margin="10px 0 70px 0">
                                {departments.length ? (
                                    <Box>
                                        <Table style={{ paddingTop: '10px' }}>
                                            <table>
                                                <thead>
                                                    <tr>
                                                        <th>
                                                            Department Title
                                                        </th>
                                                        <th>Short Code</th>
                                                        <th>Status</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {departments?.map(
                                                        (department) => (
                                                            <tr
                                                                key={
                                                                    department.department_name
                                                                }
                                                            >
                                                                <td>
                                                                    {department?.department_name ||
                                                                        department?.name}
                                                                </td>
                                                                <td>
                                                                    {
                                                                        department?.short_code
                                                                    }
                                                                </td>
                                                                {
                                                                  department?.short_code ? (
                                                                    <Tag
                                                                        font="10px"
                                                                        fontColor="#FFF"
                                                                        solid
                                                                        style={{
                                                                            width: '70px',
                                                                            marginTop:
                                                                                '20px',
                                                                            marginRight:
                                                                                '10px',
                                                                        }}
                                                                        height={
                                                                            22
                                                                        }
                                                                        color={
                                                                            Theme.PrimaryGreen
                                                                        }
                                                                    >
                                                                        success
                                                                    </Tag>
                                                                ) : (
                                                                    <Tag
                                                                        font="10px"
                                                                        fontColor="#FFF"
                                                                        solid
                                                                        style={{
                                                                            width: '70px',
                                                                            marginTop:
                                                                                '10px',
                                                                            marginRight:
                                                                                '10px',
                                                                        }}
                                                                        height={
                                                                            22
                                                                        }
                                                                        color={
                                                                            Theme.PrimaryRed
                                                                        }
                                                                    >
                                                                        faliled
                                                                    </Tag>
                                                                )}
                                                            </tr>
                                                        )
                                                    )}
                                                </tbody>
                                            </table>
                                        </Table>
                                    </Box>
                                ) : (
                                    <Uploader
                                        type="file"
                                        setEncodedFiles={setEncodedFiles}
                                        isExcel={isExcel}
                                        setItems={setItems}
                                        setDepartments={setDepartments}
                                    />
                                )}
                            </Box>
                        </Grid>
                    </Box>
                    <Box pad="0px 30px 40px 0">
                        <Grid default="2fr 1fr 1fr">
                            <Box
                                style={{
                                    marginLeft: '20px',
                                    marginTop: '-10px',
                                }}
                            ></Box>
                            <Box align="right">
                                <Button
                                    style={{
                                        border: 'none',
                                        textAling: 'center',
                                        color: '#000',
                                    }}
                                    width={90}
                                    height={30}
                                    onClick={onClose}
                                    pale
                                    pad="0 20px"
                                >
                                    Cancel
                                </Button>
                            </Box>
                            <Box align="right">
                                <Button
                                    width={120}
                                    height={30}
                                    pad="0 15px"
                                    spaceRight
                                    onClick={saveToStore}
                                    progress={loading}
                                    disabled={loading}
                                    iconRight={
                                        <i className="flexibull-angle-right" />
                                    }
                                >
                                    {loading ? 'Updating' : 'Upload'}
                                </Button>
                            </Box>
                        </Grid>
                    </Box>
                </ResponsiveModalBody>
            </Modal>
        </div>
    )
}

export default ExcelUploaderDepartment

import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import { useHistory } from 'react-router-dom'
import { Box, Text, Notify, Input, Button } from 'flexibull2'
import { useLocation } from 'react-router-dom'
import Footer from '../components/footer'
import AuthLayout from '../components/authLayout'
import { confirm, reset } from './services'
import { TOP_RIGHT, SUCCESS, ERROR_ALERT } from '../../utils/constants'
import Verified from '../../assets/verified.svg'


const ResponsiveBox = styled(Box)`
z-index: 3;
@media (max-width: 1024px) {
        width: 30%; 
        z-index:9 
    }

    @media (max-width: 768px) {
        width: 40%; 
    }

    @media screen and  (max-width: 640px) {
        width: 80%;
        margin-top: 45%
    }

`

const Confirm = ({ match }) => {
    const {
        params: { org },
    } = match

    const history = useHistory()
    const [confirmed, setConfirmed] = useState(false)
    const [passToken, setPassToken] = useState('')
    const [loading, setLoading] = useState('')

    const [state, setState] = useState({
        password: '',
        confirmPassword: '',
    })
    function handleChange(e) {
        setState({
            ...state,
            [e.target.name]: e.target.value,
        })
    }

    const params = new URLSearchParams(useLocation().search)
    const token = params.get('token')
    const user_id = params.get('user')
    useEffect(() => {
        confirm({
            token,
            user_id,
            org,
        })
            .then(({ data }) => {
                Notify(`Email Confirmed successfully`, {
                    position: TOP_RIGHT,
                    status: SUCCESS,
                })
                setConfirmed(true)
                if (data.reset_password) {
                    setPassToken(data.token)
                }
                // history.push(`${org}/login`)
            })
            .catch(() => {
                Notify(`Email Not Confirmed`, {
                    position: TOP_RIGHT,
                    status: ERROR_ALERT,
                })
            })
    }, [history, token, user_id, org])

    function submit() {
        if (state.password === state.confirmPassword) {
            const data = {
                password: state.password,
                token: passToken,
                user_id,
            }
            reset(data, setLoading, history, org)
        }
    }
    return (
        <>
            <AuthLayout>
                <Box
                    display="flex"
                    style={{ alignItems: 'center', justifyContent: 'center' }}
                >
                    {confirmed && !passToken ? (
                        <>
                            <ResponsiveBox margin="10% auto">
                                <Box align='center'>
                                    <div style={{ paddingRight: 20 }}>
                                        <img alt="verified email" src={Verified} />
                                    </div>
                                    <Box pad="30px 0 5px">
                                        <Text size="28px" bold block>
                                            Email Verified
                                        </Text>
                                    </Box>
                                </Box>
                                <Box pad="10px 0 30px" align="center">
                                    <Text style={{ lineHeight: '20px' }} block>
                                        Thank you for signing up, you email address has been verified you <br />can now carry out your certificate request process
                                    </Text>
                                    <Box pad="40px 0 20px">
                                        <Button onClick={() => history.push(`${org}/`)}> Back to login </Button>
                                    </Box>
                                </Box>
                            </ResponsiveBox>
                        </>
                    ) : passToken ? (
                        <Box pad="30px 0">
                            <Box pad="30px 0 0">
                                <Input
                                    name="password"
                                    type="password"
                                    placeholder="Enter password"
                                    label="Enter Password"
                                    width="100%"
                                    value={state.password}
                                    onChange={handleChange}
                                    spaceTop
                                    spaceBottom
                                />
                            </Box>
                            <Box pad="30px 0 22px">
                                <Input
                                    name="confirmPassword"
                                    type="password"
                                    placeholder="Confirm password"
                                    label="Confirm Password"
                                    width="100%"
                                    value={state.confirmPassword}
                                    onChange={handleChange}
                                    spaceTop
                                    spaceBottom
                                />
                            </Box>
                            <Button
                                spaceRight
                                iconRight={
                                    <i className="flexibull-angle-right" />
                                }
                                onClick={submit}
                                progress={loading}
                            >
                                Send
                            </Button>
                        </Box>
                    ) : (
                        <>
                            <Box margin="20% auto">
                                <div style={{ textAlign: 'center' }}>
                                    <Text size="16px">confirming...</Text>
                                </div>
                            </Box>
                        </>
                    )}
                </Box>
            </AuthLayout>
            <Footer />
        </>
    )
}

export default Confirm

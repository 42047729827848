import React, { useState } from 'react'
import { useAuth } from '../../context/auth-context'
import styled from 'styled-components'
import { Box, Text, Button, Input, Grid, Modal, ModalBody } from 'flexibull2'
import { useHistory } from 'react-router-dom'
// import Create from '../../assets/create.svg'
import { register } from './services'
import { validate } from '../../utils/useValidation.js'
import { Notify } from 'flexibull2'
import { TOP_RIGHT, ERROR_ALERT } from '../../utils/constants'
import useEnter from '../../utils/useEnter'
import ShowPwdImg from '../../assets/show-password.svg'
import HidePwdImg from '../../assets/hide-password.svg'
import { Checkbox } from 'flexibull2/build/checkbox'
import { GooogleButton } from '../Login'
import GoogleSVG from '../../assets/g.svg'

const Link = styled.span`
    color: rgb(42, 120, 208);
    font-size: 12px;
    cursor: pointer;
`

const ResponsiveModalBody = styled(ModalBody)`
    height: 90vh;
    overflow-y: scroll;

    @media (max-width: 1024px) {
        max-width: 40vw;
    }

    @media (max-width: 768px) {
        max-width: 70vw;
    }

    @media (max-width: 430px) {
        max-width: 90vw;
    }

    @media (max-width: 320px) {
        width: 100vw;
    }

    .WVfCw .flexi-input-affix-wrapper::after {
        font-family: 'flexibull-2-0';
        opacity: 0.3;
        content: ' ';
        position: absolute;
        right: 10px;
        display: none;

        .hUptyc .flexi-input-affix-wrapper::after {
            font-family: 'flexibull-2-0';
            opacity: 0.3;
            content: ' ';
            position: absolute;
            right: 10px;
            display: none;
        }
    }

    .space-eye {
        position: absolute;
        top: 2.7em;
        right: 10px;
        width: 20px;
        height: 20px;
        cursor: pointer;
    }
`

const ResponsiveModal = styled(Box)`
    @media (max-width: 430px) {
        padding: 20px 10px;
    }
`

const Signup = ({ open }) => {
    const { org } = useAuth()
    const [user, setUser] = useState({
        first_name: '',
        last_name: '',
        email: '',
        phone_number: '',
        password: '',
        password_confirm: '',
    })
    localStorage.setItem('tempUser', JSON.stringify(user))
    const [loading, setLoading] = useState(false)
    const [error, setError] = React.useState({})
    const [isRevealPwd, setIsRevealPwd] = useState(false)
    const googleAuthURL = `https://ums-api.component.flexisaf-stage.com/oauth2/google/authorize?organization=${org}&callback_url=http://localhost:3000/${org}&product=36163e9e-1d43-4bf6-b0d3-83bfa7da097c`

    const history = useHistory()

    function handleSubmit() {
        const { errorObj, errorCount } = validate(user)
        setError(errorObj)
        if (!(errorCount > 0)) {
            setLoading(true)
            register(user)
                .then((data) => {
                    if (data.status === 204) {
                        setLoading(false)
                        history.push({
                            pathname: `/${org}/post-register`,
                            state: { email: user.email },
                        })
                    }
                })
                .catch((err) => {
                    setLoading(false)
                    Notify(err.response.data.error || 'No user found', {
                        position: TOP_RIGHT,
                        status: ERROR_ALERT,
                    })
                })
        }
    }
    useEnter(handleSubmit)
    function handleChange(e) {
        const { name, value } = e.target
        const tempUser = { ...user }
        tempUser[name] = value
        setUser(tempUser)
    }

    return (
        <Modal outerClick open={open} center="false" className="modal-body">
            <ResponsiveModalBody width="30vw">
                <ResponsiveModal
                    display="flex"
                    style={{ alignItems: 'center', justifyContent: 'center' }}
                    pad="20px 30px"
                >
                    <Box width="90%" margin="1% auto">
                        <Box>
                            <GooogleButton
                                style={{
                                    marginTop: '2em',
                                }}
                            >
                                <a
                                    href={`${googleAuthURL}`}
                                    class="button-17"
                                    role="button"
                                >
                                    {' '}
                                    <img
                                        style={{
                                            width: '40px',
                                        }}
                                        alt="logo"
                                        src={GoogleSVG}
                                    />
                                    <span
                                        style={{
                                            margin: '0 auto',
                                            fontWeight: 'bold',
                                        }}
                                    >
                                        Sign up with Google
                                    </span>
                                </a>
                                <div
                                    class="-or"
                                    style={{
                                        marginTop: '10px',
                                    }}
                                >
                                    <span class="-text">Or</span>
                                </div>
                            </GooogleButton>
                            <Box
                                display="flex"
                                style={{ alignItems: 'center' }}
                            >
                                <div>
                                    <Text
                                        size="18px"
                                        bold
                                        block
                                        spaceBottom
                                        style={{
                                            marginBottom: ' 5px',
                                        }}
                                    >
                                        Create a new account
                                    </Text>
                                </div>
                            </Box>
                        </Box>
                        <Box pad="40px 0 20px">
                            <Grid default="1fr 1fr">
                                <Box pad="20px 0">
                                    <Input
                                        name="first_name"
                                        type="text"
                                        placeholder="Enter first name"
                                        label="First Name"
                                        width="100%"
                                        onChange={handleChange}
                                        error={error['first_name']}
                                    />
                                </Box>
                                <Box pad="20px 0">
                                    <Input
                                        name="last_name"
                                        type="text"
                                        placeholder="Enter last name"
                                        label="Last Name"
                                        width="100%"
                                        onChange={handleChange}
                                        error={error['last_name']}
                                    />
                                </Box>
                            </Grid>

                            <Box pad="20px 0">
                                <Input
                                    name="email"
                                    type="email"
                                    placeholder="Enter email address"
                                    label="Email Address"
                                    width="100%"
                                    onChange={handleChange}
                                    error={error['email']}
                                />
                            </Box>
                            <Box pad="20px 0">
                                <Input
                                    name="phone_number"
                                    type="number"
                                    placeholder="Enter phone number"
                                    label="Phone Number"
                                    width="100%"
                                    onChange={handleChange}
                                    error={error['phone_number']}
                                />
                            </Box>
                            <Grid default="1fr 1fr">
                                <Box
                                    pad="20px 0"
                                    style={{
                                        position: 'relative',
                                    }}
                                >
                                    <Input
                                        name="password"
                                        type={isRevealPwd ? 'text' : 'password'}
                                        placeholder="Enter password"
                                        label="Password"
                                        width="100%"
                                        onChange={handleChange}
                                        error={error['password']}
                                    />
                                    <div className="space-eye">
                                        <img
                                            title={
                                                isRevealPwd
                                                    ? 'Hide password'
                                                    : 'Show password'
                                            }
                                            src={
                                                isRevealPwd
                                                    ? HidePwdImg
                                                    : ShowPwdImg
                                            }
                                            onClick={() =>
                                                setIsRevealPwd(
                                                    (prevState) => !prevState
                                                )
                                            }
                                            alt="password-visibility"
                                        />
                                    </div>
                                </Box>
                                <Box
                                    pad="20px 0"
                                    style={{
                                        position: 'relative',
                                    }}
                                >
                                    <Input
                                        name="password_confirm"
                                        type={isRevealPwd ? 'text' : 'password'}
                                        placeholder="Confirm password"
                                        label="Confirm Password"
                                        width="100%"
                                        onChange={handleChange}
                                        error={error['password_confirm']}
                                    />
                                    <div className="space-eye">
                                        <img
                                            title={
                                                isRevealPwd
                                                    ? 'Hide password'
                                                    : 'Show password'
                                            }
                                            src={
                                                isRevealPwd
                                                    ? HidePwdImg
                                                    : ShowPwdImg
                                            }
                                            onClick={() =>
                                                setIsRevealPwd(
                                                    (prevState) => !prevState
                                                )
                                            }
                                            alt="password-visibility"
                                        />
                                    </div>
                                </Box>
                            </Grid>
                            <Box
                                style={{
                                    marginBottom: '5px',
                                }}
                                align="right"
                            >
                                <Checkbox
                                    label={
                                        !isRevealPwd
                                            ? 'Show Password'
                                            : 'Hide Password'
                                    }
                                    onChange={() =>
                                        setIsRevealPwd(!isRevealPwd)
                                    }
                                />
                            </Box>

                            <Button
                                onClick={handleSubmit}
                                spaceRight
                                iconRight={
                                    <i className="flexibull-angle-right" />
                                }
                                progress={loading}
                            >
                                Sign up
                            </Button>
                            <Box margin="20px 0px 0px 0px">
                                <Text>
                                    Already have an account,{' '}
                                    <Link
                                        onClick={() =>
                                            history.push(`${org}/login`)
                                        }
                                    >
                                        sign in now
                                    </Link>
                                </Text>
                            </Box>
                        </Box>
                    </Box>
                </ResponsiveModal>
            </ResponsiveModalBody>
        </Modal>
    )
}

export default Signup

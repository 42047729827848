import React from 'react'

const TourContent = ({ heading, content }) => {
    return (
        <div>
            <h2>{heading}</h2>
            <p>{content}</p>
        </div>
    )
}

export default TourContent

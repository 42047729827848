import axios, { ENDPOINT } from '../../../utils/httpClient'

export const getRequestsData = (financialObject) => {
    const { duration, durationValue } = financialObject
    if (duration === 'All Schools') {
        return axios.get(`${ENDPOINT}/dashboard/requests/stats`)
    }
    return axios.get(
        `${ENDPOINT}/dashboard/requests?duration=${duration}&duration_value=${durationValue}`
    )
}

import React, { useContext, useEffect } from 'react'
import { Box, Grid } from 'flexibull2/build/layout'
import { Table } from 'flexibull2/build/table'
import { Button } from 'flexibull2/build/button'
import { Loader } from 'flexibull2/build/loader'
import { Theme } from '../../config/theme'
import { DropDown } from 'flexibull2/build/dropdown'
import InstitutionContext from '../../../context/institution/institutionContext'
import AddMerchant from './AddMerchant'

const Merchant = ({ orgId, open, setOpen }) => {
    const institutionContext = useContext(InstitutionContext)

    const { loading, getMerchant, merchants } = institutionContext

    useEffect(() => {
        getMerchant(orgId)
        // eslint-disable-next-line
    }, [orgId])

    if (loading) {
        return (
            <Box vAlign height={`calc(100vh - 200px)`}>
                <Box margin="0 auto" align="center">
                    <Loader color={Theme.PrimaryColor} />
                </Box>
            </Box>
        )
    }

    return (
        <>
            <div>
                <Grid default="1fr">
                    <Box pad="20px 0" align="right">
                        <Button
                            width={140}
                            pad="0 1px"
                            onClick={() => setOpen(true)}
                            spaceLeft
                        >
                            Add Merchant
                        </Button>
                    </Box>
                </Grid>
                <Box margin="5px 0 5px 0" align="left">
                    <Table style={{ paddingTop: '10px' }}>
                        <table>
                            <thead>
                                <tr>
                                    <th>Api Key</th>
                                    <th>Merchant ID</th>
                                    <th>Institution</th>
                                    <th>Action</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td>{merchants?.api_key}</td>
                                    <td>{merchants?.merchant_id}</td>
                                    <td>{merchants?.institution}</td>
                                    <td>
                                        <DropDown
                                            label={
                                                <Button
                                                    pale
                                                    height={20}
                                                    style={{
                                                        border: 'none',
                                                    }}
                                                >
                                                    <i className="icon-dot-3" />
                                                </Button>
                                            }
                                            trigger="hover"
                                            menuAlign="top right"
                                            style={{
                                                cursor: 'pointer',
                                            }}
                                        />
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </Table>
                </Box>
                <AddMerchant
                    open={open}
                    orgId={orgId}
                    getMerchant={getMerchant}
                    loading={loading}
                    setOpen={setOpen}
                />
            </div>
        </>
    )
}

export default Merchant

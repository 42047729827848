import { Box, Text, Grid } from 'flexibull2'
import { Theme } from '../../config/theme'
import styled from 'styled-components'

const FooterWrapper = styled.footer`
    position: fixed;
    left: 0;
    bottom: 0;
    width: 100%;
    background: ${Theme.PrimaryFontColor};
    padding: 10px 0;
    z-index: 99;

    @media screen and (max-width: 640px) {
        position: relative;
        padding: 15px 0;
        margin-top: -50px;
    }
`

const ResponsiveBox = styled(Box)`
    @media (max-width: 1024px) {
        width: 80%;
    }

    @media (max-width: 768px) {
        width: 90%;
    }

    @media screen and (max-width: 640px) {
        width: 80%;
    }
`

const Footer = () => {
    function generateCopyright() {
        const year = new Date().getFullYear()
        return `© ${year}`
    }

    return (
        <FooterWrapper>
            <ResponsiveBox centered width="70%" pad="20px 0">
                <Grid default="max-content auto auto" md="1fr">
                    <Box>
                        <Text color="#fff">
                            {generateCopyright()} SAFCerts product of FlexiSAF
                            Edusoft
                        </Text>
                    </Box>
                    <Box />
                    <Box>
                        <Grid default="auto auto auto auto" sm="1fr 1fr">
                            <Box>
                                <Text color="#fff">support@safcerts.com</Text>
                            </Box>
                        </Grid>
                    </Box>
                </Grid>
            </ResponsiveBox>
        </FooterWrapper>
    )
}

export default Footer

import React from 'react'
import {
    Modal,
    ModalBody,
    Box,
    Input,
    Text,
    Button,
    Checkbox,
    Grid,
    Notify,
    Select
} from 'flexibull2'
import { TOP_RIGHT, ERROR_ALERT } from '../../utils/constants'

const CreateDepartmentModal = ({
    open,
    setOpen,
    faculties,
    createDepartment,
    setLoading,
    onProgress,
    all_faculties
}) => {
    const [department, setDepartment] = React.useState({
        department_name: '',
        short_code: '',
        faculty: null,
    })
    const [createAnother, setCreateAnother] = React.useState(true)

    const { department_name, short_code, faculty } = department

    let Values = []

    all_faculties &&
        all_faculties.data?.map((faculty) =>
            Values.push({
                label: faculty.faculty_name,
                subText: faculty.short_code,
                id: faculty.faculty_name,
                fid: faculty.id,
            })
        )

    const handleChange = (e) => {
        const { name, value } = e.target
        setDepartment({ ...department, [name]: value })
    }
    const onCreateAnother = (e) => {
        setOpen(true)
        setCreateAnother(!createAnother)
    }

    const handleFaculty = (faculty) => {
        setDepartment({ ...department, faculty })
    }

    const onCreateDepartment = (e) => {
        e.preventDefault()
        if (department_name === '' || short_code === '') {
            Notify(`Please fill in all the fields and try again`, {
                position: TOP_RIGHT,
                status: ERROR_ALERT,
            })
        } else {
            const { label: { props: { fid } = {} } = {} } = faculty
            const data = { name: department_name, short_code, faculty_id: fid }
            createDepartment(data)
        }
        if (createAnother === false) {
            setLoading()
            setOpen(false)
        }
        setDepartment({ department_name: '', short_code: '', faculty: null })
    }
    return (
        <>
            <Modal outerClick open={open}>
                <ModalBody style={{ background: '#fff' }} width="570px">
                    <Box pad="20px 40px 0">
                        <Box margin="20px 0 20px 0">
                            <Text size="22px" block>
                                Add Department
                            </Text>
                        </Box>
                        <Box margin="20px 0">
                            <Select
                                value={faculty}
                                onChange={handleFaculty}
                                elementOptions={Values}
                                label="Faculty"
                                placeholder="Select Faculty"
                                spaceBottom
                                spaceTop
                                width={260}
                            />
                        </Box>
                        <Grid default="1fr 1fr">
                            <Box margin="0px 0px 25px 0px">
                                <Input
                                    label="Department Title"
                                    name="department_name"
                                    value={department_name}
                                    onChange={handleChange}
                                    placeholder="Department Title"
                                    spaceBottom="45px"
                                    width="280px"
                                />
                            </Box>
                            <Box pad=" 0 0 0">
                                <Input
                                    label="Short Code"
                                    name="short_code"
                                    value={short_code}
                                    onChange={handleChange}
                                    placeholder="Short Code"
                                    width="200px"
                                    spaceBottom="45px"
                                />
                            </Box>
                        </Grid>
                    </Box>
                    <Box pad="0px 30px 40px 0">
                        <Grid default="2fr 1fr 1fr">
                            <Box
                                style={{
                                    marginLeft: '20px',
                                    marginTop: '-10px',
                                }}
                            >
                                <Checkbox
                                    label="Create another"
                                    spaceLeft
                                    onChange={onCreateAnother}
                                    checked={createAnother}
                                />
                            </Box>
                            <Box align="right">
                                <Button
                                    style={{
                                        border: 'none',
                                        textAling: 'center',
                                        color: '#000',
                                    }}
                                    width={90}
                                    onClick={() => setOpen(false)}
                                    pale
                                    pad="0 20px"
                                >
                                    Cancel
                                </Button>
                            </Box>
                            <Box align="right">
                                <Button
                                    width={120}
                                    pad="0 15px"
                                    spaceRight
                                    onClick={onCreateDepartment}
                                    progress={onProgress}
                                    disabled={onProgress}
                                    iconRight={
                                        <i className="flexibull-angle-right" />
                                    }
                                >
                                    {onProgress ? 'Creating' : 'Submit'}
                                </Button>
                            </Box>
                        </Grid>
                    </Box>
                </ModalBody>
            </Modal>
        </>
    )
}

export default CreateDepartmentModal

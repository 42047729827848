import axios, { ENDPOINT } from '../../../utils/httpClient'

export const ORG = localStorage.getItem('e_trans_org')

export const edit = (data, shortName) => {
    const bodyFormData = new FormData()
    bodyFormData.append('full_name', data.full_name)
    bodyFormData.append('location', data.location)
    bodyFormData.append('email_address', data.email_address)
    bodyFormData.append('physical_address', data.physical_address)
    bodyFormData.append('phone_number', data.phone_number)

    return axios.put(`${ENDPOINT}/institutions/${shortName}`, bodyFormData)
}

export const uploadLogo = (logo, shortName) => {
    const bodyFormData = new FormData()
    bodyFormData.append('logo', logo)

    return axios.put(
        `${ENDPOINT}/institutions/${shortName}/update-logo`,
        bodyFormData
    )
}

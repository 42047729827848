import React from 'react'
import { Box, Text, Grid } from 'flexibull2'
import styled from 'styled-components'
import { useMain } from '../../context/main-context'
import Request from '../../assets/request.svg'
import Delivery from '../../assets/delivery.svg'
import { lighten } from 'polished'

import RemitaButton from '../components/RemitaButton'
import { Theme } from '../config/theme'
import { amountValidator } from '../../utils/amountValidator'
import { Button } from 'flexibull2/build/button'

const SummaryBox = styled.div`
    @media (max-width: 768px) {
        left: 0;
        top: 0;
        position: relative;
    }
`
const lightenRed = lighten(0.45, 'red')

export const Alert = styled.div`
    padding: 20px;
    margin-top: 1.5rem;
    margin-bottom: ${(props) =>
        props.marginBottom ? props.marginBottom : '40px'};
    border: 1px solid transparent;
    border-radius: 5px;
    background-color: ${(props) =>
        props.background ? lightenRed : lighten(0.45, Theme.PrimaryBlue)};
    color: ${(props) =>
        props.color
            ? lighten(0.2, props.color)
            : lighten(0.45, Theme.PrimaryBlue)}};
    width: 100%;
`
const Timeline = styled.div`
    margin-left: -1rem;
    ul li {
        list-style-type: none;
        padding: 10px;
        font-size: 12px;
    }
    b {
        margin-right: 10px;
    }
`

const Payment = ({ handlePrevious, tourStarted }) => {
    const { main } = useMain()

    const { paymentInfo: paymentInfoResponse } = main
    const user = JSON.parse(localStorage.getItem('user'))
    const otherValues = JSON.parse(localStorage.getItem('otherValues'))

    return (
        <div
            style={{
                position: 'relative',
            }}
        >
            <SummaryBox pad="10px ">
                <Text size="16px" block bold>
                    Request Summary
                </Text>
                <Alert color={Theme.PrimaryBlue}>
                    <Text size="12px" block bold>
                        NB: This transaction / payment is non-refundable, please
                        make sure all details are correct.
                    </Text>
                </Alert>
                <Grid
                    default="1fr 1fr"
                    style={{ textTransform: 'capitalize', fontWeight: 'bold' }}
                >
                    <Box style={{ marginLeft: '2rem' }}>
                        Request Information
                    </Box>
                    <Box style={{ marginLeft: '2rem' }}>Billings</Box>
                </Grid>
                <Grid default="1fr 1fr">
                    <Timeline style={{ borderRadius: '15px' }}>
                        <ul>
                            <li>
                                <b>Name:</b> {user?.first_name}{' '}
                                {user?.last_name}
                            </li>
                            <li>
                                <b>Faculty:</b> {otherValues?.faculty_id}
                            </li>
                            <li>
                                <b>Department:</b> {otherValues?.department_id}
                            </li>
                            <li>
                                <b>Course:</b> {otherValues?.course_id}
                            </li>
                            <li>
                                <b>Matric Number:</b> {user?.matric_number}
                            </li>
                            <li>
                                <b>Year of Admission:</b> {user?.admission_year}
                            </li>
                            <li>
                                <b>Year of Graduation:</b> {user?.year}
                            </li>
                            <li>
                                <b>Delivery Email:</b> {user?.delivery_email}
                            </li>
                            <li>
                                <b>Phone Number:</b> {user?.phone_number}
                            </li>
                        </ul>
                    </Timeline>
                    <Box
                        pad="30px"
                        width="80%"
                        background={Theme.PrimaryBackground}
                        style={{ borderRadius: '15px', marginTop: '30px' }}
                    >
                        <Grid
                            default="max-content minmax(auto, 400px) minmax(auto, 200px)"
                            style={{ alignItems: 'center' }}
                        >
                            <Box>
                                <Box
                                    height="40px"
                                    width="40px"
                                    display="flex"
                                    background={Theme.PrimaryOrange}
                                    style={{
                                        borderRadius: '10px',
                                        alignItems: 'center',
                                        justifyContent: 'center',
                                    }}
                                >
                                    <img src={Request} alt="request icon" />
                                </Box>
                            </Box>
                            <Box>
                                <Text size="14px">Request Fee</Text>
                            </Box>
                            <Box align="right">
                                <Text size="14px">
                                    &#8358;{' '}
                                    {`${
                                        amountValidator(
                                            paymentInfoResponse.transcript_fee
                                        ) || 0
                                    }`}
                                </Text>
                            </Box>
                        </Grid>
                        <div
                            style={{
                                borderTop: '1px dashed #d1d1d1',
                                margin: '25px 0',
                            }}
                        />
                        <Grid
                            default="max-content minmax(auto, 400px) minmax(auto, 200px)"
                            style={{ alignItems: 'center' }}
                        >
                            <Box>
                                <Box
                                    height="40px"
                                    width="40px"
                                    display="flex"
                                    background={Theme.PrimaryOrange}
                                    style={{
                                        borderRadius: '10px',
                                        alignItems: 'center',
                                        justifyContent: 'center',
                                    }}
                                >
                                    <img src={Delivery} alt="request icon" />
                                </Box>
                            </Box>
                            <Box>
                                <Text size="14px">Delivery Fee</Text>
                            </Box>
                            <Box align="right">
                                <Text size="14px">
                                    &#8358;{' '}
                                    {`${
                                        amountValidator(
                                            paymentInfoResponse?.delivery_charge
                                        ) || 0
                                    }`}
                                </Text>
                            </Box>
                        </Grid>
                        <Box margin="60px 0 10px">
                            <Grid
                                default="minmax(auto, 460px) minmax(auto, 200px)"
                                style={{ alignItems: 'center' }}
                            >
                                <Box margin="0 0 0 60px">
                                    <Text size="14px"> Total Price </Text>
                                </Box>
                                <Box align="right">
                                    <Text size="22px" bold>
                                        &#8358;{' '}
                                        {
                                            (Object.keys(paymentInfoResponse).length !== 0 &&
                                                amountValidator(
                                                    paymentInfoResponse.transcript_fee +
                                                        paymentInfoResponse.delivery_charge
                                                )) || 0
                                        }
                                    </Text>
                                </Box>
                            </Grid>
                        </Box>
                    </Box>
                </Grid>
            </SummaryBox>
            <Box
                style={{
                    display: 'flex',
                    flex: '1',
                    marginTop: '2rem',
                    marginLeft: '1rem',
                }}
            >
                <Box align="left">
                    <Button
                        width={90}
                        height={31}
                        onClick={handlePrevious}
                        pad="0 15px"
                        pale
                        spaceRight
                    >
                        Previous
                    </Button>
                </Box>

                <Box align="left">
                    {(paymentInfoResponse.rrr || tourStarted) && (
                        <RemitaButton {...paymentInfoResponse} />
                    )}
                </Box>
            </Box>
        </div>
    )
}

export default Payment

import React, { useEffect, useState } from 'react'
import { useAuth } from '../../../context/auth-context'
import { Box, Grid, Button, Input, Table } from 'flexibull2'
import { getUsers } from './services'
import { Tag } from 'flexibull2/build/tags'
import { DropDown } from 'flexibull2/build/dropdown'
import { ColorBox } from '../../components/Styles/ColorBox.style'
import { Theme } from '../../config/theme'

export const Admin = ({
    pageSizes,
    pageSize,
    handlePagination,
    handlePageSize,
    pageNumber,
    setOpen,
    school,
}) => {
    const [data, setData] = useState([])
    const { org } = useAuth()
    useEffect(() => {
        getUsers(school ? school : org).then(({ data }) => {
            const formattedData = data.entities
                .filter((item) => {
                    return item.roles.length > 0
                })
                .map((d) => {
                    return {
                        firstName: d.first_name,
                        lastName: d.last_name,
                        status: d.status,
                        email: d.contacts.find(
                            (e) => e.contact_type === 'EMAIL'
                        ),
                    }
                })
            setData(formattedData)
        })
    }, [org, school])
    return (
        <div>
            <Grid default="1fr 2fr">
                <Box pad="25px 0">
                    <Input
                        name="search"
                        type="search"
                        placeholder="search"
                        block
                    />
                </Box>
                <Box pad="20px 0" align="right">
                    <Button
                        width={140}
                        pad="0 1px"
                        onClick={() => setOpen(true)}
                        spaceLeft
                    >
                        Add Admin
                    </Button>
                </Box>
            </Grid>
            <Box margin="5px 0 5px 0" align="left">
                <Table style={{ paddingTop: '10px' }}>
                    <table>
                        <thead>
                            <tr>
                                <th>First Name </th>
                                <th>Last Name</th>
                                <th>Email</th>
                                <th>Status</th>
                                <th>Action</th>
                            </tr>
                        </thead>
                        <tbody>
                            {data?.map((item) => {
                                return (
                                    <tr>
                                        <td>{item?.firstName}</td>
                                        <td>{item?.lastName}</td>
                                        <td>{item?.email?.contact}</td>
                                        <td>
                                            {' '}
                                            <Tag
                                                font="10px"
                                                fontColor="#FFF"
                                                solid
                                                style={{ width: '90px' }}
                                                height={25}
                                                color={
                                                    item.status
                                                        ? Theme.PrimaryGreen
                                                        : Theme.PrimaryGreen
                                                }
                                            >
                                                Active
                                            </Tag>
                                        </td>
                                        <td>
                                            <ColorBox>
                                                <DropDown
                                                    label={
                                                        <Button
                                                            pale
                                                            height={20}
                                                            style={{
                                                                border: 'none',
                                                            }}
                                                        >
                                                            <i className="icon-dot-3" />
                                                        </Button>
                                                    }
                                                    trigger="click"
                                                    menuAlign="top left"
                                                    menuList={[
                                                        {
                                                            label: 'Activate',
                                                            iconLeft:
                                                                'icon-circle tgreen',
                                                        },
                                                        {
                                                            label: 'Deactivate',
                                                            iconLeft:
                                                                'icon-circle tred',
                                                        },
                                                    ]}
                                                    style={{
                                                        cursor: 'pointer',
                                                    }}
                                                />
                                            </ColorBox>
                                        </td>
                                    </tr>
                                )
                            })}
                        </tbody>
                    </table>
                </Table>
                {/* <FlexiPagination
                    pageCounts={10}
                    itemsDisplayed
                    pageSize={10}
                    onChange={handlePagination}
                    changePageSize={handlePageSize}
                    current={pageNumber}
                /> */}
            </Box>
        </div>
    )
}
